import React, {
    useEffect,
    useState,
    //  useEffect
} from 'react';
import {
    Button,
    TextField,
    // IconButton,
} from '@mui/material';
// import { API_URL } from 'src/utils/constants';
// import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { LegalDocumentsUploader } from 'src/components/LegalDocumentUploader';
import { API_URL } from 'src/utils/constants';
export const UploadEmployeeDocuments = ({ isOpen, onClose, employee }) => {
    // const { pathname } = useLocation();
    // const partnerId = pathname.split("/")[2];
    // console.log(partnerId)
    const [selectedEmployee, setSelectedEmployee] = useState(employee);
    const [legalDocuments, setLegalDocuments] = useState(employee?.legalDocuments || []);

    useEffect(() => {
        if (employee) {
            setSelectedEmployee(employee)
            setLegalDocuments(employee?.legalDocuments || [])
        }
    }, [employee])
    const handleSubmit = async () => {
        try {
            const response = await fetch(`${API_URL}/partner/users/${selectedEmployee.id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ updateFields: { ...selectedEmployee, legalDocuments: legalDocuments } }),
            });
            if (response.ok) {
                onClose() // Close the dialog on successful POST
                location.reload();
            } else {
                console.error('Failed to submit employee:', response.statusText);
            }
        } catch (error) {
            console.error('Error submitting employee:', error);
        }
    }
    return (
        <Modal
            open={isOpen}
            onClose={() => { onClose() }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className='absolute flex flex-col py-6 p-4 bg-white rounded-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[500px] max-h-[90vh] overflow-y-scroll'>
                <h1 className='font-bold text-xl mt-2'>Add Employee</h1>
                <div className='grid grid-cols-2 gap-2'>
                    <label className="text-gray-700 font-semibold whitespace-nowrap">
                        Name
                    </label>
                    <label className="text-gray-700 font-semibold whitespace-nowrap">
                        Phone
                    </label>
                    <TextField
                        margin="dense"
                        name="name"
                        placeholder="Employee Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={employee?.name}
                        disabled={true}
                    />
                    <TextField
                        margin="dense"
                        name="phone"
                        placeholder="Employee Phone"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={employee?.phone}
                        disabled={true}
                    />
                </div>
                <span className='bg-black h-px w-full my-4' />
                <LegalDocumentsUploader
                    path='partners/team/'
                    documents={legalDocuments}
                    setDocuments={setLegalDocuments} />
                <Button
                    onClick={handleSubmit}
                    className='mt-4' variant="contained" >
                    Update
                </Button>
            </Box>
        </Modal >
    );
};

UploadEmployeeDocuments.propTypes = {
    employee: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};