/* eslint-disable */

import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Paper,
  Grid,
  Container,
  Typography,
  CardContent,
  Card,
} from '@mui/material';
import Page from 'src/components/Page';
import CreateBookingPage from './CreateBooking';

const generateMongoId = (length) => {
  const a =
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);
  return a.substring(0, length);
};

// The main component to create an order
function CreateOrderPage() {
  const [bookings, setBookings] = useState([]);
  const [guset, setGuest] = useState({ name: '', phone: '' });
  const [error, setError] = useState({ type: '', msg: '' });

  const handleGuestDetailChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      if (value.length <= 10) {
        setGuest((prev) => ({ ...prev, [name]: value.replace(/[^0-9]/g, '') }));
      }
    } else {
      setGuest((prev) => ({ ...prev, [name]: value.replace(/[^a-zA-Z ]/g, '') }));
    }
  };

  useEffect(() => {
    if (error.type.length > 0) {
      setTimeout(() => {
        setError({ type: '', msg: '' });
      }, 5000);
    }
  }, [error])

  const addBookingToOrder = () => {

    // Enable this once the booking is created

    // if (guset.name.length < 1) {
    //   setError({ type: "name", msg: "Please enter a name" })
    //   return
    // }
    // if (guset.phone.length < 10 || guset.phone.length > 10) {
    //   setError({ type: "phone", msg: "Phone number must be 10 digits" })
    //   return
    // }
    setBookings([...bookings, { totalPrice: 10, id: generateMongoId(6) }]);
  };

  const handleSubmitOrder = () => {
    // logic to submit the order
  };

  return (
    <Page title="Create Order">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Create Order
        </Typography>

        <Card>
          <CardContent
            className="cardContent-padding"
            style={{ marginBottom: '40px' }}
          >
            <div style={{ width: '100%', display: 'inline' }}>
              <div
                style={{
                  width: '30%',
                  margin: '0px 20px 0px 0px',
                  display: 'inline-grid',
                }}
              >
                <label>Name</label>
                <input
                  onChange={handleGuestDetailChange}
                  name="name"
                  value={guset.name}
                />
              </div>

              <div
                style={{
                  width: '30%',
                  margin: '0px 20px 0px 0px',
                  display: 'inline-grid',
                }}
              >
                <label>Phone</label>
                <input
                  onChange={handleGuestDetailChange}
                  name="phone"
                  value={guset.phone}
                  type="text"
                />
              </div>
            </div>
            {error.type && <p className='text-red-500 text-xs'>{error.msg}</p>}
          </CardContent>

        </Card>

        <Grid container spacing={2} marginTop={1} marginBottom={1}>
          <Grid
            item
            lg={6}
            xs={12}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Typography variant="h5">Bookings</Typography>
          </Grid>
          <Grid
            item
            lg={6}
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Button onClick={addBookingToOrder}>Add Booking</Button>
          </Grid>
          <Grid item xs={12}>
            {bookings &&
              bookings.map((booking) => (
                <CreateBookingPage id={booking.id}></CreateBookingPage>
              ))}
          </Grid>
        </Grid>

        <Typography variant="h5">
          Total Amount:{'  '}
          {bookings.reduce((accumulator, currentObject) => {
            return accumulator + currentObject.totalPrice;
          }, 0)}{' '}
        </Typography>
      </Container>
    </Page>
  );
}




export default CreateOrderPage;
