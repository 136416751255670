import React, { useEffect, useMemo, useState } from 'react';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    IconButton,
    TextField,
    Menu,
    MenuItem,
    FormControlLabel,
    Switch,
    TablePagination,
    Select,
    InputLabel,
    FormControl,
    OutlinedInput,
    Button,
    Checkbox,
    Box,
    Container,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { API_URL } from 'src/utils/constants';
import { AddOrEditAsset } from './AddOrEditAsset';
import axios from 'axios';
import PageHeading from 'src/components/PageHeading';
import Page from 'src/components/Page';
import { Loading } from 'src/components/Loading';

const createData = (id, name, brand, model, partnerTitle) => (
    { id, name, brand, model, partnerTitle, }
);

const columns = [
    // { id: 'id', label: 'Id', sortable: true, searchable: false },
    { id: 'partnerTitle', label: 'Partner Title', sortable: true, searchable: true },
    { id: 'name', label: 'Name', sortable: true, searchable: true },
    { id: 'brand', label: 'Brand', sortable: true, searchable: true },
    { id: 'model', label: 'Model', sortable: true, searchable: true },
];

function ListAssetsPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [editAsset, setEditAsset] = useState(null);
    const [open, setOpen] = useState(false);
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${API_URL}/admin/mb/assets`);
            const json = response.data || []
            const formattedData = json.map((row) => createData(row.id, row.name, row.brand, row.model, row.partnerTitle));
            setRows(formattedData);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Failed to fetch assets:', error);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
        setEditAsset(null);
    };
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('product');
    const [visibleColumnIds, setVisibleColumnIds] = useState(columns.map((col) => col.id));
    const [columnOrder, setColumnOrder] = useState(columns.map((col) => col.id));
    const [searchTerm, setSearchTerm] = useState('');
    const [dense, setDense] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [categoryFilter, setCategoryFilter] = useState('All');
    const [anchorEl, setAnchorEl] = useState(null);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const handleColumnVisibility = (id) => {
        setVisibleColumnIds((prev) => {
            const newColumnIds = prev.includes(id)
                ? prev.filter((colId) => colId !== id)
                : [...prev, id].sort((a, b) => columns.findIndex(col => col.id === a) - columns.findIndex(col => col.id === b));
            setColumnOrder(newColumnIds);
            return newColumnIds;
        });
    };


    const handleDenseChange = (event) => {
        setDense(event.target.checked);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleCategoryChange = (event) => {
        setCategoryFilter(event.target.value);
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const filteredRows = useMemo(() => {
        return rows.filter((row) => {
            const matchesSearch = Object.keys(row).some(
                (key) => row[key]?.toString().toLowerCase().includes(searchTerm)
            );
            const matchesCategory = categoryFilter === 'All' || row.partnerTitle === categoryFilter;
            return matchesSearch && matchesCategory;
        });
    }, [searchTerm, categoryFilter, rows]);

    const sortedRows = useMemo(() => {
        return filteredRows.sort((a, b) => {
            let aValue = a[orderBy];
            let bValue = b[orderBy];

            // Handle different data types for proper sorting
            if (orderBy === 'date') {
                aValue = new Date(aValue);
                bValue = new Date(bValue);
                // } else if (orderBy === 'stock') {
                //     aValue = parseInt(aValue.replace(/\D/g, ''), 10);
                //     bValue = parseInt(bValue.replace(/\D/g, ''), 10);
            } else {
                aValue = aValue?.toString().toLowerCase();
                bValue = bValue?.toString().toLowerCase();
            }

            if (aValue < bValue) return order === 'asc' ? -1 : 1;
            if (aValue > bValue) return order === 'asc' ? 1 : -1;
            return 0;
        });
    }, [filteredRows, orderBy, order, rows]);

    const paginatedRows = useMemo(() => {
        return sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }, [sortedRows, page, rowsPerPage, filteredRows, orderBy, order, rows]);

    if (isLoading) {
        return <Loading />;
    }
    return (
        <Page title="Assets List">
            <Container>
                <PageHeading heading="Assets List" />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 4, gap: 2 }}>
                    <TextField
                        label="Search"
                        variant="outlined"
                        size="small"
                        onChange={handleSearch}
                    />

                    <FormControl variant="outlined" size="small" sx={{ width: '200px' }}>
                        <InputLabel>Partner</InputLabel>
                        <Select
                            value={categoryFilter}
                            onChange={handleCategoryChange}
                            input={<OutlinedInput label="Category" />}
                        >
                            <MenuItem value="All">All</MenuItem>
                            {Array.from(new Set(rows.map((row) => row.partnerTitle))).map((category) => (
                                <MenuItem key={category} value={category}>
                                    {category}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControlLabel
                        sx={{ m: 0 }}
                        control={<Switch checked={dense} onChange={handleDenseChange} />}
                        label="Dense padding"
                    />
                    <IconButton
                        aria-label="settings"
                        onClick={handleMenuClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        {columns.map((column) => (
                            <MenuItem key={column.id} onClick={() => handleColumnVisibility(column.id)}>
                                <Checkbox
                                    checked={visibleColumnIds.includes(column.id)}
                                />
                                {column.label}
                            </MenuItem>
                        ))}
                    </Menu>
                    <Button variant='contained' color="error" onClick={handleClickOpen}>
                        Add Asset
                    </Button>
                </Box>

                <TableContainer>
                    <Table size={dense ? 'small' : 'medium'}>
                        <TableHead>
                            <TableRow>
                                {columnOrder.map((colId) => {
                                    const column = columns.find((col) => col.id === colId);
                                    if (!column || !visibleColumnIds.includes(column.id)) return null;
                                    return (
                                        <TableCell key={column.id}>
                                            {column.sortable ? (
                                                <TableSortLabel
                                                    active={orderBy === column.id}
                                                    direction={orderBy === column.id ? order : 'asc'}
                                                    onClick={(event) => handleRequestSort(event, column.id)}
                                                >
                                                    {column.label}
                                                </TableSortLabel>
                                            ) : (
                                                column.label
                                            )}
                                        </TableCell>
                                    );
                                })}
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedRows.map((row, index) => (
                                <TableRow key={index} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                                    {columnOrder.map((colId) => {
                                        const column = columns.find((col) => col.id === colId);
                                        if (!column || !visibleColumnIds.includes(column.id)) return null;
                                        return (
                                            <TableCell key={column.id}>{row[column.id]}</TableCell>
                                        );
                                    })}
                                    <TableCell>
                                        <Button variant='contained' color="error" onClick={() => {
                                            setOpen(true);
                                            setEditAsset(row);
                                        }}>
                                            Edit
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 50, 100]}
                    component="div"
                    count={sortedRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            </Container>
            {open && <AddOrEditAsset
                isOpen={open}
                asset={editAsset}
                onClose={() => {
                    setOpen(false);
                    setEditAsset(null)
                }} />}
        </Page >
    );
}



export default ListAssetsPage;

