import './activityList.css'
import PropTypes from 'prop-types';
import React  from 'react';
import { forwardRef } from 'react';
import {
    Card,
    CardHeader,
    CardContent,

  } from '@mui/material';

const ActivityList = forwardRef(({  data }) => {
    // const flag = (data.length === undefined)? true : false;
    const listItems = data.activity?.map((value, index) =>
    <Card key={index} className="max-height">
      <CardHeader title={value.vendorId}  className="header"/>
      <CardContent className='cardContent-padding'>
        <h4>{value.title}</h4>
        <h6>Description</h6>
        <p>{value.desc}</p>
        <h6>Description 2</h6>
        <p>{value.desc2}</p>
        <h6>Highlights</h6>
        <p>{value.highlights}</p>
      </CardContent>
    </Card>
    );
    return (
       <div className="activityListWrapper">
         {listItems}
       </div>
    )
  });

  
  ActivityList.propTypes = {
    data: PropTypes.any
  };
ActivityList.displayName = 'ActivityList';
export default ActivityList;