import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';

const trekDifficulty = [
  {
    label: 'Easy',
    id: 'DIFFICULTY_EASY',
  },
  {
    label: 'Easy - Moderate',
    id: 'DIFFICULTY_EASY_MODERATE',
  },
  {
    label: 'Moderate - Difficult',
    id: 'DIFFICULTY_MODERATE_DIFFICULT',
  },
  {
    label: 'Difficult',
    id: 'DIFFICULTY_DIFFICULT',
  },
];

export const TrekOptions = ({
  handleChange,
  textInput,
  onTrekDifficultyChange,
}) => {
  return (
    <div style={{ width: '100%', display: 'inline' }}>
      <div
        style={{
          width: '30%',
          margin: '0px 20px 0px 0px',
          display: 'inline-grid',
        }}
      >
        <label>Max Altitude In Ft</label>
        <input
          onChange={(e) => handleChange({ ...e, target: { ...e.target, value: e.target.value.replace(/\D/g, '') } })}
          name="additionalInfo.maxAltitude"
          type={'text'}
          value={textInput.additionalInfo?.maxAltitude}
        />
      </div>
      <div
        style={{
          width: '30%',
          margin: '0px 20px 0px 0px',
          display: 'inline-grid',
        }}
      >
        <label>Duration Days</label>
        <input
          onChange={(e) => handleChange({ ...e, target: { ...e.target, value: e.target.value.replace(/\D/g, '') } })}
          name="additionalInfo.durationDays"
          type={'text'}
          value={textInput.additionalInfo?.durationDays}
        />
      </div>
      <div
        style={{
          width: '30%',
          margin: '0px 20px 0px 0px',
          display: 'inline-grid',
        }}
      >
        <label>Total Distance in KM</label>
        <input
          onChange={(e) => handleChange({ ...e, target: { ...e.target, value: e.target.value.replace(/\D/g, '') } })}
          name="additionalInfo.distanceKm"
          type={'text'}
          value={textInput.additionalInfo?.distanceKm}
        />
      </div>
      <label>Difficulty level</label>
      <Autocomplete
        // disablePortal
        id="combo-box-demo"
        options={trekDifficulty}
        sx={{ width: 340 }}
        renderInput={(params) => <TextField {...params} label="" />}
        onChange={onTrekDifficultyChange}
      />
    </div>
  );
};

TrekOptions.propTypes = {
  handleChange: PropTypes.func.isRequired,
  textInput: PropTypes.object.isRequired,
  onTrekDifficultyChange: PropTypes.func.isRequired,
};
