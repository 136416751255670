import './gallery.css'
import PropTypes from 'prop-types';
import React  from 'react';
import { forwardRef } from 'react';
import {
    Card,
    CardHeader,
    CardContent,

  } from '@mui/material';


  
const Gallery = forwardRef(({  data }) => {
    // const flag = (data.length === undefined)? true : false;
    const [bannerInput, setBannerInput] = React.useState([]);

    const imgClicked = (evt) => {
        console.log(evt.target.src)
        bannerInput.concat(evt.target.src)
      setBannerInput(
        bannerInput.concat(evt.target.src)
        
      );
      console.log(bannerInput)
      // datapassBannerData(bannerInput);
      
    }

    const unselect = () =>{
      setBannerInput([])
    }


    const listItems = data.banners?.map((value, index) =>
    <Card key={index}>
      <CardHeader title={value.source}  className="header"/>
      <CardContent className='cardContent-padding'>
                <img
                src={value.url}
                onClick={imgClicked}
                alt=""
                  />
      </CardContent>
    </Card>
    );
    return (
      <div style={{paddingTop: 20}}>
        <h3>Selected Image: {bannerInput.length}</h3>
        <h3 className="unselect" onClick={unselect}>Unselect All</h3>
       <div className="galleyWrapper">
       
         {listItems}
       </div>
      </div>
    )
  });

  
  Gallery.propTypes = {
    passBannerData: PropTypes.any,
    props: PropTypes.any,
    data: PropTypes.any
  };
  Gallery.displayName = 'Gallery';
export default Gallery;