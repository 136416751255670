export const amenities = {
  SAFETY_INSTRUMENT: 'safety-instrument',
  TRAINED_INSTRUCTOR: 'trained-instructor',
  TRANSPORT: 'transport',
  WIFI: 'wifi',
  SWIMMING_POOL: 'swimming-pool',
  BONFIRE: 'bonfire',
  POWER_BACKUP: 'power-backup',
  MEALS: 'meals',

  AIR_CONDITIONING: 'air-conditioning',
  AIR_COOLER: 'air-cooler',
  PORTABLE_FAN: 'portable-fans',
  ROOM_HEATER: 'room-heater',
  ELECTRIC_KETTLE: 'electric-kettle',
  PARKING: 'parking',
  PAID_PARKING: 'paid-parking',
  PETS_ALLOWED: 'pets-allowed',
  LIQUOR_ZONE: 'liquor-zone',

  RIVER_VIEW: 'river-view',
  RIVER_ACCESS: 'river-access',
  MOUNTAIN_VIEW: 'mountain-view',
  BALCONY: 'balcony',
  BEACH_ACCESS: 'beach-access',
  WASHROOM_ATTACHED: 'washroom-attached',

  GEYSER: 'geyser',
  BATHING_HOT_WATER: 'bathing-hot-water',
};

export const activityAmenitiesLabels = {
  [amenities.WIFI]: 'Free Wi-Fi',
  [amenities.SWIMMING_POOL]: 'Swimming pool',
  [amenities.MEALS]: 'Meals',
  [amenities.LIQUOR_ZONE]: 'Liquor zone',

  [amenities.BONFIRE]: 'Bonfire',
  [amenities.POWER_BACKUP]: 'Power backup',
  [amenities.AIR_CONDITIONING]: 'Air conditioning',

  [amenities.PORTABLE_FAN]: 'Portable fans',
  [amenities.PARKING]: 'Free parking',
  [amenities.PAID_PARKING]: 'Paid parking',

  [amenities.PETS_ALLOWED]: 'Pets allowed',
  [amenities.RIVER_VIEW]: 'River view',
  [amenities.RIVER_ACCESS]: 'River access',
  [amenities.BEACH_ACCESS]: 'Beach access',
};

export const stayVariantAmenitiesLabels = {
  [amenities.WASHROOM_ATTACHED]: 'Attached washrooms',
  [amenities.AIR_CONDITIONING]: 'Air conditioning',
  [amenities.PORTABLE_FAN]: 'Portable fans',
  [amenities.AIR_COOLER]: 'Air cooler',

  [amenities.ROOM_HEATER]: 'Heater',
  [amenities.ELECTRIC_KETTLE]: 'Electric Kettle',

  [amenities.RIVER_VIEW]: 'River view',
  [amenities.BALCONY]: 'Balcony access',
  [amenities.GEYSER]: 'Geyser',
  [amenities.BATHING_HOT_WATER]: 'Hot water for bathing',
};
