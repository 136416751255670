import { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton } from '@mui/material';
//
import Iconify from './Iconify';

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    color: theme.palette.text.secondary,
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main
    }
  })
);

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func
};

function NavItem({ item, active }) {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium'
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Iconify
            icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle)
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: 'flex',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'text.disabled',
                        transition: (theme) => theme.transitions.create('transform'),
                        ...(isActiveSub && {
                          transform: 'scale(2)',
                          bgcolor: 'primary.main'
                        })
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle)
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
}

// import { ListItem, } from '@mui/material';
// import { ExpandLess, ExpandMore } from '@mui/icons-material';

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();
  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);
  const partnerId = pathname.split("/")[1] === "partner" ? pathname.split("/")[2] : null;

  const [open, setOpen] = useState({});

  const handleToggle = (title) => {
    setOpen((prevOpen) => ({ ...prevOpen, [title]: !prevOpen[title] }));
  };

  return (
    <Box {...other}>
      <List disablePadding>
        {navConfig.map((item) => {
          const newItem = { ...item, path: item.path.replace(":id", partnerId) };

          const hasChildren = newItem.children && newItem.children.length > 0;

          return (
            <div key={newItem.title}>
              <NavItem
                item={partnerId ? newItem : item}
                active={match}
                onClick={hasChildren ? () => handleToggle(newItem.title) : null}
              />
              {hasChildren && (
                <>
                  <Collapse in={open[newItem.title]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {newItem.children.map((subItem) => (
                        <NavItem
                          key={subItem.title}
                          item={subItem}
                          active={match}
                          sx={{ pl: 4 }} // Indent subitems
                        />
                      ))}
                    </List>
                  </Collapse>
                </>
              )}
            </div>
          );
        })}
      </List>
    </Box>
  );
}


// Define prop types for NavSection
NavSection.propTypes = {
  navConfig: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      icon: PropTypes.element.isRequired,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          path: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
};


// NavSection.propTypes = {
//   navConfig: PropTypes.array
// };

// export default function NavSection({ navConfig, ...other }) {
//   const { pathname } = useLocation();
//   const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);
//   const partnerId = pathname.split("/")[1] === "partner" ? pathname.split("/")[2] : null;
//   return (
//     <Box {...other}>
//       <List disablePadding>
//         {navConfig.map((item) => {
//           const newItem = { ...item, path: item.path.replace(":id", partnerId) }
//           return (
//             <NavItem key={item.title} item={partnerId ? newItem : item} active={match} />
//           )
//         })}
//       </List>
//     </Box>
//   );
// }
