import React, { useEffect, useMemo, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Button,
} from '@mui/material';
import { API_URL } from 'src/utils/constants';
import { PartnerAddOrEditAsset } from './PartnerAddOrEditAsset';
import axios from 'axios';
import { useLocation } from 'react-router-dom';


function PartnerListAssetsPage() {
    const { pathname } = useLocation();
    const partnerId = pathname.split("/")[2];
    // const _user = localStorage.getItem('user');
    // const user = JSON.parse(_user);
    // const partnerId = user.partnerId
    // const [isLoading, setIsLoading] = useState(false);
    const [assets, setAssets] = useState([]);
    const [open, setOpen] = useState(false);
    const [assetId, setAssetId] = useState(null);
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            // Replace 'your_api_endpoint' with the actual endpoint URL
            const response = await axios.get(`${API_URL}/partner/assets?partnerId=${partnerId}`);
            const json = response.data || []
            setAssets(json);
        } catch (error) {
            console.error('Failed to fetch assets:', error);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
        setAssetId(null);
    };
    const Assets = useMemo(() => assets?.map((row) => (
        <TableRow key={row.id}>
            {/* <TableCell component="th" scope="row">{row.id}</TableCell> */}
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.brand}</TableCell>
            {/* <TableCell>{row.model}</TableCell> */}
            <TableCell>
                <Button
                    className="bg-red-400 hover:bg-red-500 text-white px-4 py-2 rounded-lg"
                    onClick={() => {
                        setAssetId(row.id);
                        setOpen(true);
                    }}
                >
                    Edit
                </Button>
            </TableCell>
        </TableRow>
    )), [assets]);

    return (
        <div>
            <TableContainer
                component={Paper}
                sx={{ margin: 'auto', marginTop: 4, boxShadow: 3 }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        p: 2,
                        color: 'primary.main',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    Assets
                    <Button variant="outlined" onClick={handleClickOpen}>
                        Add Asset
                    </Button>
                </Typography>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Asset Name</TableCell>
                            <TableCell>Brand</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {assets.length > 0 ? Assets : <div
                            className='p-4 text-xl font-semibold'
                        >No Assets Found</div>}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Dialog for Adding Partner */}
            {open && (
                <PartnerAddOrEditAsset
                    isOpen={open}
                    assetId={assetId}
                    onClose={() => {
                        setOpen(false);
                    }}
                />
            )}
        </div>
    );
}



export default PartnerListAssetsPage;

