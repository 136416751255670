// component
import Iconify from '../../components/Iconify';
// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
const partnerSidebarConfig = [
  // {
  //   title: 'Home',
  //   path: '/partner/:id/home',
  //   icon: getIcon('eva:shopping-bag-fill'),
  // },
  {
    title: 'Bookings ',
    path: '/partner/:id/bookings',
    icon: getIcon('simple-line-icons:calender'),
  },
  {
    title: 'Ledger ',
    path: '/partner/:id/ledger',
    icon: getIcon('mdi:finance'),
  },
  {
    title: 'Employees ',
    path: '/partner/:id/employees',
    icon: getIcon('mdi:user-outline'),
  },
  {
    title: 'Assets',
    path: '/partner/:id/assets',
    icon: getIcon('eva:shopping-bag-fill'),
  },

  // {
  //   title: 'Ledger',
  //   path: '/partner/:id/ledger',
  //   icon: getIcon('eva:shopping-bag-fill'),
  // },

  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon('eva:lock-fill'),
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon('eva:person-add-fill'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill'),
  // },
];

export default partnerSidebarConfig;
