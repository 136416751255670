import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  TextField,
} from '@mui/material';
import { usePartnerList } from './hooks/usePartnerList';

export const PartnerActivity = ({ onPartnerAcivityChange }) => {
  const [isPartnerActivity, setIsPartnerActivity] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const partnerList = usePartnerList();

  useEffect(() => {
    if (!isPartnerActivity) {
      onPartnerAcivityChange(isPartnerActivity, null);
    }

    if (isPartnerActivity && selectedPartner) {
      onPartnerAcivityChange(isPartnerActivity, selectedPartner);
    }
  }, [selectedPartner, isPartnerActivity]);

  const onPartnerSelected = (_, partner) => {
    const partnerDetails = partnerList.find(
      (partnerItem) => partnerItem.title === partner
    );
    console.log(partnerDetails);
    setSelectedPartner(partnerDetails);
  };

  return (
    <>
      <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                name="partner-activity"
                checked={isPartnerActivity}
                onChange={(event) => setIsPartnerActivity(event.target.checked)}
              />
            }
            labelPlacement="end"
            label="Partner Activity"
          />
          <Autocomplete
            id="combo-box-demo"
            options={partnerList.map((partner) => partner?.title)}
            sx={{ width: 400 }}
            renderInput={(params) => (
              <TextField {...params} label="Select Partner" />
            )}
            onChange={onPartnerSelected}
          />
        </FormGroup>
      </FormControl>
    </>
  );
};

PartnerActivity.propTypes = {
  onPartnerAcivityChange: PropTypes.func.isRequired,
};
