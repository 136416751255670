/* eslint-disable */
/* @ts-nocheck */

import React, { useState, useEffect } from 'react';
import {
    // Autocomplete,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Modal,
    // TextField,
} from '@mui/material';
import { API_URL } from 'src/utils/constants';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import axiosMB from 'src/utils/axios/interceptors';
import axios from 'axios';
import { Icon } from '@iconify/react';

export const AssignAsset = (props) => {
    const { isOpen, onClose, booking } = props;
    const [assets, setAssets] = useState([]);
    const [assetsId, setAssetsId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [newAsset, setNewAsset] = useState('');
    const [options, setOptions] = useState([]);
    const { pathname } = useLocation();
    const partnerId = pathname.split("/")[2];
    const [filteredAssets, setFilteredAssets] = useState([]);
    useEffect(() => {
        console.log(setOptions);
    }, [options])

    useEffect(() => {
        const fetchPartnerSharedAssets = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${API_URL}/partner/sharedAssets?partnerId=${partnerId}`);
                const data = response.data || [];
                setAssets(data);
                setLoading(false);
            } catch (error) {
                console.error(error);
                console.error('Failed to fetch assets:', error);
            }
        };

        fetchPartnerSharedAssets();
    }, []);

    const handleOnConfirmClose = () => {
        setIsConfirmOpen(false);
        setAssetsId(null);
    };

    const handleAssignAsset = async () => {
        try {
            if (newAsset && newAsset.length > 0) {
                const response = await axiosMB.post(`${API_URL}/partner/assets/createAndAssignToBooking/${booking.id}`,
                    {
                        partnerId: partnerId,
                        sharingPartners: [partnerId],
                        name: newAsset,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                );
                if (response.status === 200) {
                    location.reload();
                } else {
                    console.error('Failed to submit Asset:', response.statusText);
                }
            } else {
                const response = await axiosMB.patch(
                    `${API_URL}/admin/mb/bookings/${booking.id}`,
                    {
                        updateFields: {
                            assignedAssets: [assetsId],
                        }
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                );
                if (response.status === 200) {
                    location.reload();
                } else {
                    console.error('Failed to submit booking:', response.statusText);
                }
            }
        } catch (error) {
            console.error('Error submitting booking:', error);
        }
    };

    // Fetch the employees 
    return (
        <Modal
            open={isOpen} onClose={() => { onClose() }}
        // aria-labelledby="modal-modal-title"
        // aria-describedby="modal-modal-description"
        >
            <Box
                className='absolute flex flex-col pb-6 px-8 bg-white rounded-lg sm:top-1/2 left-1/2 bottom-0 -translate-x-1/2 sm:-translate-y-1/2 w-full max-w-[800px] h-full max-h-[90vh] overflow-y-scroll'
            >
                <div className='grid grid-cols-1 gap-4 bg-white'>
                    <div className='sticky top-0 z-20 pt-6 bg-white min-w-full'>
                        <button onClick={() => { onClose() }} className='border rounded-xl p-3 px-4 bg-slate-100/40 mb-2'>
                            <Icon icon="ep:arrow-left" width="1em" height="1em" />
                        </button>
                        <div>
                            <label className='font-bold mb-4 pb-2'>Search Assets</label>
                            <input
                                placeholder="Asset"
                                onChange={(e) => {
                                    const value = e.target.value.replace(/\s/g, '').toUpperCase();
                                    setNewAsset(value)
                                    const filtered = assets.filter(asset => asset.name.includes(value.replace(/\s/g, '').toUpperCase()));
                                    setFilteredAssets(filtered)
                                    if (filtered.length === 0 && newAsset.length === 0) {
                                        setFilteredAssets(assets)
                                    }
                                }}
                                fullWidth
                                className='min-w-full mb-4 p-2 py-3'
                                value={newAsset}
                            />
                        </div>
                    </div>
                    {filteredAssets.length > 0 && <div className='flex items-center gap-2 col-span-full'>
                        <span className="h-[2px] w-full bg-gray-200 z-0"></span>
                        <p className="z-10 px-2 whitespace-nowrap">Assets</p>
                        <span className="h-[2px] w-full bg-gray-200 z-0"></span>
                    </div>}

                    {!loading && filteredAssets.length > 0 ? filteredAssets.map((asset) => (
                        <div key={asset.name} className='grid grid-cols-2 gap-4'>
                            <div className='col-span-2'>
                                <div>Name:<span className='font-bold ml-2 capitalize'> {asset.name}</span></div>
                                <div>Brand:<span className='font-bold ml-2 capitalize'> {asset.brand}</span></div>
                            </div>
                            <Button
                                className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[150px] font-extralight p-1.5 rounded-lg whitespace-nowrap'
                                onClick={() => {
                                    setAssetsId(asset.id);
                                    setIsConfirmOpen(true);
                                }}>
                                Assign
                            </Button>
                            <Divider className='col-span-full' orientation="horizontal" flexItem />
                        </div>
                    )) : !loading && newAsset.length === 0 && assets.length > 0 && assets.map((asset) => (
                        <div key={asset.name} className='grid grid-cols-2 gap-4'>
                            <div className='col-span-2'>
                                <div>Name:<span className='font-bold ml-2 capitalize'> {asset.name}</span></div>
                                <div>Brand:<span className='font-bold ml-2 capitalize'> {asset.brand}</span></div>
                            </div>
                            <Button
                                className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[150px] font-extralight p-1.5 rounded-lg whitespace-nowrap'
                                onClick={() => {
                                    setAssetsId(asset.id);
                                    setIsConfirmOpen(true);
                                }}>
                                Assign
                            </Button>
                            <Divider className='col-span-full' orientation="horizontal" flexItem />
                        </div>))
                    }

                    {filteredAssets.length === 0 && newAsset.length > 0 && <>
                        <div className='flex items-center gap-2 col-span-full'>
                            <span className="h-[2px] w-full bg-gray-200 z-0"></span>
                            <p className="z-10 px-2 whitespace-nowrap">Create</p>
                            <span className="h-[2px] w-full bg-gray-200 z-0"></span>
                        </div>
                        <label className='font-bold justify-self-start m-0'>Create and assign</label>
                        <input
                            type="text"
                            placeholder="NewAsset"
                            name="newAsset"
                            onChange={(e) => setNewAsset(e.target.value.replace(/\s/g, '').toUpperCase())}
                            value={newAsset}
                        />
                        <Button
                            className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[150px] font-extralight p-1.5 rounded-lg whitespace-nowrap'
                            onClick={() => {
                                setIsConfirmOpen(true);
                            }}>
                            Assign
                        </Button>
                    </>}
                </div>
                <Dialog open={isConfirmOpen} onClose={handleOnConfirmClose}>
                    <DialogTitle>Confirm Assignment</DialogTitle>
                    <DialogContent className="min-w-full overflow-visible ">
                        <div className='grid grid-cols-1 gap-2'>
                            <h1 className='font-bold text-xl mt-2'>Are you sure?</h1>
                            {newAsset && newAsset.length > 0 ?
                                <div className='col-span-2'>
                                    <div>Name:<span className='font-bold ml-2 capitalize'> {newAsset}</span></div>
                                </div> :
                                <div className='col-span-2'>
                                    <div>Name:<span className='font-bold ml-2 capitalize'> {assets.find(asset => asset.id === assetsId)?.name}</span></div>
                                    <div>Brand:<span className='font-bold ml-2 capitalize'> {assets.find(asset => asset.id === assetsId)?.brand}</span></div>
                                    <div>Model:<span className='font-bold ml-2 capitalize'> {assets.find(asset => asset.id === assetsId)?.model}</span></div>
                                </div>
                            }
                            <Button
                                onClick={handleAssignAsset}
                                className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[150px] font-extralight p-1.5 rounded-lg whitespace-nowrap'>
                                Confirm
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>
            </Box>
        </Modal>);
};

AssignAsset.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    booking: PropTypes.object.isRequired,
};
