import React, {
    useEffect,
    useState
} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Modal,
    Box,
} from '@mui/material';

import { API_URL } from 'src/utils/constants';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import { PartnerPhotosUploader } from './PartnerPhotosUploader';
import { useLocation } from 'react-router-dom';
import { LegalDocumentsUploader } from 'src/components/LegalDocumentUploader';

export const PartnerAddOrEditAsset = (props) => {
    const { pathname } = useLocation();
    const partnerId = pathname.split("/")[2];

    const { isOpen, assetId, onClose } = props;
    const [isLoading, setIsLoading] = useState(false);
    // Asset
    const [assetLoading, setAssetLoading] = useState(false);
    const [assetData, setAssetData] = useState({
        partnerId,
        sharingPartners: [partnerId],
        name: '',
        model: '',
        brand: '',
        manufactureYear: '',
        purchaseDate: '',
    });
    const [legalDocuments, setLegalDocuments] = useState([]);
    const [photos, setPhotos] = useState([]);

    const fetchAsset = async (assetId) => {
        try {
            setAssetLoading(true);
            const response = await axios.get(`${API_URL}/partner/assets/${assetId}`);
            const assetData = response.data
            setAssetData({
                name: assetData?.name || '',
                model: assetData?.model || '',
                brand: assetData?.brand || '',
                manufactureYear: assetData?.manufactureYear || '',
                purchaseDate: moment(assetData?.purchaseDate).format('YYYY-MM-DD') || '',
            });
            setLegalDocuments(assetData?.legalDocuments || []);
            setPhotos(assetData?.photos || []);
            setAssetLoading(false);
        } catch (error) {
            console.log(error.response.data);
            setAssetLoading(false);
            console.error('Failed to fetch assets:', error);
        }
    };

    // Fetch asset
    useEffect(() => {
        if (assetId) {
            fetchAsset(assetId);
        }
    }, [assetId]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'name') {
            setAssetData((prev) => ({ ...prev, [name]: value.replace(/\s/g, '').toUpperCase() }));
        } else {
            setAssetData((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleUpdate = async () => {
        try {
            setIsLoading(true);
            const response = await axios.patch(`${API_URL}/partner/assets/${assetId}`,
                {
                    ...assetData,
                    legalDocuments,
                    photos,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
            if (response.status == 200) {
                handleClose(); // Close the dialog on successful POST
                setIsLoading(false);
                location.reload();
            } else {
                console.error('Failed to submit Asset:', response.statusText);
            }
        } catch (error) {
            console.error('Error submitting Asset:', error);
        }
    };

    const handleCreate = async () => {
        try {
            setIsLoading(true);
            const response = await fetch(`${API_URL}/partner/assets`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...assetData,
                    legalDocuments,
                    photos,
                }),
            });
            if (response.ok) {
                handleClose(); // Close the dialog on successful POST
                setIsLoading(false);
                location.reload();
            } else {
                console.error('Failed to submit Asset:', response.statusText);
            }
        } catch (error) {
            console.error('Error submitting Asset:', error);
        }
    };

    // Reset the form
    const handleClose = () => {
        onClose();
        setAssetData({});
    };

    return (
        <div>
            {assetLoading ? (
                <Dialog open={isOpen} onClose={handleClose}>
                    <DialogTitle>Loading...</DialogTitle>
                    <DialogContent></DialogContent>
                </Dialog>
            ) : (
                <Modal
                    open={isOpen} onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        className='absolute flex flex-col py-6 p-8 bg-white rounded-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-[800px] h-full max-h-[90vh] overflow-y-scroll'
                    >
                        {/* AutoComplete here */}
                        <div className="grid grid-cols-1 gap-x-3 place-items-start">
                            <h1 className='col-span-full text-xl font-bold '>Asset</h1>
                            <label className="text-gray-700 font-semibold whitespace-nowrap">
                                Name
                            </label>
                            <TextField
                                margin="dense"
                                name="name"
                                placeholder="Asset name"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={assetData.name}
                                onChange={handleInputChange}
                            />
                            <label className="text-gray-700 font-semibold whitespace-nowrap">
                                Model
                            </label>
                            <TextField
                                margin="dense"
                                name="model"
                                placeholder="Asset Model"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={assetData.model}
                                onChange={handleInputChange}
                            />
                            <label className="text-gray-700 font-semibold whitespace-nowrap">
                                Brand
                            </label>
                            <TextField
                                margin="dense"
                                name="brand"
                                placeholder="Asset Brand"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={assetData.brand}
                                onChange={handleInputChange}
                            />
                            <label className="text-gray-700 font-semibold whitespace-nowrap">
                                Manufacture year
                            </label>
                            <TextField
                                margin="dense"
                                name="manufactureYear"
                                placeholder="Asset Manufacture Year"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={assetData.manufactureYear}
                                onChange={handleInputChange}
                            />
                            <label className="text-gray-700 font-semibold whitespace-nowrap">
                                Purchase date
                            </label>
                            <input
                                className='w-full'
                                name="purchaseDate"
                                placeholder="Asset Purchase Date"
                                value={assetData.purchaseDate}
                                onChange={handleInputChange}
                                type="date"
                            />
                        </div>
                        <div className="col-span-2 grid grid-cols-1 gap-1 py-4">
                            <span className='bg-gray-400 w-full h-px my-2'></span>
                            <LegalDocumentsUploader
                                path='partners/assets/'
                                documents={legalDocuments}
                                setDocuments={setLegalDocuments} />
                            <span className='bg-gray-400 w-full h-px my-2'></span>
                            <PartnerPhotosUploader photos={photos} setPhotos={setPhotos} />
                        </div>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button
                                disabled={isLoading}
                                onClick={assetId ? handleUpdate : handleCreate}
                            >
                                {assetId ? "Save" : "Create"}
                            </Button>
                        </DialogActions>
                    </Box>
                </Modal>
            )}
        </div>
    );
};

PartnerAddOrEditAsset.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    assetId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};
