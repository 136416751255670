/* eslint-disable */

import axios from 'axios';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { API_URL } from '../../utils/constants';

export const UploadLegalDocuments = ({ path, title, onUploadSuccess }) => {
    const [uploadedDocument, setUploadedDocument] = useState({});

    const handleBannersUpload = async (e) => {
        e.preventDefault();
        if (e.target.files) {
            const body = {
                file: Object.values(e.target.files).map((file) =>
                ({
                    fileName: path + new Date().getTime() + file.name.replaceAll(' ', '-'),
                    fileType: file.type,
                })
                )[0],
                type: 'aws',
            };

            axios
                .post(`${API_URL}/util/signed-url`, body)
                .then((response) => {
                    const url = response.data?.data?.url;
                    if (url?.length > 0) {
                        setUploadedDocument({
                            url,
                            file: e.target.files[0],
                            fileName: e.target.files[0].name,
                            fileType: e.target.files[0].type,
                        });
                    } else {
                        setUploadedDocument({
                            url: null,
                            file: null,
                        });
                    }
                })
                .catch((error) => console.log(error));
        }
    };

    useEffect(() => {
        if (uploadedDocument?.url && uploadedDocument?.file) {
            const body = new FormData();
            const api = uploadedDocument.url
            const file = uploadedDocument.file;
            body.append(uploadedDocument.fileName, file);
            axios.put(api, file, {
                headers: {
                    'Content-Type': file.type
                }
            }).then((res) => {
                if (res.status === 200) {
                    const s3Url = res.request.responseURL.split('?Content-Type=')[0];
                    onUploadSuccess({
                        url: s3Url,
                        fileType: file.type,
                        fileName: file.name
                    });
                }
            }).catch((error) => console.log('Upload: ', error));

            setUploadedDocument({});
        }
    }, [uploadedDocument]);

    return (
        <Button variant="contained" component="label">
            {title}
            <input
                hidden
                accept="*"
                multiple={false}
                type="file"
                onChange={handleBannersUpload}
            />
        </Button>
    );
};

UploadLegalDocuments.propTypes = {
    path: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    onUploadSuccess: PropTypes.func.isRequired,
};
