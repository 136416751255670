/* eslint-disable */
import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  amenities as amenityTypes,
  activityAmenitiesLabels,
} from '../amenities';
import {
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
} from '@mui/material';

const amenities = {
  [amenityTypes.WIFI]: {
    id: amenityTypes.WIFI,
    iconId: amenityTypes.WIFI,
    label: activityAmenitiesLabels[amenityTypes.WIFI],
  },
  [amenityTypes.SWIMMING_POOL]: {
    id: amenityTypes.SWIMMING_POOL,
    iconId: amenityTypes.SWIMMING_POOL,
    label: activityAmenitiesLabels[amenityTypes.SWIMMING_POOL],
  },
  [amenityTypes.BONFIRE]: {
    id: amenityTypes.BONFIRE,
    iconId: amenityTypes.BONFIRE,
    label: activityAmenitiesLabels[amenityTypes.BONFIRE],
  },
  [amenityTypes.LIQUOR_ZONE]: {
    id: amenityTypes.LIQUOR_ZONE,
    iconId: amenityTypes.LIQUOR_ZONE,
    label: activityAmenitiesLabels[amenityTypes.LIQUOR_ZONE],
  },
  [amenityTypes.POWER_BACKUP]: {
    id: amenityTypes.POWER_BACKUP,
    iconId: amenityTypes.POWER_BACKUP,
    label: activityAmenitiesLabels[amenityTypes.POWER_BACKUP],
  },
  [amenityTypes.AIR_CONDITIONING]: {
    id: amenityTypes.AIR_CONDITIONING,
    iconId: amenityTypes.AIR_CONDITIONING,
    label: activityAmenitiesLabels[amenityTypes.AIR_CONDITIONING],
  },
  [amenityTypes.PORTABLE_FAN]: {
    id: amenityTypes.PORTABLE_FAN,
    iconId: amenityTypes.PORTABLE_FAN,
    label: activityAmenitiesLabels[amenityTypes.PORTABLE_FAN],
  },
  [amenityTypes.PARKING]: {
    id: amenityTypes.PARKING,
    iconId: amenityTypes.PARKING,
    label: activityAmenitiesLabels[amenityTypes.PARKING],
  },
  [amenityTypes.PAID_PARKING]: {
    id: amenityTypes.PAID_PARKING,
    iconId: amenityTypes.PAID_PARKING,
    label: activityAmenitiesLabels[amenityTypes.PAID_PARKING],
  },
  [amenityTypes.PETS_ALLOWED]: {
    id: amenityTypes.PETS_ALLOWED,
    iconId: amenityTypes.PETS_ALLOWED,
    label: activityAmenitiesLabels[amenityTypes.PETS_ALLOWED],
  },
  [amenityTypes.RIVER_VIEW]: {
    id: amenityTypes.RIVER_VIEW,
    iconId: amenityTypes.RIVER_VIEW,
    label: activityAmenitiesLabels[amenityTypes.RIVER_VIEW],
  },
  [amenityTypes.RIVER_ACCESS]: {
    id: amenityTypes.RIVER_ACCESS,
    iconId: amenityTypes.RIVER_ACCESS,
    label: activityAmenitiesLabels[amenityTypes.RIVER_ACCESS],
  },
  [amenityTypes.BEACH_ACCESS]: {
    id: amenityTypes.BEACH_ACCESS,
    iconId: amenityTypes.BEACH_ACCESS,
    label: activityAmenitiesLabels[amenityTypes.BEACH_ACCESS],
  },
};

export const StayAmenities = ({
  onAmenitiesChanged,
  selectedAmenities = [],
}) => {
  const [checkBoxState, setCheckBoxState] = useState({
    [amenityTypes.WIFI]: false,
    [amenityTypes.SWIMMING_POOL]: false,
    [amenityTypes.BONFIRE]: false,
    [amenityTypes.POWER_BACKUP]: false,
    [amenityTypes.AIR_CONDITIONING]: false,
    [amenityTypes.PORTABLE_FAN]: false,
    [amenityTypes.PARKING]: false,
    [amenityTypes.PETS_ALLOWED]: false,
    [amenityTypes.RIVER_VIEW]: false,
    [amenityTypes.BEACH_ACCESS]: false,
  });
  return (
    <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
      <FormGroup>
        {Object.keys(amenities).map((aminityType) => (
          <FormControlLabel
            key={amenities[aminityType].id}
            control={
              <Checkbox
                name={aminityType}
                checked={
                  checkBoxState[aminityType] ||
                  selectedAmenities.filter(
                    (a) => a.iconId == amenities[aminityType].iconId
                  ).length
                }
                onChange={(event) => {
                  setCheckBoxState({
                    ...checkBoxState,
                    [aminityType]: event.target.checked,
                  });
                  onAmenitiesChanged(
                    amenities[aminityType].iconId,
                    amenities[aminityType].label
                  );
                }}
              />
            }
            label={amenities[aminityType].label}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

StayAmenities.propTypes = {
  onAmenitiesChanged: PropTypes.func.isRequired,
};
