/* eslint-disable */

import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import './activityList.css';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Card,
  CardContent,
  Select,
  MenuItem,
  TextField,
  Divider,
  Button,
} from '@mui/material';
import FlyHTMLeditor from '../FlyHTMLeditor';
import { TrekOptions } from './activityTypes/trek/trekOptions';
import { StayAmenities } from './activityTypes/stay/stayAmenities';
import { PartnerActivity } from '../partner/partnerActivity';
import { API_URL } from '../../utils/constants';
import { activityType } from './activityTypes';
import AddActivityLocation from '../AddActivityLocation';

const createGenericActivityParams = (props) => {
  const _activityType = activityType;
  const [textInput, setTextInput] = React.useState({});
  const [initialData, setInitialData] = useState({});
  const [destination, setDestination] = useState([]);
  const [amenities, setAmenities] = useState(textInput.iconLabelView || []);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    props.passActivityData(prev => ({ ...prev, locations }))
  }, [locations])

  useEffect(() => {
    if (props.initialData) {
      setInitialData(props.initialData);
      setTextInput(props.initialData);
      setSelectedActivityType(props.initialData.type);
      setAmenities(props.initialData.iconLabelView);
      setLocations(props.initialData.locations?.map(item => ({
        shortAddress: item.shortAddress,
        address: item.address,
        lat: item.location?.lat,
        long: item.location?.long,
        type: item?.type,
        placeId: item?.placeId,
        googlePlaceId: item?.googlePlaceId,
      })))
    }
  }, [props.initialData]);

  const [selectedActivityType, setSelectedActivityType] = useState('');

  useEffect(() => {
    if (!destination.length) {
      axios
        .get(`${API_URL}/place/mb?isActive=true`)
        .then((res) => {
          setDestination(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const handleChange = (evt) => {
    console.log(evt.target.name);
    const _textInput = { ...textInput };
    if (evt.target.name?.includes('additionalInfo')) {
      if (!_textInput['additionalInfo']) _textInput['additionalInfo'] = {};
      const key = evt.target.name.split('.')[1];
      _textInput['additionalInfo'][key] = evt.target.value;
    } else {
      _textInput[evt.target.name] = evt.target.value;
    }
    setTextInput(_textInput);
    props.passActivityData(_textInput);
  };

  const onEditorStateChange = (editorHTML, attribute) => {
    setTextInput({
      ...textInput,
      [attribute]: editorHTML,
    });
    props.passActivityData(textInput);
  };

  const onDestinationChange = (_, newValue) => {
    if (newValue?.id) {
      console.log(newValue)
      const _updated = {
        ...textInput,
        placeId: newValue.id,
        path: newValue.path,
        priceCurrency: newValue.priceCurrency,
      };
      setTextInput(_updated);
      props.passActivityData(_updated);
    }
  };

  const onStatusChange = (e) => {
    const _updated = {
      ...textInput,
      status: e.target.value,
    };

    setTextInput(_updated);
    props.passActivityData(_updated);
  };

  const onAmenitiesChanged = (id, label) => {
    let newAmenities = [];
    const isExisting = amenities.filter((a) => a.iconId == id).length;
    if (isExisting) {
      newAmenities = amenities.filter((a) => a.iconId != id);
    } else {
      newAmenities = [...amenities, { iconId: id, title: label }];
    }
    const _updated = {
      ...textInput,
      iconLabelView: newAmenities,
    };
    setTextInput(_updated);
    setAmenities(newAmenities);
    props.passActivityData(_updated);
  };

  const onTypeChange = (_, newValue) => {
    if (newValue?.id) {
      const currTextInput = {
        ...textInput,
        type: newValue.id,
      };
      setTextInput(currTextInput);
      props.passActivityData(currTextInput);
      setSelectedActivityType(newValue.id);
    }
  };

  const onTrekDifficultyChange = (_, newValue) => {
    const _textInput = Object.assign(textInput, {});
    if (!_textInput['additionalInfo']) _textInput['additionalInfo'] = {};
    _textInput['additionalInfo']['difficulty'] = newValue.id;
    setTextInput(_textInput);
    props.passActivityData(_textInput);
  };

  const onPartnerAcivityChange = (activitySelected, partner) => {
    let currTextInput = {
      ...textInput,
    };
    if (activitySelected) {
      currTextInput.isPartnerActivity = activitySelected;
      currTextInput.partnerId = partner.id;
    } else {
      delete currTextInput.isPartnerActivity;
      delete currTextInput.partnerId;
    }
    setTextInput({ ...currTextInput });
    props.passActivityData(currTextInput);
  };

  return (
    <div className="addactivity-wrapper">
      <Card>
        <CardContent className="cardContent-padding">
          {textInput && (
            <>
              <div style={{ width: '100%', display: 'inline' }}>
                <div
                  style={{
                    width: '20%',
                    margin: '0px 200px 0px 0px',
                    padding: '0px 20px 0px 0px',
                    display: 'inline-grid',
                  }}
                >
                  <label>Type</label>
                  <Autocomplete
                    id="combo-box-demo"
                    options={_activityType}
                    sx={{ width: 340 }}
                    renderInput={(params) => <TextField {...params} label="" />}
                    onChange={onTypeChange}
                    value={
                      _activityType.find((a) => a.id == textInput.type) || null
                    }
                  />
                </div>

                { }
                <div
                  style={{
                    width: '20%',
                    margin: '0px 200px 0px 0px',
                    display: 'inline-grid',
                  }}
                >
                  <label>Destination</label>
                  <Autocomplete
                    id="combo-box-demo"
                    options={destination}
                    sx={{ width: 340 }}
                    renderInput={(params) => <TextField {...params} label="" />}
                    value={
                      destination.find((a) => a.id == textInput.placeId) || null
                    }
                    onChange={onDestinationChange}
                  />
                </div>
                <div
                  style={{
                    width: '20%',
                    margin: '0px 200px 0px 0px',
                    display: 'inline-grid',
                  }}
                >
                  <label>Status</label>
                  <Select
                    id="combo-box-demo"
                    value={textInput.status || 'draft'}
                    onChange={onStatusChange}
                  >
                    <MenuItem value="live">Live</MenuItem>
                    <MenuItem value="draft">Draft</MenuItem>
                    <MenuItem value="expired">Expired</MenuItem>
                    <MenuItem value="out-of-stock">Out of Stock</MenuItem>
                    <MenuItem value="soft-deleted">Soft Deleted</MenuItem>
                  </Select>
                </div>
              </div>

              <label>Title</label>
              <input
                onChange={handleChange}
                name="title"
                value={textInput.title || ''}
              />

              <label>Slug</label>
              <input
                onChange={handleChange}
                name="slug"
                value={textInput.slug || ''}
                disabled={props.initialData}
              />

              <div style={{ width: '100%', display: 'inline' }}>
                <div
                  style={{
                    width: '30%',
                    margin: '0px 20px 0px 0px',
                    display: 'inline-grid',
                  }}
                >
                  <label>Booked in K</label>
                  <input
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^\d.]/g, '');
                      const target = e.target;
                      target.value = value;
                      handleChange({ ...e, target })
                    }}
                    name="bookedCount"
                    type={'text'}
                    value={textInput.bookedCount || ''}
                  />
                </div>
                <div
                  style={{
                    width: '30%',
                    margin: '0px 20px 0px 0px',
                    display: 'inline-grid',
                  }}
                >
                  <label>Rating in decimals</label>
                  <input
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^\d.]/g, '');
                      const target = e.target;
                      target.value = value;
                      handleChange({ ...e, target })
                    }}
                    name="rating"
                    type={'text'}
                    value={textInput.rating || ''}
                  />
                </div>
                <div style={{ width: '30%', display: 'inline-grid' }}>
                  <label>Number of Rating in k</label>
                  <input
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^\d.]/g, '');
                      const target = e.target;
                      target.value = value;
                      handleChange({ ...e, target })
                    }}
                    name="ratingCount"
                    type={'text'}
                    value={textInput.ratingCount || ''}
                  />
                </div>
              </div>
            </>
          )}
          <label>Highlights HTML</label>
          <FlyHTMLeditor
            initialData={initialData.highlightsHTML}
            onEditorStateChange={onEditorStateChange}
            attribute={'highlightsHTML'}
          ></FlyHTMLeditor>

          <label>Community Highlights HTML</label>
          <FlyHTMLeditor
            initialData={initialData.communityHighlightsHTML}
            onEditorStateChange={onEditorStateChange}
            attribute={'communityHighlightsHTML'}
          ></FlyHTMLeditor>

          <div className='flex items-center justify-between mb-2'>
            <h1 className='font-bold text-xl'>Locations</h1>
            <Button
              variant="contained"
              // className="save"
              enabled
              onClick={() => {
                setLocations((prev) => [...prev, {
                  shortAddress: '',
                  address: '',
                  lat: 0,
                  long: 0,
                  type: '',
                  googlePlaceId: '',
                  placeId: '',
                  placePath: '',
                }]);
              }}
            >Add Location</Button>
          </div>
          <div className='grid grid-cols-1 gap-4'>
            {locations?.map((location, index) =>
              <div key={index}>
                <div className='flex items-center justify-between'>
                  <h1 className="font-bold text-xl">Location {index + 1}</h1>
                  <button
                    className='self-end bg-[#FF455B] hover:bg-[#da4848] text-white font-extralight p-1.5 px-3 rounded-lg whitespace-nowrap'
                    onClick={() => {
                      setLocations(locations.filter((item, i) => i !== index))
                    }}>Remove Location</button>
                </div>
                <AddActivityLocation location={{ ...location, index }} updateLocations={(data) => {
                  const { index, type, location } = data;
                  const updateLocation = locations.map((item, i) => {
                    if (i === index) {
                      return {
                        type,
                        location
                      }
                    }
                    return item;
                  })
                  setLocations(updateLocation)

                }} />
                <Divider flexItem className='col-span-full w-full my-3' orientation="horizontal" />
              </div>
            )}
          </div>


          {selectedActivityType == 'trek' && (
            <TrekOptions
              handleChange={handleChange}
              textInput={textInput}
              onTrekDifficultyChange={onTrekDifficultyChange}
            />
          )}

          {selectedActivityType == 'stay' && (
            <StayAmenities
              onAmenitiesChanged={onAmenitiesChanged}
              selectedAmenities={textInput.iconLabelView}
            />
          )}

          <PartnerActivity onPartnerAcivityChange={onPartnerAcivityChange} />
        </CardContent>
      </Card>
    </div>
  );
};

createGenericActivityParams.propTypes = {
  passActivityData: PropTypes.any,
};

export default createGenericActivityParams;
