import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Drawer, Typography, Avatar } from '@mui/material';
// hooks
import mockAccount from 'src/_mocks_/account';
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
import adminSidebarConfig from './adminSidebarConfig';
import partnerSidebarConfig from './partnerSidebarConfig.js';
import { API_URL } from 'src/utils/constants';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12]
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const accountId = pathname.split("/")[1] === "partner" ? pathname.split("/")[2] : null;

  const [account, setAccount] = useState(mockAccount);
  useEffect(() => {
    const fetchaccount = async () => {
      try {
        const response = await fetch(`${API_URL}/admin/mb/partner?partnerId=${accountId}`);
        const data = await response.json();
        setAccount(data);
      } catch (error) {
        console.error('Failed to fetch account:', error);
      }
    };
    if (accountId) {
      // Fetch account details
      fetchaccount();
    }
  }, [accountId]);
  useEffect(() => {
    document.title = accountId ? account?.title || 'loading...' : 'Motherboard';
  }, [accountId, account]);
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  const getUserName = () => {
    try {
      return JSON.parse(localStorage.getItem("user")).name;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const userName = getUserName()

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={mockAccount.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {accountId ? account?.title || 'loading...' : userName ? userName : 'Admin Dashboard'}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {mockAccount.role}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection navConfig={pathname.split("/")[1] === "dashboard" ? adminSidebarConfig : partnerSidebarConfig} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed'
            }
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
