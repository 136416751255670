// material
import { Box, Card, CardHeader, Divider } from '@mui/material';
//
import axios from 'axios';
import { API_URL } from 'src/utils/constants';
import { useEffect, useState } from 'react';
import { formatCurrency } from 'src/utils/formatCurrency';
import DateRangePicker from 'src/components/DatePicker';
import moment from 'moment';

// ----------------------------------------------------------------------

export const getActivityReports = async ({ startDate, endDate }) => {
  const activityFinanceData = await axios.get(`${API_URL}/admin/mb/dashboard/partnerReports?startDate=${startDate}&endDate=${endDate}`)
  return activityFinanceData.data;
};


export default function ActivityReports() {
  const [partnerReports, setPartnerReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const toady = moment().format('YYYY-MM-DD');
  const oneWeek = moment().add(-1, 'week').format('YYYY-MM-DD');

  const fetchPartnerReports = async ({ startDate, endDate }) => {
    try {
      setLoading(true);
      const response = await getActivityReports({
        startDate,
        endDate: moment(endDate).format('YYYY-MM-DD'),
      });
      setPartnerReports(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Failed to fetch activity reports:', error);
    }
  };
  useEffect(() => {
    fetchPartnerReports({ startDate: oneWeek, endDate: toady });
  }, []);

  const { totalProfit, totalAmount, totalBookings } = partnerReports ? partnerReports.reduce((acc, report) => {
    const { totalProfit, totalAmount, totalBookings } = acc;
    return {
      totalProfit: totalProfit + report.totalProfit,
      totalAmount: totalAmount + report.totalAmount,
      totalBookings: totalBookings + report.totalBookings,
    };
  }, { totalProfit: 0, totalAmount: 0, totalBookings: 0 }) : { totalProfit: 0, totalAmount: 0, totalBookings: 0 };
  const handleDateChange = async (date) => {
    await fetchPartnerReports({ startDate: date.startDate, endDate: date.endDate });
  };

  return (
    <Card>
      <CardHeader title="Partner sales report"
      // subheader="Comparison of current and previous week"
      />
      <div className='relative flex items-start'>
        <div className=' '>
          <DateRangePicker startDate={toady} endDate={oneWeek} onSelect={handleDateChange} />
        </div>
        {loading ? <h1>Loading...</h1> : <Box>
          <div className='grid grid-cols-1 p-4 gap-1 min-h-96 flex-1 min-w-full'>
            <div className='grid grid-cols-4 place-items-center' >
              <p className='text-lg font-semibold place-self-start'>Partner Title</p>
              <p className='text-lg font-semibold text-center'>Total Bookings</p>
              <p className='text-lg font-semibold'>Total Amount</p>
              <p className='text-lg font-semibold text-center'>Profit</p>
            </div>
            {partnerReports.length > 0 && partnerReports.map((report) => {
              const { partnerId, title, totalBookings, totalAmount, totalProfit } = report;
              return (
                <div key={partnerId}>
                  <div className='grid grid-cols-4 place-items-center' >
                    <p className='text-sm font-medium place-self-start'>{title}</p>
                    <p className='text-sm font-medium'>{totalBookings}</p>
                    <p className='text-sm font-medium'>{formatCurrency(totalAmount)}</p>
                    <p className='text-sm font-medium'>{formatCurrency(totalProfit)}</p>
                  </div>
                  <Divider className='w-full' orientation="horizontal" />
                </div>
              )
            })}
            <div>
              <div className='grid grid-cols-4 place-items-center font-bold' >
                <p className='text-sm font-medium place-self-start'></p>
                <p className='text-sm font-medium'>{totalBookings}</p>
                <p className='text-sm font-medium'>{formatCurrency(totalAmount)}</p>
                <p className='text-sm font-medium'>{formatCurrency(totalProfit)}</p>
              </div>
              <Divider className='w-full' orientation="horizontal" />
            </div>
          </div >
          {/* <ReactApexChart type="bar" series={CHART_DATA} options={chartOptions} height={364} /> */}
        </Box>
        }
      </div>
    </Card>
  );
}
