/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Container,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Avatar,
} from '@mui/material';
import { API_URL } from 'src/utils/constants';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Loading } from 'src/components/Loading';

const stayAmenitiesType = {
    ROOM: 'room',
    BATHROOM: 'bathroom',
    COMMON: 'common',
    KITCHEN: 'kitchen',
    DEFAULT: 'default',
};

function CreateStayAmenity() {
    const [isLoading, setIsLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        name: '',
        type: stayAmenitiesType.STAY,
        icon: '',
        naIcon: '',
    });
    const [flyIcons, setFlyIcons] = useState([]);
    const [iconPreview, setIconPreview] = useState(null);
    const [naIconPreview, setNaIconPreview] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchFlyIcons();
    }, []);

    const fetchFlyIcons = async () => {
        try {
            const response = await axios.get(`${API_URL}/admin/mb/flyIcons`);
            setFlyIcons(response.data);
        } catch (error) {
            console.error('Failed to fetch FlyIcons:', error);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });

        // Update preview for selected icon
        if (name === 'icon') {
            console.log(flyIcons)
            const selectedIcon = flyIcons.find((icon) => icon.flyIconId === value);
            setIconPreview(selectedIcon ? `https://cdn-passprt.s3.ap-south-1.amazonaws.com/assets/flyIcons/${selectedIcon.flyIconId}` : null);
        }
        if (name === 'naIcon') {
            const selectedNaIcon = flyIcons.find((icon) => icon.flyIconId === value);
            setNaIconPreview(selectedNaIcon ? `https://cdn-passprt.s3.ap-south-1.amazonaws.com/assets/flyIcons/${selectedNaIcon.flyIconId}` : null);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        if (formValues.name.trim().length <= 0 && formValues.icon.trim().length <= 0) {
            return
        }

        try {
            const response = await axios.post(`${API_URL}/admin/mb/stayAmenities`, formValues);

            if (response.status === 201) {
                navigate('/dashboard/stayAmenities');
            }
        } catch (error) {
            console.error('Failed to create stay amenity:', error);
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Container maxWidth="sm">
            <Box sx={{ mt: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Create Stay Amenity
                </Typography>

                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label="Amenity Name"
                        name="name"
                        value={formValues.name}
                        onChange={handleInputChange}
                        margin="normal"
                        variant="outlined"
                        required
                    />

                    <FormControl fullWidth margin="normal">
                        <InputLabel id="type-select-label">Amenity Type</InputLabel>
                        <Select
                            labelId="type-select-label"
                            name="type"
                            value={formValues.type}
                            onChange={handleInputChange}
                            label="Amenity Type"
                            required
                        >
                            {Object.values(stayAmenitiesType).map((type) => (
                                <MenuItem key={type} value={type}>
                                    {type}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* FlyIcon Dropdown for Amenity Icon */}
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="icon-select-label">Select Icon</InputLabel>
                        <Select
                            fullWidth
                            labelId="icon-select-label"
                            name="icon"
                            value={formValues.icon}
                            onChange={handleInputChange}
                            label="Select Icon"
                            required
                        >
                            {flyIcons.map((icon) => (
                                <MenuItem key={icon.id} value={icon.flyIconId}>
                                    {icon.flyIconId}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Box display="flex" alignItems="center" marginY={2}>
                        <Avatar src={iconPreview} sx={{ width: 56, height: 56, marginRight: 2 }} />
                        <Typography variant="body2" color="textSecondary">
                            Icon Preview
                        </Typography>
                    </Box>

                    {/* FlyIcon Dropdown for NA Icon */}
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="na-icon-select-label">Select NA Icon</InputLabel>
                        <Select
                            fullWidth
                            labelId="na-icon-select-label"
                            name="naIcon"
                            value={formValues.naIcon}
                            onChange={handleInputChange}
                            label="Select NA Icon"
                        >
                            {flyIcons.map((icon) => (
                                <MenuItem key={icon.id} value={icon.flyIconId}>
                                    {icon.flyIconId}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Box display="flex" alignItems="center" marginY={2}>
                        <Avatar src={naIconPreview} sx={{ width: 56, height: 56, marginRight: 2 }} />
                        <Typography variant="body2" color="textSecondary">
                            NA Icon Preview
                        </Typography>
                    </Box>

                    <Button type="submit" fullWidth variant="contained" color="primary">
                        Create Amenity
                    </Button>
                </form>
            </Box>
        </Container>
    );
}

export default CreateStayAmenity;
