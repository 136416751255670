import { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from 'src/utils/constants';

export const usePartnerList = () => {
  const [partnerList, setPartnerList] = useState([]);

  useEffect(() => {
    if (!partnerList.length) {
      axios
        .get(`${API_URL}/vendor/partners`)
        .then((res) => {
          setPartnerList(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return partnerList;
};
