export const activityType = [
  {
    label: 'Trek',
    id: 'trek',
  },
  {
    label: 'Tour',
    id: 'tour',
  },
  {
    label: 'Activity',
    id: 'activity',
  },
  {
    label: 'Global Affiliate',
    id: 'affiliate',
  },
  {
    label: 'Stay',
    id: 'stay',
  },
  {
    label: 'Transfer',
    id: 'transfer',
  },
  {
    label: 'Taxi Tour',
    id: 'taxi-tour',
  },
];

export const activityTypes = {
  TREK: 'trek',
  TOUR: 'tour',
  ACTIVITY: 'activity',
  GLOBAL_AFFILIATE: 'affiliate',
  STAY: 'stay',
  TRANSFER: 'transfer',
};
