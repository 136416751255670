/* eslint-disable */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
    Container,
    Typography,
    Grid,
    Card,
    CardHeader,
    CardContent,
    Button,
    TextField,
    Stack,
    CircularProgress,
    IconButton,
    Autocomplete,
    Switch,
    Input
} from '@mui/material';
import { API_URL } from 'src/utils/constants';
import Page from '../components/Page';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { UploadButton } from 'src/components/buttons/upload';
import { Loading } from 'src/components/Loading';

export default function EditActivityVariant() {
    const [loading, setLoading] = useState(true);
    const [variant, setVariant] = useState({});
    const [isLoading, setLoader] = useState(false);
    const { slug } = useParams();
    const [amenities, setAmenities] = useState([]);
    const [photosRemoved, setPhotosRemoved] = useState([]);
    const [photosAdded, setPhotosAdded] = useState([]);
    const [selectedAmenity, setSelectedAmenity] = useState({
        amenityId: '',
        available: false
    });

    const getStayAmenities = async () => {
        try {
            const res = await axios.get(`${API_URL}/admin/mb/stayAmenities`);
            setAmenities(res.data.map((amenity) => ({ label: amenity.name, value: amenity.name })));
        } catch (error) {
            console.log(error);
        }
    };
    const getActivityVariants = async () => {
        try {
            const variantId = slug.split("-")[0];
            const res = await axios.get(`${API_URL}/admin/mb/activty/variant?variantId=${variantId}`);
            setVariant(res.data);
            setLoading(false)
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getActivityVariants();
        getStayAmenities();
    }, []);

    const addRoomAmenity = useCallback((amenity) => {
        if (amenity.amenityId?.length > 0) {
            setVariant(prev => ({
                ...prev,
                stayRoomAmenities: [...prev.stayRoomAmenities, amenity]
            }));
            setSelectedAmenity({
                amenityId: '',
                available: false
            })
        }
    }, []);

    const removeRoomAmenity = useCallback((amenityIndex) => {
        setVariant(prev => ({
            ...prev,
            stayRoomAmenities: prev.stayRoomAmenities.filter((_, i) => i !== amenityIndex)
        }));
    }, []);

    const handleInputChange = useCallback((e, field) => {
        const { value } = e.target;
        setVariant(prev => ({ ...prev, [field]: value }));
    }, []);

    const handleAmenityChange = useCallback((e, amenityIndex, field) => {
        setVariant(prev => {
            const updatedAmenities = [...prev.stayRoomAmenities];
            updatedAmenities[amenityIndex][field] = field === 'available' ? e.target.checked : e.target.value;
            return { ...prev, stayRoomAmenities: updatedAmenities };
        });
    }, []);

    const saveFinalData = async () => {
        setLoader(true);
        const variantId = slug.split("-")[0];
        const body = {
            activityId: variant.activityId,
            variantId: variantId,
            variant: variant,
            photosRemoved,
            banners: photosAdded
        };
        const res = await axios.put(`${API_URL}/admin/mb/variant/${variantId}`, body);
        if (res.status === 200) {
            location.reload();
        }
        setLoader(false);
    };

    const returnOnlyNumberFromEvent = (e) => ({
        ...e,
        target: {
            ...e.target,
            value: Number(e.target.value.replace(/[^0-9]/g, ''))
        }
    });

    if (loading) {
        return <Loading />
    }
    return (
        <Page title="Edit Activity Variant">
            <Container>
                <Typography variant="h4" sx={{ mb: 2 }}>{variant.title}</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader title="Edit Variant" />
                            <CardContent>
                                <Stack spacing={2}>
                                    <TextField
                                        label="Title"
                                        value={variant.title || ""}
                                        onChange={(e) => handleInputChange(e, 'title')}
                                        fullWidth
                                    />
                                    <TextField
                                        label="Description"
                                        value={variant.desc || ""}
                                        onChange={(e) => handleInputChange(e, 'desc')}
                                        fullWidth
                                    />
                                    <TextField
                                        label="Stay Max Quantity"
                                        value={variant.stayMaxQuantity || ""}
                                        onChange={(e) => handleInputChange(returnOnlyNumberFromEvent(e), 'stayMaxQuantity')}
                                        fullWidth
                                    />
                                    <TextField
                                        label="Stay Max Quantity"
                                        value={variant.stayMaxQuantity || ""}
                                        onChange={(e) => handleInputChange(returnOnlyNumberFromEvent(e), 'stayMaxQuantity')}
                                        fullWidth
                                    />
                                    <Typography variant="subtitle2" sx={{ mt: 2 }}>Photos:</Typography>
                                    <div className='grid grid-cols-5 gap-4 my-3'>
                                        {variant.photos.items.map((photo, index) => {
                                            return (
                                                <div className='relative' key={photo.url}>
                                                    <button
                                                        className='absolute top-0 right-0 p-1 bg-red-400 hover:bg-red-500 text-white font-extralight rounded-full'
                                                        onClick={() => {
                                                            if (photo?.isNew) {
                                                                setPhotosAdded(prev => prev.filter((item, ind) => item !== photo.url));
                                                            } else {
                                                                setPhotosRemoved(prev => [...prev, photo])
                                                            }
                                                            const newPhotos = variant.photos.items.filter((item, ind) => index !== ind);
                                                            setVariant(prev => ({
                                                                ...prev,
                                                                photos: {
                                                                    ...prev.photos,
                                                                    items: newPhotos
                                                                }
                                                            }))
                                                        }}>
                                                        <RemoveIcon />
                                                    </button>
                                                    <img src={photo.url} alt={photo.url} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <UploadButton
                                        title={'Upload Images'}
                                        uploadUrls={[]}
                                        onUploadUrlChange={(data) => {
                                            setPhotosAdded(prev => [...prev, data[0]])
                                            setVariant(prev => {
                                                const newVariant = { ...prev };
                                                newVariant.photos.items.push({ url: data[0], isNew: true });
                                                return newVariant;
                                            });
                                        }}
                                    />
                                    <Typography variant="subtitle2" sx={{ mt: 2 }}>Room Amenities:</Typography>
                                    {variant.stayRoomAmenities?.map((amenity, amenityIndex) => {
                                        return (
                                            <Stack key={amenityIndex} direction="row" spacing={2} alignItems="center">
                                                <Input
                                                    value={amenity.amenityId}
                                                    disabled={true}
                                                    fullWidth
                                                />
                                                <Switch
                                                    checked={amenity.available || false}
                                                    onChange={(e) => handleAmenityChange(e, amenityIndex, 'available')}
                                                    color="primary"
                                                />
                                                <IconButton onClick={() => removeRoomAmenity(amenityIndex)} color="error">
                                                    <RemoveIcon />
                                                </IconButton>
                                            </Stack>
                                        )
                                    })}
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Autocomplete
                                            options={amenities}
                                            // getOptionLabel={(option) => option.label}
                                            value={selectedAmenity.amenityId}
                                            onChange={(e, newValue) => {
                                                setSelectedAmenity(prev => ({
                                                    ...prev,
                                                    amenityId: newValue?.value || ''
                                                }))
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Amenity ID" />}
                                            fullWidth
                                        />
                                        <Switch
                                            checked={selectedAmenity.available}
                                            onChange={(e) => {
                                                console.log(e.target.checked)
                                                setSelectedAmenity(prev => ({
                                                    ...prev,
                                                    available: e.target.checked
                                                }))
                                            }}
                                            color="primary"
                                        />
                                        <Button
                                            variant="outlined"
                                            onClick={() => addRoomAmenity(selectedAmenity)}
                                            startIcon={<AddIcon />}
                                            sx={{ mt: 2 }}
                                        >
                                            Add
                                        </Button>
                                    </Stack>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Button
                    variant="contained"
                    onClick={saveFinalData}
                    sx={{ mt: 3 }}
                    startIcon={isLoading ? <CircularProgress size="1rem" /> : null}
                >
                    Save All
                </Button>
            </Container>
        </Page>
    );
}
