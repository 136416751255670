import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  Autocomplete,
} from '@mui/material';
import { Container, Typography } from '@mui/material';
import Page from '../../components/Page';
import axios from 'axios';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import PropTypes from 'prop-types';
import { API_URL } from 'src/utils/constants';

export default function MakeBooking() {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    activityName: '',
    variantName: '',
    date: '',
    adultPrice: 1000,
    total: 0,
  });
  const [activities, setActivities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activityVariants, setActivityVariants] = useState([]);

  useEffect(() => {
    const fetchActivities = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${API_URL}/activity/mb?bookingType=passprt-managed&status=live&placeId=p2975b6`
        );
        const fetchedActivities = response.data.map((activity) => ({
          id: activity.slug,
          slug: activity.slug,
          title: activity.label,
          priceLabel: activity.priceLabel,
        }));

        setActivities(fetchedActivities);
      } catch (error) {
        console.error('Error fetching activities:', error);
      }
      setIsLoading(false);
    };

    fetchActivities();
  }, []);

  const handleSelectChange = async (e) => {
    const selectedTitle = e.target.value;
    setFormData({ ...formData, activityName: selectedTitle });

    const selectedActivity = activities.find(
      (activity) => activity.title === selectedTitle
    );
    if (selectedActivity) {
      try {
        // Assuming variants are fetched based on the selected activity's slug
        const response = await axios.get(
          `${API_URL}/activity/${selectedActivity.slug}`
        );
        setActivityVariants(response.data.variants || []);
      } catch (error) {
        console.error('Error fetching activity variants:', error);
        setActivityVariants([]);
      }
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //   const handleAddBooking = () => {
  //     setFormData({ ...formData, total: formData.total + formData.adultPrice });
  //   };

  const handleCreateOrder = async () => {
    // Find the selected activity and variant
    const selectedActivity = activities.find(
      (activity) => activity.title === formData.activityName
    );
    const selectedVariant = activityVariants.find(
      (variant) => variant.title === formData.variantName
    );

    if (!selectedActivity || !selectedVariant) {
      console.error('Selected activity or variant not found');
      return;
    }

    // Prepare the data to be sent
    const orderData = {
      bookings: [
        {
          activityId: selectedActivity.slug,
          activitySlug: selectedActivity.slug,
          activityTitle: selectedActivity.title,
          thumbnail: selectedActivity.thumbnail || '',
          totalPrice: formData.total,
          date: new Date(formData.date).toISOString(),
          dateLabel: new Date(formData.date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }),
          variants: [
            {
              variantId: selectedVariant.id,
              variantTitle: selectedVariant.title,
              pricing: [
                {
                  quantity: formData.total / selectedVariant.price,
                  title: 'Person',
                },
              ],
            },
          ],
          startDate: new Date(formData.date).toISOString(),
        },
      ],
      user: {
        phone: formData.phone,
        name: formData.name,
      },
      paymentMethod: 'razorpay-gateway-and-link',
      amountFinal: formData.total, // Assuming no discounts for now
      amountTotal: formData.total,
      amountDiscount: 0, // Assuming no discounts for now
      coupon: '', // Assuming no coupon for now
    };

    try {
      // Sending POST request to the server with the orderData
      const response = await axios.post(`${API_URL}/order`, orderData);
      console.log('Order response:', response.data);
      // Handle further logic here, like redirecting to a thank you page or resetting the form
    } catch (error) {
      console.error('Error creating order:', error);
    }
  };
  const Pricing = ({ price, onAdd, onRemove, count }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          my: 2,
        }}
      >
        <Box>
          <Typography variant="subtitle1">Person</Typography>
          <Typography variant="caption" color="text.secondary">
            Above 14 years old
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button size="small" onClick={onRemove} disabled={count <= 1}>
            <RemoveCircleOutlineIcon />
          </Button>
          <Typography sx={{ marginX: 1 }}>{count}</Typography>
          <Button size="small" onClick={onAdd}>
            <AddCircleOutlineIcon />
          </Button>
          <Typography>₹{price}</Typography>
        </Box>
      </Box>
    );
  };

  Pricing.propTypes = {
    price: PropTypes.number.isRequired,
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    count: PropTypes.number.isRequired,
  };

  Pricing.defaultProps = {
    count: 1, // Assuming 1 as default count if not passed
  };

  return (
    <Page title="Create Booking | Travel App">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Book an Activity
        </Typography>
        <Box sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }}>
          <TextField
            required
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          <TextField
            required
            label="Phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </Box>
        {/* <Box sx={{ m: 1 }}>
          <FormControl fullWidth>
            <InputLabel id="activity-name-label">Activity Name</InputLabel>
            <Select
              labelId="activity-name-label"
              name="activityName"
              value={formData.activityName}
              label="Activity Name"
              onChange={handleSelectChange}
              disabled={isLoading}
            >
              {activities.map((activity) => (
                <MenuItem key={activity.slug} value={activity.title}>
                  {activity.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box> */}
        <label>Activity Name</label>
        <input
          onChange={handleSelectChange}
          name="activityName"
          value={formData.activityName}
          disabled={isLoading}
        />
        <label>Activity Name</label>
        <Autocomplete
          id="combo-box-demo"
          options={activities}
          sx={{ width: 340 }}
          renderInput={(params) => <TextField {...params} label="" />}
          onChange={handleSelectChange}
        />
        <Box sx={{ m: 1 }}>
          <FormControl fullWidth>
            <InputLabel id="variant-name-label">Variant Name</InputLabel>
            <Select
              labelId="variant-name-label"
              name="variantName"
              value={formData.variantName}
              label="Variant Name"
              onChange={handleChange}
              disabled={!activityVariants.length}
            >
              {activityVariants.map((variant) => (
                <MenuItem key={variant.id} value={variant.title}>
                  {variant.title} - {variant.desc} (₹{variant.price})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {/* Add Date Selector */}

        <Box sx={{ m: 1 }}>
          <TextField
            id="date"
            label="Date"
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>

        <Box sx={{ m: 1 }}>
          <Typography variant="h6">Pricing</Typography>
          <Pricing
            price={formData.adultPrice}
            count={formData.total / formData.adultPrice} // assuming this is the count of adults
            onAdd={() =>
              setFormData({
                ...formData,
                total: formData.total + formData.adultPrice,
              })
            }
            onRemove={() =>
              setFormData({
                ...formData,
                total: formData.total - formData.adultPrice,
              })
            }
          />
          <Typography variant="h6">Total: ₹ {formData.total}</Typography>
        </Box>
        <Button
          onClick={handleCreateOrder}
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Create Order
        </Button>
      </Container>
    </Page>
  );
}
