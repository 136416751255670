import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
  Box,
} from '@mui/material';
import { activityTypes } from './activityTypes';
import { StayVariantAmenities } from './activityTypes/stay/stayVariantAmenities';
import { ActivityBanners } from '../banners/activityBanners';

export const ActivityVariant = ({ activityType, onVariantChange }) => {
  const [variants, setVariants] = useState([]);

  const handleVariationNameChange = (event) => {
    const temp = [...variants];
    temp[event.target.id].title = event.target.value;
    setVariants(temp);
  };

  const handleVariationDescChange = (event) => {
    const temp = [...variants];
    temp[event.target.id].desc = event.target.value;
    setVariants(temp);
  };

  const handleVariationPhotoIdChange = (event) => {
    const temp = [...variants];
    temp[event.target.id].photoId = event.target.value;
    setVariants(temp);
  };

  const removeVariant = (event) => {
    variants.splice(event.target.id, 1);
    setVariants([...variants]);
  };

  const onVariantAmenitiesChanged = (idx, id, label) => {
    setVariants((prev) => {
      const temp = [...prev];
      if (temp.length > idx) {
        let newAmenitiesProvided = [];
        const amititesProvided = prev[idx].secondaryIconLabelView || [];
        const isExisting = amititesProvided.filter(
          (a) => a.iconId == id
        ).length;
        if (isExisting) {
          newAmenitiesProvided = amititesProvided.filter((a) => a.iconId != id);
        } else {
          newAmenitiesProvided = [
            ...amititesProvided,
            { iconId: id, title: label },
          ];
        }
        temp[idx].secondaryIconLabelView = newAmenitiesProvided;
      }
      return temp;
    });
  };

  const addVariant = () => {
    setVariants((prev) => [
      ...prev,
      {
        title: '',
        desc: '',
        banners: [],
        secondaryIconLabelView: [],
        photoId: '',
      },
    ]);
  };

  const onVariantUploadUrlChange = (idx, urls) => {
    setVariants((prev) => {
      prev[idx].banners = urls;
      return prev;
    });
  };

  useEffect(() => {
    onVariantChange(variants);
  }, [variants]);

  return (
    <>
      <div className="addVariants">
        <Stack direction="column" sx={{ mt: 5 }}></Stack>
        <Typography variant="h5"> Variants </Typography>
        {variants.map((variant, idx) => (
          <div key={idx}>
            <Card>
              <CardContent>
                <h6 className="close" id={idx} onClick={removeVariant}>
                  Close
                </h6>
                <label>Variant Name</label>
                <input
                  id={idx}
                  label="Variation Name"
                  onChange={handleVariationNameChange}
                  value={variant.title}
                />
                <label>Description</label>
                <textarea
                  id={idx}
                  label="Variation Name"
                  onChange={handleVariationDescChange}
                  value={variant.desc}
                />
                <label>Photos</label>
                <input
                  id={idx}
                  label="Photos"
                  onChange={handleVariationPhotoIdChange}
                  value={variant.photoId}
                />
                {activityType === activityTypes.STAY && (
                  <StayVariantAmenities
                    idx={idx}
                    onVariantAmenitiesChanged={onVariantAmenitiesChanged}
                  />
                )}
                <div className="textarea-wrapper"></div>
                {activityType === activityTypes.STAY && (
                  <ActivityBanners
                    handleActivityBannerChange={(urls) =>
                      onVariantUploadUrlChange(idx, urls)
                    }
                  />
                )}
              </CardContent>
            </Card>
          </div>
        ))}
      </div>
      <Stack direction="column" sx={{ mt: 5 }}></Stack>
      <Box>
        <Button variant="contained" onClick={addVariant}>
          Add Variants
        </Button>
      </Box>
    </>
  );
};

ActivityVariant.propTypes = {
  activityType: PropTypes.string,
  onVariantChange: PropTypes.func.isRequired,
};
