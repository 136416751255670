import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import {
  amenities as amenityTypes,
  stayVariantAmenitiesLabels,
} from '../amenities';

const amenities = {
  [amenityTypes.WASHROOM_ATTACHED]: {
    id: amenityTypes.WASHROOM_ATTACHED,
    iconId: amenityTypes.WASHROOM_ATTACHED,
    label: stayVariantAmenitiesLabels[amenityTypes.WASHROOM_ATTACHED],
  },
  [amenityTypes.BALCONY]: {
    id: amenityTypes.BALCONY,
    iconId: amenityTypes.BALCONY,
    label: stayVariantAmenitiesLabels[amenityTypes.BALCONY],
  },

  [amenityTypes.AIR_CONDITIONING]: {
    id: amenityTypes.AIR_CONDITIONING,
    iconId: amenityTypes.AIR_CONDITIONING,
    label: stayVariantAmenitiesLabels[amenityTypes.AIR_CONDITIONING],
  },
  [amenityTypes.AIR_COOLER]: {
    id: amenityTypes.AIR_COOLER,
    iconId: amenityTypes.AIR_COOLER,
    label: stayVariantAmenitiesLabels[amenityTypes.AIR_COOLER],
  },
  [amenityTypes.PORTABLE_FAN]: {
    id: amenityTypes.PORTABLE_FAN,
    iconId: amenityTypes.PORTABLE_FAN,
    label: stayVariantAmenitiesLabels[amenityTypes.PORTABLE_FAN],
  },

  [amenityTypes.ROOM_HEATER]: {
    id: amenityTypes.ROOM_HEATER,
    iconId: amenityTypes.ROOM_HEATER,
    label: stayVariantAmenitiesLabels[amenityTypes.ROOM_HEATER],
  },

  [amenityTypes.GEYSER]: {
    id: amenityTypes.GEYSER,
    iconId: amenityTypes.GEYSER,
    label: stayVariantAmenitiesLabels[amenityTypes.GEYSER],
  },
  [amenityTypes.BATHING_HOT_WATER]: {
    id: amenityTypes.BATHING_HOT_WATER,
    iconId: amenityTypes.BATHING_HOT_WATER,
    label: stayVariantAmenitiesLabels[amenityTypes.BATHING_HOT_WATER],
  },

  [amenityTypes.ELECTRIC_KETTLE]: {
    id: amenityTypes.ELECTRIC_KETTLE,
    iconId: amenityTypes.ELECTRIC_KETTLE,
    label: stayVariantAmenitiesLabels[amenityTypes.ELECTRIC_KETTLE],
  },
};

export const StayVariantAmenities = ({ idx, onVariantAmenitiesChanged }) => {
  const [checkBoxState, setCheckBoxState] = useState({
    [amenityTypes.WASHROOM_ATTACHED]: false,
    [amenityTypes.AIR_CONDITIONING]: false,
    [amenityTypes.PORTABLE_FAN]: false,
    [amenityTypes.RIVER_VIEW]: false,
    [amenityTypes.BALCONY]: false,
  });
  return (
    <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
      <FormGroup>
        {Object.keys(amenities).map((aminityType) => (
          <FormControlLabel
            key={amenities[aminityType].id}
            control={
              <Checkbox
                name={aminityType}
                checked={checkBoxState[aminityType]}
                onChange={(event) => {
                  setCheckBoxState({
                    ...checkBoxState,
                    [aminityType]: event.target.checked,
                  });
                  onVariantAmenitiesChanged(
                    idx,
                    amenities[aminityType].iconId,
                    amenities[aminityType].label
                  );
                }}
              />
            }
            label={amenities[aminityType].label}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

StayVariantAmenities.propTypes = {
  idx: PropTypes.number.isRequired,
  onVariantAmenitiesChanged: PropTypes.func.isRequired,
};
