/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Page from '../../components/Page';
import { Container, MenuItem, Select, Button, Divider, TextField, TextareaAutosize, Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import { API_URL } from 'src/utils/constants';
import { useParams } from 'react-router-dom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { formatCurrency } from 'src/utils/formatCurrency';
import PropTypes from 'prop-types'
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { fDateTimeSuffix } from 'src/utils/formatTime';
import axiosMB from 'src/utils/axios/interceptors';
import { AddNote, Notes } from '../Bookings/SingleBooking';
import moment from 'moment';
import axios from 'axios';
import ChangeBookingVariants from './ChangeBookingVariants';


export default function Developers() {


    return (
        <div>
            {/* <h1>Developers</h1>
            <AIEnhancedInput placeholder="Input text here" className="w-full p-2 resize-none" />
             */}
            <h1>Edit Booking</h1>
            {/* <BookingDetailsPage bookingId={'vvf9z6'} /> */}
            {/* <BookingDetailsPage bookingId={'vvlsnh'} /> */}
            <BookingDetailsPage bookingId={'53fu0q'} />
        </div>
    );
}


const BookingDetailsPage = ({ bookingId }) => {
    const [booking, setBooking] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const updateBooking = async (changes) => {
        const { id: bookingId, changedData: updateFields } = changes
        try {
            const response = await axiosMB.patch(
                `${API_URL}/admin/mb/bookings/${bookingId}`,
                { updateFields },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (response.status === 200) {
                location.reload();
            }
        } catch (error) {
            console.error('Failed to update booking finance:', error);
        }
    };

    useEffect(() => {
        const fetchBooking = async () => {
            try {
                setIsLoading(true);
                // Replace 'your_api_endpoint' with the actual API endpoint.
                const response = await fetch(
                    `${API_URL}/admin/mb/bookings/${bookingId}`
                );
                const data = await response.json();
                setBooking(data);
                setIsLoading(false);
            } catch (error) {
                console.error('Failed to fetch bookings:', error);
            }
        };
        fetchBooking();
    }, []);

    const { id } = booking;


    return (
        <Page>
            <Container>
                {id === null || isLoading ? (
                    <h1>Loading...</h1>
                ) : (
                    <>
                        <div className='flex flex-col justify-start gap-8'>
                            <ChangeBookingVariants bookingId={id} />
                        </div></>)}
            </Container>
        </Page>)
}