import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
// import { addDays } from 'date-fns';
import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
export const compareDates = (d1, d2) => {
    let date1 = new Date(d1).getTime();
    let date2 = new Date(d2).getTime();
    if (date1 < date2 || date1 > date2) {
        return false;
    }
    return true;
};


export default function DatePicker({ onSelect, startDate, endDate }) {
    const [selectionRange, setSelectionRange] = useState({
        startDate: startDate || new Date(),
        endDate: endDate || new Date(new Date().setDate(new Date().getDate() + 1)),
        key: 'selection',
    });
    const handleSelect = (ranges) => {
        if (!ranges.selection) return;
        setSelectionRange(ranges.selection);
        // if (compareDates(ranges.selection.startDate, ranges.selection.endDate)) {
        onSelect({
            startDate: moment(ranges.selection.startDate).format('YYYY-MM-DD'),
            endDate: moment(ranges.selection.endDate).add(1, 'days').format('YYYY-MM-DD'),
        });
        // } else {
        //     onSelect({
        //         startDate: moment(ranges.selection.startDate).format('YYYY-MM-DD'),
        //         endDate: moment(ranges.selection.endDate).format('YYYY-MM-DD'),
        //     });
        // }
    };


    return (
        <DateRangePicker
            rangeColors={['#ff455b']}
            inputRanges={[new Date()]}
            editableDateInputs={true}
            onChange={handleSelect}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            direction={"horizontal"}
            scroll={{ enabled: true }}
            ranges={[selectionRange]}
            preventSnapRefocus={true}
        />
    );
}

DatePicker.propTypes = {
    onSelect: PropTypes.func,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
};