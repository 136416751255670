import { statusColors } from 'src/utils/statuses/colors';

export const listBookingStatusLabels = {
    "cancelled-refund-pending": "Cancelled Refund Pending",
    "confirmed": "Confirmed",
    "completed": "Completed",

    "partner-settlement-acknowledgement-pending": "Partner Acknowledgement Pending",
    "partner-settlement-pending": "Settlement Pending",


    "completed-settlement-done": "Completed Settlement Done",
    "cancelled-refund-processed": "Cancelled Refund Processed",
    "cancelled-refund-adjusted": "Cancelled Refund Adjusted",
    "cancelled-no-refund": "Cancelled No Refund",
    "cancelled-partial-refund": "Cancelled Partial Refund",

    "processing": "Processing",
    "confirmation-pending": "Confirmation Pending",
    "confirmed-and-partner-action-pending": "Confirmed and Partner Action Pending",
};


export const listBookingStatusColors = {
    // Orange
    "cancelled-refund-pending": statusColors.processing,
    "confirmed": statusColors.processing,
    "completed": statusColors.processing,
    "partner-settlement-acknowledgement-pending": statusColors.processing,

    // Light Green
    "partner-settlement-pending": statusColors.softAction,

    // Green
    "completed-settlement-done": statusColors.done,
    "cancelled-refund-processed": statusColors.done,
    "cancelled-refund-adjusted": statusColors.done,
    "cancelled-no-refund": statusColors.done,
    "cancelled-partial-refund": statusColors.done,

    // Red
    "processing": statusColors.action,
    "confirmation-pending": statusColors.action,
    "confirmed-and-partner-action-pending": statusColors.action,
};

// Booking Details
export const bookingDetailsStatusLabel = {
    "confirmed": "Confirmed",
    "completed": "Settlement Pending",

    "partner-settlement-acknowledgement-pending": "Partner Acknowledgement Pending",
    "partner-settlement-pending": "Settlement Pending",


    "cancelled-refund-pending": "Cancelled",
    "completed-settlement-done": "Settlement Done",
    "cancelled-refund-processed": "Cancelled",
    "cancelled-refund-adjusted": "Cancelled",
    "cancelled-no-refund": "Cancelled",
    "cancelled-partial-refund": "Cancelled",

    "processing": "Processing",
    "confirmation-pending": "Confirmation Pending",
    "confirmed-and-partner-action-pending": "Action Pending",
};
export const bookingDetailsStatusColors = {
    // Orange
    "cancelled-refund-pending": statusColors.processing,
    "confirmed": statusColors.processing,
    "completed": statusColors.processing,
    "partner-settlement-acknowledgement-pending": statusColors.processing,

    // Light Green
    "partner-settlement-pending": statusColors.softAction,

    // Green
    "completed-settlement-done": statusColors.done,
    "cancelled-refund-processed": statusColors.done,
    "cancelled-refund-adjusted": statusColors.done,
    "cancelled-no-refund": statusColors.done,
    "cancelled-partial-refund": statusColors.done,
    // Red
    "processing": statusColors.action,
    "confirmation-pending": statusColors.action,
    "confirmed-and-partner-action-pending": statusColors.action,
};


