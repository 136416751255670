import React, { useState } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { UploadButton } from 'src/components/buttons/upload';

const PartnerPhotosUploader = ({ photos, setPhotos }) => {
    const [urls, setUrls] = useState('');
    const [banners, setBanners] = useState([]);
    console.log(banners);

    const onBannerChangeUrl = (urls) => {
        setBanners(urls);
        setUrls(urls.join('\n'));

        setPhotos(prev => {
            const newPhotos = urls
                .filter(url => url !== '' && !prev.some(photo => photo.url === url))
                .map(url => ({
                    // ...newPhoto,
                    url,
                    uploadDate: new Date(),
                    createdAt: new Date()
                }));

            return [...prev, ...newPhotos];
        });
    };

    return (
        <Box mb={2} className='w-full'>
            <h3 className="text-lg font-semibold">Photos</h3>
            <div className='w-full grid grid-cols-1 lg:grid-cols-2 gap-4 my-3'>
                {photos.map((photo,
                    // index
                ) => (
                    <div className='grid grid-cols-1 place-items-center gap-2 p-2 border rounded-lg' key={photo.url} >
                        <div className='flex items-center justify-between gap-2 w-full'>
                            <p className='text-sm'>Upload Date: {new Date(photo.uploadDate).toLocaleDateString()}</p>
                        </div>
                        <img
                            className='aspect-square w-52'
                            src={photo.url}
                            alt="uploaded-image" />
                    </div>
                ))}
            </div>
            <div className='flex items-center justify-between gap-2'>
                <UploadButton
                    title="Upload photos"
                    uploadUrls={urls.split('\n')}
                    onUploadUrlChange={onBannerChangeUrl}
                />
            </div>
        </Box>
    );
};

PartnerPhotosUploader.propTypes = {
    photos: PropTypes.array.isRequired,
    setPhotos: PropTypes.func.isRequired,
};

export {
    PartnerPhotosUploader
};
