// @ts-check

import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

/**
 * @typedef {import('../../types/Pricing.type').Pricing} Pricing
 */

/**
 * @param {object} props
 * @param {Pricing[]} props.pricing
 * @param {function} props.setSelectedPricing
 */
const StayPricingSelector = ({
    maxRoomsCount = 4,
    pricing,
    setSelectedPricing,
}) => {
    const [roomPricing, setRoomPricing] = useState(pricing[0]);
    const extraAdultPricing = roomPricing.extraPrices.find(
        (p) => p.id === 'stay-extra-adult'
    ).price;
    const extraKidPricing = roomPricing.extraPrices.find(
        (p) => p.id === 'stay-extra-kid'
    ).price;

    const [totalPrice, setTotalPrice] = useState(roomPricing.price);
    const [roomsCount, setRoomsCount] = useState(1);
    const [adultsCount, setAdultsCount] = useState(1);
    const [kidsCount, setKidsCount] = useState(0);

    const calculateTotalPrice = () => {
        const baseAdultPrice = roomPricing.price / roomPricing.minQuantity;
        const basePrice = roomPricing.price * roomsCount;
        const kidsPrice = extraKidPricing * kidsCount;
        const minAdults = roomPricing.minQuantity * roomsCount;
        const adultsPrice =
            adultsCount <= minAdults
                ? baseAdultPrice * adultsCount
                : baseAdultPrice * minAdults +
                extraAdultPricing *
                (adultsCount - roomPricing.minQuantity * roomsCount);
        const _totalPrice =
            kidsPrice + adultsPrice > basePrice ? kidsPrice + adultsPrice : basePrice;
        setTotalPrice(_totalPrice);
        setSelectedPricing({
            pricing: [
                {
                    pricingId: roomPricing.id,
                    pricingTitle: 'Meals',
                    pricingValue: MEAL_PLANS[roomPricing.type],
                    quantity: adultsCount + kidsCount,
                    title: 'Guests',
                    extraPricing: {
                        rooms: roomsCount,
                        adults: adultsCount,
                        kids: kidsCount,
                    }
                },
            ],
            totalPrice: _totalPrice,
        });
    };

    useEffect(() => {
        setRoomPricing(pricing[0])
    }, [pricing])
    useEffect(() => {
        calculateTotalPrice();
    }, [adultsCount, kidsCount, roomsCount, roomPricing]);

    const handleAdultCountChange = (quantity) => {
        setAdultsCount(quantity);
    };
    const handleKidCountChange = (quantity) => {
        setKidsCount(quantity);
    };

    const handleRoomCountChange = (quantity) => {
        if (quantity < roomsCount) handleAdultCountChange(1);
        if (quantity < roomsCount) handleKidCountChange(0);
        setRoomsCount(quantity);
    };

    const handleMealplanChange = (e) => {
        setRoomPricing(pricing[e.target.value]);
    };
    const MEAL_PLANS = {
        'stay-meal-ep': 'No Meals',
        'stay-meal-cp': 'Breakfast',
        'stay-meal-mapi': 'Breakfast and Lunch / Dinner',
        'stay-meal-apai': 'Breakfast, Lunch and Dinner',
    };

    return (
        <div >
            <div className="grid grid-cols-1 gap-6 rounded-xl bg-gray-200/50 p-4">
                <div key="rooms" className="flex items-center justify-between">
                    <div className="price-info">
                        <h2>Meals</h2>
                        <h3 />
                    </div>
                    <div className="price-action">
                        <select onChange={handleMealplanChange}>
                            {pricing &&
                                pricing.map((p, index) => {
                                    return (
                                        <option value={index} key={p.id}>
                                            {MEAL_PLANS[p.type]}
                                        </option>
                                    )
                                })}
                        </select>
                    </div>
                </div>

                <div key="rooms" className="flex items-center justify-between">
                    <div className="price-info">
                        <h2>Rooms</h2>
                        <h3>
                            Maximum
                            {` ${roomPricing.maxQuantity * roomsCount}`} adults allowed per
                            room
                        </h3>
                    </div>
                    <div className="flex items-center justify-center gap-6">
                        <button
                            onClick={() => handleRoomCountChange(roomsCount - 1)}
                            disabled={roomsCount <= 1}
                            className="bg-red-100/50 w-10 aspect-square rounded-full flex items-center justify-center"
                        >
                            <span>-</span>
                        </button>
                        <span className="current-quantity">{roomsCount}</span>
                        <button
                            onClick={() => handleRoomCountChange(roomsCount + 1)}
                            disabled={roomsCount >= maxRoomsCount}
                            className="bg-red-100/50 w-10 aspect-square rounded-full flex items-center justify-center"
                        >
                            <span>+</span>
                        </button>
                    </div>
                </div>

                <div key="rooms" className="flex items-center justify-between">
                    <div className="price-info">
                        <h2>Adults</h2>
                        <h3 />
                    </div>
                    <div className="flex items-center justify-center gap-6">
                        <button
                            onClick={() => handleAdultCountChange(adultsCount - 1)}
                            disabled={adultsCount <= 1}
                            className="bg-red-100/50 w-10 aspect-square rounded-full flex items-center justify-center"
                        >
                            <span>-</span>
                        </button>
                        <span className="current-quantity">{adultsCount}</span>
                        <button
                            onClick={() => handleAdultCountChange(adultsCount + 1)}
                            disabled={adultsCount >= roomPricing.maxQuantity * roomsCount}
                            className="bg-red-100/50 w-10 aspect-square rounded-full flex items-center justify-center"
                        >
                            <span>+</span>
                        </button>
                    </div>
                </div>
                <div key="rooms" className="flex items-center justify-between">
                    <div className="price-info">
                        <h2>Kids</h2>
                        <h3 />
                    </div>
                    <div className="flex items-center justify-center gap-6">
                        <button
                            onClick={() => handleKidCountChange(kidsCount - 1)}
                            disabled={kidsCount <= 0}
                            className="bg-red-100/50 w-10 aspect-square rounded-full flex items-center justify-center"
                        >
                            <span>-</span>
                        </button>
                        <span className="current-quantity">{kidsCount}</span>
                        <button
                            onClick={() => handleKidCountChange(kidsCount + 1)}
                            disabled={kidsCount >= roomPricing.maxQuantity * roomsCount}
                            className="bg-red-100/50 w-10 aspect-square rounded-full flex items-center justify-center"
                        >
                            <span>+</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="total-price">
                <span>Total</span>{' '}
                <span>
                    ₹{totalPrice.toLocaleString('en-IN', { maximumFractionDigits: 2 })}
                </span>
            </div>
        </div>
    );
};

StayPricingSelector.propTypes = {
    maxRoomsCount: PropTypes.number,
    pricing: PropTypes.array.isRequired,
    setSelectedPricing: PropTypes.func.isRequired,
};
export default StayPricingSelector;
