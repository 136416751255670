// ignore eslint
/* eslint-disable */
// @ts-ignore

const TAXI_TYPE = {
  EECO: 'economy-class',
  SCORPIO: 'comfort-class',
  INNOVA: 'premium-class',
  CRYSTA: 'luxury-class',
  TRAVELLER: 'traveller',
};

const PLACE_ID = {
  LEH: 'a150f21',
  DISKIT: 'p25d04f',
  HUNDER: 'p6524c3',
  TURTUK: 'p8fd2a0',
  SUMUR: 'p25d041',
  PANAMIK: '',
  LUKUNG: 'a036c07',
  SPANGMIK: 'a036c05',
  MAN: 'a036c06',
  MEREK: 'a036c04',
  TSO_MORIRI: 'ac37499',
  HANLE: 'a150f22',
  SHEY: '',
  THIKSHEY: '',
  HEMIS: '',
  SRINAGAR: '',
  KARGIL: '',
  DRASS: '',
  SONAMARG: '',
};

const PLACES_TO_VISIT = {
  [PLACE_ID.DISKIT]: ['', ''],
  [PLACE_ID.DISKIT]: ['', ''],
  SHAM_VALLEY_POINTS: ['a6d7c07', 'a8f0ca3', 'a069fa8', 'af28d47', 'af28d47'],
};

const MEDIA = {
  LAMAYURU: '',
  MULBEKH:
    'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1720000582/mb/mulbekh-monastery.jpg.jpg',

  ALCHI:
    'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486888/activities/leh/alchi_monastery_rbhzum.jpg',
  THIKSEY_MONASTERY:
    'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714651973/mb/Thiksey-monastery.jpg.jpg',


  LEH_GENERIC:
    'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1715595805/mb/photo-1558187424-f786111643b0.avif.jpg',
  HALL_OF_FAME:
    'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714802393/mb/hall-of-fame-1.jpg.jpg',
  MAGNETIC_HILL:
    'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1715592750/mb/licensed-image-%283%29.jpeg.jpg',

  RANCHO_SCHOOL:
    'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/noz40nipxdtpgju2jus5.jpg',
  NUBRA_VALLEY:
    'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
  TURTUK:
    'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689492029/activities/leh/turtuk_alwvyq.jpg',
  TURTUK2:
    'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714480487/mb/turtuk.jpeg.jpg',


  SIACHEN_BASE_CAMP:
    'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1717326945/mb/0ace2adb24e39f801c61436bb20065a4_1000x1000.jpg.jpg',
  PANGONG_LAKE:
    'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
  HANLE:
    'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714906536/mb/pexels-nick-kwan-2745254-1_11zon-scaled.jpg.jpg',

  UMLING_PASS:
    'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1715843073/mb/DP-2.jpg.jpg',
  TSO_MORIRI:
    'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714740700/mb/licensed-image-%281%29.jpeg.jpg',
  PUGA_VALLEY:
    'https://res.cloudinary.com/passprt-media/image/upload/v1722335176/mb/puga3.webp.jpg',
};

const EXTRA_8KM_CHARGE = {
  [TAXI_TYPE.CRYSTA]: 345,
  [TAXI_TYPE.INNOVA]: 345,
  [TAXI_TYPE.SCORPIO]: 345,
  [TAXI_TYPE.EECO]: 345,
  [TAXI_TYPE.TRAVELLER]: 495,
};

const PRICE_FREE_DAY = {
  [TAXI_TYPE.CRYSTA]: 4132,
  [TAXI_TYPE.INNOVA]: 3792,
  [TAXI_TYPE.SCORPIO]: 3622,
  [TAXI_TYPE.EECO]: 3320,
  [TAXI_TYPE.TRAVELLER]: 4470,
};

const IN_ROUTE_HEMIS = {
  [TAXI_TYPE.CRYSTA]: 1141,
  [TAXI_TYPE.INNOVA]: 1037,
  [TAXI_TYPE.SCORPIO]: 985,
  [TAXI_TYPE.EECO]: 880,
  [TAXI_TYPE.TRAVELLER]: 1485,
};

const IN_ROUTE_MEREK = {
  // Maan-Meraik via Pangong in 2 days - Pangong-Lake, Spangmik in 2 days
  [TAXI_TYPE.CRYSTA]: 1064,
  [TAXI_TYPE.INNOVA]: 967,
  [TAXI_TYPE.SCORPIO]: 918,
  [TAXI_TYPE.EECO]: 832,
  [TAXI_TYPE.TRAVELLER]: 1464,
};

const IN_ROUTE_SUMUR = {
  // Disket-Hunder-Summor in 2 days - Disket-Hunder in 1/2 days
  [TAXI_TYPE.CRYSTA]: 1697,
  [TAXI_TYPE.INNOVA]: 1697,
  [TAXI_TYPE.SCORPIO]: 1697,
  [TAXI_TYPE.EECO]: 1697,
  [TAXI_TYPE.TRAVELLER]: 2105,
};

const IN_ROUTE_SUMUR_TWO_WAY = {
  // Disket-Hunder-Summor in 2 days - Disket-Hunder in 1/2 days
  [TAXI_TYPE.CRYSTA]: 2205,
  [TAXI_TYPE.INNOVA]: 2205,
  [TAXI_TYPE.SCORPIO]: 2205,
  [TAXI_TYPE.EECO]: 2205,
  [TAXI_TYPE.TRAVELLER]: 2735,
};

const IN_ROUTE_HANLE = {
  [TAXI_TYPE.CRYSTA]: 4658,
  [TAXI_TYPE.INNOVA]: 4235,
  [TAXI_TYPE.SCORPIO]: 4023,
  [TAXI_TYPE.EECO]: 3647,
  [TAXI_TYPE.TRAVELLER]: 5226,
};

const PRICE_UMLING_PASS = {
  [TAXI_TYPE.CRYSTA]: 7548,
  [TAXI_TYPE.INNOVA]: 6793,
  [TAXI_TYPE.SCORPIO]: 6453,
  [TAXI_TYPE.EECO]: 6130,
  [TAXI_TYPE.TRAVELLER]: 7700,
};

const PRICE_SS_LM_LP = {
  [TAXI_TYPE.CRYSTA]: 1675,
  [TAXI_TYPE.INNOVA]: 1522,
  [TAXI_TYPE.SCORPIO]: 1446,
  [TAXI_TYPE.EECO]: 1312,
  [TAXI_TYPE.TRAVELLER]: 1958,
};

const IN_ROUTE_THANG = {
  [TAXI_TYPE.CRYSTA]: 847,
  [TAXI_TYPE.INNOVA]: 770,
  [TAXI_TYPE.SCORPIO]: 731,
  [TAXI_TYPE.EECO]: 658,
  [TAXI_TYPE.TRAVELLER]: 0,
};

const IN_ROUTE_TURTUK = {
  [TAXI_TYPE.CRYSTA]: 5283,
  [TAXI_TYPE.INNOVA]: 4803,
  [TAXI_TYPE.SCORPIO]: 4563,
  [TAXI_TYPE.EECO]: 4136,
  [TAXI_TYPE.TRAVELLER]: 7321,
};

const PRICE_IN_ROUTE_PANAMIK = {
  [TAXI_TYPE.CRYSTA]: 2222, // 1652 2256 2481
  [TAXI_TYPE.INNOVA]: 2020, // 1501 2050 2255
  [TAXI_TYPE.SCORPIO]: 1919, // 1158 1944 2142
  [TAXI_TYPE.EECO]: 1727, // 1233
  [TAXI_TYPE.TRAVELLER]: 2733,
};

const PRICE_IN_ROUTE_PUGA = {
  [TAXI_TYPE.CRYSTA]: 690,
  [TAXI_TYPE.INNOVA]: 690,
  [TAXI_TYPE.SCORPIO]: 690,
  [TAXI_TYPE.EECO]: 690,
  [TAXI_TYPE.TRAVELLER]: 990,
};

const PRICE_DEDUCT_MAHAY_BRIDGE = {
  [TAXI_TYPE.CRYSTA]: 3308,
  [TAXI_TYPE.INNOVA]: 3007,
  [TAXI_TYPE.SCORPIO]: 2857,
  [TAXI_TYPE.EECO]: 2589,
  [TAXI_TYPE.TRAVELLER]: 1116,
};

const PRICE_DEDUCT_YAYA_TSO = {
  [TAXI_TYPE.CRYSTA]: 1619,
  [TAXI_TYPE.INNOVA]: 1472,
  [TAXI_TYPE.SCORPIO]: 1399,
  [TAXI_TYPE.EECO]: 1531,
  [TAXI_TYPE.TRAVELLER]: 1816,
};

const PRICE_DEDUCT_TURTUK = {
  [TAXI_TYPE.CRYSTA]: 5283,
  [TAXI_TYPE.INNOVA]: 4803,
  [TAXI_TYPE.SCORPIO]: 4563,
  [TAXI_TYPE.EECO]: 4136,
  [TAXI_TYPE.TRAVELLER]: 7321,//21883-14571 DESKIT, HUNDUR, TURTUK, ZERO POINT 2 DAYS - DESKIT, HUNDUR 2 DAYS
};

const ITINERARIES = {
  TAXI_FREE_DAY: [
    {
      thumbnail:
        'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1715595805/mb/photo-1558187424-f786111643b0.avif.jpg',
      title: 'Free day',
      desc: 'Spend the time exploring on your own. If you wish to use taxi, first 8 KMs are included in the package and beyond that, you will be charged extra at rate of ₹345 per 8 KM',
      descHtml: '',
    },
  ],

};

const SIMPLE_ITINERARY = {

  LEH_TO_NUBRA_VALLEY:
  {
    thumbnail: MEDIA.NUBRA_VALLEY,
    title: 'Leh to Nubra Valley via Khardung La',
    desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Diskit and Hunder villages, with optional double-hump camel rides',
    descHtml: '',
    places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
    distance: '130 KM',
    travel_time: '5 hrs',
  },
  NUBRA_VALLEY_TO_TURTUK:
  {
    thumbnail: MEDIA.TURTUK,
    title: 'Day visit to Turtuk, Tyakshi, Thang and Zero Point',
    desc: `Visit Turtuk and Thang, including Tyakshi, India's last village. Learn about Turtuk's shift from Pakistan to India. You can stay overnight in Turtuk or Hunder/Diskit as per your plan`,
    descHtml: '',
    places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
    distance: '200 KM',
    travel_time: '6 hrs',
  },
  NUBRA_VALLEY_TO_LEH:
  {
    thumbnail: MEDIA.LEH_GENERIC,
    title: 'Travel back to Leh',
    desc: 'Travel back to Leh',
    descHtml:
      '<p>Start day two with a visit to Turtuk, the northernmost village of India, where you can learn about the local culture and history. Admire the picturesque village landscape before beginning your journey back to Leh, bringing the unforgettable two-day journey to a close.</p>',
    places: [PLACE_ID.LEH],
    distance: '130 KM',
    travel_time: '4 hrs',
  },

  NUBRA_VALLEY_TO_PANGONG_VIA_SHYOK:
  {
    thumbnail: MEDIA.PANGONG_LAKE,
    title: 'Nubra Valley to Pangong',
    desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
    descHtml: '',
    places: [PLACE_ID.LUKUNG, PLACE_ID.SPANGMIK],
    distance: '171 KM',
    travel_time: '5 hrs',
  },

  NUBRA_VALLEY_TO_PANGONG_PLUS: //TO ACCOMODATE MAAN AND MERAK PLACE ID
  {
    thumbnail: MEDIA.PANGONG_LAKE,
    title: 'Nubra Valley to Pangong',
    desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
    descHtml: '',
    places: [PLACE_ID.LUKUNG, PLACE_ID.SPANGMIK, PLACE_ID.MAN, PLACE_ID.MEREK],
    distance: '171 KM',
    travel_time: '5 hrs',
  },

  LEH_TO_PANGONG_VIA_CHANGLA:
  {
    thumbnail:
      'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
    title: 'Leh to Pangong via Chang La',
    desc: 'Travel from Leh to Pangong via Chang La Pass, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
    descHtml: '',
    places: [PLACE_ID.LUKUNG, PLACE_ID.SPANGMIK],
    distance: '160 KM',
    travel_time: '5 hrs',
  },

  PANGONG_TO_LEH_VIA_CHANGLA:
  {
    thumbnail: MEDIA.LEH_GENERIC,
    title: 'Pangong to Leh',
    desc: 'Journey back to Leh from Pangong crossing Chang La Pass',
    descHtml: '',
    places: [PLACE_ID.LEH],
    distance: '160 KM',
    travel_time: '5 hrs',
  },

  PANGONG_TO_LEH_VIA_HEMIS:
  {
    thumbnail: MEDIA.RANCHO_SCHOOL,
    title: 'Pangong to Leh',
    desc: 'Journey back to Leh from Pangong crossing Chang La Pass,stopping to explore the rich heritage at Hemis, Thiksey, Shey, and the famous Rancho School.',
    descHtml: '',
    places: [PLACE_ID.LEH],
    distance: '160 KM',
    travel_time: '5 hrs',
  },

  PANGONG_TO_TSOMORIRI_VIA_TSAGALA:
  {
    thumbnail: MEDIA.TSO_MORIRI,
    title: 'Pangong to Tso Moriri via Tsaga La',
    desc: 'Travel from Pangong to Tso Moriri, covering Chushul and Rezing La War Memorial. Spend the night at the Village:Karzok nearby lake.',
    descHtml: '',
    places: [PLACE_ID.TSO_MORIRI],
    distance: '210 KM',
    travel_time: '7 hrs',
  },

  PANGONG_TO_TSOMORIRI_VIA_YAYATSO:
  {
    thumbnail:
      'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714740700/mb/licensed-image-%281%29.jpeg.jpg',
    title: 'Pangong to Tso Moriri via Yaya Tso',
    desc: 'Travel from Pangong to Tso Moriri, covering Thato La,Mirpal Tso, Yaya Tso. Spend the night at the Village:Karzok nearby lake.',
    descHtml: '',
    places: [PLACE_ID.TSO_MORIRI],
    distance: '171 KM',
    travel_time: '6 hrs',
  },
  PANGONG_TO_HANLE:
  {
    thumbnail: MEDIA.HANLE,
    title: 'Pangong to Hanle',
    desc: 'Travel from Pangong to Rezangla Memorial to Hanle, a remote village in Ladakh know for Stargazing. Visit the Hanle Monastery and the Indian Astronomical Observatory.',
    descHtml: '',
    places: [PLACE_ID.HANLE],
    distance: '160 KM',
    travel_time: '5 hrs',
  },
  HANLE_TO_TSOMORIRI:
  {
    thumbnail:
      'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714740700/mb/licensed-image-%281%29.jpeg.jpg',
    title: 'Hanle to Tso Moriri',
    desc: 'Travel to Tso Moriri, a high-altitude lake known for its serene beauty and birdlife. Spend the night at the lake.',
    descHtml: '',
    places: [PLACE_ID.TSO_MORIRI],
    distance: '150 KM',
    travel_time: '5 hrs',
  },

  TSOMORIRI_TO_LEH_VIA_TSOKAR:
  {
    thumbnail: MEDIA.PUGA_VALLEY,
    title: 'Tso Moriri to Leh via Tso kar',
    desc: 'Journey back to Leh covering unique hotsprings of Puga Valley, Tso Kar lake and Tanglang La Pass',
    descHtml: '',
    places: [PLACE_ID.LEH],
    distance: '232 KM',
    travel_time: '7 hrs',
  },

  TSOMORIRI_TO_LEH_VIA_MAHE_BRIDGE:
  {
    thumbnail: MEDIA.PUGA_VALLEY,
    title: 'Tso Moriri to Leh via Mahe Bridge',
    desc: 'Journey back to Leh covering unique hotsprings of Puga Valley ',
    descHtml: '',
    places: [PLACE_ID.LEH],
    distance: '222 KM',
    travel_time: '6 hrs',
  },
}

const LADAKH_PRESET = [
  /* Sample Itinerary */
  {
    title: 'Sample Itinerary',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 1,
      [TAXI_TYPE.INNOVA]: 1,
      [TAXI_TYPE.SCORPIO]: 1,
      [TAXI_TYPE.EECO]: 1,
      [TAXI_TYPE.TRAVELLER]: 1,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1715595805/mb/photo-1558187424-f786111643b0.avif.jpg',
        title: 'Free day',
        desc: 'Spend the time exploring on your own. No itinerary planned for the day.',
        descHtml: '',
      },
    ],
  },

  /* Free day */
  {
    title: 'Free day',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 0,
      [TAXI_TYPE.INNOVA]: 0,
      [TAXI_TYPE.SCORPIO]: 0,
      [TAXI_TYPE.EECO]: 0,
      [TAXI_TYPE.TRAVELLER]: 0,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1715595805/mb/photo-1558187424-f786111643b0.avif.jpg',
        title: 'Free day',
        desc: 'Spend the time exploring on your own. No itinerary planned for the day.',
        descHtml: '',
      },
    ],
  },

  /* Free day + taxi */
  {
    title: 'Free day + taxi',
    pricingOptions: PRICE_FREE_DAY,
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1715595805/mb/photo-1558187424-f786111643b0.avif.jpg',
        title: 'Free day',
        desc: 'Spend the time exploring on your own. If you wish to use taxi, first 8 KMs are included in the package and beyond that, you will be charged extra at rate of ₹345 per 8 KM',
        descHtml: '',
      },
    ],
  },

  /* Airport Pickup to Leh */
  {
    title: 'Airport Pickup to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 935,
      [TAXI_TYPE.INNOVA]: 850,
      [TAXI_TYPE.SCORPIO]: 850,
      [TAXI_TYPE.EECO]: 812,
      [TAXI_TYPE.TRAVELLER]: 1400,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/dog0wl2gl8cojhxno92m.jpg',
        title: 'Airport to Hotel Transfer',
        desc: 'Arrive in Leh, transfer to your hotel, and acclimatize comfortably. Spend the evening exploring the local market on your own.',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
  },

  /* Airport Pickup + SS + LM + LP */
  {
    title: 'Airport Pickup + SS + LM + LP',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 2610,
      [TAXI_TYPE.INNOVA]: 2372,
      [TAXI_TYPE.SCORPIO]: 2296,
      [TAXI_TYPE.EECO]: 2124,
      [TAXI_TYPE.TRAVELLER]: 3385,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714801912/mb/shanti-stupta-leh3.jpg.jpg',
        title: 'Airport to Hotel Transfer and Local Sightseeing',
        desc: 'Arrive in Leh, transfer to your hotel, and acclimatize comfortably. Spend the evening exploring Shanti Stupa, Royal Leh Palace, Leh Market.',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
  },

  /* Airport Pickup + Sham Valley + SS + LM + LP */
  {
    title: 'Airport Pickup + Sham Valley + SS + LM + LP',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 6359,
      [TAXI_TYPE.INNOVA]: 5780,
      [TAXI_TYPE.SCORPIO]: 5534,
      [TAXI_TYPE.EECO]: 5059,
      [TAXI_TYPE.TRAVELLER]: 7563,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714801912/mb/shanti-stupta-leh3.jpg.jpg',
        title: 'Airport to Hotel Transfer and Local Sightseeing',
        desc: 'Arrive in Leh, transfer to your hotel, and acclimatize comfortably. Visit Hall of Fame, Sangam Point, Gurudwara Pathar Sahib, Magnetic Hill, and Stupik Monastery. Spend the evening exploring Shanti Stupa, Royal Leh Palace, Leh Market.',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
  },

  /* SS + LM + LP */
  {
    title: 'SS + LM + LP',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 1675,
      [TAXI_TYPE.INNOVA]: 1522,
      [TAXI_TYPE.SCORPIO]: 1446,
      [TAXI_TYPE.EECO]: 1312,
      [TAXI_TYPE.TRAVELLER]: 1958,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714801912/mb/shanti-stupta-leh3.jpg.jpg',
        title: 'Leh Local Sightseeing',
        desc: 'Spend the evening exploring Shanti Stupa, Royal Leh Palace, Leh Market.',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
  },

  /* Airport Pickup + Sham valley points */
  {
    title: 'Airport Pickup + Sham valley points',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 4684,
      [TAXI_TYPE.INNOVA]: 4258,
      [TAXI_TYPE.SCORPIO]: 4088,
      [TAXI_TYPE.EECO]: 3747,
      [TAXI_TYPE.TRAVELLER]: 5605,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714802393/mb/hall-of-fame-1.jpg.jpg',
        title: 'Airport Pickup + Sham Valley Famous Attractions',
        desc: 'Pickup from Airport. Visit Hall of Fame, Sangam Point, Gurudwara Pathar Sahib, Magnetic Hill, and Stupik Monastery',
        descHtml:
          '<p>Start the day with a visit to the <strong>Hall of Fame</strong>, a museum constructed and maintained by the Indian Army to pay homage to its brave hearts.</p>',
        places: [PLACE_ID.LEH],
        points: [PLACES_TO_VISIT.SHAM_VALLEY_POINTS],
      },
    ],
  },

  /* 1 Day Sham valley points */
  {
    title: 'Sham valley points',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 3749,
      [TAXI_TYPE.INNOVA]: 3408,
      [TAXI_TYPE.SCORPIO]: 3238,
      [TAXI_TYPE.EECO]: 2935,
      [TAXI_TYPE.TRAVELLER]: 4205,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714802393/mb/hall-of-fame-1.jpg.jpg',
        title: 'Visit Sham Valley Famous Attractions',
        desc: 'Hall of Fame, Sangam Point, Gurudwara Pathar Sahib, Magnetic Hill, and Stupik Monastery',
        descHtml:
          '<p>Start the day with a visit to the <strong>Hall of Fame</strong>, a museum constructed and maintained by the Indian Army to pay homage to its brave hearts.</p>',
        places: [PLACE_ID.LEH],
      },
    ],

    activityId: 'akfb8ll',
  },

  /* 1 Day Sham valley points + Shanti Stupa + Leh Palace + Market */
  {
    title: 'Sham valley points + Shanti Stupa + Leh Palace + Market',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 5424,
      [TAXI_TYPE.INNOVA]: 4930,
      [TAXI_TYPE.SCORPIO]: 4684,
      [TAXI_TYPE.EECO]: 4247,
      [TAXI_TYPE.TRAVELLER]: 6163,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714801912/mb/shanti-stupta-leh3.jpg.jpg',
        title: 'Visit Leh’s Famous Attractions',
        desc: `Hall of Fame, Sangam Point, Gurudwara Pathar Sahib, Magnetic Hill, Stupik Monastery, Shanti Stupa, Leh Market, and Leh Palace`,
        descHtml:
          '<p>Start the day with a visit to the <strong>Hall of Fame</strong>, a museum constructed and maintained by the Indian Army to pay homage to its brave hearts.</p>',
        places: [PLACE_ID.LEH],
      },
    ],
  },

  /* 1 Day Alchi, Liker + Sham valley points */
  {
    title: '1 Day Alchi, Liker + Sham valley points',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 5827,
      [TAXI_TYPE.INNOVA]: 5297,
      [TAXI_TYPE.SCORPIO]: 5032,
      [TAXI_TYPE.EECO]: 4529,
      [TAXI_TYPE.TRAVELLER]: 6537,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486888/activities/leh/alchi_monastery_rbhzum.jpg',
        title: 'Explore Alchi, Likir, and Sham Valley Points',
        desc: ' Embark on a journey to Alchi and Likir, visiting Hall of Fame, Sangam Point, Gurudwara Pathar Sahib, Magnetic Hill, Stupik Monastery, Shanti Stupa, Leh Market, and Leh Palace',
        descHtml:
          "<h1>Monastery Tour</h1><p>Start your day visiting <strong>Alchi Monastery</strong>, known for its unique structure and ancient wall paintings. Then move to <strong>Lamayuru Monastery</strong>, famed as Ladakh's oldest spiritual centre. Finally, explore <strong>Liker Monastery</strong> and soak in the peaceful atmosphere.</p>",
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'a5f01a0',
  },

  /* 1 Day Alchi, Lamayuru, Like */
  {
    title: '1 Day Alchi, Lamayuru, Liker',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 8376,
      [TAXI_TYPE.INNOVA]: 7614,
      [TAXI_TYPE.SCORPIO]: 7233,
      [TAXI_TYPE.EECO]: 6560,
      [TAXI_TYPE.TRAVELLER]: 10383,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486888/activities/leh/alchi_monastery_rbhzum.jpg',
        title: 'Alchi, Lamayuru, and Liker Tour',
        desc: 'Embark on a spiritual journey across Alchi, Lamayuru, and Liker',
        descHtml:
          "<h1>Monastery Tour</h1><p>Start your day visiting <strong>Alchi Monastery</strong>, known for its unique structure and ancient wall paintings. Then move to <strong>Lamayuru Monastery</strong>, famed as Ladakh's oldest spiritual centre. Finally, explore <strong>Liker Monastery</strong> and soak in the peaceful atmosphere.</p>",
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'a5f01a0',
  },

  /* 1 Day Airport pickup - Alchi, Lamayuru, Liker */
  {
    title: '1 Day Airport pickup - Alchi, Lamayuru, Liker',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 8376 + EXTRA_8KM_CHARGE[TAXI_TYPE.CRYSTA] + 935,
      [TAXI_TYPE.INNOVA]: 7614 + EXTRA_8KM_CHARGE[TAXI_TYPE.INNOVA] + 850,
      [TAXI_TYPE.SCORPIO]: 7233 + EXTRA_8KM_CHARGE[TAXI_TYPE.SCORPIO] + 850,
      [TAXI_TYPE.EECO]: 6560 + EXTRA_8KM_CHARGE[TAXI_TYPE.EECO] + 812,
      [TAXI_TYPE.TRAVELLER]:
        10383 + EXTRA_8KM_CHARGE[TAXI_TYPE.TRAVELLER] + 1400,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486888/activities/leh/alchi_monastery_rbhzum.jpg',
        title: 'Pickup from Airport and day tour to Lamayuru',
        desc: 'Pickup from Airport. Embark on a journey to Lamayuru. Enroute visit Alchi, Liker, Hall of Fame, Sangam Point, Gurudwara Pathar Sahib, Magnetic Hill, and Stupik Monastery',
        descHtml:
          "<h1>Monastery Tour</h1><p>Start your day visiting <strong>Alchi Monastery</strong>, known for its unique structure and ancient wall paintings. Then move to <strong>Lamayuru Monastery</strong>, famed as Ladakh's oldest spiritual centre. Finally, explore <strong>Liker Monastery</strong> and soak in the peaceful atmosphere.</p>",
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'a5f01a0',
  },

  /* 1 Day Mulbekh + Lamayuru + Sham Valley Points */
  {
    title: '1 Day Mulbekh + Lamayuru and Sham Valley Points',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 10910 + EXTRA_8KM_CHARGE[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 9918 + EXTRA_8KM_CHARGE[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]: 9422 + EXTRA_8KM_CHARGE[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]: 8595 + EXTRA_8KM_CHARGE[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]: 13606 + EXTRA_8KM_CHARGE[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        thumbnail: MEDIA.MULBEKH,
        title: 'Mulbekh + Lamayuru, and Sham Valley Sightseeing',
        desc: 'Embark on a spiritual journey to Mulbekh. In route visit Lamayuru, Hall of Fame, Sangam Point, Gurudwara Pathar Sahib, Magnetic Hill, Stupik Monastery',
        descHtml:
          "<h1>Monastery Tour</h1><p>Start your day visiting <strong>Alchi Monastery</strong>, known for its unique structure and ancient wall paintings. Then move to <strong>Lamayuru Monastery</strong>, famed as Ladakh's oldest spiritual centre. Finally, explore <strong>Liker Monastery</strong> and soak in the peaceful atmosphere.</p>",
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'a5f01a0',
  },

  /* 1 Day Lamayuru + Sham Valley Points */
  {
    title: '1 Day Lamayuru and Sham Valley Points',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 7788,
      [TAXI_TYPE.INNOVA]: 7112,
      [TAXI_TYPE.SCORPIO]: 6773,
      [TAXI_TYPE.EECO]: 6174,
      [TAXI_TYPE.TRAVELLER]: 9124,
    },
    itinerary: [
      {
        thumbnail: MEDIA.HALL_OF_FAME,
        title: 'Lamayuru, and Sham Valley Sightseeing',
        desc: 'Embark on a spiritual journey to Lamayuru. In route visit Hall of Fame, Sangam Point, Gurudwara Pathar Sahib, Magnetic Hill, Stupik Monastery',
        descHtml:
          "<h1>Monastery Tour</h1><p>Start your day visiting <strong>Alchi Monastery</strong>, known for its unique structure and ancient wall paintings. Then move to <strong>Lamayuru Monastery</strong>, famed as Ladakh's oldest spiritual centre. Finally, explore <strong>Liker Monastery</strong> and soak in the peaceful atmosphere.</p>",
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'a5f01a0',
  },

  /* 1 Day Shey, Thiksay, Hemis, Sindhu Ghart, R.School */
  {
    title: '1 Day Shey, Thiksay, Hemis, Sindhu Ghart, R.School',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 3727,
      [TAXI_TYPE.INNOVA]: 3388,
      [TAXI_TYPE.SCORPIO]: 3218,
      [TAXI_TYPE.EECO]: 2619,
      [TAXI_TYPE.TRAVELLER]: 4745,
    },
    itinerary: [
      {
        thumbnail: MEDIA.THIKSEY_MONASTERY,
        title: 'Shey, Thiksay, Hemis, Sindhu Ghart, Rancho School',
        desc: 'Embark on a spiritual journey across Shey, Thiksay, Hemis, Sindhu Ghart, and Rancho School',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'a5f01a0',
  },

  /* 1 Day Shey, Thiksay, Hemis, Sindhu Ghart, R.School + Leh Market */
  {
    title: '1 Day Shey, Thiksay, Hemis, Sindhu Ghart, R.School + Leh Market',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 4029,
      [TAXI_TYPE.INNOVA]: 3663,
      [TAXI_TYPE.SCORPIO]: 3479,
      [TAXI_TYPE.EECO]: 2845,
      [TAXI_TYPE.TRAVELLER]: 5290,
    },
    itinerary: [
      {
        thumbnail: MEDIA.THIKSEY_MONASTERY,
        title:
          'Shey, Thiksay, Hemis, Sindhu Ghart, Rancho School, and Leh Market',
        desc: 'Embark on a spiritual journey across Shey, Thiksay, Hemis, Sindhu Ghart, Rancho School, and Leh Market',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'a5f01a0',
  },

  /* 1 Day Shey, Thiksay, Hemis, Sindhu Ghart, R.School */
  {
    title: '1 Day Shey, Thiksay, Hemis, Sindhu Ghart, R.School + SS + LM + LP',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 3727 + PRICE_SS_LM_LP[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 3388 + PRICE_SS_LM_LP[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]: 3218 + PRICE_SS_LM_LP[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]: 2619 + PRICE_SS_LM_LP[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]: 4745 + PRICE_SS_LM_LP[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        thumbnail: MEDIA.THIKSEY_MONASTERY,
        title:
          'Shey, Thiksay, Hemis, Sindhu Ghart, Rancho School, Leh Palace, Shanti Stupa, and Leh Market',
        desc: 'Embark on a spiritual journey across Shey, Thiksay, Hemis, Sindhu Ghart, and Rancho School. In the evening explore Leh Palace, Shanti Stupa, and Leh Market',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'a5f01a0',
  },

  /* 1D Leh to Nubra to Leh */
  {
    title: '1D Leh to Nubra to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 10718,
      [TAXI_TYPE.INNOVA]: 9744,
      [TAXI_TYPE.SCORPIO]: 9257,
      [TAXI_TYPE.EECO]: 8431,
      [TAXI_TYPE.TRAVELLER]: 12025,
    },
    itinerary: [
      {
        thumbnail: MEDIA.NUBRA_VALLEY,
        title: 'Nubra Valley day tour',
        desc: `Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides. Return back to Leh.`,
        descHtml:
          '<p>Begin the day with a journey to the Nubra Valley. Enjoy the breathtaking landscapes as you traverse Khardung La, one of the highest motorable roads in the world. Arrive at the beautiful Hunder sand dunes and indulge in a unique double-hump camel ride. Relax and soak in the beauty of the serene surroundings.</p>',
        places: [PLACE_ID.LEH],
      },
    ],
  },

  /* 1D Pangong Lake & Spangmik */
  {
    title: '1D Pangong Lake & Spangmik',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 11517,
      [TAXI_TYPE.INNOVA]: 10470,
      [TAXI_TYPE.SCORPIO]: 9946,
      [TAXI_TYPE.EECO]: 9021,
      [TAXI_TYPE.TRAVELLER]: 13087,
    },
    itinerary: [
      {
        title: 'Day tour to Pangong Lake & Spangmik',
        thumbnail: MEDIA.PANGONG_LAKE,
        desc: 'Embark on a day trip to the enchanting Pangong Lake and the serene village of Spangmik',
        descHtml:
          "<p>After lunch, proceed to Spangmik, a quiet village on the banks of Pangong Lake. Spend the afternoon exploring this serene hamlet and interact with the locals to get a glimpse of rural Ladakhi life. Don't miss the opportunity to enjoy the stunning views of the lake from the village.</p>",
        places: [PLACE_ID.LEH],
        distance: '320 KM',
        travel_time: '8 hrs',
      },
    ],
    activityId: 'aglr5jy',
  },

  /* 1D Pangong Lake and Hemis */
  {
    title: '1D Pangong Lake and Hemis',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 11517 + IN_ROUTE_HEMIS[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 10470 + IN_ROUTE_HEMIS[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]: 9946 + IN_ROUTE_HEMIS[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]: 9021 + IN_ROUTE_HEMIS[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]: 13087 + IN_ROUTE_HEMIS[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        title: 'Day tour to Pangong Lake via Chang La',
        thumbnail: MEDIA.PANGONG_LAKE,
        desc: 'Embark on a day trip to the enchanting Pangong Lake and the serene village of Spangmik.Also cover rich heritage of Hemis, Thiksey, Shey, and the renowned Rancho School',
        descHtml:
          "<p>After lunch, proceed to Spangmik, a quiet village on the banks of Pangong Lake. Spend the afternoon exploring this serene hamlet and interact with the locals to get a glimpse of rural Ladakhi life. Don't miss the opportunity to enjoy the stunning views of the lake from the village.</p>",
        places: [PLACE_ID.LEH],
        distance: '320 KM',
        travel_time: '8 hrs',
      },
    ],
    activityId: 'aglr5jy',
  },

  /* 1D Leh to Kargil to Leh. In route visit Sham Valley Points*/
  {
    title: '1D Leh to Kargil day tour. In route visit Sham Valley Points',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 14623 + EXTRA_8KM_CHARGE[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 13293 + EXTRA_8KM_CHARGE[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]: 12628 + EXTRA_8KM_CHARGE[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]: 11365 + EXTRA_8KM_CHARGE[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]: 16618 + EXTRA_8KM_CHARGE[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        thumbnail: MEDIA.MAGNETIC_HILL,
        title: 'Kargil day tour. In route visit Sham Valley Sightseeing Points',
        desc: 'Visit Kargil, known for their historical significance. In route visit Magnetic Hill, Gurudwara Pathar Sahib, Hall of Fame, Sangam Point, and Spituk Monastery',
        places: [PLACE_ID.LEH],
      },
    ],
  },

  /* 1 D Leh to Srinagar Drop */
  {
    title: '1 Day Leh to Srinagar Drop',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 20828,
      [TAXI_TYPE.INNOVA]: 18934,
      [TAXI_TYPE.SCORPIO]: 17987,
      [TAXI_TYPE.EECO]: 16311,
      [TAXI_TYPE.TRAVELLER]: 23603,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1715682451/mb/Houseboats%2C_Dal_Lake%2C_Kashmir.jpg.jpg',
        title: 'Leh to Srinagar Drop',
        desc: 'Drive through the scenic Kargil and Batalik regions, known for their historical significance. Drop to Srinagar.',
        descHtml:
          '<p>Begin the day with a journey to the Nubra Valley. Enjoy the breathtaking landscapes as you traverse Khardung La, one of the highest motorable roads in the world. Arrive at the beautiful Hunder sand dunes and indulge in a unique double-hump camel ride. Relax and soak in the beauty of the serene surroundings.</p>',
        places: [PLACE_ID.SRINAGAR],
      },
    ],
  },

  /* 1D Leh to Sonmarag Drop */
  {
    title: '1D Leh to Sonmarag Drop',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 17712 + EXTRA_8KM_CHARGE[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 16102 + EXTRA_8KM_CHARGE[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]: 15297 + EXTRA_8KM_CHARGE[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]: 13837 + EXTRA_8KM_CHARGE[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]: 20579 + EXTRA_8KM_CHARGE[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714640471/mb/26_07_2022-kagil_war_memorial4_22925701_15261881.jpeg.jpeg',
        title:
          'Drop to Sonmarg. In route visit Hall of Fame, Magnetic Hill, Gurudwara Pathar Sahib, Sangam Point and Kargil War Memorial',
        desc: 'Drive through the scenic landscapes of Kargil, offering breathtaking views and unique charm. Along the way, visit the Hall of Fame, Magnetic Hill, Gurudwara Pathar Sahib, and Sangam Point.',
        descHtml:
          '<p>Begin the day with a journey to the Nubra Valley. Enjoy the breathtaking landscapes as you traverse Khardung La, one of the highest motorable roads in the world. Arrive at the beautiful Hunder sand dunes and indulge in a unique double-hump camel ride. Relax and soak in the beauty of the serene surroundings.</p>',
        places: [PLACE_ID.SONAMARG],
      },
    ],
  },

  /* 2D/1N Leh to Srinagar via Kargil / Batalik to Sonamarg Drop */
  {
    title: '2D/1N Leh to Srinagar via Kargil / Batalik to Sonamarg Drop',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 27152,
      [TAXI_TYPE.INNOVA]: 24684,
      [TAXI_TYPE.SCORPIO]: 23450,
      [TAXI_TYPE.EECO]: 21490,
      [TAXI_TYPE.TRAVELLER]: 31225,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714640471/mb/26_07_2022-kagil_war_memorial4_22925701_15261881.jpeg.jpeg',
        title: 'Travel to Kargil via Batalik',
        desc: 'Drive through the scenic Kargil and Batalik regions, known for their historical significance',
        descHtml:
          '<p>Begin the day with a journey to the Nubra Valley. Enjoy the breathtaking landscapes as you traverse Khardung La, one of the highest motorable roads in the world. Arrive at the beautiful Hunder sand dunes and indulge in a unique double-hump camel ride. Relax and soak in the beauty of the serene surroundings.</p>',
        places: [PLACE_ID.KARGIL],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714640476/mb/SONAMARG.jpg.jpg',
        title: 'Drop to Sonamarg',
        desc: 'Travel to Sonamarg, a picturesque town known for its lush meadows and snow-capped mountains',
        descHtml: '',
        places: [PLACE_ID.SONAMARG],
      },
    ],
  },

  /* 2D/1N Leh to Srinagar Drop. In route Sham Valley Points  */
  {
    title: '2D/1N Leh to Srinagar Drop. In route Sham Valley Points ',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 26215,
      [TAXI_TYPE.INNOVA]: 23989,
      [TAXI_TYPE.SCORPIO]: 25102,
      [TAXI_TYPE.EECO]: 20904,
      [TAXI_TYPE.TRAVELLER]: 27831,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714640471/mb/26_07_2022-kagil_war_memorial4_22925701_15261881.jpeg.jpeg',
        title:
          'Travel to Kargil or Sonamarg. In route visit Magnetic Hill, Gurudwara Pathar Sahib, Hall of Fame, Sangam Point, and Spituk Monastery',
        desc: 'Drive through the scenic landscapes of Kargil or Sonamarg, each offering breathtaking views and unique charm. Enjoy a comfortable night stay in either destination. Visit Magnetic Hill, Gurudwara Pathar Sahib, Hall of Fame, Sangam Point, and Spituk Monastery en route.',
        descHtml:
          '<p>Begin the day with a journey to the Nubra Valley. Enjoy the breathtaking landscapes as you traverse Khardung La, one of the highest motorable roads in the world. Arrive at the beautiful Hunder sand dunes and indulge in a unique double-hump camel ride. Relax and soak in the beauty of the serene surroundings.</p>',
        places: [PLACE_ID.KARGIL, PLACE_ID.SONAMARG],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1715682451/mb/Houseboats%2C_Dal_Lake%2C_Kashmir.jpg.jpg',
        title: 'Drop to Srinagar',
        desc: 'Travel to Srinagar known for its stunning landscapes, serene lakes, and rich cultural heritage.',
        descHtml: '',
        places: [PLACE_ID.SRINAGAR],
      },
    ],
  },

  /* 2D/1N Srinagar to Leh pickup. In route Sham Valley Points  */
  {
    title: '2D/1N Srinagar to Leh pickup. In route Sham Valley Points ',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 33535,
      [TAXI_TYPE.INNOVA]: 30643,
      [TAXI_TYPE.SCORPIO]: 29197,
      [TAXI_TYPE.EECO]: 26635,
      [TAXI_TYPE.TRAVELLER]: 37619,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714640471/mb/26_07_2022-kagil_war_memorial4_22925701_15261881.jpeg.jpeg',
        title: 'Srinagar to Drass / Kargil',
        desc: 'Drive through the scenic landscapes of Kargil, each offering breathtaking views and unique charm. Enjoy a comfortable night stay in either destination. Visit Kargil War Memorial in route.',
        descHtml:
          '<p>Begin the day with a journey to the Nubra Valley. Enjoy the breathtaking landscapes as you traverse Khardung La, one of the highest motorable roads in the world. Arrive at the beautiful Hunder sand dunes and indulge in a unique double-hump camel ride. Relax and soak in the beauty of the serene surroundings.</p>',
        places: [PLACE_ID.KARGIL, PLACE_ID.SONAMARG],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714802393/mb/hall-of-fame-1.jpg.jpg',
        title: 'Drass / Kargil to Leh',
        desc: 'Travel to Leh. In route visit Magnetic Hill, Gurudwara Pathar Sahib, Hall of Fame, and Sangam Point.',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
  },

  /* 2D/1N Leh to Srinagar Drop */
  {
    title: '2D/1N Leh to Srinagar Drop',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 24490,
      [TAXI_TYPE.INNOVA]: 22264,
      [TAXI_TYPE.SCORPIO]: 23377,
      [TAXI_TYPE.EECO]: 19179,
      [TAXI_TYPE.TRAVELLER]: 27831,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714640471/mb/26_07_2022-kagil_war_memorial4_22925701_15261881.jpeg.jpeg',
        title: 'Travel to Kargil or Sonamarg',
        desc: 'Drive through the scenic landscapes of Kargil or Sonamarg, each offering breathtaking views and unique charm. Enjoy a comfortable night stay in either destination',
        descHtml:
          '<p>Begin the day with a journey to the Nubra Valley. Enjoy the breathtaking landscapes as you traverse Khardung La, one of the highest motorable roads in the world. Arrive at the beautiful Hunder sand dunes and indulge in a unique double-hump camel ride. Relax and soak in the beauty of the serene surroundings.</p>',
        places: [PLACE_ID.KARGIL, PLACE_ID.SONAMARG],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1715682451/mb/Houseboats%2C_Dal_Lake%2C_Kashmir.jpg.jpg',
        title: 'Drop to Srinagar',
        desc: 'Travel to Srinagar known for its stunning landscapes, serene lakes, and rich cultural heritage.',
        descHtml: '',
        places: [PLACE_ID.SRINAGAR],
      },
    ],
  },

  /* 2D/1N Leh to Jammu Drop with stay in Sonmarag or Kargil */
  {
    title: '2D/1N Leh to Jammu Drop with stay in Sonmarag or Kargil',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 35972,
      [TAXI_TYPE.INNOVA]: 32828,
      [TAXI_TYPE.SCORPIO]: 31256,
      [TAXI_TYPE.EECO]: 28470,
      [TAXI_TYPE.TRAVELLER]: 40830,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714640471/mb/26_07_2022-kagil_war_memorial4_22925701_15261881.jpeg.jpeg',
        title:
          'Travel to Kargil / Sonmarg. In route visit Magnetic Hill, Gurudwara Pathar Sahib, Hall of Fame',
        desc: 'Drive through the scenic landscapes of Kargil, offering breathtaking views and unique charm. Along the way, visit Magnetic Hill, Gurudwara Pathar Sahib, and the Hall of Fame. Enjoy a comfortable night stay in Kargil or continue to Sonmarg for the night',
        descHtml:
          '<p>Begin the day with a journey to the Nubra Valley. Enjoy the breathtaking landscapes as you traverse Khardung La, one of the highest motorable roads in the world. Arrive at the beautiful Hunder sand dunes and indulge in a unique double-hump camel ride. Relax and soak in the beauty of the serene surroundings.</p>',
        places: [PLACE_ID.KARGIL, PLACE_ID.SONAMARG],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1715693009/mb/IMG_20190704_112324.jpg.jpg',
        title: 'Drop to Jammu',
        desc: 'Travel to Jammu, known for its rich cultural heritage and religious significance',
        descHtml: '',
        places: [PLACE_ID.JAMMU],
      },
    ],
  },

  /* 3D/2N Leh to Jammu Drop with stay in Sonmarag or Kargil and Srinagar */
  {
    title:
      '3D/2N Leh to Jammu Drop with stay in Sonmarag or Kargil and Srinagar',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 37943 + EXTRA_8KM_CHARGE[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 34494 + EXTRA_8KM_CHARGE[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]: 32769 + EXTRA_8KM_CHARGE[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]: 29727 + EXTRA_8KM_CHARGE[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]:
        39310 + 3872 + 598 + EXTRA_8KM_CHARGE[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714640471/mb/26_07_2022-kagil_war_memorial4_22925701_15261881.jpeg.jpeg',
        title:
          'Travel to Kargil / Sonmarg. In route visit Magnetic Hill, Gurudwara Pathar Sahib, Hall of Fame',
        desc: 'Drive through the scenic landscapes of Kargil, offering breathtaking views and unique charm. Along the way, visit Magnetic Hill, Gurudwara Pathar Sahib, and the Hall of Fame. Enjoy a comfortable night stay in Kargil or continue to Sonmarg for the night',
        descHtml:
          '<p>Begin the day with a journey to the Nubra Valley. Enjoy the breathtaking landscapes as you traverse Khardung La, one of the highest motorable roads in the world. Arrive at the beautiful Hunder sand dunes and indulge in a unique double-hump camel ride. Relax and soak in the beauty of the serene surroundings.</p>',
        places: [PLACE_ID.KARGIL, PLACE_ID.SONAMARG],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1715682451/mb/Houseboats%2C_Dal_Lake%2C_Kashmir.jpg.jpg',
        title: 'Drive to Srinagar',
        desc: 'Travel to Srinagr, known for its stunning landscapes, serene lakes, and rich cultural heritage',
        descHtml: '',
        places: [PLACE_ID.SRINAGAR],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1715693009/mb/IMG_20190704_112324.jpg.jpg',
        title: 'Drop to Jammu',
        desc: 'Travel to Jammu, known for its rich cultural heritage and religious significance',
        descHtml: '',
        places: [PLACE_ID.JAMMU],
      },
    ],
  },


  /* 2D/1N Leh to Kargil - Drass to Leh */
  {
    title: '2D/1N Leh to Kargil - Drass to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 19694,
      [TAXI_TYPE.INNOVA]: 17904,
      [TAXI_TYPE.SCORPIO]: 17009,
      [TAXI_TYPE.EECO]: 15306,
      [TAXI_TYPE.TRAVELLER]: 22096,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714640471/mb/26_07_2022-kagil_war_memorial4_22925701_15261881.jpeg.jpeg',
        title: 'Travel to Kargil',
        desc: 'Drive through the scenic Kargil region, known for their historical significance',
        descHtml:
          '<p>Begin the day with a journey to the Nubra Valley. Enjoy the breathtaking landscapes as you traverse Khardung La, one of the highest motorable roads in the world. Arrive at the beautiful Hunder sand dunes and indulge in a unique double-hump camel ride. Relax and soak in the beauty of the serene surroundings.</p>',
        places: [PLACE_ID.KARGIL, PLACE_ID.DRASS],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1715592750/mb/licensed-image-%283%29.jpeg.jpg',
        title: 'Visit Drass and back to Leh.',
        desc: 'Visit Drass, the second coldest inhabited place in the world and Kargil War Memorial.',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
  },

  /* 2D/1N Leh to Kargil - Drass to Leh. In route visit Sham Valley Points*/
  {
    title:
      '2D/1N Leh to Kargil - Drass to Leh. In route visit Sham Valley Points',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 19694 + EXTRA_8KM_CHARGE[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 17904 + EXTRA_8KM_CHARGE[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]: 17009 + EXTRA_8KM_CHARGE[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]: 15306 + EXTRA_8KM_CHARGE[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]: 22096 + EXTRA_8KM_CHARGE[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1715592750/mb/licensed-image-%283%29.jpeg.jpg',
        title:
          'Travel to Kargil. In route visit Sham Valley Sightseeing Points',
        desc: 'Drive through the scenic Kargil region, known for their historical significance. In route visit Magnetic Hill, Gurudwara Pathar Sahib, Hall of Fame, Sangam Point, and Spituk Monastery',
        descHtml:
          '<p>Begin the day with a journey to the Nubra Valley. Enjoy the breathtaking landscapes as you traverse Khardung La, one of the highest motorable roads in the world. Arrive at the beautiful Hunder sand dunes and indulge in a unique double-hump camel ride. Relax and soak in the beauty of the serene surroundings.</p>',
        places: [PLACE_ID.KARGIL, PLACE_ID.DRASS],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714640471/mb/26_07_2022-kagil_war_memorial4_22925701_15261881.jpeg.jpeg',
        title: 'Visit Drass and back to Leh.',
        desc: 'Visit Drass, the second coldest inhabited place in the world and Kargil War Memorial.',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
  },

  /* 2D/1N Leh Airport to Kargil - Drass to Leh. In route visit Sham Valley Points*/
  {
    title:
      '2D/1N Leh Airport to Kargil - Drass to Leh. In route visit Sham Valley Points',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 935 + 19694 + EXTRA_8KM_CHARGE[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 850 + 17904 + EXTRA_8KM_CHARGE[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]: 850 + 17009 + EXTRA_8KM_CHARGE[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]: 812 + 15306 + EXTRA_8KM_CHARGE[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]:
        1400 + 22096 + EXTRA_8KM_CHARGE[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1715592750/mb/licensed-image-%283%29.jpeg.jpg',
        title:
          'Pickup from Airport. Travel to Kargil. In route visit Sham Valley Sightseeing Points',
        desc: 'Pickup from Airport. Drive through the scenic Kargil region, known for their historical significance. In route visit Magnetic Hill, Gurudwara Pathar Sahib, Hall of Fame, Sangam Point, and Spituk Monastery',
        descHtml:
          '<p>Begin the day with a journey to the Nubra Valley. Enjoy the breathtaking landscapes as you traverse Khardung La, one of the highest motorable roads in the world. Arrive at the beautiful Hunder sand dunes and indulge in a unique double-hump camel ride. Relax and soak in the beauty of the serene surroundings.</p>',
        places: [PLACE_ID.KARGIL, PLACE_ID.DRASS],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714640471/mb/26_07_2022-kagil_war_memorial4_22925701_15261881.jpeg.jpeg',
        title: 'Visit Drass and back to Leh.',
        desc: 'Visit Drass, the second coldest inhabited place in the world and Kargil War Memorial.',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
  },

  /* 2D/1N Leh to Kargil - Drass- Batalik to Leh */
  {
    title: '2D/1N Leh to Kargil - Drass- Batalik to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 21246,
      [TAXI_TYPE.INNOVA]: 19315,
      [TAXI_TYPE.SCORPIO]: 18349,
      [TAXI_TYPE.EECO]: 16637,
      [TAXI_TYPE.TRAVELLER]: 27140,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714640471/mb/26_07_2022-kagil_war_memorial4_22925701_15261881.jpeg.jpeg',
        title: 'Travel to Kargil via Batalik',
        desc: 'Drive through the scenic Kargil and Batalik regions, known for their historical significance',
        descHtml:
          '<p>Begin the day with a journey to the Nubra Valley. Enjoy the breathtaking landscapes as you traverse Khardung La, one of the highest motorable roads in the world. Arrive at the beautiful Hunder sand dunes and indulge in a unique double-hump camel ride. Relax and soak in the beauty of the serene surroundings.</p>',
        places: [PLACE_ID.KARGIL, PLACE_ID.DRASS],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1715592750/mb/licensed-image-%283%29.jpeg.jpg',
        title:
          'Visit Drass and back to Leh. In route visit Magnetic Hill, Gurudwara Pathar Sahib, Hall of Fame',
        desc: 'Visit Drass, the second coldest inhabited place in the world and Kargil War Memorial. In route visit Magnetic Hill, Gurudwara Pathar Sahib, Hall of Fame while returning back to Leh',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
  },

  /* 2D/1N Leh to (Tso Moriri-Tsokar) to (Mahe-Leh) */
  {
    title: '2D/1N Leh to (Tso Moriri-Tsokar) to (Mahe-Leh)',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 19010,
      [TAXI_TYPE.INNOVA]: 17282,
      [TAXI_TYPE.SCORPIO]: 16418,
      [TAXI_TYPE.EECO]: 14888,
      [TAXI_TYPE.TRAVELLER]: 18655,
    },
    itinerary: [
      {
        thumbnail: MEDIA.TSO_MORIRI,
        title: 'Leh to Tso Moriri via Tso Kar',
        desc: 'Drive to Tso Moriri via Taglang La, Tso Kar and Puga Valley, a high-altitude lake known for its serene beauty and birdlife. ',
        descHtml: '',
        places: [PLACE_ID.TSO_MORIRI],
      },
      {
        thumbnail: MEDIA.LEH_GENERIC,
        title: 'Tso Moriri to Leh',
        desc: 'Reach back to Leh by late evening',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
  },

  /* 2D/1N Leh to Hanle to Tso Moriri to Leh */
  {
    title: '2D/1N Leh to Hanle to (Tso Moriri-Tsokar) Leh via ',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 19010 + IN_ROUTE_HANLE[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 17282 + IN_ROUTE_HANLE[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]: 16418 + IN_ROUTE_HANLE[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]: 14888 + IN_ROUTE_HANLE[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]: 18655 + IN_ROUTE_HANLE[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        thumbnail: MEDIA.HANLE,
        title: 'Leh to Hanle',
        desc: 'Drive to Hanle, a remote village in Ladakh known for Stargazing. Visit the Hanle Monastery and the Indian Astronomical Observatory.',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714740700/mb/licensed-image-%281%29.jpeg.jpg',
        title: 'Hanle to Tso Moriri and back to Leh',
        desc: 'Travel to Tso Moriri, a high-altitude lake known for its serene beauty and birdlife. Reach back to Leh by late evening',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
  },

  /* 2D/1N Leh to Alchi, Liker, Uleytokpo, Lamayuru + Sham valley points */
  {
    title:
      '2D/1N Leh to Alchi, Liker, Uleytokpo, Lamayuru + Sham valley points',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 11270 + EXTRA_8KM_CHARGE[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 10245 + EXTRA_8KM_CHARGE[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]: 9732 + EXTRA_8KM_CHARGE[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]: 8825 + EXTRA_8KM_CHARGE[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]: 13970 + EXTRA_8KM_CHARGE[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        thumbnail: MEDIA.ALCHI,
        title: 'Travel to Alchi, Liker, Uleytokpo, Lamayuru',
        desc: 'Drive through the scenic landscapes of Alchi, Liker, Uleytokpo, and Lamayuru, each offering breathtaking views and unique charm. Visit the famous monasteries and enjoy the serene surroundings. Night stay in any of the locations',
        descHtml:
          '<p>Begin the day with a journey to the Nubra Valley. Enjoy the breathtaking landscapes as you traverse Khardung La, one of the highest motorable roads in the world. Arrive at the beautiful Hunder sand dunes and indulge in a unique double-hump camel ride. Relax and soak in the beauty of the serene surroundings.</p>',
        places: [
          PLACE_ID.ALCHI,
          PLACE_ID.LIKIR,
          PLACE_ID.ULEYTOKPO,
          PLACE_ID.LAMAYURU,
        ],
      },
      {
        thumbnail: MEDIA.HALL_OF_FAME,
        title:
          'Travel back to Leh. En route visit Sham Valley Sightseeing Points',
        desc: 'Travel back to Leh. En route visit Hall of Fame, Magnetic Hill, Gurudwara Pathar Sahib, and Sangam Point',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
  },

  /* 2D/1N Leh to Nubra to Leh */
  {
    title: '2D/1N Leh to Nubra to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 12988,
      [TAXI_TYPE.INNOVA]: 11807,
      [TAXI_TYPE.SCORPIO]: 11217,
      [TAXI_TYPE.EECO]: 10170,
      [TAXI_TYPE.TRAVELLER]: 14571,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486890/activities/leh/nubra_valley_wn0vub.jpg',
        title: 'Travel to the Lush Nubra Valley',
        desc: `Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides`,
        descHtml:
          '<p>Begin the day with a journey to the Nubra Valley. Enjoy the breathtaking landscapes as you traverse Khardung La, one of the highest motorable roads in the world. Arrive at the beautiful Hunder sand dunes and indulge in a unique double-hump camel ride. Relax and soak in the beauty of the serene surroundings.</p>',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1715596271/mb/licensed-image-%285%29.jpeg.jpg',
        title: `Nubra Valley to Leh`,
        desc: 'Return to Leh from Nubra Valley',
        descHtml:
          '<p>Start day two with a visit to Turtuk, the northernmost village of India, where you can learn about the local culture and history. Admire the picturesque village landscape before beginning your journey back to Leh, bringing the unforgettable two-day journey to a close.</p>',
        places: [PLACE_ID.LEH],
      },
    ],
  },

  /* 2D/1N Leh to Nubra to Turtuk - Thang - Leh */
  {
    title: '2D/1N Leh to Nubra to Turtuk - Thang - Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 18308,
      [TAXI_TYPE.INNOVA]: 16644,
      [TAXI_TYPE.SCORPIO]: 15811,
      [TAXI_TYPE.EECO]: 14332,
      [TAXI_TYPE.TRAVELLER]: 21883,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486890/activities/leh/nubra_valley_wn0vub.jpg',
        title: 'Travel to the Lush Nubra Valley visit Turtuk and Thang',
        desc: 'Drive through the scenic Khardung La pass, visit Hunder sand dunes, and experience a unique camel ride.',
        descHtml:
          '<p>Begin the day with a journey to the Nubra Valley. Enjoy the breathtaking landscapes as you traverse Khardung La, one of the highest motorable roads in the world. Arrive at the beautiful Hunder sand dunes and indulge in a unique double-hump camel ride. Relax and soak in the beauty of the serene surroundings.</p>',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER, PLACE_ID.TURTUK],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: "Explore Turtuk, India's Last Habitable Village",
        desc: 'Visit Turtuk, the northernmost village in India, learn about its history and culture, and head back to Leh.',
        descHtml:
          '<p>Start day two with a visit to Turtuk, the northernmost village of India, where you can learn about the local culture and history. Admire the picturesque village landscape before beginning your journey back to Leh, bringing the unforgettable two-day journey to a close.</p>',
        places: [PLACE_ID.LEH],
      },
    ],
  },


  /* 2D/1N Leh to Pangong - Spangmik to Leh */
  {
    title: '2D/1N Leh to Pangong - Spangmik to Leh ',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 13710,
      [TAXI_TYPE.INNOVA]: 12464,
      [TAXI_TYPE.SCORPIO]: 11841,
      [TAXI_TYPE.EECO]: 10738,
      [TAXI_TYPE.TRAVELLER]: 16288,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Leh to Pangong Lake',
        desc: 'Head to the enchanting Pangong Lake and revel in its tranquil beauty',
        descHtml:
          "<h1>Leh to Pangong Lake</h1><p>Embark on an exciting journey to the famous Pangong Lake. Enjoy the serene beauty of this high altitude lake, which changes its colors with the sun's movement. Capture the incredible views in your memories and your camera. Overnight at a camp near the lake.</p>",
        places: [PLACE_ID.LUKUNG, PLACE_ID.SPANGMIK],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689497940/activities/leh/Spangmik_coc19v.jpg',
        title: 'Spangmik Exploration and Return',
        desc: 'Explore Spangmik village and return to Leh',
        descHtml:
          '<h1>Spangmik Exploration and Return</h1><p>Begin your day with a visit to the offbeat Spangmik village. Immerse in the local culture and interact with the warm-hearted locals. Post visit, return to Leh with unforgettable memories.</p>',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'aotvrjs',
  },

  /* 2D/1N Leh to Pangong to Spangmik - Hemis - Shey - Leh */
  {
    title: '2D/1N Leh to Pangong - Spangmik - Hemis - Shey to Leh ',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 14851,
      [TAXI_TYPE.INNOVA]: 13501,
      [TAXI_TYPE.SCORPIO]: 12826,
      [TAXI_TYPE.EECO]: 11618,
      [TAXI_TYPE.TRAVELLER]: 17278,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Leh to Pangong Lake',
        desc: 'Head to the enchanting Pangong Lake and revel in its tranquil beauty',
        descHtml:
          "<h1>Leh to Pangong Lake</h1><p>Embark on an exciting journey to the famous Pangong Lake. Enjoy the serene beauty of this high altitude lake, which changes its colors with the sun's movement. Capture the incredible views in your memories and your camera. Overnight at a camp near the lake.</p>",
        places: [PLACE_ID.LUKUNG, PLACE_ID.SPANGMIK],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/noz40nipxdtpgju2jus5.jpg',
        title: 'Visit Spangmik, Hemis, Thiksay, Shey, and return to Leh',
        desc: 'Explore Spangmik Village and then return to Leh, making stops to delve into the rich heritage at Hemis, Thiksey, Shey, and the renowned Rancho School',
        descHtml:
          '<h1>Spangmik Exploration and Return</h1><p>Begin your day with a visit to the offbeat Spangmik village. Immerse in the local culture and interact with the warm-hearted locals. Post visit, return to Leh with unforgettable memories.</p>',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'aotvrjs',
  },

  /* 2D/1N Leh to Nubra to Pangong - Leh */
  {
    title: '2D/1N Leh to Nubra to Pangong - Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 20837,
      [TAXI_TYPE.INNOVA]: 18943,
      [TAXI_TYPE.SCORPIO]: 17999,
      [TAXI_TYPE.EECO]: 16317,
      [TAXI_TYPE.TRAVELLER]: 25050,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong to Leh',
        desc: 'Travel from Nubra to Pangong via Shyok River, known for its color-changing waters. Return to Leh by late evening',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 3D/2N Leh to Nubra to Turtuk - Thang to Leh */
  {
    title: '3D/2N Leh to Nubra to Turtuk - Thang to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 17461 + IN_ROUTE_THANG[TAXI_TYPE.CRYSTA] + PRICE_FREE_DAY[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 15874 + IN_ROUTE_THANG[TAXI_TYPE.INNOVA] + PRICE_FREE_DAY[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]: 15080 + IN_ROUTE_THANG[TAXI_TYPE.SCORPIO] + PRICE_FREE_DAY[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]: 13674 + IN_ROUTE_THANG[TAXI_TYPE.EECO] + PRICE_FREE_DAY[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]: 21883 + IN_ROUTE_THANG[TAXI_TYPE.TRAVELLER] + PRICE_FREE_DAY[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      SIMPLE_ITINERARY.LEH_TO_NUBRA_VALLEY,
      SIMPLE_ITINERARY.NUBRA_VALLEY_TO_TURTUK,
      SIMPLE_ITINERARY.NUBRA_VALLEY_TO_LEH,
    ],
  },

  /* 3D/2N Leh to Nubra to Pangong to Leh */
  {
    title: '3D/2N Leh to Nubra to Pangong to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 24651,
      [TAXI_TYPE.INNOVA]: 22410,
      [TAXI_TYPE.SCORPIO]: 21289,
      [TAXI_TYPE.EECO]: 19305,
      [TAXI_TYPE.TRAVELLER]: 29520,
    },
    itinerary: [
      SIMPLE_ITINERARY.LEH_TO_NUBRA_VALLEY,
      SIMPLE_ITINERARY.NUBRA_VALLEY_TO_PANGONG_VIA_SHYOK,
      SIMPLE_ITINERARY.PANGONG_TO_LEH_VIA_CHANGLA,
    ],
    activityId: 'au114hq',
  },

  /* 3D/2N Leh to Nubra to Pangong to Hemis - Leh */
  {
    title: ' 3D/2N Leh to Nubra to Pangong to (Hemis-Leh)',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 24651 + IN_ROUTE_HEMIS[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 22410 + IN_ROUTE_HEMIS[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]: 21289 + IN_ROUTE_HEMIS[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]: 19305 + IN_ROUTE_HEMIS[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]: 29520 + IN_ROUTE_HEMIS[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      SIMPLE_ITINERARY.LEH_TO_NUBRA_VALLEY,
      SIMPLE_ITINERARY.NUBRA_VALLEY_TO_PANGONG_VIA_SHYOK,
      SIMPLE_ITINERARY.PANGONG_TO_LEH_VIA_HEMIS,
    ],
    activityId: 'au114hq',
  },

  /* 3D/2N Leh to Nubra to Turtuk - Pangong to Leh */
  {
    title: '3D/2N Leh to Nubra to Turtuk - Pangong to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 29934,
      [TAXI_TYPE.INNOVA]: 27213,
      [TAXI_TYPE.SCORPIO]: 25852,
      [TAXI_TYPE.EECO]: 23440,
      [TAXI_TYPE.TRAVELLER]: 34971,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486890/activities/leh/nubra_valley_wn0vub.jpg',
        title: 'Leh to Nubra Valley. Visit Turtuk and Thang',
        desc: "Leh to Nubra Valley via Khardung La. Visit Turtuk, India's last village, and Thang. Return to Diskit and Hunder villages, with optional double-hump camel rides.",
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
        distance: '330 KM',
        time: '9 hrs',
      },
      SIMPLE_ITINERARY.NUBRA_VALLEY_TO_PANGONG_VIA_SHYOK,
      SIMPLE_ITINERARY.PANGONG_TO_LEH_VIA_CHANGLA,
    ],
    activityId: 'au114hq',
  },

  /* 3D/2N Leh to Nubra to Base Camp to Pangong - Leh */
  {
    title: '3D/2N Leh to Nubra to Base Camp to Pangong - Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 31085,
      [TAXI_TYPE.INNOVA]: 47750,
      [TAXI_TYPE.SCORPIO]: 26846,
      [TAXI_TYPE.EECO]: 25504,
      [TAXI_TYPE.TRAVELLER]: 37452,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La Pass',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides. ',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail: MEDIA.SIACHEN_BASE_CAMP,
        title: 'Day visit to Panamic, Sumur, and Siachen Base Camp',
        desc: `Visit Sumur village, Panamic hot water spring and visit Siachen Base Camp, the world's highest battlefield, and witness the life of the Indian Army.`,
        descHtml: '',
        places: [PLACE_ID.SUMUR],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong and back to Leh',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. Return to Leh by late evening',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 3D/2N Leh to Nubra to Turtuk - Thang to Pangong - Leh */
  {
    title: '3D/2N Leh to Nubra to Turtuk - Thang to (Pangong - Leh)',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 30781,
      [TAXI_TYPE.INNOVA]: 27983,
      [TAXI_TYPE.SCORPIO]: 26583,
      [TAXI_TYPE.EECO]: 24098,
      [TAXI_TYPE.TRAVELLER]: 34971,
    },
    itinerary: [
      SIMPLE_ITINERARY.LEH_TO_NUBRA_VALLEY,
      SIMPLE_ITINERARY.NUBRA_VALLEY_TO_TURTUK,
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Visit Pangong Tso and head back to Leh',
        desc: 'Travel from Nubra to Pangong known for its color-changing waters via Shyok River, witnessing stunning landscapes. Head back to Leh',
        descHtml: '',
        places: [PLACE_ID.LEH],
        distance: '330 KM',
        time: '9 hrs',
      },
    ],
    activityId: 'au114hq',
  },

  /*3D/2N Leh to Nubra to Turtuk - Thang - Pangong to Hemis - Leh */
  {
    title: '3D/2N Leh to (Nubra-Turtuk-Thang) to Pangong to Hemis - Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 31922,
      [TAXI_TYPE.INNOVA]: 29020,
      [TAXI_TYPE.SCORPIO]: 27568,
      [TAXI_TYPE.EECO]: 24978,
      [TAXI_TYPE.TRAVELLER]: 35961,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley and visit Turtuk via Khardung La',
        desc: 'Drive to Turtuk via the high Khardung La Pass. Visit Thang, Tyakshi, and Zero Point. Return to Disket and Hunder villages, with optional double-hump camel rides',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER, PLACE_ID.TURTUK],
      },
      SIMPLE_ITINERARY.NUBRA_VALLEY_TO_PANGONG_VIA_SHYOK,
      SIMPLE_ITINERARY.PANGONG_TO_LEH_VIA_HEMIS,

    ],
    activityId: 'au114hq',
  },

  /* 3D/2N Leh to Pangong to (Tsaga La-Tso Moriri) to (Tsokar-Leh) */
  {
    title: '3D/2N Leh to Pangong to (Tsaga La-Tso Moriri) to (Tsokar-Leh)',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 28860,
      [TAXI_TYPE.INNOVA]: 26236,
      [TAXI_TYPE.SCORPIO]: 24924,
      [TAXI_TYPE.EECO]: 22592,
      [TAXI_TYPE.TRAVELLER]: 36897,
    },
    itinerary: [ //Manoj
      SIMPLE_ITINERARY.LEH_TO_PANGONG_VIA_CHANGLA,
      SIMPLE_ITINERARY.PANGONG_TO_TSOMORIRI_VIA_TSAGALA,
      SIMPLE_ITINERARY.TSOMORIRI_TO_LEH_VIA_TSOKAR

    ],
    activityId: 'au114hq',
  },

  /* 3D/2N Leh to Pangong to (Tsaga La-Tso Moriri) to (Puga-Mahe-Leh) */
  {
    title: '3D/2N Leh to Pangong to (Tsaga La-Tso Moriri) to (Puga-Mahe-Leh)',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 28860 - PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.CRYSTA] + PRICE_IN_ROUTE_PUGA[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 26236 - PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.INNOVA] + PRICE_IN_ROUTE_PUGA[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]: 24924 - PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.SCORPIO] + PRICE_IN_ROUTE_PUGA[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]: 22592 - PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.EECO] + PRICE_IN_ROUTE_PUGA[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]: 36897 - PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.TRAVELLER] + PRICE_IN_ROUTE_PUGA[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      SIMPLE_ITINERARY.LEH_TO_PANGONG_VIA_CHANGLA,
      SIMPLE_ITINERARY.PANGONG_TO_TSOMORIRI_VIA_TSAGALA,
      SIMPLE_ITINERARY.TSOMORIRI_TO_LEH_VIA_MAHE_BRIDGE,

    ],
    activityId: 'au114hq',
  },

  /* 3D/2N Leh to Pangong to (Yaya Tso-Tso Moriri) to (Tsokar-Leh) */
  {
    title: '3D/2N Leh to Pangong to (Yaya Tso-Tso Moriri) to (Tsokar-Leh)',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 28860 - PRICE_DEDUCT_YAYA_TSO[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 26236 - PRICE_DEDUCT_YAYA_TSO[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]: 24924 - PRICE_DEDUCT_YAYA_TSO[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]: 22592 - PRICE_DEDUCT_YAYA_TSO[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]: 36897 - PRICE_DEDUCT_YAYA_TSO[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      SIMPLE_ITINERARY.LEH_TO_PANGONG_VIA_CHANGLA,
      SIMPLE_ITINERARY.PANGONG_TO_TSOMORIRI_VIA_YAYATSO,
      SIMPLE_ITINERARY.TSOMORIRI_TO_LEH_VIA_TSOKAR
    ],
    activityId: 'au114hq',
  },

  /* 3D/2N Leh to Pangong to (Yaya Tso-Tso Moriri) to (Puga-Mahe-Leh) */
  {
    title: '3D/2N Leh to Pangong to (Yaya Tso-Tso Moriri) to (Puga-Mahe-Leh)',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 28860 - PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.CRYSTA] - PRICE_DEDUCT_YAYA_TSO[TAXI_TYPE.CRYSTA] + PRICE_IN_ROUTE_PUGA[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 26236 - PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.INNOVA] - PRICE_DEDUCT_YAYA_TSO[TAXI_TYPE.INNOVA] + PRICE_IN_ROUTE_PUGA[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]: 24924 - PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.SCORPIO] - PRICE_DEDUCT_YAYA_TSO[TAXI_TYPE.SCORPIO] + PRICE_IN_ROUTE_PUGA[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]: 22592 - PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.EECO] - PRICE_DEDUCT_YAYA_TSO[TAXI_TYPE.EECO] + PRICE_IN_ROUTE_PUGA[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]: 36897 - PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.TRAVELLER] - PRICE_DEDUCT_YAYA_TSO[TAXI_TYPE.TRAVELLER] + PRICE_IN_ROUTE_PUGA[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      SIMPLE_ITINERARY.LEH_TO_PANGONG_VIA_CHANGLA,
      SIMPLE_ITINERARY.PANGONG_TO_TSOMORIRI_VIA_YAYATSO,
      SIMPLE_ITINERARY.TSOMORIRI_TO_LEH_VIA_MAHE_BRIDGE,
    ],
    activityId: 'au114hq',
  },


  /* 3D/2N Leh to Hanle to Tso Moriri to Tsokar - Leh */
  {
    title: '3D/2N Leh to Hanle to Tso Moriri to (Tsokar-Leh)',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 28183,
      [TAXI_TYPE.INNOVA]: 25364,
      [TAXI_TYPE.SCORPIO]: 24960,
      [TAXI_TYPE.EECO]: 22891,
      [TAXI_TYPE.TRAVELLER]: 35520,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714906536/mb/pexels-nick-kwan-2745254-1_11zon-scaled.jpg.jpg',
        title: 'Leh to Hanle',
        desc: 'Drive to Hanle, a remote village in Ladakh know for Stargazing. Visit the Hanle Monastery and the Indian Astronomical Observatory.',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },
      SIMPLE_ITINERARY.HANLE_TO_TSOMORIRI,
      SIMPLE_ITINERARY.TSOMORIRI_TO_LEH_VIA_TSOKAR
    ],
    activityId: 'au114hq',
  },

  /* 4D/3N Leh to Nubra to free day to Pangong to Leh */
  {
    title: '4D/3N Leh to Nubra to Free day to Pangong to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 24651 + PRICE_FREE_DAY[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 22410 + PRICE_FREE_DAY[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]: 21289 + PRICE_FREE_DAY[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]: 19305 + PRICE_FREE_DAY[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]: 29520 + PRICE_FREE_DAY[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      SIMPLE_ITINERARY.LEH_TO_NUBRA_VALLEY,
      {
        ...ITINERARIES.TAXI_FREE_DAY[0],
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      SIMPLE_ITINERARY.NUBRA_VALLEY_TO_PANGONG_VIA_SHYOK,
      SIMPLE_ITINERARY.PANGONG_TO_LEH_VIA_CHANGLA,
    ],
    activityId: 'au114hq',
  },

  /* 4D/3N Leh to Nubra to Turtuk - Thang to Free Day to Pangong - Leh */
  {
    title: '4D/3N Leh to Nubra to Turtuk - Thang to Free Day to Pangong - Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 33773,
      [TAXI_TYPE.INNOVA]: 30703,
      [TAXI_TYPE.SCORPIO]: 29167,
      [TAXI_TYPE.EECO]: 26441,
      [TAXI_TYPE.TRAVELLER]: 39441,
    },
    itinerary: [
      SIMPLE_ITINERARY.LEH_TO_NUBRA_VALLEY,
      SIMPLE_ITINERARY.NUBRA_VALLEY_TO_TURTUK,
      {
        ...ITINERARIES.TAXI_FREE_DAY[0],
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong to Leh',
        desc: 'Travel from Nubra to Pangong via Shyok River, known for its color-changing waters. Return to Leh by late evening',
        descHtml: '',
        places: [PLACE_ID.LEH],
        distance: '330 KM',
        time: '9 hrs',
      },
    ],
    activityId: 'au114hq',
  },

  /* 4D/3N Leh to Nubra to Free Day to Pangong to Hemis - Leh */
  {
    title: '4D/3N Leh to Nubra to Free Day to Pangong to Hemis - Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]:
        24651 +
        PRICE_FREE_DAY[TAXI_TYPE.CRYSTA] +
        IN_ROUTE_HEMIS[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]:
        22410 +
        PRICE_FREE_DAY[TAXI_TYPE.INNOVA] +
        IN_ROUTE_HEMIS[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]:
        21289 +
        PRICE_FREE_DAY[TAXI_TYPE.SCORPIO] +
        IN_ROUTE_HEMIS[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]:
        19305 + PRICE_FREE_DAY[TAXI_TYPE.EECO] + IN_ROUTE_HEMIS[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]:
        29520 +
        PRICE_FREE_DAY[TAXI_TYPE.TRAVELLER] +
        IN_ROUTE_HEMIS[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      SIMPLE_ITINERARY.LEH_TO_NUBRA_VALLEY,
      {
        ...ITINERARIES.TAXI_FREE_DAY[0],
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      SIMPLE_ITINERARY.NUBRA_VALLEY_TO_PANGONG_VIA_SHYOK,
      SIMPLE_ITINERARY.PANGONG_TO_LEH_VIA_HEMIS,

    ],
    activityId: 'au114hq',
  },

  /* 4D/3N Leh to Nubra to Turtuk - Thang to Pangong to Leh */
  {
    title: '4D/3N Leh to Nubra to Turtuk - Thang to Pangong to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 33773,
      [TAXI_TYPE.INNOVA]: 30703,
      [TAXI_TYPE.SCORPIO]: 29167,
      [TAXI_TYPE.EECO]: 26441,
      [TAXI_TYPE.TRAVELLER]: 39441,
    },
    itinerary: [
      SIMPLE_ITINERARY.LEH_TO_NUBRA_VALLEY,
      SIMPLE_ITINERARY.NUBRA_VALLEY_TO_TURTUK,
      SIMPLE_ITINERARY.NUBRA_VALLEY_TO_PANGONG_VIA_SHYOK,
      SIMPLE_ITINERARY.PANGONG_TO_LEH_VIA_CHANGLA,

    ],
    activityId: 'au114hq',
  },

  /* 4D/3N Leh to Nubra to Turtuk - Thang to Pangong to Hemis - Leh */
  {
    title: '4D/3N Leh to Nubra to Turtuk - Thang to Pangong to Hemis - Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 34914,
      [TAXI_TYPE.INNOVA]: 31740,
      [TAXI_TYPE.SCORPIO]: 30152,
      [TAXI_TYPE.EECO]: 27321,
      [TAXI_TYPE.TRAVELLER]: 40431,
    },
    itinerary: [
      SIMPLE_ITINERARY.LEH_TO_NUBRA_VALLEY,
      SIMPLE_ITINERARY.NUBRA_VALLEY_TO_TURTUK,
      SIMPLE_ITINERARY.NUBRA_VALLEY_TO_PANGONG_VIA_SHYOK,
      SIMPLE_ITINERARY.PANGONG_TO_LEH_VIA_HEMIS,
    ],
    activityId: 'au114hq',
  },

  /* 4D/3N Leh to Nubra (Stay in Sumur) to Turtuk - Thang to Pangong (Stay in Merek) to Hemis - Leh */
  {
    title:
      '4D/3N Leh to Nubra (Stay in Sumur) to Turtuk - Thang to Pangong (Stay in Merek) to Hemis - Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]:
        34914 +
        IN_ROUTE_SUMUR_TWO_WAY[TAXI_TYPE.CRYSTA] +
        IN_ROUTE_SUMUR[TAXI_TYPE.CRYSTA] +
        IN_ROUTE_MEREK[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]:
        31740 +
        IN_ROUTE_SUMUR_TWO_WAY[TAXI_TYPE.INNOVA] +
        IN_ROUTE_SUMUR[TAXI_TYPE.INNOVA] +
        IN_ROUTE_MEREK[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]:
        30152 +
        IN_ROUTE_SUMUR_TWO_WAY[TAXI_TYPE.SCORPIO] +
        IN_ROUTE_SUMUR[TAXI_TYPE.SCORPIO] +
        IN_ROUTE_MEREK[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]:
        27321 +
        IN_ROUTE_SUMUR_TWO_WAY[TAXI_TYPE.EECO] +
        IN_ROUTE_SUMUR[TAXI_TYPE.EECO] +
        IN_ROUTE_MEREK[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]:
        40431 +
        IN_ROUTE_SUMUR_TWO_WAY[TAXI_TYPE.TRAVELLER] +
        IN_ROUTE_SUMUR[TAXI_TYPE.TRAVELLER] +
        IN_ROUTE_MEREK[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides. Night stay in Sumur',
        descHtml: '',
        places: [PLACE_ID.SUMUR],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714480487/mb/turtuk.jpeg.jpg',
        title: 'Day visit to Turtuk, Tyakshi, Thang and Zero Point',
        desc: `Visit Turtuk and Thang, including Tyakshi, India's last village. Learn about Turtuk's shift from Pakistan to India. Night stay in Sumur`,
        descHtml: '',
        places: [PLACE_ID.SUMUR],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters. Night stay in Merek',
        descHtml: '',
        places: [PLACE_ID.MEREK],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/noz40nipxdtpgju2jus5.jpg',
        title: 'Pangong to Hemis / Thiksay / Shey to Leh',
        desc: 'Journey back to Leh from Pangong, stopping to explore the rich heritage at Hemis, Thiksey, Shey, and the famous Rancho School.',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 4D/3N Leh to Nubra (Stay in Sumur) to Turtuk - Thang to Freeday to Pangong - Leh */
  {
    title:
      '4D/3N Leh to Nubra (Stay in Sumur) to Turtuk - Thang to Freeday to Pangong - Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]:
        32926 +
        IN_ROUTE_THANG[TAXI_TYPE.CRYSTA] +
        IN_ROUTE_SUMUR_TWO_WAY[TAXI_TYPE.CRYSTA] +
        IN_ROUTE_SUMUR[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]:
        29933 +
        IN_ROUTE_THANG[TAXI_TYPE.INNOVA] +
        IN_ROUTE_SUMUR_TWO_WAY[TAXI_TYPE.INNOVA] +
        IN_ROUTE_SUMUR[TAXI_TYPE.INNOVA],

      [TAXI_TYPE.SCORPIO]:
        28436 +
        IN_ROUTE_THANG[TAXI_TYPE.SCORPIO] +
        IN_ROUTE_SUMUR_TWO_WAY[TAXI_TYPE.SCORPIO] +
        IN_ROUTE_SUMUR[TAXI_TYPE.SCORPIO],

      [TAXI_TYPE.EECO]:
        25783 +
        IN_ROUTE_THANG[TAXI_TYPE.EECO] +
        IN_ROUTE_SUMUR_TWO_WAY[TAXI_TYPE.EECO] +
        IN_ROUTE_SUMUR[TAXI_TYPE.EECO],

      [TAXI_TYPE.TRAVELLER]:
        40431 +
        IN_ROUTE_SUMUR_TWO_WAY[TAXI_TYPE.TRAVELLER] +
        IN_ROUTE_SUMUR[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides. Night stay in Sumur',
        descHtml: '',
        places: [PLACE_ID.SUMUR],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714480487/mb/turtuk.jpeg.jpg',
        title: 'Day visit to Turtuk, Tyakshi, Thang and Zero Point',
        desc: `Visit Turtuk and Thang, including Tyakshi, India's last village. Learn about Turtuk's shift from Pakistan to India. Night stay in Sumur`,
        descHtml: '',
        places: [PLACE_ID.SUMUR],
      },
      { ...ITINERARIES.TAXI_FREE_DAY[0], places: [PLACE_ID.SUMUR] },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong and back to Leh',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters. Return to Leh by late evening',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 4D/3N Leh to Nubra to  Turtuk - Thang to Base Camp to Pangong to Leh */
  {
    title: '4D/3N Leh to Nubra to  Turtuk - Thang to Base Camp to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]:
        23461 +
        IN_ROUTE_TURTUK[TAXI_TYPE.CRYSTA] +
        IN_ROUTE_THANG[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]:
        21328 +
        IN_ROUTE_TURTUK[TAXI_TYPE.INNOVA] +
        IN_ROUTE_THANG[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]:
        20262 +
        IN_ROUTE_TURTUK[TAXI_TYPE.SCORPIO] +
        IN_ROUTE_THANG[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]:
        19248 +
        IN_ROUTE_TURTUK[TAXI_TYPE.EECO] +
        IN_ROUTE_THANG[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]:
        28898 +
        IN_ROUTE_TURTUK[TAXI_TYPE.TRAVELLER] +
        IN_ROUTE_THANG[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title:
          'Leh to Nubra Valley via Khardung La and day visit Diskit & Hunder',
        desc: `Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides. Visit Turtuk and Thang, including Tyakshi, India's last village. Learn about Turtuk's shift from Pakistan to India.`,
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://resComfort Class Taxis.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714480487/mb/turtuk.jpeg.jpg',
        title: 'Day visit to Turtuk, Tyakshi, Thang and Zero Point',
        desc: `Visit Turtuk and Thang, including Tyakshi, India's last village. Learn about Turtuk's shift from Pakistan to India.`,
        descHtml: '',
        places: [PLACE_ID.TURTUK, PLACE_ID.DISKIT],
      },
      {
        thumbnail: MEDIA.SIACHEN_BASE_CAMP,
        title: 'Day visit to Siachen Base Camp & Panamik Hot Springs',
        desc: `Visit Siachen Base Camp, the world's highest battlefield, and witness the life of the Indian Army. Also, visit Panamik Hot Springs for a relaxing experience. `,
        descHtml: '',
        places: [PLACE_ID.SUMUR, PLACE_ID.PANAMIK],
      },
      {
        thumbnail: MEDIA.LEH_GENERIC,
        title: 'Back to Leh',
        desc: 'Journey back to Leh',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 4D/3N Leh to Nubra to  Turtuk - Thang to Base Camp to Pangong to Leh */
  {
    title:
      '4D/3N Leh to Nubra to  Turtuk - Thang to Base Camp to Pangong to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 40425,
      [TAXI_TYPE.INNOVA]: 36750,
      [TAXI_TYPE.SCORPIO]: 34912,
      [TAXI_TYPE.EECO]: 33130,
      [TAXI_TYPE.TRAVELLER]: 48367,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title:
          'Leh to Nubra Valley via Khardung La and day visit to Turtuk, Tyakshi, Thang and Zero Point',
        desc: `Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides. Visit Turtuk and Thang, including Tyakshi, India's last village. Learn about Turtuk's shift from Pakistan to India.`,
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714480487/mb/turtuk.jpeg.jpg',
        title: 'Day visit to Siachen Base Camp ',
        desc: `Visit Siachen Base Camp, the world's highest battlefield, and witness the life of the Indian Army.`,
        descHtml: '',
        places: [PLACE_ID.SUMUR, PLACE_ID.PANAMIK],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color changing waters.',
        descHtml: '',
        places: [PLACE_ID.LUKUNG, PLACE_ID.SPANGMIK],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/noz40nipxdtpgju2jus5.jpg',
        title: 'Pangong to Leh',
        desc: 'Journey back to Leh from Pangong',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 4D/3N Leh to Nubra to Pangong to Hanle to Leh */
  {
    title: '4D/3N Leh to Nubra to Pangong to Hanle to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 40015,
      [TAXI_TYPE.INNOVA]: 36013,
      [TAXI_TYPE.SCORPIO]: 34212,
      [TAXI_TYPE.EECO]: 30708,
      [TAXI_TYPE.TRAVELLER]: 45967,
    },
    itinerary: [
      SIMPLE_ITINERARY.LEH_TO_NUBRA_VALLEY,
      SIMPLE_ITINERARY.NUBRA_VALLEY_TO_PANGONG_VIA_SHYOK,

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714906536/mb/pexels-nick-kwan-2745254-1_11zon-scaled.jpg.jpg',
        title: 'Pangong to Hanle',
        desc: 'Travel from Pangong to Hanle, a remote village in Ladakh known for Stargazing. Visit the Hanle Monastery and the Indian Astronomical Observatory.',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/noz40nipxdtpgju2jus5.jpg',
        title: 'Hanle to Leh',
        desc: 'Journey back to Leh from Hanle',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 4D/3N Leh to Nubra - Panamik to Pangong to Hanle to Leh */
  {
    title: '4D/3N Leh to Nubra - Panamik to Pangong to Hanle to Hemis - Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]:
        40015 +
        PRICE_IN_ROUTE_PANAMIK[TAXI_TYPE.CRYSTA] +
        IN_ROUTE_HEMIS[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]:
        36013 +
        PRICE_IN_ROUTE_PANAMIK[TAXI_TYPE.INNOVA] +
        IN_ROUTE_HEMIS[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]:
        34212 +
        PRICE_IN_ROUTE_PANAMIK[TAXI_TYPE.SCORPIO] +
        IN_ROUTE_HEMIS[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]:
        30708 +
        PRICE_IN_ROUTE_PANAMIK[TAXI_TYPE.EECO] +
        IN_ROUTE_HEMIS[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]:
        45967 +
        PRICE_IN_ROUTE_PANAMIK[TAXI_TYPE.TRAVELLER] +
        IN_ROUTE_HEMIS[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides. Head to Panamik or Sumur for a relaxing evening.',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Visit Panamik hot spring early morning and head to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714906536/mb/pexels-nick-kwan-2745254-1_11zon-scaled.jpg.jpg',
        title: 'Pangong to Hanle',
        desc: 'Travel from Pangong to Hanle, a remote village in Ladakh know for Stargazing. Visit the Hanle Monastery and the Indian Astronomical Observatory.',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/noz40nipxdtpgju2jus5.jpg',
        title:
          'Hanle to Leh. Enroute visit Hemis, Thiksey, Shey, and Rancho School',
        desc: 'Journey back to Leh from Hanle. Enroute visit Hemis, Thiksey, Shey, and the famous Rancho School.',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 4D/3N Leh to Nubra to Base Camp to Pangong to Hemis - Leh */
  {
    title: '4D/3N Leh to Nubra to Base Camp to Pangong to Hemis - Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]:
        31085 +
        PRICE_FREE_DAY[TAXI_TYPE.CRYSTA] +
        IN_ROUTE_HEMIS[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]:
        47750 +
        PRICE_FREE_DAY[TAXI_TYPE.INNOVA] +
        IN_ROUTE_HEMIS[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]:
        26846 +
        PRICE_FREE_DAY[TAXI_TYPE.SCORPIO] +
        IN_ROUTE_HEMIS[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]:
        25504 + PRICE_FREE_DAY[TAXI_TYPE.EECO] + IN_ROUTE_HEMIS[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]:
        37452 +
        PRICE_FREE_DAY[TAXI_TYPE.TRAVELLER] +
        IN_ROUTE_HEMIS[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La Pass',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides. ',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail: MEDIA.SIACHEN_BASE_CAMP,
        title: 'Day visit to Panamic, Sumur, and Siachen Base Camp',
        desc: `Visit Sumur village, Panamic hot water spring and visit Siachen Base Camp, the world's highest battlefield, and witness the life of the Indian Army.`,
        descHtml: '',
        places: [PLACE_ID.SUMUR, PLACE_ID.PANAMIK],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong Tso',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [PLACE_ID.LUKUNG, PLACE_ID.SPANGMIK],
      },

      {
        thumbnail: MEDIA.RANCHO_SCHOOL,
        title:
          'Pangong Tso to Leh. En route visit Hemis, Thiksey, Shey, and Rancho School',
        desc: 'Journey back to Leh from Pangong via Chang La Pass, stopping to explore the rich heritage at Hemis, Thiksey, Shey, and the famous Rancho School.',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 4D/3N Leh to Nubra to Pangong to Hanle to Hemis - Leh */
  {
    title: '4D/3N Leh to Nubra to Pangong to Hanle to Hemis - Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 41156,
      [TAXI_TYPE.INNOVA]: 37138,
      [TAXI_TYPE.SCORPIO]: 35303,
      [TAXI_TYPE.EECO]: 31984,
      [TAXI_TYPE.TRAVELLER]: 47179,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714906536/mb/pexels-nick-kwan-2745254-1_11zon-scaled.jpg.jpg',
        title: 'Pangong to Hanle',
        desc: 'Travel from Pangong to Hanle, a remote village in Ladakh know for Stargazing. Visit the Hanle Monastery and the Indian Astronomical Observatory.',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/noz40nipxdtpgju2jus5.jpg',
        title: 'Hanle to Hemis / Thiksay / Shey to Leh',
        desc: 'Journey back to Leh from Hanle, stopping to explore the rich heritage at Hemis, Thiksey, Shey, and the famous Rancho School.',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 4D/3N Leh to Nubra to Pangong to Hanle to Umling La Pass - Leh */
  {
    title: '4D/3N Leh to Nubra to Pangong to Hanle to Umling La Pass - Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 48704,
      [TAXI_TYPE.INNOVA]: 43931,
      [TAXI_TYPE.SCORPIO]: 41756,
      [TAXI_TYPE.EECO]: 38114,
      [TAXI_TYPE.TRAVELLER]: 53889,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714906536/mb/pexels-nick-kwan-2745254-1_11zon-scaled.jpg.jpg',
        title: 'Pangong to Hanle',
        desc: 'Travel from Pangong to Hanle, a remote village in Ladakh know for Stargazing. Visit the Hanle Monastery and the Indian Astronomical Observatory.',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1715843073/mb/DP-2.jpg.jpg',
        title: 'Hanle to Umling La Pass to Leh',
        desc: 'Drive to Umling La Pass, the world’s highest motorable road, and return to Leh',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 4D/3N Leh to Nubra to Pangong to (Yaya Tso-Tso Moriri) to (Tsokar-Leh) */
  {
    title: '4D/3N Leh to Nubra to Pangong to (Yaya Tso-Tso Moriri) to (Tsokar-Leh)',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 44829 - PRICE_DEDUCT_TURTUK[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 40754 - PRICE_DEDUCT_TURTUK[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]: 38716 - PRICE_DEDUCT_TURTUK[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]: 34844 - PRICE_DEDUCT_TURTUK[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]: 54294 - PRICE_DEDUCT_TURTUK[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      SIMPLE_ITINERARY.LEH_TO_NUBRA_VALLEY,
      {
        ...SIMPLE_ITINERARY.NUBRA_VALLEY_TO_PANGONG_VIA_SHYOK,
        places: [
          ...SIMPLE_ITINERARY.NUBRA_VALLEY_TO_PANGONG_VIA_SHYOK.places,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },
      SIMPLE_ITINERARY.PANGONG_TO_TSOMORIRI_VIA_YAYATSO,
      SIMPLE_ITINERARY.TSOMORIRI_TO_LEH_VIA_TSOKAR,

    ],
    activityId: 'au114hq',
  },

  /* 4D/3N Leh to Nubra to Pangong to (Tsaga La-Tso Moriri) to (Tsokar-Leh) */
  {
    title: '4D/3N Leh to Nubra to Pangong to (Tsaga La-Tso Moriri) to (Tsokar-Leh)',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 46448 - PRICE_DEDUCT_TURTUK[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 42226 - PRICE_DEDUCT_TURTUK[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]: 40115 - PRICE_DEDUCT_TURTUK[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]: 36375 - PRICE_DEDUCT_TURTUK[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]: 46189,//DESKIT, HUNDUR, PANGONG VIA SHAYOK TSOMORIRI, TSAGALA BACK VIA TANGLANGLA4 DAYS
    },
    itinerary: [
      SIMPLE_ITINERARY.LEH_TO_NUBRA_VALLEY,
      {
        ...SIMPLE_ITINERARY.NUBRA_VALLEY_TO_PANGONG_VIA_SHYOK,
        places: [
          ...SIMPLE_ITINERARY.NUBRA_VALLEY_TO_PANGONG_VIA_SHYOK.places,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },
      SIMPLE_ITINERARY.PANGONG_TO_TSOMORIRI_VIA_TSAGALA,
      SIMPLE_ITINERARY.TSOMORIRI_TO_LEH_VIA_TSOKAR,

    ],
    activityId: 'au114hq',
  },

  /* 4D/3N Leh to Nubra to Pangong to (Yaya Tso-Tso Moriri) to (Puga-Mahe-Leh) */
  {
    title: '4D/3N Leh to Nubra to Pangong to (Yaya Tso-Tso Moriri) to (Puga-Mahe-Leh)',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 44829 - PRICE_DEDUCT_TURTUK[TAXI_TYPE.CRYSTA] - PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.CRYSTA] + PRICE_IN_ROUTE_PUGA[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 40754 - PRICE_DEDUCT_TURTUK[TAXI_TYPE.INNOVA] - PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.INNOVA] + PRICE_IN_ROUTE_PUGA[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]: 38716 - PRICE_DEDUCT_TURTUK[TAXI_TYPE.SCORPIO] - PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.SCORPIO] + PRICE_IN_ROUTE_PUGA[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]: 34844 - PRICE_DEDUCT_TURTUK[TAXI_TYPE.EECO] - PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.EECO] + PRICE_IN_ROUTE_PUGA[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]: 54294 - PRICE_DEDUCT_TURTUK[TAXI_TYPE.TRAVELLER] - PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.TRAVELLER] + PRICE_IN_ROUTE_PUGA[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      SIMPLE_ITINERARY.LEH_TO_NUBRA_VALLEY,
      {
        ...SIMPLE_ITINERARY.NUBRA_VALLEY_TO_PANGONG_VIA_SHYOK,
        places: [
          ...SIMPLE_ITINERARY.NUBRA_VALLEY_TO_PANGONG_VIA_SHYOK.places,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },
      SIMPLE_ITINERARY.PANGONG_TO_TSOMORIRI_VIA_YAYATSO,
      SIMPLE_ITINERARY.TSOMORIRI_TO_LEH_VIA_MAHE_BRIDGE,

    ],
    activityId: 'au114hq',
  },

  /* 4D/3N Leh to Nubra to Pangong to (Tsaga La-Tso Moriri) to (Puga-Mahe-Leh) */
  {
    title: '4D/3N Leh to Nubra to Pangong to (Tsaga La-Tso Moriri) to (Puga-Mahe-Leh)',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 46448 - PRICE_DEDUCT_TURTUK[TAXI_TYPE.CRYSTA] - PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.CRYSTA] + PRICE_IN_ROUTE_PUGA[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 42226 - PRICE_DEDUCT_TURTUK[TAXI_TYPE.INNOVA] - PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.INNOVA] + PRICE_IN_ROUTE_PUGA[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]: 40115 - PRICE_DEDUCT_TURTUK[TAXI_TYPE.SCORPIO] - PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.SCORPIO] + PRICE_IN_ROUTE_PUGA[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]: 36375 - PRICE_DEDUCT_TURTUK[TAXI_TYPE.EECO] - PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.EECO] + PRICE_IN_ROUTE_PUGA[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]: 46189 - PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.TRAVELLER] + PRICE_IN_ROUTE_PUGA[TAXI_TYPE.TRAVELLER],//DESKIT, HUNDUR, PANGONG VIA SHAYOK TSOMORIRI, TSAGALA BACK VIA TANGLANGLA4 DAYS
    },
    itinerary: [
      SIMPLE_ITINERARY.LEH_TO_NUBRA_VALLEY,
      {
        ...SIMPLE_ITINERARY.NUBRA_VALLEY_TO_PANGONG_VIA_SHYOK,
        places: [
          ...SIMPLE_ITINERARY.NUBRA_VALLEY_TO_PANGONG_VIA_SHYOK.places,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },
      SIMPLE_ITINERARY.PANGONG_TO_TSOMORIRI_VIA_TSAGALA,
      SIMPLE_ITINERARY.TSOMORIRI_TO_LEH_VIA_MAHE_BRIDGE,

    ],
    activityId: 'au114hq',
  },

  /* 4D/3N Leh to Nubra to Pangong to (Yaya Tso-Tso Moriri) to (Puga-Mahe-Hemis-Leh) */
  {
    title: '4D/3N Leh to Nubra to Pangong to (Yaya Tso-Tso Moriri) to (Puga-Mahe-Hemis-Leh)',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 44829 - PRICE_DEDUCT_TURTUK[TAXI_TYPE.CRYSTA] - PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.CRYSTA] + PRICE_IN_ROUTE_PUGA[TAXI_TYPE.CRYSTA] + IN_ROUTE_HEMIS[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 40754 - PRICE_DEDUCT_TURTUK[TAXI_TYPE.INNOVA] - PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.INNOVA] + PRICE_IN_ROUTE_PUGA[TAXI_TYPE.INNOVA] + IN_ROUTE_HEMIS[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]: 38716 - PRICE_DEDUCT_TURTUK[TAXI_TYPE.SCORPIO] - PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.SCORPIO] + PRICE_IN_ROUTE_PUGA[TAXI_TYPE.SCORPIO] + IN_ROUTE_HEMIS[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]: 34844 - PRICE_DEDUCT_TURTUK[TAXI_TYPE.EECO] - PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.EECO] + PRICE_IN_ROUTE_PUGA[TAXI_TYPE.EECO] + IN_ROUTE_HEMIS[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]: 54294 - PRICE_DEDUCT_TURTUK[TAXI_TYPE.TRAVELLER] - PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.TRAVELLER] + PRICE_IN_ROUTE_PUGA[TAXI_TYPE.TRAVELLER] + IN_ROUTE_HEMIS[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      SIMPLE_ITINERARY.LEH_TO_NUBRA_VALLEY,
      {
        ...SIMPLE_ITINERARY.NUBRA_VALLEY_TO_PANGONG_VIA_SHYOK,
        places: [
          ...SIMPLE_ITINERARY.NUBRA_VALLEY_TO_PANGONG_VIA_SHYOK.places,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },
      SIMPLE_ITINERARY.PANGONG_TO_TSOMORIRI_VIA_YAYATSO,
      {
        thumbnail: MEDIA.PUGA_VALLEY,
        title: 'Tso Moriri to Leh via Mahe Bridge and Hemis',
        desc: 'Journey back to Leh covering unique hotsprings of Puga Valley,stopping to explore the rich heritage at Hemis, Thiksey, Shey, and the famous Rancho School.',
        descHtml: '',
        places: [PLACE_ID.LEH],
        distance: '222 KM',
        travel_time: '6 hrs',
      },
    ],
    activityId: 'au114hq',
  },

  /* 4D/3N Leh to Pangong to Hanle to Tso Moriri to Leh */
  {
    title: '4D/3N Leh to Pangong to Hanle to Tso Moriri to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 33518,
      [TAXI_TYPE.INNOVA]: 30471,
      [TAXI_TYPE.SCORPIO]: 28947,
      [TAXI_TYPE.EECO]: 26239,
      [TAXI_TYPE.TRAVELLER]: 42123,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Leh to Pangong',
        desc: 'Travel from Leh to Pangong via Chang La Pass, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },
      SIMPLE_ITINERARY.PANGONG_TO_HANLE,

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714740700/mb/licensed-image-%281%29.jpeg.jpg',
        title: 'Hanle to Tso Moriri',
        desc: 'Travel from Hanle to Tso Moriri, a high-altitude lake known for its serene beauty and birdlife. Spend the night at the lake.',
        descHtml: '',
        places: [PLACE_ID.TSO_MORIRI],
      },
      {
        thumbnail: MEDIA.LEH_GENERIC,
        title: 'Tso Moriri to Leh',
        desc: 'Journey back to Leh from Tso Moriri',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 4D/3N Leh to Pangong to Hanle to Umling La Pass - Tso Moriri to Leh */
  {
    title:
      '4D/3N Leh to Pangong to Hanle to Umling La Pass - Tso Moriri to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 33518 + PRICE_UMLING_PASS[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 30471 + PRICE_UMLING_PASS[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]: 28947 + PRICE_UMLING_PASS[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]: 26239 + PRICE_UMLING_PASS[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]: 42123 + PRICE_UMLING_PASS[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Leh to Pangong',
        desc: 'Travel from Leh to Pangong via Chang La Pass, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },
      SIMPLE_ITINERARY.PANGONG_TO_HANLE,

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714740700/mb/licensed-image-%281%29.jpeg.jpg',
        title: 'Hanle to Umling La Pass to Tso Moriri',
        desc: 'Drive to Umling La Pass, the world’s highest motorable road, and continue to Tso Moriri, a high-altitude lake known for its serene beauty and birdlife. Spend the night at the lake.',
        descHtml: '',
        places: [PLACE_ID.TSO_MORIRI],
      },
      {
        thumbnail: MEDIA.PUGA_VALLEY,
        title: 'Tso Moriri to Leh',
        desc: 'Journey back to Leh from Tso Moriri',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 4D/3N Leh to Hanle to Umling La Pass - hanle to Tso Moriri to Puga Valley - Leh */
  {
    title:
      '4D/3N Leh to Hanle to Umling La Pass - hanle to Tso Moriri to Puga Valley - Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 28183 + PRICE_UMLING_PASS[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 25364 + PRICE_UMLING_PASS[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]: 24960 + PRICE_UMLING_PASS[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]: 22891 + PRICE_UMLING_PASS[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]: 35552 + PRICE_UMLING_PASS[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714906536/mb/pexels-nick-kwan-2745254-1_11zon-scaled.jpg.jpg',
        title: 'Leh to Hanle',
        desc: 'Travel from Leh to Hanle, a remote village in Ladakh know for Stargazing. Visit the Hanle Monastery and the Indian Astronomical Observatory.',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },
      {
        thumbnail: MEDIA.UMLING_PASS,
        title: 'Hanle to Umling La Pass to Hanle',
        desc: 'Drive to Umling La Pass, the world’s highest motorable road, and return back to Hanle',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714740700/mb/licensed-image-%281%29.jpeg.jpg',
        title: 'Hanle to Tso Moriri',
        desc: 'Drive to Tso Moriri, a high-altitude lake known for its serene beauty and birdlife. Spend the night at the lake.',
        descHtml: '',
        places: [PLACE_ID.TSO_MORIRI],
      },
      {
        thumbnail: MEDIA.LEH_GENERIC,
        title: 'Tso Moriri to Leh via Puga Valley & Tso Kar',
        desc: 'Journey back to Leh from Tso Moriri. Enroute visit Puga Valley and Tso Kar Lake.',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 4D/3N Leh to Nubra to Pangong to Umling Pass - Hanle to Puga Valley - Tso Moriri - Leh */
  {
    title:
      '4D/3N Leh to Nubra to Pangong to Umling Pass - Hanle to Puga Valley - Tso Moriri - Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 44204 + PRICE_UMLING_PASS[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 40186 + PRICE_UMLING_PASS[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]: 38176 + PRICE_UMLING_PASS[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]: 34355 + PRICE_UMLING_PASS[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]: 51415 + PRICE_UMLING_PASS[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714906536/mb/pexels-nick-kwan-2745254-1_11zon-scaled.jpg.jpg',
        title: 'Pangong to Umling Pass to Hanle',
        desc: `Travel from Pangong to Umling Pass, the world's highest motorable road. Then, travel to Hanle, a remote village in Ladakh know for Stargazing. Visit the Hanle Monastery and the Indian Astronomical Observatory.`,
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714740700/mb/licensed-image-%281%29.jpeg.jpg',
        title: 'Hanle to Puga Valley to Tso Moriri to Leh',
        desc: 'Travel from Hanle to Tso Moriri, a high-altitude lake known for its serene beauty and birdlife. Spend the night at the lake. Enroute visit Puga Valley. Travel back to Leh',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 4D/3N Leh to Nubra to Pangong to Umling Pass - Hanle to Puga Valley - Leh */
  {
    title:
      '4D/3N Leh to Nubra to Pangong to Umling Pass - Hanle to Puga Valley - Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]:
        44204 +
        PRICE_UMLING_PASS[TAXI_TYPE.CRYSTA] -
        IN_ROUTE_HANLE[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]:
        40186 +
        PRICE_UMLING_PASS[TAXI_TYPE.INNOVA] -
        IN_ROUTE_HANLE[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]:
        38176 +
        PRICE_UMLING_PASS[TAXI_TYPE.SCORPIO] -
        IN_ROUTE_HANLE[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]:
        34355 +
        PRICE_UMLING_PASS[TAXI_TYPE.EECO] -
        IN_ROUTE_HANLE[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]:
        51415 +
        PRICE_UMLING_PASS[TAXI_TYPE.TRAVELLER] -
        IN_ROUTE_HANLE[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714906536/mb/pexels-nick-kwan-2745254-1_11zon-scaled.jpg.jpg',
        title: 'Pangong to Umling Pass to Hanle',
        desc: `Travel from Pangong to Umling Pass, the world's highest motorable road. Then, travel to Hanle, a remote village in Ladakh know for Stargazing. Visit the Hanle Monastery and the Indian Astronomical Observatory.`,
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714740700/mb/licensed-image-%281%29.jpeg.jpg',
        title: 'Hanle to Puga Valley to Leh',
        desc: 'Travel from Hanle to Puga Valley. Travel back to Leh',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 4D/3N Leh to Turtuk - Thang - Nubra to Pangong to Hanle to Umling La Pass - Leh */
  {
    title:
      '4D/3N Leh to Turtuk - Thang - Nubra to Pangong to Hanle to Umling La Pass - Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 46145 + PRICE_UMLING_PASS[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 41586 + PRICE_UMLING_PASS[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.SCORPIO]: 39506 + PRICE_UMLING_PASS[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.EECO]: 35502 + PRICE_UMLING_PASS[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.TRAVELLER]: 55888 + PRICE_UMLING_PASS[TAXI_TYPE.CRYSTA],
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Turtuk to Hunder via Khardung La',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Turtuk, Tyakshi, Thang and Zero Point, Disket and Hunder villages, with optional double-hump camel rides',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      SIMPLE_ITINERARY.NUBRA_VALLEY_TO_PANGONG_PLUS,
      SIMPLE_ITINERARY.PANGONG_TO_HANLE,
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1715843073/mb/DP-2.jpg.jpg',
        title: 'Hanle to Umling La Pass to Leh',
        desc: 'Drive to Umling La Pass, the world’s highest motorable road, and return to Leh',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 5D/4N Leh to Nubra to Turtuk to Base Camp to Pangong to Hemis - Leh */
  {
    title: 'Leh to Nubra to Turtuk to Base Camp to Pangong to Hemis - Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]:
        39578 +
        PRICE_FREE_DAY[TAXI_TYPE.CRYSTA] +
        IN_ROUTE_HEMIS[TAXI_TYPE.CRYSTA] +
        IN_ROUTE_THANG[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]:
        35980 +
        PRICE_FREE_DAY[TAXI_TYPE.INNOVA] +
        IN_ROUTE_HEMIS[TAXI_TYPE.INNOVA] +
        IN_ROUTE_THANG[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]:
        34181 +
        PRICE_FREE_DAY[TAXI_TYPE.SCORPIO] +
        IN_ROUTE_HEMIS[TAXI_TYPE.SCORPIO] +
        IN_ROUTE_THANG[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]:
        32472 +
        PRICE_FREE_DAY[TAXI_TYPE.EECO] +
        IN_ROUTE_HEMIS[TAXI_TYPE.EECO] +
        IN_ROUTE_THANG[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]:
        48367 +
        PRICE_FREE_DAY[TAXI_TYPE.TRAVELLER] +
        IN_ROUTE_HEMIS[TAXI_TYPE.TRAVELLER] +
        IN_ROUTE_THANG[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      SIMPLE_ITINERARY.LEH_TO_NUBRA_VALLEY,
      SIMPLE_ITINERARY.NUBRA_VALLEY_TO_TURTUK,

      {
        thumbnail: MEDIA.SIACHEN_BASE_CAMP,
        title: 'Day visit to Panamic, Sumur, and Siachen Base Camp',
        desc: `Visit Sumur village, Panamic hot water spring and visit Siachen Base Camp, the world's highest battlefield, and witness the life of the Indian Army.`,
        descHtml: '',
        places: [PLACE_ID.SUMUR, PLACE_ID.PANAMIK],
      },
      SIMPLE_ITINERARY.NUBRA_VALLEY_TO_PANGONG_VIA_SHYOK,
      SIMPLE_ITINERARY.PANGONG_TO_LEH_VIA_HEMIS,

    ],
    activityId: 'au114hq',
  },

  /* 5D/4N Leh to Nubra to Pangong to Hanle to Free Day to Mahay - Leh */
  {
    title: '5D/4N Leh to Nubra to Pangong to Hanle to Free Day to Mahay - Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]:
        40015 +
        PRICE_FREE_DAY[TAXI_TYPE.CRYSTA] -
        PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]:
        36013 +
        PRICE_FREE_DAY[TAXI_TYPE.INNOVA] -
        PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]:
        34212 +
        PRICE_FREE_DAY[TAXI_TYPE.SCORPIO] -
        PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]:
        30708 +
        PRICE_FREE_DAY[TAXI_TYPE.EECO] -
        PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]:
        45967 +
        PRICE_FREE_DAY[TAXI_TYPE.TRAVELLER] -
        PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714906536/mb/pexels-nick-kwan-2745254-1_11zon-scaled.jpg.jpg',
        title: 'Pangong to Hanle',
        desc: 'Travel from Pangong to Hanle, a remote village in Ladakh know for Stargazing. Visit the Hanle Monastery and the Indian Astronomical Observatory.',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },
      { ...ITINERARIES.TAXI_FREE_DAY[0], places: [PLACE_ID.HANLE] },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/noz40nipxdtpgju2jus5.jpg',
        title: 'Hanle to Leh',
        desc: 'Journey back to Leh from Hanle',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 5D/4N Leh to Nubra to Pangong to Hanle to Umling La Pass - Hanle to  Leh */
  {
    title:
      '5D/4N Leh to Nubra to Pangong to Hanle to Umling La Pass - Hanle to  Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 48333,
      [TAXI_TYPE.INNOVA]: 43714,
      [TAXI_TYPE.SCORPIO]: 41441,
      [TAXI_TYPE.EECO]: 38114,
      [TAXI_TYPE.TRAVELLER]: 54132,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714906536/mb/pexels-nick-kwan-2745254-1_11zon-scaled.jpg.jpg',
        title: 'Pangong to Hanle',
        desc: 'Travel from Pangong to Hanle, a remote village in Ladakh know for Stargazing. Visit the Hanle Monastery and the Indian Astronomical Observatory.',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1715843073/mb/DP-2.jpg.jpg',
        title: 'Hanle to Umling La Pass and back to Hanle',
        desc: 'Drive to Umling La Pass, the world’s highest motorable road, and return to Hanle',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },
      {
        thumbnail: MEDIA.LEH_GENERIC,
        title: 'Hanle to Leh',
        desc: 'Drive back to Leh',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 5D/4N Leh to Hanle to Umling La Pass - Hanle to Pangong to  Nubra to Leh */
  {
    title:
      '5D/4N Leh to Hanle to Umling La Pass - Hanle to Pangong to  Nubra to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 48333,
      [TAXI_TYPE.INNOVA]: 43714,
      [TAXI_TYPE.SCORPIO]: 41441,
      [TAXI_TYPE.EECO]: 38114,
      [TAXI_TYPE.TRAVELLER]: 54132,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714906536/mb/pexels-nick-kwan-2745254-1_11zon-scaled.jpg.jpg',
        title: 'Leh to Hanle',
        desc: 'Travel from Leh to Hanle, a remote village in Ladakh know for Stargazing. Visit the Hanle Monastery and the Indian Astronomical Observatory.',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1715843073/mb/DP-2.jpg.jpg',
        title: 'Hanle to Umling La Pass and back to Hanle',
        desc: 'Drive to Umling La Pass, the world’s highest motorable road, and return to Hanle',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Hanle to Pangong',
        desc: 'Travel from Hanle to Pangong, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [PLACE_ID.LUKUNG, PLACE_ID.SPANGMIK],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Pangong to Nubra Valley via Shyok',
        desc: 'Drive to Nubra Valley. Visit Disket and Hunder villages, with optional double-hump camel rides',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail: MEDIA.LEH_GENERIC,
        title: 'Nubra Valley to Leh via Khardung La',
        desc: 'Drive back to Leh',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 5D/4N Leh to Nubra to Pangong to Hanle to Umling La Pass - Hanle to Hemis - Leh */
  {
    title:
      '5D/4N Leh to Nubra to Pangong to Hanle to Umling La Pass - Hanle to Hemis - Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 49474,
      [TAXI_TYPE.INNOVA]: 44751,
      [TAXI_TYPE.SCORPIO]: 42426,
      [TAXI_TYPE.EECO]: 38994,
      [TAXI_TYPE.TRAVELLER]: 55444,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714906536/mb/pexels-nick-kwan-2745254-1_11zon-scaled.jpg.jpg',
        title: 'Pangong to Hanle',
        desc: 'Travel from Pangong to Hanle, a remote village in Ladakh know for Stargazing. Visit the Hanle Monastery and the Indian Astronomical Observatory.',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1715843073/mb/DP-2.jpg.jpg',
        title: 'Hanle to Umling La Pass and back to Hanle',
        desc: 'Drive to Umling La Pass, the world’s highest motorable road, and return to Hanle',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },
      {
        thumbnail: MEDIA.RANCHO_SCHOOL,
        title: 'Hanle to Hemis, Thiksay, Shey, Rancho School to Leh',
        desc: 'Drive back to Leh from Hanle, stopping to explore the rich heritage at Hemis, Thiksey, Shey, and the famous Rancho School.',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 5D/4N Leh to Nubra to Turtuk - Thang to Pangong to Tso Moriri to Manali */
  {
    title:
      '5D/4N Leh to Nubra to Turtuk - Thang to Pangong to Tso Moriri to Manali Drop',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 44829 + 20986,
      [TAXI_TYPE.INNOVA]: 40754 + 19078,
      [TAXI_TYPE.SCORPIO]: 38716 + 18124,
      [TAXI_TYPE.EECO]: 34844 + 16434,
      [TAXI_TYPE.TRAVELLER]: 54294 + 25389,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714480487/mb/turtuk.jpeg.jpg',
        title: 'Day visit to Turtuk, Tyakshi, Thang and Zero Point',
        desc: `Visit Turtuk and Thang, including Tyakshi, India's last village. Learn about Turtuk's shift from Pakistan to India. You can stay overnight in Turtuk if you wish`,
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714740700/mb/licensed-image-%281%29.jpeg.jpg',
        title: 'Pangong to Tso Moriri',
        desc: 'Travel from Pangong to Tso Moriri, a high-altitude lake known for its serene beauty and birdlife. Spend the night at the lake.',
        descHtml: '',
        places: [PLACE_ID.TSO_MORIRI],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/v1720160218/mb/licensed-image-%288%29.jpeg.jpg',
        title: 'Tso Moriri to Manali Drop',
        desc: 'Manali drop via Tso Moriri, Tso Kar, Sarchu, Baralacha La, and Manali',
        descHtml: '',
        places: [PLACE_ID.MANALI],
      },
    ],
    activityId: 'au114hq',
  },


  /* 5D/4N Leh to Nubra to Turtuk - Thang to Pangong to Tso Moriri to Hemis - Leh */
  {
    title:
      '5D/4N Leh to Nubra to Turtuk - Thang to Pangong to Tso Moriri to Hemis - Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 46817,
      [TAXI_TYPE.INNOVA]: 42561,
      [TAXI_TYPE.SCORPIO]: 40432,
      [TAXI_TYPE.EECO]: 55779,
      [TAXI_TYPE.TRAVELLER]: 55490,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714480487/mb/turtuk.jpeg.jpg',
        title: 'Day visit to Turtuk, Tyakshi, Thang and Zero Point',
        desc: `Visit Turtuk and Thang, including Tyakshi, India's last village. Learn about Turtuk's shift from Pakistan to India. You can stay overnight in Turtuk if you wish`,
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714740700/mb/licensed-image-%281%29.jpeg.jpg',
        title: 'Pangong to Tso Moriri',
        desc: 'Travel from Pangong to Tso Moriri, a high-altitude lake known for its serene beauty and birdlife. Spend the night at the lake.',
        descHtml: '',
        places: [PLACE_ID.TSO_MORIRI],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/noz40nipxdtpgju2jus5.jpg',
        title: 'Tso Moriri to Hemis / Thiksay / Shey to Leh',
        desc: 'Journey back to Leh from Tso Moriri, stopping to explore the rich heritage at Hemis, Thiksey, Shey, and the famous Rancho School.',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 5D/4N Leh to Nubra to Turtuk - Thang to Pangong to aoriri - Mahay bridge to Leh */
  {
    title:
      '5D/4N Leh to Nubra to Turtuk - Thang to Pangong to Tso Moriri - Mahay bridge to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]:
        44829 +
        IN_ROUTE_THANG[TAXI_TYPE.CRYSTA] -
        PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]:
        40754 +
        IN_ROUTE_THANG[TAXI_TYPE.INNOVA] -
        PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]:
        40432 +
        IN_ROUTE_THANG[TAXI_TYPE.SCORPIO] -
        PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]:
        34844 +
        IN_ROUTE_THANG[TAXI_TYPE.EECO] -
        PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]:
        56110 +
        IN_ROUTE_THANG[TAXI_TYPE.TRAVELLER] -
        PRICE_DEDUCT_MAHAY_BRIDGE[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714480487/mb/turtuk.jpeg.jpg',
        title: 'Day visit to Turtuk, Tyakshi, Thang and Zero Point',
        desc: `Visit Turtuk and Thang, including Tyakshi, India's last village. Learn about Turtuk's shift from Pakistan to India. You can stay overnight in Turtuk if you wish`,
        descHtml: '',
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714740700/mb/licensed-image-%281%29.jpeg.jpg',
        title: 'Pangong to Tso Moriri',
        desc: 'Travel from Pangong to Tso Moriri, a high-altitude lake known for its serene beauty and birdlife. Spend the night at the lake.',
        descHtml: '',
        places: [PLACE_ID.TSO_MORIRI],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/noz40nipxdtpgju2jus5.jpg',
        title: 'Tso Moriri to Leh',
        desc: 'Journey back to Leh from Tso Moriri.',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 4D/3N Leh to Nubra to Turtuk - Thang - Pangong to Tso Moriri to Leh */
  {
    title: '4D/3N Leh to Nubra Turtuk - Thang to Pangong to Tso Moriri to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 45676,
      [TAXI_TYPE.INNOVA]: 41524,
      [TAXI_TYPE.SCORPIO]: 39447,
      [TAXI_TYPE.EECO]: 35502,
      [TAXI_TYPE.TRAVELLER]: 54294,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title:
          'Leh to Nubra Valley via Khardung La and day visit to Turtuk and Tyakshi',
        desc: "Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides. Visit Turtuk and Thang, including Tyakshi, India's last village.",
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714740700/mb/licensed-image-%281%29.jpeg.jpg',
        title: 'Pangong to Tso Moriri',
        desc: 'Travel from Pangong to Tso Moriri, a high-altitude lake known for its serene beauty and birdlife. Spend the night at the lake.',
        descHtml: '',
        places: [PLACE_ID.TSO_MORIRI],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/noz40nipxdtpgju2jus5.jpg',
        title: 'Tso Moriri to Leh',
        desc: 'Journey back to Leh from Tso Moriri.',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 5D/4N Leh to Nubra to Turtuk - Thang to Pangong to Tso Moriri to Leh */
  {
    title:
      '5D/4N Leh to Nubra to Turtuk - Thang to Pangong to Tso Moriri to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 45676,
      [TAXI_TYPE.INNOVA]: 41524,
      [TAXI_TYPE.SCORPIO]: 39447,
      [TAXI_TYPE.EECO]: 35502,
      [TAXI_TYPE.TRAVELLER]: 54294,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714480487/mb/turtuk.jpeg.jpg',
        title: 'Day visit to Turtuk, Tyakshi, Thang and Zero Point',
        desc: `Visit Turtuk and Thang, including Tyakshi, India's last village. Learn about Turtuk's shift from Pakistan to India. You can stay overnight in Turtuk if you wish`,
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714740700/mb/licensed-image-%281%29.jpeg.jpg',
        title: 'Pangong to Tso Moriri',
        desc: 'Travel from Pangong to Tso Moriri, a high-altitude lake known for its serene beauty and birdlife. Spend the night at the lake.',
        descHtml: '',
        places: [PLACE_ID.TSO_MORIRI],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/noz40nipxdtpgju2jus5.jpg',
        title: 'Tso Moriri to Leh',
        desc: 'Journey back to Leh from Tso Moriri.',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 5D/4N Leh to Nubra to Turtuk - Thang to Pangong to Hanle to Hemis - Leh */
  {
    title:
      '5D/4N Leh to Nubra to Turtuk - Thang to Pangong to Hanle to Hemis - Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 47286,
      [TAXI_TYPE.INNOVA]: 42623,
      [TAXI_TYPE.SCORPIO]: 40491,
      [TAXI_TYPE.EECO]: 36382,
      [TAXI_TYPE.TRAVELLER]: 58170,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714480487/mb/turtuk.jpeg.jpg',
        title: 'Day visit to Turtuk, Tyakshi, Thang and Zero Point',
        desc: `Visit Turtuk and Thang, including Tyakshi, India's last village. Learn about Turtuk's shift from Pakistan to India. You can stay overnight in Turtuk if you wish`,
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714906536/mb/pexels-nick-kwan-2745254-1_11zon-scaled.jpg.jpg',
        title: 'Pangong to Hanle',
        desc: 'Travel from Pangong to Hanle, a remote village in Ladakh know for Stargazing. Visit the Hanle Monastery and the Indian Astronomical Observatory.',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/noz40nipxdtpgju2jus5.jpg',
        title: 'Hanle to Hemis / Thiksay / Shey to Leh',
        desc: 'Journey back to Leh from Hanle, stopping to explore the rich heritage at Hemis, Thiksey, Shey, and the famous Rancho School.',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 5D/4N Leh to Nubra to Turtuk - Thang to Pangong to Hanle to Leh */
  {
    title: '5D/4N Leh to Nubra to Turtuk - Thang to Pangong to Hanle to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 46145,
      [TAXI_TYPE.INNOVA]: 41586,
      [TAXI_TYPE.SCORPIO]: 39506,
      [TAXI_TYPE.EECO]: 35502,
      [TAXI_TYPE.TRAVELLER]: 55888,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714480487/mb/turtuk.jpeg.jpg',
        title: 'Day visit to Turtuk, Tyakshi, Thang and Zero Point',
        desc: `Visit Turtuk and Thang, including Tyakshi, India's last village. Learn about Turtuk's shift from Pakistan to India. You can stay overnight in Turtuk if you wish`,
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714906536/mb/pexels-nick-kwan-2745254-1_11zon-scaled.jpg.jpg',
        title: 'Pangong to Hanle',
        desc: 'Travel from Pangong to Hanle, a remote village in Ladakh know for Stargazing. Visit the Hanle Monastery and the Indian Astronomical Observatory.',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/noz40nipxdtpgju2jus5.jpg',
        title: 'Hanle to Leh',
        desc: 'Journey back to Leh from Hanle.',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },


  /* 5D/4N Leh to Nubra to Turtuk - Thang to Pangong to Hanle - Umling La Pass to Leh */
  {
    title:
      '5D/4N Leh to Nubra to Turtuk - Thang to Pangong to Hanle - Umling La Pass to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 46145 + PRICE_UMLING_PASS[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 41586 + PRICE_UMLING_PASS[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.SCORPIO]: 39506 + PRICE_UMLING_PASS[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.EECO]: 35502 + PRICE_UMLING_PASS[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.TRAVELLER]: 55888 + PRICE_UMLING_PASS[TAXI_TYPE.CRYSTA],
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714480487/mb/turtuk.jpeg.jpg',
        title: 'Day visit to Turtuk, Tyakshi, Thang and Zero Point',
        desc: `Visit Turtuk and Thang, including Tyakshi, India's last village. Learn about Turtuk's shift from Pakistan to India. You can stay overnight in Turtuk if you wish`,
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714906536/mb/pexels-nick-kwan-2745254-1_11zon-scaled.jpg.jpg',
        title: 'Pangong to Hanle',
        desc: 'Travel from Pangong to Hanle, a remote village in Ladakh know for Stargazing. Visit the Hanle Monastery and the Indian Astronomical Observatory.',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1715843073/mb/DP-2.jpg.jpg',
        title: 'Hanle to Umling La Pass to Leh',
        desc: 'Drive to Umling La Pass, the world’s highest motorable road, and return to Leh',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 5D/4N Leh to Nubra to Pangong to Hanle to Tso Moriri to Leh */
  {
    title: '5D/4N Leh to Nubra to Pangong to Hanle to Tso Moriri to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 44204,
      [TAXI_TYPE.INNOVA]: 40186,
      [TAXI_TYPE.SCORPIO]: 38176,
      [TAXI_TYPE.EECO]: 34355,
      [TAXI_TYPE.TRAVELLER]: 51415,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714906536/mb/pexels-nick-kwan-2745254-1_11zon-scaled.jpg.jpg',
        title: 'Pangong to Hanle',
        desc: 'Travel from Pangong to Hanle, a remote village in Ladakh know for Stargazing. Visit the Hanle Monastery and the Indian Astronomical Observatory.',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714740700/mb/licensed-image-%281%29.jpeg.jpg',
        title: 'Hanle to Tso Moriri',
        desc: 'Travel from Hanle to Tso Moriri, a high-altitude lake known for its serene beauty and birdlife. Spend the night at the lake.',
        descHtml: '',
        places: [PLACE_ID.TSO_MORIRI],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/noz40nipxdtpgju2jus5.jpg',
        title: 'Tso Moriri to Leh',
        desc: 'Journey back to Leh from Tso Moriri.',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 5D/4N Leh to Nubra to Pangong to Umling Pass - Hanle to Tso Moriri to Leh' */
  {
    title:
      '5D/4N Leh to Nubra to Pangong to Umling Pass - Hanle to Tso Moriri to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 44204 + PRICE_UMLING_PASS[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 40186 + PRICE_UMLING_PASS[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]: 38176 + PRICE_UMLING_PASS[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]: 34355 + PRICE_UMLING_PASS[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]: 51415 + PRICE_UMLING_PASS[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714906536/mb/pexels-nick-kwan-2745254-1_11zon-scaled.jpg.jpg',
        title: 'Pangong to Umling Pass to Hanle',
        desc: `Travel from Pangong to Umling Pass, the world's highest motorable road. Then, travel to Hanle, a remote village in Ladakh know for Stargazing. Visit the Hanle Monastery and the Indian Astronomical Observatory.`,
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714740700/mb/licensed-image-%281%29.jpeg.jpg',
        title: 'Hanle to Tso Moriri',
        desc: 'Travel from Hanle to Tso Moriri, a high-altitude lake known for its serene beauty and birdlife. Spend the night at the lake.',
        descHtml: '',
        places: [PLACE_ID.TSO_MORIRI],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/noz40nipxdtpgju2jus5.jpg',
        title: 'Tso Moriri to Leh',
        desc: 'Journey back to Leh from Tso Moriri.',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 5D/4N Leh to Turtuk - Nubra to Pangong to Hanle to Umling Pass - Tso Moriri to Leh */
  {
    title:
      '5D/4N Leh to Turtuk - Nubra to Pangong to Hanle to Umling Pass - Tso Moriri to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]:
        44829 +
        IN_ROUTE_THANG[TAXI_TYPE.CRYSTA] +
        IN_ROUTE_HANLE[TAXI_TYPE.CRYSTA] +
        PRICE_UMLING_PASS[TAXI_TYPE.CRYSTA] +
        IN_ROUTE_HEMIS[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]:
        40754 +
        IN_ROUTE_THANG[TAXI_TYPE.INNOVA] +
        IN_ROUTE_HANLE[TAXI_TYPE.INNOVA] +
        PRICE_UMLING_PASS[TAXI_TYPE.INNOVA] +
        IN_ROUTE_HEMIS[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]:
        38716 +
        IN_ROUTE_THANG[TAXI_TYPE.SCORPIO] +
        IN_ROUTE_HANLE[TAXI_TYPE.SCORPIO] +
        PRICE_UMLING_PASS[TAXI_TYPE.SCORPIO] +
        IN_ROUTE_HEMIS[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]:
        34844 +
        IN_ROUTE_THANG[TAXI_TYPE.EECO] +
        IN_ROUTE_HANLE[TAXI_TYPE.EECO] +
        PRICE_UMLING_PASS[TAXI_TYPE.EECO] +
        IN_ROUTE_HEMIS[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]:
        56110 +
        IN_ROUTE_HANLE[TAXI_TYPE.TRAVELLER] +
        PRICE_UMLING_PASS[TAXI_TYPE.TRAVELLER] +
        IN_ROUTE_HEMIS[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Turtuk, Thang to Nubra Valley via Khardung La',
        desc: 'Drive to Turutuk via the high Khardung La Pass. Visit Tyakshi, Thang and Zeropoint. Return to Hunder village, visit Sand Dunes with optional double-hump camel rides. ',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Visit Diskit Monastery in the morning. Travel to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714906536/mb/pexels-nick-kwan-2745254-1_11zon-scaled.jpg.jpg',
        title: 'Pangong to Hanle',
        desc: 'Travel from Pangong to Hanle, a remote village in Ladakh know for Stargazing. Visit the Hanle Monastery and the Indian Astronomical Observatory.',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714740700/mb/licensed-image-%281%29.jpeg.jpg',
        title: 'Hanle to Umling Pass to Tso Moriri',
        desc: "Travel from Hanle to Umling Pass, the world's highest motorable road. Travel to Tso Moriri, a high-altitude lake known for its serene beauty and birdlife. Spend the night at the lake.",
        descHtml: '',
        places: [PLACE_ID.TSO_MORIRI],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/noz40nipxdtpgju2jus5.jpg',
        title: 'Tso Moriri to Leh. En route visit Hemis, Thiksey, Shey',
        desc: 'Journey back to Leh from Tso Moriri. En route visit Hemis, Thiksey, Shey, and the famous Rancho School.',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 6D/5N Leh to Nubra to Turtuk - Thang to Pangong to Tso Moriri to Jispa to Manali */
  {
    title:
      '6D/5N Leh to Nubra to Turtuk - Thang to Pangong to Tso Moriri to Jispa to Manali Drop',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 44829 + 20986,
      [TAXI_TYPE.INNOVA]: 40754 + 19078,
      [TAXI_TYPE.SCORPIO]: 38716 + 18124,
      [TAXI_TYPE.EECO]: 34844 + 16434,
      [TAXI_TYPE.TRAVELLER]: 54294 + 25389,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714480487/mb/turtuk.jpeg.jpg',
        title: 'Day visit to Turtuk, Tyakshi, Thang and Zero Point',
        desc: `Visit Turtuk and Thang, including Tyakshi, India's last village. Learn about Turtuk's shift from Pakistan to India. You can stay overnight in Turtuk if you wish`,
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714740700/mb/licensed-image-%281%29.jpeg.jpg',
        title: 'Pangong to Tso Moriri',
        desc: 'Travel from Pangong to Tso Moriri, a high-altitude lake known for its serene beauty and birdlife. Spend the night at the lake.',
        descHtml: '',
        places: [PLACE_ID.TSO_MORIRI],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/v1721053329/mb/licensed-image-%288%29.jpeg.jpg',
        title: 'Tso Moriri to Jispa',
        desc: 'Tso Moriri to Jispa',
        descHtml: '',
        places: [PLACE_ID.JISPA],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/v1720160218/mb/licensed-image-%288%29.jpeg.jpg',
        title: 'Jispa to Manali Drop',
        desc: 'Jispa to Manali drop',
        descHtml: '',
        places: [PLACE_ID.MANALI],
      },
    ],
    activityId: 'au114hq',
  },

  /* 6D/5N Leh to Nubra to Turtuk to Pangong to Hanle to Umling Pass - Tso Moriri to Leh */
  {
    title:
      '6D/5N Leh to Nubra to Turtuk to Pangong to Hanle to Umling Pass - Tso Moriri to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]:
        44829 +
        IN_ROUTE_THANG[TAXI_TYPE.CRYSTA] +
        IN_ROUTE_HANLE[TAXI_TYPE.CRYSTA] +
        PRICE_UMLING_PASS[TAXI_TYPE.CRYSTA] +
        IN_ROUTE_HEMIS[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]:
        40754 +
        IN_ROUTE_THANG[TAXI_TYPE.INNOVA] +
        IN_ROUTE_HANLE[TAXI_TYPE.INNOVA] +
        PRICE_UMLING_PASS[TAXI_TYPE.INNOVA] +
        IN_ROUTE_HEMIS[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]:
        38716 +
        IN_ROUTE_THANG[TAXI_TYPE.SCORPIO] +
        IN_ROUTE_HANLE[TAXI_TYPE.SCORPIO] +
        PRICE_UMLING_PASS[TAXI_TYPE.SCORPIO] +
        IN_ROUTE_HEMIS[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]:
        34844 +
        IN_ROUTE_THANG[TAXI_TYPE.EECO] +
        IN_ROUTE_HANLE[TAXI_TYPE.EECO] +
        PRICE_UMLING_PASS[TAXI_TYPE.EECO] +
        IN_ROUTE_HEMIS[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]:
        56110 +
        IN_ROUTE_HANLE[TAXI_TYPE.TRAVELLER] +
        PRICE_UMLING_PASS[TAXI_TYPE.TRAVELLER] +
        IN_ROUTE_HEMIS[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides',
        descHtml: '',
        places: [PLACE_ID.TURTUK, PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714480487/mb/turtuk.jpeg.jpg',
        title: 'Leh to Turtuk, Thang, and Zero Point',
        desc: "Drive to Turutuk. Visit Tyakshi, Thang and Zeropoint. Learn about Turtuk's shift from Pakistan to India. You can stay overnight in Turtuk if you wish",
        descHtml: '',
        places: [PLACE_ID.TURTUK, PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Visit Diskit Monastery in the morning. Travel to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714906536/mb/pexels-nick-kwan-2745254-1_11zon-scaled.jpg.jpg',
        title: 'Pangong to Hanle',
        desc: 'Travel from Pangong to Hanle, a remote village in Ladakh know for Stargazing. Visit the Hanle Monastery and the Indian Astronomical Observatory.',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714740700/mb/licensed-image-%281%29.jpeg.jpg',
        title: 'Hanle to Umling Pass to Tso Moriri',
        desc: "Travel from Hanle to Umling Pass, the world's highest motorable road. Travel to Tso Moriri, a high-altitude lake known for its serene beauty and birdlife. Spend the night at the lake.",
        descHtml: '',
        places: [PLACE_ID.TSO_MORIRI],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/noz40nipxdtpgju2jus5.jpg',
        title: 'Tso Moriri to Leh. En route visit Hemis, Thiksey, Shey',
        desc: 'Journey back to Leh from Tso Moriri. En route visit Hemis, Thiksey, Shey, and the famous Rancho School.',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 6D/5N Leh to Nubra to Turtuk - Thang to Pangong to Hanle to Tso Moriri to Leh */
  {
    title:
      '6D/5N Leh to Nubra to Turtuk - Thang to Pangong to Hanle to Tso Moriri to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 50334,
      [TAXI_TYPE.INNOVA]: 45759,
      [TAXI_TYPE.SCORPIO]: 43470,
      [TAXI_TYPE.EECO]: 39149,
      [TAXI_TYPE.TRAVELLER]: 59520,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides',
        descHtml: '',
        places: [PLACE_ID.TURTUK, PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714480487/mb/turtuk.jpeg.jpg',
        title: 'Day visit to Turtuk, Tyakshi, Thang and Zero Point',
        desc: `Visit Turtuk and Thang, including Tyakshi, India's last village. Learn about Turtuk's shift from Pakistan to India. You can stay overnight in Turtuk if you wish`,
        descHtml: '',
        places: [PLACE_ID.TURTUK, PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714906536/mb/pexels-nick-kwan-2745254-1_11zon-scaled.jpg.jpg',
        title: 'Pangong to Hanle',
        desc: 'Travel from Pangong to Hanle, a remote village in Ladakh know for Stargazing. Visit the Hanle Monastery and the Indian Astronomical Observatory.',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714740700/mb/licensed-image-%281%29.jpeg.jpg',
        title: 'Hanle to Tso Moriri',
        desc: 'Travel from Hanle to Tso Moriri, a high-altitude lake known for its serene beauty and birdlife. Spend the night at the lake.',
        descHtml: '',
        places: [PLACE_ID.TSO_MORIRI],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/noz40nipxdtpgju2jus5.jpg',
        title: 'Tso Moriri to Leh',
        desc: 'Journey back to Leh from Tso Moriri.',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 6D/5N Leh to Nubra to Turtuk - Thang to Pangong to Hanle to Tso Moriri to Hemis - Leh */
  {
    title:
      '6D/5N Leh to Nubra to Turtuk - Thang to Pangong to Hanle to Tso Moriri to Hemis - Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 50334 + IN_ROUTE_HEMIS[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 45759 + IN_ROUTE_HEMIS[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]: 43470 + IN_ROUTE_HEMIS[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]: 39149 + IN_ROUTE_HEMIS[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]: 59520 + IN_ROUTE_HEMIS[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714480487/mb/turtuk.jpeg.jpg',
        title: 'Day visit to Turtuk, Tyakshi, Thang and Zero Point',
        desc: `Visit Turtuk and Thang, including Tyakshi, India's last village. Learn about Turtuk's shift from Pakistan to India. You can stay overnight in Turtuk if you wish`,
        descHtml: '',
        places: [PLACE_ID.TURTUK, PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714906536/mb/pexels-nick-kwan-2745254-1_11zon-scaled.jpg.jpg',
        title: 'Pangong to Hanle',
        desc: 'Travel from Pangong to Hanle, a remote village in Ladakh know for Stargazing. Visit the Hanle Monastery and the Indian Astronomical Observatory.',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714740700/mb/licensed-image-%281%29.jpeg.jpg',
        title: 'Hanle to Tso Moriri',
        desc: 'Travel from Hanle to Tso Moriri, a high-altitude lake known for its serene beauty and birdlife. Spend the night at the lake.',
        descHtml: '',
        places: [PLACE_ID.TSO_MORIRI],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/noz40nipxdtpgju2jus5.jpg',
        title: 'Tso Moriri to Hemis, Thikshey, Shey, and Rancho School to Leh',
        desc: 'Journey back to Leh from Tso Moriri. En route visit Hemis, Thiksey, Shey, and the famous Rancho School.',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },


  /* 6D/5N Leh to Nubra - Turtuk - Thang to Base Camp - Pangong to Hanle t0 Tso Moriri to Leh */
  {
    title:
      '6D/5N Leh to Nubra - Turtuk - Thang to Base Camp - Pangong to Hanle t0 Tso Moriri to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]:
        52525 +
        IN_ROUTE_HEMIS[TAXI_TYPE.CRYSTA] +
        IN_ROUTE_HANLE[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]:
        47750 +
        IN_ROUTE_HEMIS[TAXI_TYPE.INNOVA] +
        IN_ROUTE_HANLE[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]:
        45362 +
        IN_ROUTE_HEMIS[TAXI_TYPE.SCORPIO] +
        IN_ROUTE_HANLE[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]:
        43094 + IN_ROUTE_HEMIS[TAXI_TYPE.EECO] + IN_ROUTE_HANLE[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]:
        65503 +
        IN_ROUTE_HEMIS[TAXI_TYPE.TRAVELLER] +
        IN_ROUTE_HANLE[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La Pass and visit TurTuk',
        desc: "Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides. Visit Turtuk and Tyakshi, India's last village. Learn about Turtuk's shift from Pakistan to India.",
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER, PLACE_ID.TURTUK],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/v1717915367/mb/stlqyjxtybqegx2389ckmzrv9yl4_download-%286%29.avif.jpg',
        title: 'Day visit to Panamic & Sumur ',
        desc: `Visit Sumur village, Panamic hot water spring and Yarab Tso lake.`,
        descHtml: '',
        places: [PLACE_ID.SUMUR, PLACE_ID.PANAMIK],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714906536/mb/pexels-nick-kwan-2745254-1_11zon-scaled.jpg.jpg',
        title: 'Pangong to Hanle',
        desc: 'Travel from Pangong to Hanle, a remote village in Ladakh know for Stargazing. Visit the Hanle Monastery and the Indian Astronomical Observatory.',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714740700/mb/licensed-image-%281%29.jpeg.jpg',
        title: 'Hanle to Tso Moriri',
        desc: 'Travel from Hanle to Tso Moriri, a high-altitude lake known for its serene beauty and birdlife. Spend the night at the lake.',
        descHtml: '',
        places: [PLACE_ID.TSO_MORIRI],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/noz40nipxdtpgju2jus5.jpg',
        title:
          'Tso Moriri to Leh via Puga Valley. In route visit Hemis Monastery, Shey Palace, Thiksey Monastery, and 3 Idiots School',
        desc: 'Journey back to Leh from Tso Moriri.In route visit Hemis Monastery, Shey Palace, Thiksey Monastery, and 3 Idiots School',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 6D/5N Leh to Turtuk - Thang to Nubra Valley to Base Camp - Pangong to Tso Moriri to Leh */
  {
    title:
      '6D/5N Leh to Turtuk - Thang to Nubra Valley to Base Camp - Pangong to Tso Moriri to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 56696 + IN_ROUTE_THANG[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 51541 + IN_ROUTE_THANG[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]: 48964 + IN_ROUTE_THANG[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]: 46516 + IN_ROUTE_THANG[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]: 65503 + PRICE_FREE_DAY[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        thumbnail: MEDIA.TURTUK,
        title: 'Leh to Turtuk via Khardung La Pass',
        desc: "Drive to Turtuk via the high Khardung La Pass. Visit Turtuk and Tyakshi, India's last village. Learn about Turtuk's shift from Pakistan to India.",
        descHtml: '',
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Turtuk to Diskit / Hunder',
        desc: 'Visit Disket and Hunder villages, with optional double-hump camel rides.',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/v1717915367/mb/stlqyjxtybqegx2389ckmzrv9yl4_download-%286%29.avif.jpg',
        title: 'Day visit to Panamic & Sumur ',
        desc: `Visit Sumur village, Panamic hot water spring and Yarab Tso lake.`,
        descHtml: '',
        places: [PLACE_ID.SUMUR, PLACE_ID.PANAMIK],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714740700/mb/licensed-image-%281%29.jpeg.jpg',
        title: 'Pangong to Tso Moriri',
        desc: 'Travel from Pangong to Tso Moriri, a high-altitude lake known for its serene beauty and birdlife. Spend the night at the lake.',
        descHtml: '',
        places: [PLACE_ID.TSO_MORIRI],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/noz40nipxdtpgju2jus5.jpg',
        title: 'Tso Moriri to Leh via Puga Valley',
        desc: 'Journey back to Leh from Tso Moriri',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 6D/5N Leh to Nubra to Turtuk - Thang - Nubra to Base Camp - Nubra to Pangong to Hanle to Leh */
  {
    title:
      '6D/5N Leh to Nubra to Turtuk - Thang - Nubra to Base Camp - Nubra to Pangong to Hanle to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]:
        56696 +
        IN_ROUTE_THANG[TAXI_TYPE.CRYSTA] +
        IN_ROUTE_HANLE[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]:
        51541 +
        IN_ROUTE_THANG[TAXI_TYPE.INNOVA] +
        IN_ROUTE_HANLE[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]:
        48964 +
        IN_ROUTE_THANG[TAXI_TYPE.SCORPIO] +
        IN_ROUTE_HANLE[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]:
        46516 + IN_ROUTE_HEMIS[TAXI_TYPE.EECO] + IN_ROUTE_HANLE[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]:
        65503 +
        IN_ROUTE_THANG[TAXI_TYPE.TRAVELLER] +
        PRICE_FREE_DAY[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La Pass',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides.',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail: MEDIA.TURTUK,
        title: 'Visit TurTuk, Thang, and Zero Point',
        desc: "Visit Turtuk and Tyakshi, India's last village. Learn about Turtuk's shift from Pakistan to India. Night stay in Turtuk or travel back to Diskit / Hunder.",
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail: MEDIA.SIACHEN_BASE_CAMP,
        title: 'Day visit to Siachen Base Camp, Panamik Hot Springs & Sumur',
        desc: `Visit Siachen Base Camp, Panamic hot water spring, Sumur village, and Yarab Tso lake. Stay in Sumur or Panamik.`,
        descHtml: '',
        places: [PLACE_ID.SUMUR, PLACE_ID.PANAMIK],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714906536/mb/pexels-nick-kwan-2745254-1_11zon-scaled.jpg.jpg',
        title: 'Pangong to Hanle',
        desc: 'Travel from Pangong to Hanle, a remote village in Ladakh know for Stargazing. Visit the Hanle Monastery and the Indian Astronomical Observatory.',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/noz40nipxdtpgju2jus5.jpg',
        title: 'Hanle to Leh',
        desc: 'Journey back to Leh from Hanle',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 7D/6N Leh to Nubra to Turtuk - Thang to Base Camp - Pangong to Hanle t0 Tso Moriri to Leh */
  {
    title:
      '7D/6N Leh to Nubra to Turtuk - Thang to Base Camp - Pangong to Hanle t0 Tso Moriri to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 61354,
      [TAXI_TYPE.INNOVA]: 55776,
      [TAXI_TYPE.SCORPIO]: 52987,
      [TAXI_TYPE.EECO]: 50163,
      [TAXI_TYPE.TRAVELLER]: 75199,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714480487/mb/turtuk.jpeg.jpg',
        title: 'Day visit to Turtuk, Tyakshi, Thang and Zero Point',
        desc: `Visit Turtuk and Thang, including Tyakshi, India's last village. Learn about Turtuk's shift from Pakistan to India. You can stay overnight in Turtuk if you wish`,
        descHtml: '',
        places: [PLACE_ID.TURTUK, PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail: MEDIA.SIACHEN_BASE_CAMP,
        title: 'Day visit to Siachen Base Camp ',
        desc: `Visit Siachen Base Camp, the world's highest battlefield, and witness the life of the Indian Army.`,
        descHtml: '',
        places: [PLACE_ID.SUMUR, PLACE_ID.PANAMIK],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714906536/mb/pexels-nick-kwan-2745254-1_11zon-scaled.jpg.jpg',
        title: 'Pangong to Hanle',
        desc: 'Travel from Pangong to Hanle, a remote village in Ladakh know for Stargazing. Visit the Hanle Monastery and the Indian Astronomical Observatory.',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714740700/mb/licensed-image-%281%29.jpeg.jpg',
        title: 'Hanle to Tso Moriri',
        desc: 'Travel from Hanle to Tso Moriri, a high-altitude lake known for its serene beauty and birdlife. Spend the night at the lake.',
        descHtml: '',
        places: [PLACE_ID.TSO_MORIRI],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/noz40nipxdtpgju2jus5.jpg',
        title: 'Tso Moriri to Leh',
        desc: 'Journey back to Leh from Tso Moriri.',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 7D/6N Leh to Nubra to Turtuk - Thang to Pangong to Hanle to Tso Moriri to Jispa to Manali */
  {
    title:
      '6D/5N Leh to Nubra to Turtuk - Thang to Pangong to Hanle to Tso Moriri to Jispa to Manali Drop',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 44829 + 20986 + IN_ROUTE_HANLE[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 40754 + 19078 + IN_ROUTE_HANLE[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]: 38716 + 18124 + IN_ROUTE_HANLE[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]: 34844 + 16434 + IN_ROUTE_HANLE[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]:
        54294 + 25389 + IN_ROUTE_HANLE[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714480487/mb/turtuk.jpeg.jpg',
        title: 'Day visit to Turtuk, Tyakshi, Thang and Zero Point',
        desc: `Visit Turtuk and Thang, including Tyakshi, India's last village. Learn about Turtuk's shift from Pakistan to India. You can stay overnight in Turtuk if you wish`,
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714906536/mb/pexels-nick-kwan-2745254-1_11zon-scaled.jpg.jpg',
        title: 'Pangong to Hanle',
        desc: 'Travel from Pangong to Hanle, a remote village in Ladakh know for Stargazing. Visit the Hanle Monastery and the Indian Astronomical Observatory.',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714740700/mb/licensed-image-%281%29.jpeg.jpg',
        title: 'Hanle to Tso Moriri',
        desc: 'Travel from Pangong to Tso Moriri, a high-altitude lake known for its serene beauty and birdlife. Spend the night at the lake.',
        descHtml: '',
        places: [PLACE_ID.TSO_MORIRI],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/v1721053329/mb/licensed-image-%288%29.jpeg.jpg',
        title: 'Tso Moriri to Jispa',
        desc: 'Tso Moriri to Jispa',
        descHtml: '',
        places: [PLACE_ID.JISPA],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/v1720160218/mb/licensed-image-%288%29.jpeg.jpg',
        title: 'Jispa to Manali Drop',
        desc: 'Jispa to Manali drop',
        descHtml: '',
        places: [PLACE_ID.MANALI],
      },
    ],
    activityId: 'au114hq',
  },

  /* 7D/6N Leh to Nubra to Turtuk to Pangong to Hanle to Umling Pass - Hanle to Tso Moriri to Leh */
  {
    title:
      '7D/6N Leh to Nubra to Turtuk to Pangong to Hanle to Umling Pass - Hanle to Tso Moriri to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]:
        44829 +
        IN_ROUTE_THANG[TAXI_TYPE.CRYSTA] +
        IN_ROUTE_HANLE[TAXI_TYPE.CRYSTA] +
        PRICE_UMLING_PASS[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]:
        40754 +
        IN_ROUTE_THANG[TAXI_TYPE.INNOVA] +
        IN_ROUTE_HANLE[TAXI_TYPE.INNOVA] +
        PRICE_UMLING_PASS[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]:
        38716 +
        IN_ROUTE_THANG[TAXI_TYPE.SCORPIO] +
        IN_ROUTE_HANLE[TAXI_TYPE.SCORPIO] +
        PRICE_UMLING_PASS[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]:
        34844 +
        IN_ROUTE_THANG[TAXI_TYPE.EECO] +
        IN_ROUTE_HANLE[TAXI_TYPE.EECO] +
        PRICE_UMLING_PASS[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]:
        56110 +
        IN_ROUTE_HANLE[TAXI_TYPE.TRAVELLER] +
        PRICE_UMLING_PASS[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides',
        descHtml: '',
        places: [PLACE_ID.TURTUK, PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714480487/mb/turtuk.jpeg.jpg',
        title: 'Leh to Turtuk, Thang, and Zero Point',
        desc: "Drive to Turutuk. Visit Tyakshi, Thang and Zeropoint. Learn about Turtuk's shift from Pakistan to India. You can stay overnight in Turtuk if you wish",
        descHtml: '',
        places: [PLACE_ID.TURTUK, PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Visit Diskit Monastery in the morning. Travel to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714906536/mb/pexels-nick-kwan-2745254-1_11zon-scaled.jpg.jpg',
        title: 'Pangong to Hanle',
        desc: 'Travel from Pangong to Hanle, a remote village in Ladakh know for Stargazing. Visit the Hanle Monastery and the Indian Astronomical Observatory.',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },

      {
        thumbnail: MEDIA.UMLING_PASS,
        title: 'Hanle to Umling Pass to Hanle',
        desc: "Travel from Hanle to Umling Pass, the world's highest motorable road.",
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714740700/mb/licensed-image-%281%29.jpeg.jpg',
        title: 'Hanle to Tso Moriri',
        desc: 'Travel from Hanle to Tso Moriri, a high-altitude lake known for its serene beauty and birdlife. Spend the night at the lake.',
        descHtml: '',
        places: [PLACE_ID.TSO_MORIRI],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/noz40nipxdtpgju2jus5.jpg',
        title: 'Tso Moriri to Leh. En route visit Hemis, Thiksey, Shey',
        desc: 'Journey back to Leh from Tso Moriri. En route visit Hemis, Thiksey, Shey, and the famous Rancho School.',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 7D/6N Leh to Nubra to Turtuk - Thang to Base Camp - Pangong to Hanle to Umling Pass - Tso Moriri to Leh */
  {
    title:
      '7D/6N Leh to Nubra to Turtuk - Thang to Base Camp - Pangong to Hanle to Umling Pass - Tso Moriri to Leh',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 68902,
      [TAXI_TYPE.INNOVA]: 62569,
      [TAXI_TYPE.SCORPIO]: 59440,
      [TAXI_TYPE.EECO]: 56293,
      [TAXI_TYPE.TRAVELLER]: 78429,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/cpyawvwc0njuigtnbrpl.jpg',
        title: 'Leh to Nubra Valley via Khardung La',
        desc: 'Drive to Nubra Valley via the high Khardung La Pass. Visit Disket and Hunder villages, with optional double-hump camel rides',
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714480487/mb/turtuk.jpeg.jpg',
        title: 'Day visit to Turtuk, Tyakshi, Thang and Zero Point',
        desc: `Visit Turtuk and Thang, including Tyakshi, India's last village. Learn about Turtuk's shift from Pakistan to India. You can stay overnight in Turtuk if you wish`,
        descHtml: '',
        places: [PLACE_ID.DISKIT, PLACE_ID.HUNDER],
      },
      {
        thumbnail: MEDIA.SIACHEN_BASE_CAMP,
        title: 'Day visit to Siachen Base Camp ',
        desc: `Visit Siachen Base Camp, the world's highest battlefield, and witness the life of the Indian Army.`,
        descHtml: '',
        places: [PLACE_ID.SUMUR, PLACE_ID.PANAMIK],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1689486889/activities/leh/pangong_lake_zacl7j.jpg',
        title: 'Nubra Valley to Pangong',
        desc: 'Travel from Nubra to Pangong via Shyok River, witnessing stunning landscapes. End your day by the captivating Pangong Lake, known for its color-changing waters.',
        descHtml: '',
        places: [
          PLACE_ID.LUKUNG,
          PLACE_ID.SPANGMIK,
          PLACE_ID.MAN,
          PLACE_ID.MEREK,
        ],
      },

      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714906536/mb/pexels-nick-kwan-2745254-1_11zon-scaled.jpg.jpg',
        title: 'Pangong to Hanle',
        desc: 'Travel from Pangong to Hanle, a remote village in Ladakh know for Stargazing. Visit the Hanle Monastery and the Indian Astronomical Observatory.',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },

      {
        thumbnail: MEDIA.UMLING_PASS,
        title: 'Hanle to Umling La Pass Tso Moriri',
        desc: 'Travel from Hanle to Tso Moriri, a high-altitude lake known for its serene beauty and birdlife. Spend the night at the lake.',
        descHtml: '',
        places: [PLACE_ID.TSO_MORIRI],
      },
      {
        thumbnail: MEDIA.TSO_MORIRI,
        title: 'Tso Moriri to Leh',
        desc: 'Journey back to Leh from Tso Moriri.',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
    activityId: 'au114hq',
  },

  /* 2D/1N Leh to Srinagar Drop. In route Sham Valley Points  */
  {
    title: '7D/6N Leh Hanle',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 20675 + 5 * PRICE_FREE_DAY[TAXI_TYPE.CRYSTA],
      [TAXI_TYPE.INNOVA]: 18796 + 5 * PRICE_FREE_DAY[TAXI_TYPE.INNOVA],
      [TAXI_TYPE.SCORPIO]: 17856 + 5 * PRICE_FREE_DAY[TAXI_TYPE.SCORPIO],
      [TAXI_TYPE.EECO]: 16192 + 5 * PRICE_FREE_DAY[TAXI_TYPE.EECO],
      [TAXI_TYPE.TRAVELLER]: 25630 + 5 * PRICE_FREE_DAY[TAXI_TYPE.TRAVELLER],
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1714906536/mb/pexels-nick-kwan-2745254-1_11zon-scaled.jpg.jpg',
        title: 'Leh to Hanle',
        desc: 'Travel to Hanle, a remote village in Ladakh know for Stargazing. Visit the Hanle Monastery and the Indian Astronomical Observatory.',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1715595805/mb/photo-1558187424-f786111643b0.avif.jpg',
        title: 'Free day',
        desc: 'Spend the time exploring on your own. If you wish to use taxi, first 8 KMs are included in the package and beyond that, you will be charged extra at rate of ₹345 per 8 KM',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1715595805/mb/photo-1558187424-f786111643b0.avif.jpg',
        title: 'Free day',
        desc: 'Spend the time exploring on your own. If you wish to use taxi, first 8 KMs are included in the package and beyond that, you will be charged extra at rate of ₹345 per 8 KM',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1715595805/mb/photo-1558187424-f786111643b0.avif.jpg',
        title: 'Free day',
        desc: 'Spend the time exploring on your own. If you wish to use taxi, first 8 KMs are included in the package and beyond that, you will be charged extra at rate of ₹345 per 8 KM',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1715595805/mb/photo-1558187424-f786111643b0.avif.jpg',
        title: 'Free day',
        desc: 'Spend the time exploring on your own. If you wish to use taxi, first 8 KMs are included in the package and beyond that, you will be charged extra at rate of ₹345 per 8 KM',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/v1715595805/mb/photo-1558187424-f786111643b0.avif.jpg',
        title: 'Free day',
        desc: 'Spend the time exploring on your own. If you wish to use taxi, first 8 KMs are included in the package and beyond that, you will be charged extra at rate of ₹345 per 8 KM',
        descHtml: '',
        places: [PLACE_ID.HANLE],
      },
      {
        thumbnail: MEDIA.LEH_GENERIC,
        title: 'Hanle to Leh',
        desc: 'Journey back to Leh.',
        descHtml: '',
        places: [PLACE_ID.LEH],
      },
    ],
  },
  /* Leh to Airport Drop */

  {
    title: 'Leh to Airport Drop',
    pricingOptions: {
      [TAXI_TYPE.CRYSTA]: 667,
      [TAXI_TYPE.INNOVA]: 606,
      [TAXI_TYPE.SCORPIO]: 606,
      [TAXI_TYPE.EECO]: 578,
      [TAXI_TYPE.TRAVELLER]: 1132,
    },
    itinerary: [
      {
        thumbnail:
          'https://res.cloudinary.com/passprt-media/image/upload/c_fill,dpr_1.0,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_720/activities/a1om4in/y5v8ocmm3iypl7evatyr.jpg',
        title: 'Leh Hotel to Airport Drop',
        desc: 'Embark on the return journey with a memory-filled heart',
        descHtml: '',
      },
    ],
  },
];

export { LADAKH_PRESET, TAXI_TYPE };
