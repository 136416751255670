/* eslint-disable */

import { TextField, Card, CardHeader, CardContent } from '@mui/material';
import { useState, useEffect } from 'react';
import { UploadButton } from '../buttons/upload';

import PropTypes from 'prop-types';

export const ActivityBanners = ({
  handleActivityBannerChange,
  initialUrls = null,
}) => {
  const [urls, setUrls] = useState('');
  const [banners, setBanners] = useState([]);
  const [sections, setSections] = useState({});

  const onBannerChanged = (event) => {
    const _urls = event.target.value;
    setUrls(_urls);
    const _banners = _urls.split('\n');
    const sections = _urls
      .split('####')
      .filter((section) => section.length > 0);
    const finalSections = [];
    sections.forEach((section) => {
      const key = section.split('\n')[0];
      const value = section
        .split('\n')
        .slice(1)
        .filter((url) => url.length > 0);
      finalSections.push({
        id: key.split('$$')[0],
        title: key.split('$$')[1],
        items: value,
      });
    });
    setSections(finalSections);
    setBanners(_banners);
  };

  useEffect(() => {
    if (initialUrls) {
      setUrls(initialUrls);
    }
  }, [initialUrls]);

  // Gallery Rendering
  const _renderGalleryList = () =>
    urls &&
    urls.split('\n').map((value, index) => (
      <Card key={index}>
        <CardHeader className="header" />
        <CardContent className="cardContent-padding">
          <img src={value} />
        </CardContent>
      </Card>
    ));

  const onBannerChangeUrl = (urls) => {
    setBanners(urls);
    setUrls(urls.join('\n'));
  };

  useEffect(() => {
    handleActivityBannerChange(banners, sections);
  }, [banners]);

  return (
    <div style={{ paddingTop: 32 }}>
      <label>
        <h3>Banners</h3>
      </label>
      <TextField
        name="apis"
        multiline
        maxRows={10}
        fullWidth
        onChange={onBannerChanged}
        value={urls}
        defaultValue="Enter Banner Links"
      />
      <div className="galleyWrapper">{_renderGalleryList()}</div>
      <UploadButton
        title="Upload Banners"
        uploadUrls={urls.split('\n')}
        onUploadUrlChange={onBannerChangeUrl}
      />
    </div>
  );
};

ActivityBanners.propTypes = {
  handleActivityBannerChange: PropTypes.func.isRequired,
};
