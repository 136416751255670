import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Modal,
} from '@mui/material';
import { API_URL } from 'src/utils/constants';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import axiosMB from 'src/utils/axios/interceptors';
import { Icon } from '@iconify/react';

export const AssignEmployee = (props) => {
    const { isOpen, onClose, booking } = props;
    const [employeeDetails, setEmployeeDetails] = useState([]);
    const [employeeId, setEmployeeId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [newEmployee, setNewEmployee] = useState('');
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const { pathname } = useLocation();
    const partnerId = pathname.split("/")[2];

    useEffect(() => {
        const fetchPartnerUsers = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${API_URL}/partner/users?partnerId=${partnerId}`);
                const data = await response.json();
                setEmployeeDetails(data);
                setLoading(false);
            } catch (error) {

                console.error(error);
                console.error('Failed to fetch employeeDetails:', error);
            }
        };

        fetchPartnerUsers();
    }, []);

    const handleOnConfirmClose = () => {
        setIsConfirmOpen(false);
        setEmployeeId(null);
    };

    const handleAssignEmployee = async () => {
        try {
            const response = await axiosMB.patch(
                `${API_URL}/admin/mb/bookings/${booking.id}`,
                {
                    updateFields: {
                        partnerAssignedTo: employeeId,
                        status: 'confirmed',
                        internalStatus: 'partner-sorted'
                    }
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (response.status === 200) {
                location.reload();
            } else {
                console.error('Failed to submit booking:', response.statusText);
            }
        } catch (error) {
            console.error('Error submitting booking:', error);
        }
    };

    // Fetch the employees 
    return (<Modal open={isOpen} onClose={() => { onClose() }}        >
        <Box
            className='absolute flex flex-col pb-6 px-8 bg-white rounded-lg sm:top-1/2 left-1/2 bottom-0 -translate-x-1/2 sm:-translate-y-1/2 w-full max-w-[800px] h-full max-h-[90vh] overflow-y-scroll'
        >
            <div className='grid grid-cols-1 gap-4 bg-white'>
                <div className='sticky top-0 z-20 pt-6 bg-white min-w-full'>
                    <button onClick={() => { onClose() }} className='border rounded-xl p-3 px-4 bg-slate-100/40 mb-2'>
                        <Icon icon="ep:arrow-left" width="1em" height="1em" />
                    </button>
                    <div>
                        <label className='font-bold mb-4 pb-2'>Search Employees</label>
                        <input
                            placeholder="Employees"
                            onChange={(e) => {
                                const value = e.target.value;
                                setNewEmployee(value)
                                const regex = new RegExp(value.replace(/\s/g, ''), 'i');
                                const filtered = employeeDetails.filter(emp => regex.test(emp.name.replace(/\s/g, '')));

                                setFilteredEmployees(filtered)
                                if (filtered.length === 0 && newEmployee.length === 0) {
                                    setFilteredEmployees(employeeDetails)
                                }
                            }}
                            className='min-w-full mb-4 p-2 py-3'
                            value={newEmployee}
                        />
                    </div>
                </div>
                {filteredEmployees.length > 0 || newEmployee.length === 0 && <div className='flex items-center gap-2 col-span-full'>
                    <span className="h-[2px] w-full bg-gray-200 z-0"></span>
                    <p className="z-10 px-2 whitespace-nowrap">Employees</p>
                    <span className="h-[2px] w-full bg-gray-200 z-0"></span>
                </div>}

                {!loading && filteredEmployees.length > 0 ? filteredEmployees.map((employee) => (
                    <div key={employee.name} className='grid grid-cols-2 gap-4'>
                        <Divider className='col-span-full' orientation="horizontal" flexItem />
                        <div className='col-span-2'>
                            <div>Name:<span className='font-bold ml-2 capitalize'> {employee.name}</span></div>
                            <div>Phone:<span className='font-bold ml-2 capitalize'> {employee.phone}</span></div>
                        </div>
                        <Button
                            className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[150px] font-extralight p-1.5 rounded-lg whitespace-nowrap'
                            onClick={() => {
                                setEmployeeId(employee.id);
                                setIsConfirmOpen(true);
                            }}>
                            Assign
                        </Button>
                    </div>
                )) : !loading && newEmployee.length === 0 && employeeDetails.length > 0 && employeeDetails.map((employee) => (
                    <div key={employee.name} className='grid grid-cols-2 gap-4'>
                        <div className='col-span-2'>
                            <div>Name:<span className='font-bold ml-2 capitalize'> {employee.name}</span></div>
                            <div>Phone:<span className='font-bold ml-2 capitalize'> {employee.phone}</span></div>
                        </div>
                        <Button
                            className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[150px] font-extralight p-1.5 rounded-lg whitespace-nowrap'
                            onClick={() => {
                                setEmployeeId(employee.id);
                                setIsConfirmOpen(true);
                            }}>
                            Assign
                        </Button>
                        <Divider className='col-span-full' orientation="horizontal" flexItem />
                    </div>
                ))}
            </div>
            <Dialog open={isConfirmOpen} onClose={handleOnConfirmClose}>
                <DialogTitle>Confirm Assignment</DialogTitle>
                <DialogContent className="min-w-full overflow-visible ">
                    <div className='grid grid-cols-1 gap-2'>
                        <h1 className='font-bold text-xl mt-2'>Are you sure?</h1>
                        <div className='col-span-2'>
                            <div>Name:<span className='font-bold ml-2 capitalize'> {employeeDetails.find(employee => employee.id === employeeId)?.name}</span></div>
                            <div>Phone:<span className='font-bold ml-2 capitalize'> {employeeDetails.find(employee => employee.id === employeeId)?.phone}</span></div>
                        </div>
                        <Button
                            onClick={handleAssignEmployee}
                            className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[150px] font-extralight p-1.5 rounded-lg whitespace-nowrap'>
                            Confirm
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </Box>
    </Modal>
    );
};

AssignEmployee.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    booking: PropTypes.object.isRequired,
};

