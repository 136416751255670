import axios from 'axios';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { API_URL, CLOUDINARY_API_KEY } from '../../utils/constants';

export const UploadButton = ({ title, uploadUrls, onUploadUrlChange }) => {
  const [bannerFiles, setBannerFiles] = useState({});

  const handleBannersUpload = async (e) => {
    e.preventDefault();
    if (e.target.files) {
      const body = {
        folder: 'mb',
        public_ids: Object.values(e.target.files).map((file) =>
          file.name.replaceAll(' ', '-')
        ),
        timestamp: Math.floor(Date.now() / 1000),
        type: 'cloudinary',
      };

      axios
        .post(`${API_URL}/util/signed-url`, body)
        .then((response) => {
          const urls = response.data?.data?.urls;
          if (urls?.length) {
            let bFiles = {};
            urls.forEach((url, index) => {
              bFiles[url] = e.target.files[index];
            });
            setBannerFiles(bFiles);
          }
        })
        .catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    if (Object.keys(bannerFiles).length) {
      const body = new FormData();
      for (const bannerUrl of Object.keys(bannerFiles)) {
        const file = bannerFiles[bannerUrl];
        if (
          uploadUrls.find(
            (url) => url.search(file.name.replaceAll(' ', '-')) >= 0
          )
        )
          continue;

        const url = new URL(bannerUrl);
        const api = url.href.split('?')[0];
        const folder = url.searchParams.get('folder');
        const timestamp = url.searchParams.get('timestamp');
        const public_id = url.searchParams.get('public_id');
        const signature = url.searchParams.get('signature');
        body.append('file', file);
        body.append('api_key', CLOUDINARY_API_KEY);
        body.append('timestamp', timestamp);
        body.append('public_id', public_id);
        body.append('signature', signature);
        body.append('folder', folder);
        axios
          .post(api, body)
          .then((response) => {
            const secure_url = response.data?.secure_url;
            console.log('Upload: secure_url', secure_url);
            const newUrls = [...uploadUrls, secure_url];
            onUploadUrlChange(newUrls);
          })
          .catch((error) => console.log('Upload: ', error));
      }
      setBannerFiles({});
    }
  }, [bannerFiles]);

  return (
    <Button variant="contained" component="label">
      {title}
      <input
        hidden
        accept="image/*"
        multiple
        type="file"
        onChange={handleBannersUpload}
      />
    </Button>
  );
};

UploadButton.propTypes = {
  title: PropTypes.string.isRequired,
  uploadUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
  onUploadUrlChange: PropTypes.func.isRequired,
};
