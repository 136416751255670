import { Typography } from "@mui/material";

export default function PageHeading({ heading }) {
    return (
        <Typography
            variant="h4"
            sx={{
                mb: 5
            }}
        >
            {heading}
        </Typography>
    )
}
PageHeading.defaultProps = {
    heading: '',
};
PageHeading.propTypes = {
    heading: String,
};