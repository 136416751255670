import React, { useState, useEffect } from 'react';
import Page from '../../components/Page';
import {
    Container, Table, TableBody, TableCell, TableHead, TableRow, Checkbox, FormGroup, FormControlLabel, Typography,
    Button,
    TextField,
    // IconButton,
} from '@mui/material';
import { API_URL } from 'src/utils/constants';
import { useLocation } from 'react-router-dom';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { UploadEmployeeDocuments } from './UploadEmployeeDocuments';




const PartnerEmployeeDetails = () => {
    const { pathname } = useLocation();
    const partnerId = pathname.split("/")[2];
    const [userDetails, setUserDetails] = useState([]);
    const [visibleColumns, setVisibleColumns] = useState(['name', 'phone']);
    const [partner, setPartner] = useState({ name: '', phone: '' });
    const [error, setError] = useState({ type: '', msg: '' });
    const [open, setOpen] = useState(false);
    const [uploadedDocumentsOpen, setUploadedDocumentsOpen] = useState(null);
    const fields = [
        { id: 'name', label: 'Employees' },
        { id: 'phone', label: 'Phone number' },
    ];

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false)
        setPartner({ name: '', phone: '' })
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'phone') {
            if (value.length <= 10) {
                setPartner((prev) => ({ ...prev, [name]: value.replace(/[^0-9]/g, '') }));
            }
        } else {
            setPartner((prev) => ({ ...prev, [name]: value.replace(/[^a-zA-Z ]/g, '') }));
        }
    };

    const createPartnerUser = async () => {
        if (partner.name.length < 1) {
            setError({ type: "name", msg: "Please enter a name" })
            return
        }
        if (partner.phone.length < 10 || partner.phone.length > 10) {
            setError({ type: "phone", msg: "Phone number must be 10 digits" })
            return
        }
        try {
            const response = await fetch(
                `${API_URL}/partner/users?partnerId=${partnerId}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ name: partner.name, phone: partner.phone }),
                }
            );
            if (response.ok) {
                location.reload()
            }
        } catch (error) {
            console.error('Failed to update user:', error);
        }
    };

    useEffect(() => {
        const fetchPartnerUsers = async () => {
            try {

                const response = await fetch(`${API_URL}/partner/users?partnerId=${partnerId}`);
                const data = await response.json();
                setUserDetails(data);
            } catch (error) {

                console.log(error);
                console.error('Failed to fetch userDetails:', error);
            }
        };

        fetchPartnerUsers();
    }, []);

    // Function to toggle visibility of columns
    const handleColumnToggle = (columnId) => {
        setVisibleColumns((prev) =>
            prev.includes(columnId)
                ? prev.filter((c) => c !== columnId)
                : [...prev, columnId]
        );
    };


    useEffect(() => {
        if (error.type.length > 0) {
            setTimeout(() => {
                setError({ type: '', msg: '' });
            }, 5000);
        }
    }, [error])


    return (
        <>
            <Modal
                open={open}
                onClose={() => { handleClose() }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='absolute flex flex-col py-6 p-4 bg-white rounded-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[500px] min-h-[300px]'>
                    <h1 className='font-bold text-xl mt-2'>Add Employee</h1>
                    <label className="text-gray-700 font-semibold whitespace-nowrap">
                        Name
                    </label>
                    <TextField
                        margin="dense"
                        name="name"
                        placeholder="Employee Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={partner.name}
                        onChange={handleInputChange}
                    />
                    {error.type === 'name' && <p className='text-red-500 text-xs'>{error.msg}</p>}
                    <label className="text-gray-700 font-semibold whitespace-nowrap">
                        Phone
                    </label>
                    <TextField
                        margin="dense"
                        name="phone"
                        placeholder="Employee Phone"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={partner.phone}
                        onChange={handleInputChange}
                    />
                    {error.type === 'phone' && <p className='text-red-500 text-xs'>{error.msg}</p>}
                    <Button
                        onClick={createPartnerUser}
                        className='mt-4' variant="contained" >
                        Add Employee
                    </Button>

                </Box>
            </Modal>
            <UploadEmployeeDocuments
                isOpen={uploadedDocumentsOpen !== null}
                onClose={() => setUploadedDocumentsOpen(null)}
                employee={uploadedDocumentsOpen} />
            <Page>
                <div className='flex'></div>
                <Container>
                    <Typography variant="h4" sx={{ mb: 5 }}>
                        Manage Employees
                    </Typography>

                    <div className='flex items-center justify-between'>
                        <FormGroup row>
                            {fields.map((field) => (
                                <FormControlLabel
                                    key={field.id}
                                    control={
                                        <Checkbox
                                            checked={visibleColumns.includes(field.id)}
                                            onChange={() => handleColumnToggle(field.id)}
                                        />
                                    }
                                    label={field.label}
                                />
                            ))}
                        </FormGroup>
                        <Button
                            onClick={handleOpen}
                            className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[150px] font-extralight p-1.5 rounded-lg whitespace-nowrap'>
                            Add Employee
                        </Button>
                    </div>
                    {userDetails.length ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {fields
                                        .filter((field) => visibleColumns.includes(field.id))
                                        .map((field) => (
                                            <TableCell key={field.id}>{field.label}</TableCell>
                                        ))}
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userDetails.map((user) => (
                                    <TableRow key={user.id}>
                                        {fields
                                            .filter((field) => visibleColumns.includes(field.id))
                                            .map((field) => (
                                                <TableCell key={field.id}>{user[field.id]}</TableCell>
                                            ))}
                                        <TableCell key={'action'}>
                                            <Button
                                                onClick={() => { setUploadedDocumentsOpen(user) }}
                                                className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[150px] font-extralight p-1.5 rounded-lg whitespace-nowrap'>
                                                Upload Documents
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>) : (<div className='grid grid-cols-1 gap-4 my-5 font-bold text-xl'>No Employees</div>)}
                </Container>
            </Page>
        </>
    );
};

export default PartnerEmployeeDetails;
