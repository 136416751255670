import React, {
  useState, useEffect,
  useMemo,
} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  IconButton,
  TextField,
  Menu,
  MenuItem,
  FormControlLabel,
  Switch,
  TablePagination,
  Select,
  InputLabel,
  FormControl,
  OutlinedInput,
  // Button,
  Checkbox,
  Box,
  Container,
} from '@mui/material';
import Page from '../../components/Page';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  // Autocomplete,
  // Button,
  // MenuItem,
  // Select,
  // TextField,
} from '@mui/material';
import { API_URL } from 'src/utils/constants';
// import { formatCurrency } from 'src/utils/formatCurrency';
import { Loading } from 'src/components/Loading';
// import { Link } from "react-router-dom"
// import PropTypes from 'prop-types'
// import { formatCurrency } from 'src/utils/formatCurrency';
// import { listBookingStatusColors, listBookingStatusLabels } from 'src/utils/statuses/bookings';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { formatCurrency, formatLedgerCurrency } from 'src/utils/formatCurrency';

const columns = [
  { id: 'name', label: 'Name', sortable: true, searchable: true },
  { id: 'phone', label: 'Phone', sortable: true, searchable: true },
  { id: 'partner', label: 'Partner', sortable: true, searchable: true },
  { id: 'summary', label: 'Summary', sortable: false, searchable: true },
  { id: 'total', label: 'Total', sortable: true, searchable: true },
  { id: 'profit', label: 'Profit', sortable: true, searchable: true },
];

const createData = (id, name, phone, partner, summary, total, profit, bookingData) => {
  return { id, name, phone, partner, summary, total, profit, bookingData };
};

const AdminFinancesList = () => {
  // const defalutFilter = localStorage.getItem("actionFIlter") ? localStorage.getItem("actionFIlter") : "#ffffff";
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [startDate, setStartDate] = useState(moment().add(-1, 'month'));
  const [endDate, setEndDate] = useState(moment());
  const [bookingDetails, setBookingDetails] = useState({
    totalBookings: 0,
    totalAmount: 0,
    totalProfit: 0,
    grossMargin: 0,
  });
  const [pendingSettlementBookings, setPendingSettlementBookings] = useState([]);
  useEffect(() => {
    const fetchBookings = async () => {
      try {
        setIsLoading(true)
        const response = await fetch(`${API_URL}/admin/mb/bookingFinances`);
        const data = await response.json();
        const filteredBookings = data.map((booking) => createData(
          booking.id,
          booking.guestName,
          booking.guestPhone,
          booking.partner.title,
          booking.bookingsSummary,
          booking.amountTotal,
          Number(booking.amountTotal - booking.amountPartnerSettlementActual),
          booking,
        ));
        let amountLeft = 0

        const settlementPendingBookings = data.filter((booking) => booking.status == "partner-settlement-pending").map((book) => {
          amountLeft += book.amountPartnerSettlementLeft
          console.log(book.partner.title)
          return book
        })
        console.log(settlementPendingBookings)
        setPendingSettlementBookings({
          bookings: settlementPendingBookings.length,
          amount: amountLeft
        })
        setRows(filteredBookings);
        setIsLoading(false)
      } catch (error) {
        console.error('Failed to fetch bookings:', error);
      }
    };
    fetchBookings();
  }, []);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('product');
  const [visibleColumnIds, setVisibleColumnIds] = useState(columns.map((col) => col.id));
  const [columnOrder, setColumnOrder] = useState(columns.map((col) => col.id));
  const [searchTerm, setSearchTerm] = useState('');
  const [dense, setDense] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [categoryFilter, setCategoryFilter] = useState('All');
  const [secondCategoryFilter, setSecondCCategoryFilter] = useState('partner-settlement-pending'); // All
  const [anchorEl, setAnchorEl] = useState(null);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleColumnVisibility = (id) => {
    setVisibleColumnIds((prev) => {
      const newColumnIds = prev.includes(id)
        ? prev.filter((colId) => colId !== id)
        : [...prev, id].sort((a, b) => columns.findIndex(col => col.id === a) - columns.findIndex(col => col.id === b));
      setColumnOrder(newColumnIds);
      return newColumnIds;
    });
  };


  const handleDenseChange = (event) => {
    setDense(event.target.checked);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCategoryChange = (event) => {
    setCategoryFilter(event.target.value);
  };
  const handleSecondCategoryChange = (event) => {
    setSecondCCategoryFilter(event.target.value);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const filteredRows = useMemo(() => {
    return rows.filter((row) => {
      const matchesSearch = Object.keys(row).some(
        (key) => row[key]?.toString().toLowerCase().includes(searchTerm)
      );
      const matchesCategory = categoryFilter === 'All' || row.partner === categoryFilter;
      const matchesSecondCategory = secondCategoryFilter === 'All' || row.bookingData.status === secondCategoryFilter;
      const matchesPeriod = moment(row.bookingData.updatedAt).isBetween(startDate, endDate, 'day', '[]');
      return matchesSearch && matchesPeriod && matchesCategory && matchesSecondCategory;
    });
  }, [searchTerm, categoryFilter, secondCategoryFilter, rows, startDate, endDate]);

  useEffect(() => {
    const filteredRows = rows.filter((row) => {
      const date = new Date(row.date);
      return date >= startDate && date <= endDate;
    });
    setRows(filteredRows);
  }, []);

  const sortedRows = useMemo(() => {
    return filteredRows.sort((a, b) => {
      let aValue = a[orderBy];
      let bValue = b[orderBy];

      // Handle different data types for proper sorting
      if (orderBy === 'date') {
        aValue = new Date(aValue);
        bValue = new Date(bValue);
        // } else if (orderBy === 'stock') {
        //     aValue = parseInt(aValue.replace(/\D/g, ''), 10);
        //     bValue = parseInt(bValue.replace(/\D/g, ''), 10);
      } else {
        aValue = aValue?.toString().toLowerCase();
        bValue = bValue?.toString().toLowerCase();
      }

      if (aValue < bValue) return order === 'asc' ? -1 : 1;
      if (aValue > bValue) return order === 'asc' ? 1 : -1;
      return 0;
    });
  }, [filteredRows, orderBy, order, rows]);

  const paginatedRows = useMemo(() => {
    return sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [sortedRows, page, rowsPerPage, filteredRows, orderBy, order, rows]);

  useEffect(() => {
    const totalData = {
      totalAmount: 0,
      totalProfit: 0,
    }
    sortedRows.forEach((row) => {
      totalData.totalAmount += Number(row.total);
      totalData.totalProfit += row.profit;
    });
    totalData.totalBookings = sortedRows.length;
    totalData.grossMargin = (totalData.totalProfit / totalData.totalAmount * 100).toFixed(2);
    setBookingDetails(totalData);
  }, [sortedRows])

  if (isLoading) {
    return <Loading />;
  }
  return (
    <Page title="Finances">
      <Container className='flex flex-col gap-4'>
        <div className='flex flex-col gap-5 bg-white rounded-2xl p-5 mb-5'>
          <p className='text-xl font-semibold'>Pending Settlement</p>
          <span className='w-full bg-gray-500 h-px'></span>
          <div className='flex items-start justify-start gap-10'>
            <div className='text-center'>
              <p>Bookings</p>
              <p>{pendingSettlementBookings.bookings}</p>
            </div>
            <div className='text-center'>
              <p>Amount</p>
              <p>{formatLedgerCurrency(pendingSettlementBookings.amount)}</p>
            </div>
          </div>
        </div>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 4, gap: 2 }}>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              onChange={handleSearch}
            />
            <FormControl variant="outlined" size="small" sx={{ width: '240px' }}>
              <InputLabel>Partner</InputLabel>
              <Select
                value={categoryFilter}
                onChange={handleCategoryChange}
                input={<OutlinedInput label="Category" />}
              >
                <MenuItem value="All">All</MenuItem>
                {Array.from(new Set(rows.map((row) => row.partner))).map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" size="small" sx={{ width: '240px' }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={secondCategoryFilter}
                onChange={handleSecondCategoryChange}
                input={<OutlinedInput label="Category" />}
              >
                <MenuItem value="All">All</MenuItem>
                {Array.from(new Set(rows.map((row) => row.bookingData.status))).map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className='grid grid-cols-2 gap-4'>
              <DatePicker
                disableFuture
                label="Start Date"
                openTo="day"
                views={['year', 'month', 'day']}
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
              <DatePicker
                disableFuture
                label="End Date"
                openTo="day"
                views={['year', 'month', 'day']}
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
            <FormControlLabel
              sx={{ m: 0 }}
              control={<Switch checked={dense} onChange={handleDenseChange} />}
              label="Dense padding"
            />
            <IconButton
              aria-label="settings"
              onClick={handleMenuClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {columns.map((column) => (
                <MenuItem key={column.id} onClick={() => handleColumnVisibility(column.id)}>
                  <Checkbox
                    checked={visibleColumnIds.includes(column.id)}
                  />
                  {column.label}
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <TableContainer>
            <Table size={dense ? 'small' : 'medium'}>
              <TableHead>
                <TableRow>
                  {columnOrder.map((colId) => {
                    const column = columns.find((col) => col.id === colId);
                    if (!column || !visibleColumnIds.includes(column.id)) return null;
                    return (
                      <TableCell key={column.id}>
                        {column.sortable ? (
                          <TableSortLabel
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : 'asc'}
                            onClick={(event) => handleRequestSort(event, column.id)}
                          >
                            {column.label}
                          </TableSortLabel>
                        ) : (
                          column.label
                        )}
                      </TableCell>
                    );
                  })}
                  {/* <TableCell>Actions</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedRows.map((row, index) => (
                  <TableRow key={index} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                    {columnOrder.map((colId) => {
                      const column = columns.find((col) => col.id === colId);
                      if (!column || !visibleColumnIds.includes(column.id)) return null;
                      return (
                        <TableCell key={column.id}>{column.id === 'profit' ? (row[column.id]).toFixed(2) : row[column.id]}</TableCell>
                      );
                    })}
                    {/* <TableCell>
                    <Button
                      variant='contained' color="error">
                      Edit
                    </Button>
                  </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={sortedRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </LocalizationProvider >
        <div className='fixed bottom-2 p-5 bg-white shadow-sm w-[800px] border rounded-2xl'>
          <div className='grid grid-cols-4 gap-5 place-items-center'>
            <p className='space-y-2'>Total Bookings:<p /> {bookingDetails.totalBookings}</p>
            <p className='space-y-2'>Total Amount:<p /> {formatCurrency(bookingDetails.totalAmount)}</p>
            <p className='space-y-2'>Total Profit:<p /> {formatCurrency(bookingDetails.totalProfit)}</p>
            <p className='space-y-2'>Gross Margin:<p /> {bookingDetails.grossMargin}%</p>
          </div>
        </div>
      </Container>
    </Page>
  );
};

export default AdminFinancesList;