/* eslint-disable */
import { useEffect, useState } from 'react';
import { Divider, Modal, TextField } from '@mui/material';
import { fDateTimeSuffix } from 'src/utils/formatTime';
import ReactMarkdown from 'react-markdown';
import MDEditor from '@uiw/react-md-editor';
import Box from '@mui/material/Box';

import PropTypes from 'prop-types'

export const AddNote = ({ bookingId, noteType, allNotes, isOpen, onAddNote, onClose }) => {
    const [newNote, setNewNote] = useState({
        title: "",
        content: "",
    });
    const [value, setValue] = useState(``);

    useEffect(() => {
        setNewNote(prev => ({ ...prev, content: `${value}` }))
    }, [value]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setNewNote((prev) => ({ ...prev, [name]: value }));
    };

    const handleClose = () => {
        onClose();
        setNewNote({});
    };
    const { notesInternal, notesPartner, notesGuest } = allNotes;

    // const { id: bookingId, changedData: updateFields } = changes
    const handleAddNote = async () => {
        const userId = '63ad61587981b1447e23b167'
        const createdAt = new Date().toISOString()
        let note = { userId, createdAt, ...newNote }
        let changedData = {}
        switch (noteType) {
            case 'customer': {
                changedData = { id: bookingId, changedData: { notesGuest: [...notesGuest, note] } }
                break;
            }
            case 'internal': {
                changedData = { id: bookingId, changedData: { notesInternal: [...notesInternal, note] } }
                break;
            }
            case 'partner': {
                changedData = { id: bookingId, changedData: { notesPartner: [...notesPartner, note] } }
                break;
            }
            default:
                break;
        }
        await onAddNote(changedData)
    };
    return (
        <>
            <Modal
                open={isOpen} onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    className='absolute w-full max-w-[min(800px,90vw)] flex flex-col py-6 p-4 bg-white rounded-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                    <div>

                        <p className='text-xl font-bold mb-4'>Add <span className='capitalize'>{noteType}</span> note</p>
                        <label className="text-gray-700 font-semibold whitespace-nowrap">
                            Title
                        </label>
                        <TextField
                            margin="dense"
                            name="title"
                            placeholder="Title"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={newNote.title}
                            onChange={handleInputChange}
                        />
                        <label className="text-gray-700 font-semibold whitespace-nowrap">
                            Content
                        </label>
                        <MDEditor
                            value={value}
                            onChange={setValue}
                        />
                    </div>
                    <button
                        className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[100px] font-extralight p-1.5 rounded-lg whitespace-nowrap'
                        onClick={handleAddNote}
                    >
                        Save
                    </button>
                </Box>
            </Modal >
        </>
    );
}
AddNote.propTypes = {
    bookingId: PropTypes.string.isRequired,
    noteType: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    allNotes: PropTypes.object.isRequired,
    onAddNote: PropTypes.func.isRequired,
};

export const Notes = ({ note }) => {
    const { title, content } = note;
    return (
        <div className='flex flex-col gap-2'>
            <Divider flexItem className='col-span-full w-full' orientation="horizontal" />
            <div className='flex items-center justify-between'>
                <h2 className='text-lg font-bold'>{title}</h2>
                <h1 className='text-xs text-gray-600'>{fDateTimeSuffix(note.createdAt)}</h1>
            </div>
            <h1>
                <ReactMarkdown
                    children={content?.toString()}
                    components={{
                        a: LinkRenderer
                    }}
                />
            </h1>
        </div>
    );
}

Notes.propTypes = {
    note: PropTypes.object.isRequired,
}


const LinkRenderer = ({ href, children }) => (
    <a className='text-sky-500 hover:underline' href={href} target="_blank" rel="noopener noreferrer">
        {children}
    </a>
);