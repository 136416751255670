// @ts-check

import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

/**
 * @typedef {import('../../types/Pricing.type').Pricing} Pricing
 */

/**
 * @param {object} props
 * @param {Pricing[]} props.pricing
 * @param {function} props.setSelectedPricing
 */
const ActivityPricingSelector = ({ pricing, setSelectedPricing }) => {
    const [selectedPrices, setSelectedPrices] = useState(() => pricing.map((p) => ({
        pricingId: p.id,
        quantity: p.minQuantity,
        title: p.title,
    })));

    useEffect(() => {
        setSelectedPrices(pricing.map((p) => ({
            pricingId: p.id,
            quantity: p.minQuantity,
            title: p.title,
        })));
    }, [pricing])

    const [totalPrice, setTotalPrice] = useState(0);

    useEffect(() => {
        const total = selectedPrices.reduce((acc, cur) => {
            const { price } = pricing.find((p) => p.id === cur.pricingId);
            return acc + price * cur.quantity;
        }, 0);
        setTotalPrice(total);
        setSelectedPricing({
            pricing: selectedPrices.filter((sp) => sp.quantity > 0),
            totalPrice: total,
        });
    }, [selectedPrices]);

    const adjustQuantity = (id, adjustment) => {
        setSelectedPrices((prevPrices) => prevPrices.map((p) => {
            if (p.pricingId !== id) return p;
            const priceInfo = pricing.find((price) => price.id === id);
            let newQuantity = p.quantity + adjustment;
            if (newQuantity > priceInfo.maxQuantity) newQuantity = priceInfo.maxQuantity;
            if (newQuantity < priceInfo.minQuantity) newQuantity = priceInfo.minQuantity;
            return { ...p, quantity: newQuantity };
        }));
    };

    return (
        <div className='space-y-2'>
            {pricing.map((p) => {
                const currentQuantity = selectedPrices.find(
                    (sp) => sp.pricingId === p.id,
                ).quantity;
                return (
                    <div key={p.id} className="flex items-center justify-between rounded-xl bg-gray-200/50 p-4">
                        <div className="price-info">
                            <h2>{p.title}</h2>
                            <h3>{p.desc}</h3>
                        </div>
                        <div className="flex items-center gap-6">
                            <button
                                onClick={() => adjustQuantity(p.id, -1)}
                                disabled={currentQuantity <= p.minQuantity}
                                className="bg-red-100/50 w-10 aspect-square rounded-full flex items-center justify-center"
                            >
                                <span className='text-black text-2xl'>-</span>
                            </button>
                            <span className="current-quantity">{currentQuantity}</span>
                            <button
                                onClick={() => adjustQuantity(p.id, 1)}
                                disabled={currentQuantity >= p.maxQuantity}
                                className="bg-red-100/50 w-10 aspect-square rounded-full flex items-center justify-center"
                            >
                                <span className='text-black text-2xl'>+</span>
                            </button>
                        </div>
                        <div className="price-element">
                            <h4>
                                ₹
                                {p.price}
                            </h4>
                        </div>
                    </div>
                );
            })}
            <p className="ml-2">
                Total ₹ {totalPrice}
            </p>
        </div >
    );
};

ActivityPricingSelector.propTypes = {
    pricing: PropTypes.array.isRequired,
    setSelectedPricing: PropTypes.func.isRequired,
};

export default ActivityPricingSelector;
