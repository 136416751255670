import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import {
    Autocomplete,
    Button,
    TextField,
} from '@mui/material';
import axios from "axios";
import { API_URL } from "src/utils/constants";
const ACTIVITY_LOCATION_TYPE = [
    'starting-location',
    'drop-location',
    'related-location',
    'nearby-popular-location',
    'primary-location',
]

export default function AddActivityLocation({ location, updateLocations }) {
    const [locationType, setLocationType] = useState(location?.type || ACTIVITY_LOCATION_TYPE[0]);
    const [placeId, setPlaceId] = useState({ label: location?.placeId });
    const [locationDetails, setLocationDetails] = useState({
        shortAddress: location?.shortAddress || '',
        address: location?.address || '',
        lat: location?.lat || 0,
        long: location?.long || 0,
        googlePlaceId: location?.googlePlaceId || '',
    });

    const [places, setPlaces] = useState([]);

    const fetchPlaces = async (query) => {
        try {
            // setIsLoading(true);
            const response = await axios.get(`${API_URL}/place/mb/search?query=${query}`);
            setPlaces(response.data.map(item => ({
                id: item.id,
                label: item.label
            })));
            // setIsLoading(false);
        } catch (error) {
            // setIsLoading(false);
            console.error('Failed to fetch data:', error);
        }
    };

    useEffect(() => {
        updateLocations({
            index: location.index,
            type: locationType,
            location: {
                locIndex: { coordinates: [parseFloat(locationDetails.long), parseFloat(locationDetails.lat)] },
            },
            shortAddress: locationDetails.shortAddress,
            address: locationDetails.address,
            lat: parseFloat(locationDetails.lat),
            long: parseFloat(locationDetails.long),
            placeId: placeId,
            googlePlaceId: locationDetails?.googlePlaceId,
        })
    }, [locationDetails, locationType])
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'lat' || name === 'long') {
            const numValue = value.replace(/[^0-9.]/g, '');
            setLocationDetails({ ...locationDetails, [name]: numValue });
        } else {
            setLocationDetails({ ...locationDetails, [e.target.name]: e.target.value });
        }
    };
    return (
        <div className="grid grid-cols-2 gap-x-5">
            <div className="col-span-full grid grid-cols-2 gap-3">
                <label className='font-bold m-0'>Type</label>
                <label className='font-bold m-0'>Place</label>
                <Autocomplete
                    id="combo-box-demo"
                    options={ACTIVITY_LOCATION_TYPE}
                    sx={{ width: 340 }}
                    renderInput={(params) => <TextField {...params} label="Location Type" />}
                    onChange={(_, newValue) => {
                        setLocationType(newValue);
                    }}
                    value={locationType}
                />
                <Autocomplete
                    id="combo-box-demo"
                    options={places}
                    sx={{ width: 340 }}
                    renderInput={(params) => <TextField {...params} label="Place" />}
                    onInputChange={(_, newInputValue) => {
                        if (newInputValue.length > 0) {
                            fetchPlaces(newInputValue)
                        }
                    }}
                    onChange={(_, newValue) => {
                        console.log(newValue)
                        setPlaceId(newValue);
                    }}
                    value={placeId?.label || null}
                />
            </div>
            <div className="grid grid-cols-1 gap-1">
                <label className='font-bold m-0'>Address</label>
                <input type="text" name="address" value={locationDetails.address}
                    onChange={handleChange}
                />
            </div>
            <div className="grid grid-cols-1 gap-1">
                <label className='font-bold m-0'>Short Address</label>
                <input type="text" name="shortAddress" value={locationDetails.shortAddress}
                    onChange={handleChange}
                />
            </div>
            <div className="grid grid-cols-1 gap-1">
                <label className='font-bold m-0'>Latitude</label>
                <input type="text" name="lat" value={locationDetails.lat}
                    onChange={handleChange}
                />
            </div>
            <div className="grid grid-cols-1 gap-1">
                <label className='font-bold m-0'>Longitude</label>
                <input type="text" name="long" value={locationDetails.long}
                    onChange={handleChange}
                />
            </div>
            {locationDetails.googlePlaceId &&
                <a
                    className="m-1 cursor-pointer"
                    href={generateGoogleMapsUrl(locationDetails.googlePlaceId)}
                    target="_blank"
                    rel="noreferrer" >
                    <Button className="grid grid-cols-1 gap-1">
                        <label className='font-bold m-0'>Google Place</label>
                    </Button>
                </a>}
        </div>
    );
}
function generateGoogleMapsUrl(placeId) {
    return `https://www.google.com/maps/place/?q=place_id:${placeId}`;
}
AddActivityLocation.propTypes = {
    location: PropTypes.object,
    updateLocations: PropTypes.func,
};