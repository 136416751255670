import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box } from '@mui/material';
//
import { BaseOptionChart } from '../../../components/charts';
// import PropTypes from 'prop-types'
import { useEffect, useState } from 'react';
import { getWeeklySalesData } from './LastWeekSalesComparison';
// ----------------------------------------------------------------------


export default function WeeklySalesChart() {
  const [salesData, setSalesData] = useState([]);
  const weeks = 10;
  useEffect(() => {
    const fetchWeeklySalesData = async () => {
      try {
        const response = await getWeeklySalesData(weeks);
        setSalesData(response);
      } catch (error) {
        console.error('Failed to fetch weekly sales data:', error);
      }
    };
    fetchWeeklySalesData();
  }, []);
  const CHART_DATA = [
    {
      name: 'GMB',
      type: 'bar',
      data: salesData.map((data) => data.totalAmount)
    },
    {
      name: 'Profit',
      type: 'bar',
      data: salesData.map((data) => data.totalProfit)
    },
    // {
    //   name: 'Bookings',
    //   type: 'line',
    //   data: salesData.map((data) => data.totalBookings),
    //   yAxisIndex: 1 // Maps this series to the second y-axis
    // },

  ];

  const labels = salesData.map((data) => data.date);
  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: [0, 0, 3] },

    chart: {
      height: 450,
      type: 'bar',
    },
    bar: {
      columnWidth: '100%',
      borderRadius: 0,
      barHeight: '100%',
      horizontal: false,
      // stacking: 'normal' // Enable stacking
    },
    fill: { type: ['solid', 'solid'] },
    labels: labels,
    xaxis: { type: 'datetime' },
    yaxis: [
      {
        title: {
          text: 'Total Amount'
        },
        labels: {
          formatter: (val) => `${val.toFixed(0)}`
        }
      },

    ],
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y, { dataPointIndex }) => {
          const { startDate, endDate } = salesData[dataPointIndex];
          const formattedDate = `${startDate} - ${endDate}`;
          let tooltipText = '';
          if (typeof y !== 'undefined') {
            tooltipText = `${y.toFixed(0)}<span style="margin-left:10px;">(${formattedDate})</span>`;
          }
          return tooltipText;
        }
      }
    }
  });

  return (
    <Card>
      <CardHeader title="Weekly sales"
        subheader={`Last ${weeks} week sales`}
      />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={CHART_DATA} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
