// material
import { Autocomplete, Box, Card, CardHeader, Divider, TextField } from '@mui/material';
//
import axios from 'axios';
import { API_URL } from 'src/utils/constants';
import { useEffect, useMemo, useState } from 'react';
import { formatCurrency } from 'src/utils/formatCurrency';
import DateRangePicker from 'src/components/DatePicker';
import moment from 'moment';

// ----------------------------------------------------------------------

export const getActivityReports = async ({ startDate, endDate }) => {
  const activityFinanceData = await axios.get(`${API_URL}/admin/mb/dashboard/activityReports?startDate=${startDate}&endDate=${endDate}`);
  return activityFinanceData.data;
};


export default function ActivitySalesReports() {
  const [activityReports, setActivityReports] = useState([]);
  const [activityTypes, setActivityTypes] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [loading, setLoading] = useState(false);
  const toady = moment().format('YYYY-MM-DD');
  const oneWeek = moment().add(-1, 'week').format('YYYY-MM-DD');
  const fetchActivityReports = async ({ startDate, endDate }) => {
    try {
      setLoading(true);
      const response = await getActivityReports({
        startDate: startDate,
        endDate: moment(endDate).format('YYYY-MM-DD'),
      });
      const actTypes = new Array(...new Set(response.map((report) => report.type)));
      setActivityTypes(actTypes);
      setActivityReports(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Failed to fetch activity reports:', error);
    }
  };
  useEffect(() => {
    fetchActivityReports({ startDate: oneWeek, endDate: toady });
  }, []);

  const [totalData, setTotalData] = useState({
    totalProfit: 0,
    totalAmount: 0,
    totalBookings: 0,
  });


  const handleDateChange = async (date) => {
    await fetchActivityReports({ startDate: date.startDate, endDate: date.endDate });
  };

  const ActivityReports = useMemo(() => {
    const filteredActivityReports = activityReports
      .filter((report) => selectedType ? report.type === selectedType : true)

    setTotalData(filteredActivityReports.reduce((acc, report) => {
      const { totalProfit, totalAmount, totalBookings } = acc;
      return {
        totalProfit: totalProfit + report.totalProfit,
        totalAmount: totalAmount + report.totalAmount,
        totalBookings: totalBookings + report.totalBookings,
      };
    }, { totalProfit: 0, totalAmount: 0, totalBookings: 0 }));

    return filteredActivityReports.map((report) => {
      const { activityId, title, totalBookings, totalAmount, totalProfit } = report;
      return (
        <div key={activityId}>
          <div className='grid grid-cols-4 place-items-center' >
            <p className='text-sm font-medium place-self-start'>{title}</p>
            <p className='text-sm font-medium'>{totalBookings}</p>
            <p className='text-sm font-medium'>{formatCurrency(totalAmount)}</p>
            <p className='text-sm font-medium'>{formatCurrency(totalProfit)}</p>
          </div>
          <Divider className='w-full' orientation="horizontal" />
        </div>
      )
    })
      ;
  }, [activityReports, selectedType, activityTypes])

  const handleChange = (event, newValue) => {
    setSelectedType(newValue);
  };

  return (
    <Card>
      <CardHeader title="Activity sales reports" subheader="" />
      <div className='relative flex items-start justify-between'>
        <div className=' '>
          <DateRangePicker startDate={toady} endDate={oneWeek} onSelect={handleDateChange} />
        </div>
        {loading ? <h1>Loading...</h1> :
          <Box>
            <div className='grid grid-cols-1 p-4 pt-0 gap-1 min-h-96 flex-1 min-w-full'>
              <div className='col-span-full flex items-center gap-4 mb-4'>
                <label className="m-0 font-bold">Activity Type</label>
                <Autocomplete
                  id="combo-box-demo"
                  options={activityTypes}
                  sx={{ width: 340 }}
                  renderInput={(params) => <TextField {...params} label="" />}
                  value={selectedType}
                  onChange={handleChange}
                />
              </div>
              <div className='grid grid-cols-4 place-items-center' >
                <p className='text-lg font-semibold place-self-start'>Activity Title</p>
                <p className='text-lg font-semibold text-center'>Total Bookings</p>
                <p className='text-lg font-semibold'>GMB</p>
                <p className='text-lg font-semibold text-center'>Profit</p>
              </div>
              {ActivityReports}
              <div>
                <div className='grid grid-cols-4 place-items-center font-bold' >
                  <p className='text-sm font-medium place-self-start'></p>
                  <p className='text-sm font-medium'>{totalData.totalBookings}</p>
                  <p className='text-sm font-medium'>{formatCurrency(totalData.totalAmount)}</p>
                  <p className='text-sm font-medium'>{formatCurrency(totalData.totalProfit)}</p>
                </div>
                <Divider className='w-full' orientation="horizontal" />
              </div>

            </div >
            {/* <ReactApexChart type="bar" series={CHART_DATA} options={chartOptions} height={364} /> */}
          </Box>}
      </div>
    </Card >
  );
}
