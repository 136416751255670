// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const adminSidebarConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: getIcon('eva:pie-chart-2-fill'),
  // },
  // {
  //   title: 'user',
  //   path: '/dashboard/user',
  //   icon: getIcon('eva:people-fill'),
  // },
  {
    title: 'Bookings',
    path: '/dashboard/bookings',
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'Orders',
    path: '/dashboard/orders',
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'Activities',
    path: '/dashboard/list-activities',
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'Partners',
    path: '/dashboard/partners',
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'Finances',
    path: '/dashboard/finances',
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'Assets',
    path: '/dashboard/assets',
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'Employees',
    path: '/dashboard/employees',
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'Coupons',
    path: '/dashboard/coupons',
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'Guests',
    path: '/dashboard/guests',
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'Places',
    path: '/dashboard/places',
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'Extras',
    path: '/dashboard/extras',
    icon: getIcon('eva:shopping-bag-fill'),
    children: [
      { title: 'Stay Amenities', path: '/dashboard/stayAmenities' },
      { title: 'Fly Icons', path: '/dashboard/flyIcons' },
    ],
  },
  {
    title: 'Edit Activities',
    path: '/dashboard/edit-activity',
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'Master Inventory',
    path: '/dashboard/update-master-inventory',
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'Ladakh Itinerary',
    path: '/dashboard/ladakh-itinerary',
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'Inventory',
    path: '/dashboard/update-inventory',
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'Make Booking',
    path: '/dashboard/make-booking',
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'Create Order',
    path: '/dashboard/create-order',
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'Laial Clients',
    path: '/dashboard/laila-clients',
    icon: getIcon('eva:file-text-fill'),
  },
  {
    title: 'Add Affiliate Activity ',
    path: '/dashboard/products/null',
    icon: getIcon('eva:shopping-bag-fill'),
  },

  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon('eva:lock-fill'),
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon('eva:person-add-fill'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill'),
  // },
];

export default adminSidebarConfig;
