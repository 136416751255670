import React, { useState, useEffect } from 'react';
import Page from '../../components/Page';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Modal,
  Box,
  MenuItem,
  Select,
} from '@mui/material';
import { API_URL } from 'src/utils/constants';

const paymentMethods = {
  razorpay_gateway: 'razorpay-gateway',
  razorpay_link: 'razorpay-link',
  razorpay_gateway_and_link: 'razorpay-gateway-and-link',
  direct_razorpay_link: 'direct-razorpay-link',
  direct_UPI: 'direct-upi',
  direct_bank_transfer: 'direct-bank-transfer',
  zero_advance: 'zero-advance',
};

const ListOrdersPage = () => {
  const [orders, setOrders] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [paymentOption, setPaymentOption] = useState({
    orderId: '',
    paymentMethod: '',
    paymentDate: '',
    paymentTime: '',
    paymentMethodLink: '',
    paymentMethodId: ''
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPaymentOption((prev) => ({ ...prev, [name]: value }));
  };
  const [visibleColumns, setVisibleColumns] = useState([
    'id',
    'date',
    'paymentStatus',
    'guestName',
    'guestPhone',
    'amountFinal',
    'amountAdvance',
    'bookingsSummary',
  ]);

  const fields = [
    { id: 'id', label: 'Id' },
    { id: 'date', label: 'Date' },
    { id: 'paymentStatus', label: 'Payment Status' },
    { id: 'guestName', label: 'Guest Name' },
    { id: 'guestPhone', label: 'Guest Phone' },
    { id: 'amountFinal', label: 'Amount Final' },
    { id: 'amountAdvance', label: 'Amount Advance' },
    { id: 'amountTotal', label: 'Amount Total' },
    { id: 'coupon', label: 'Coupon' },
    { id: 'bookingsSummary', label: 'Bookings Summary' },
  ];

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        // Replace 'your_api_endpoint' with the actual API endpoint.
        const response = await fetch(`${API_URL}/admin/mb/orders`);
        const data = await response.json();
        setOrders(data);
      } catch (error) {
        console.error('Failed to fetch orders:', error);
      }
    };

    fetchOrders();
    document.title = "Orders";
  }, []);

  // Function to toggle visibility of columns
  const handleColumnToggle = (columnId) => {
    setVisibleColumns((prev) =>
      prev.includes(columnId)
        ? prev.filter((c) => c !== columnId)
        : [...prev, columnId]
    );
  };


  const handleVerifyBoking = async (orderId) => {
    const { paymentDate, paymentTime } = paymentOption;
    const timeFormat = paymentTime.split(':')[0] < 12 ? 'am' : 'pm'
    const formattedTime = timeFormat === 'am'
      ? paymentTime
      : `${paymentTime.split(':')[0] - 12}:${paymentTime.split(':').slice(1).join(':')}`
      + ' ' + timeFormat;

    const paymentDateAndTime = paymentDate + ', ' + formattedTime
    const response = await fetch(`${API_URL}/admin/order/payment/received`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        orderGetQuery: { _id: orderId },
        paymentDate: paymentDateAndTime,
        paymentMethod: paymentOption.paymentMethod,
        paymentMethodLink: paymentOption.paymentMethodLink,
        paymentMethodId: paymentOption.paymentMethodId,

      }),
    });
    if (response.ok) {
      location.reload()
    }
  };

  return (
    <Page>
      <Modal
        open={open} onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className='absolute flex flex-col py-6 p-4 bg-white rounded-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
          <h1 className='mb-2 font-bold'>Order {paymentOption.orderId}</h1>
          <div className='grid grid-cols-2 gap-4'>
            <label>Payment method</label>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              label="Age"
              className='max-w-[400px]'
              fullWidth
              value={paymentOption.paymentMethod}
              onChange={(event) => {
                setPaymentOption(prev => { return { ...prev, paymentMethod: event.target.value } });
              }}
            >
              {Object.keys(paymentMethods).map((method) => (
                <MenuItem
                  fullWidth
                  className='capitalize'
                  key={paymentMethods[method]}
                  value={paymentMethods[method]}
                >
                  {method.split('_').join(' ')}
                </MenuItem>
              ))}
            </Select>
            <label>Payment date</label>
            <input
              type="date"
              name="paymentDate"
              value={paymentOption.paymentDate}
              onChange={handleInputChange}
            />
            <label>Payment time</label>
            <input
              type="time"
              name="paymentTime"
              step={2}
              value={paymentOption.paymentTime}
              onChange={handleInputChange}
            />
            <label>Payment method Id</label>
            <input
              type="text"
              name="paymentMethodId"
              value={paymentOption.paymentMethodId}
              onChange={handleInputChange}
            />
            <label>Payment method link</label>
            <input
              type="text"
              name="paymentMethodLink"
              value={paymentOption.paymentMethodLink}
              onChange={handleInputChange}
            />
            <button className='col-span-full place-self-start bg-red-400 hover:bg-red-500 text-white px-4 py-2 rounded-lg w-40 text-center h-10 ml-auto'
              onClick={() => handleVerifyBoking(paymentOption.orderId)}>
              Verify
            </button>
          </div>
        </Box>
      </Modal>
      <Container>
        <FormGroup row>
          {fields.map((field) => (
            <FormControlLabel
              key={field.id}
              control={
                <Checkbox
                  checked={visibleColumns.includes(field.id)}
                  onChange={() => handleColumnToggle(field.id)}
                />
              }
              label={field.label}
            />
          ))}
        </FormGroup>
        <Table>
          <TableHead>
            <TableRow>
              {fields
                .filter((field) => visibleColumns.includes(field.id))
                .map((field) => (
                  <TableCell key={field.id}>{field.label}</TableCell>
                ))}

              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <TableRow key={order.id}>
                {fields
                  .filter((field) => visibleColumns.includes(field.id))
                  .map((field) => (
                    <TableCell key={field.id}>{order[field.id]}</TableCell>
                  ))}
                <TableCell>
                  {order.paymentStatus === 'payment-pending' && <button className='bg-red-400 hover:bg-red-500 text-white px-4 py-2 rounded-lg w-40 text-center h-10 ml-auto'
                    onClick={() => {
                      handleOpen();
                      setPaymentOption(prev => ({ ...prev, orderId: order.id }))
                    }}>
                    Manual Verification
                  </button>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Container>
    </Page>
  );
};

export default ListOrdersPage;
