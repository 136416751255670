/* eslint-disable */
import _ from 'lodash';
import React from 'react';
import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import axios from 'axios';
import CircleIcon from '@mui/icons-material/Circle';
import './../components/gallery/gallery.css';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Button,
  Container,
  Stack,
  TextField,
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Box,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
// components
import Page from '../components/Page';
import ActivityList from '../components/activityList/activityList';
import CreateGenericActivityParams from '../components/activityList/createGenericActivityParams';
import Gallery from '../components/gallery/gallery';
import { useParams } from 'react-router-dom';
// import {
//   ProductSort,
//   ProductList,
//   ProductCartWidget,
//   ProductFilterSidebar
// } from '../sections/@dashboard/products';
// //
// import PRODUCTS from '../_mocks_/products';

// ----------------------------------------------------------------------

export default function EcommerceShop() {
  const [draftData, setDraftData] = useState([]);
  const [newVariants, setNewVariants] = useState([]);
  const [productData, setProductData] = useState([]);
  const [newActivityData, setNewActivity] = useState({});
  const [newbannerData, setBannerSelection] = React.useState([]);
  const [textAreaValue, setTextAreaValue] = useState('');
  const [isLoading, setLoader] = useState(false);

  // const { slug } = useParams();
  // const getActivity = async () => {
  //   try {
  //     if (slug) {
  //       const res = await axios.get(
  //         `${API_URL}/activity/mb/${slug}`
  //       );
  //       const aData = res.data.mb;
  //       setNewVariants(aData.variants);
  //       setBannerSelection(aData.banners);
  //       setNewActivity(aData);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   getActivity();
  // }, []);

  const _renderIncExc = (el) => {
    return _.map(el.incExcHTML, (incExc, idx) => {
      if (incExc.type === 'title') {
        return (
          <TreeItem
            nodeId="3"
            label={incExc.type === 'title' && `${incExc.value}:`}
            key={idx}
          />
        );
      } else {
        return (
          <TreeItem
            nodeId="8"
            label={incExc.value}
            sx={{ pl: 4 }}
            icon={<CircleIcon sx={{ width: 10, height: 10 }} />}
          />
        );
      }
    });
  };

  const openInNewTab = (href) => {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: href,
    }).click();
  };

  const _renderVendorVariants = () =>
    _.map(draftData.variants, (variant, idx) => (
      <Grid item lg={6} md={6} key={idx}>
        <Card>
          <CardHeader title={variant.vendorId} />
          <CardContent>
            <TreeView
              aria-label="multi-select"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              // multiSelect
              sx={{ height: 216, flexGrow: 1, overflowY: 'auto' }}
            >
              {_.map(variant.variants, (el, idx) => (
                <TreeItem nodeId="1" label={el.title} key={idx}>
                  <TreeItem nodeId="2" label={`Price: ${el.price}`} />
                  {_renderIncExc(el)}
                </TreeItem>
              ))}
            </TreeView>
          </CardContent>
        </Card>
      </Grid>
    ));

  const handleVariationMapping = (event) => {
    const eventValues = event.target.value.split('-');
    const variantsCopy = newVariants;
    const changeVariant = variantsCopy[eventValues[0]];
    changeVariant.mapping[eventValues[1]] = eventValues[2];
    variantsCopy[eventValues[0]] = changeVariant;
    setNewVariants(variantsCopy);
  };

  const handleVariationNameChange = (event) => {
    newVariants[event.target.id].title = event.target.value;
    setNewVariants(newVariants);
  };

  const handleVariationdescChange = (event) => {
    newVariants[event.target.id].desc = event.target.value;
    setNewVariants(newVariants);
  };

  const removeVariant = (event) => {
    console.log(event.target.id);
    console.log(newVariants);
    newVariants.splice(event.target.id, 1);
    setNewVariants([...newVariants]);
  };

  const _renderVariants = () =>
    _.map(newVariants, (newVariant, idNewVariant) => (
      <Grid item lg={12} md={12} key={idNewVariant}>
        <Card>
          <CardContent>
            <h6 className="close" id={idNewVariant} onClick={removeVariant}>
              Close
            </h6>
            <Grid item lg={6} md={6} key={idNewVariant}>
              <TextField
                fullWidth
                id={idNewVariant}
                label="Variation Name"
                variant="standard"
                onChange={handleVariationNameChange}
              />
            </Grid>
            <div className="variant-dropdown-wrapper">
              {_.map(draftData.variants, (vendorVariants, idv) => (
                <Grid item lg={3} md={3} key={idv}>
                  <InputLabel id="demo-simple-select-standard-label">
                    {vendorVariants.vendorId}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={`${idNewVariant}-${vendorVariants.vendorId}-${
                      newVariants[idNewVariant].mapping[vendorVariants.vendorId]
                    }`}
                    label="Age"
                    fullWidth
                    onChange={handleVariationMapping}
                  >
                    {_.map(vendorVariants.variants, (vendorVariant, idv) => (
                      <MenuItem
                        value={`${idNewVariant}-${vendorVariants.vendorId}-${vendorVariant.id}`}
                      >
                        {vendorVariant.title}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              ))}
            </div>
            <div className="textarea-wrapper">
              <textarea
                id={idNewVariant}
                placeholder="Write desc"
                onChange={handleVariationdescChange}
              />
            </div>
          </CardContent>
        </Card>
      </Grid>
    ));

  const addVariant = () => {
    setNewVariants((previousState) => [
      ...previousState,
      { mapping: {}, title: '', desc: '' },
    ]);
  };

  const onFetchClick = async () => {
    try {
      setLoader(true);
      const res = await axios
        .post(`${API_URL}/activity/draft`, {
          urls: textAreaValue.split('\n'),
        })
        .then((response) => {
          setDraftData(response.data);
          setProductData(response.data);
          setLoader(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const textareachangehandler = (event) => {
    setTextAreaValue(event.target.value);
  };

  // Gallery Rendering
  const _renderGalleryList = () =>
    _.map(productData.banners, (value, index) => (
      <Card key={index}>
        <CardHeader title={value.source} className="header" />
        <CardContent className="cardContent-padding">
          <img
            src={value.url}
            onClick={imgClicked}
            alt=""
            className={value.selected ? 'selected' : null}
          />
        </CardContent>
      </Card>
    ));

  const imgClicked = (evt) => {
    console.log(evt.target.src);
    const index = newbannerData.findIndex((url) => url === evt.target.src);
    if (index >= 0) {
      const unselectedIndex = productData.banners.findIndex(
        (img) => img.url === evt.target.src
      );
      productData.banners[unselectedIndex].selected = false;
      newbannerData.splice(index, 1);
      setBannerSelection([...newbannerData]);
    } else {
      console.log(productData.banners);
      let tmp = evt.target.src;
      newbannerData.push(tmp);
      setBannerSelection([...newbannerData]);
      const selectedIndex = productData.banners.findIndex(
        (img) => img.url == evt.target.src
      );
      console.log(selectedIndex);
      productData.banners[selectedIndex].selected = true;
    }

    setTimeout(() => {
      console.log(newbannerData);
    }, 500);

    // debugger
  };

  const unselect = () => {
    for (var i = 0; i < productData.banners.length; i++) {
      productData.banners[i].selected = false;
    }
    setBannerSelection([]);
  };

  const saveFinalData = () => {
    setLoader(true);
    console.log(newVariants);
    console.log(textAreaValue.split('\n'));
    console.log(newActivityData);
    console.log(newbannerData);
    try {
      const body = {
        title: newActivityData.title,
        slug: newActivityData.slug,
        rating: newActivityData.rating,
        ratingCount: newActivityData.ratingCount,
        bookedCount: newActivityData.booked,
        variants: newVariants,
        banners: newbannerData,
        urls: textAreaValue.split('\n'),
        ...newActivityData,
      };
      console.log(body);
      const res = axios
        .post(`${API_URL}/activity/mb`, body)
        .then((response) => {
          setLoader(false);
          openInNewTab(`https://www.passprt.co/activity/${response.data.slug}`);
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Page title="Dashboard: Products | Minimal-UI">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Activity
        </Typography>
        <Stack spacing={1} direction="row">
          <TextField
            name="apis"
            multiline
            maxRows={10}
            fullWidth
            onChange={textareachangehandler}
            value={textAreaValue}
          />

          <Button
            variant="contained"
            sx={{ width: '20ch' }}
            onClick={onFetchClick}
            disabled={isLoading}
          >
            Fetch
          </Button>
        </Stack>

        <Stack direction="column" sx={{ mt: 5 }}>
          <Typography variant="h5"> Activity List </Typography>
          <ActivityList data={productData} />
        </Stack>

        <CreateGenericActivityParams passActivityData={setNewActivity} />

        <div style={{ paddingTop: 20 }}>
          <h3>Selected Image: {newbannerData.length}</h3>
          <h3 className="unselect" onClick={unselect}>
            Unselect All
          </h3>
          <div className="galleyWrapper">{_renderGalleryList()}</div>
        </div>
        {/* <Gallery data={productData} passBannerData={setBannerSelection} /> */}

        <Stack direction="column" sx={{ mt: 5 }}>
          <Typography variant="h5"> Variants </Typography>
          <Grid container spacing={1}>
            {_renderVendorVariants()}
          </Grid>

          <div className="addVariants">{_renderVariants()}</div>

          <Box>
            <Button variant="contained" onClick={addVariant}>
              Add Variants
            </Button>
          </Box>
        </Stack>
        {/* 
        <ProductList products={PRODUCTS} />
        <ProductCartWidget /> */}

        <div className="finalsave">
          <Button
            className="save"
            variant="contained"
            disabled={isLoading}
            onClick={saveFinalData}
          >
            SAVE
          </Button>
          {isLoading ? <CircularProgress /> : null}
        </div>
      </Container>
    </Page>
  );
}
