import axios from "axios";
import { API_URL } from "../constants";


export const fetchPartnersExtract = async (setPartners) => {
    try {
        const partners = await axios.get(`${API_URL}/admin/mb/partners`);
        setPartners(partners.data || []);
        return partners.data
    } catch (error) {
        console.error('Failed to fetch data:', error);
        return [];
    }
};
