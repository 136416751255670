import React, { useState } from 'react';
import {
    Button,
    TextField,
    Box,
} from '@mui/material';
import PropTypes from 'prop-types';
import { UploadButton } from 'src/components/buttons/upload';



const PhotosUploader = ({ photos, setPhotos }) => {
    const [newPhoto, setNewPhoto] = useState({
        url: '',
        mimeType: '',
        uploadDate: '',
        createdAt: '',
    });

    const [urls, setUrls] = useState('');
    const [banners, setBanners] = useState([]);
    console.log(banners);

    const onBannerChangeUrl = (urls) => {
        setBanners(urls);
        setUrls(urls.join('\n'));

        setPhotos(prev => {
            const newPhotos = urls
                .filter(url => url !== '' && !prev.some(photo => photo.url === url))
                .map(url => ({
                    ...newPhoto,
                    url,
                    uploadDate: new Date(),
                    createdAt: new Date()
                }));

            return [...prev, ...newPhotos];
        });
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setNewPhoto((prev) => ({ ...prev, [name]: value }));
    };

    const handleAddPhoto = () => {
        setPhotos((prev) => [...prev, { ...newPhoto, uploadDate: new Date(), createdAt: new Date() }]);
        setNewPhoto({
            url: '',
            mimeType: '',
            uploadDate: '',
            createdAt: '',
        });
    };

    return (
        <Box mb={2} className='w-full'>
            <h3 className="text-lg font-semibold">Photos</h3>
            <div className='w-full grid grid-cols-2 gap-4 my-3'>
                {photos.map((photo, index) => (
                    <div className='grid grid-cols-1 place-items-center gap-2 p-2 border rounded-lg' key={photo.url} >
                        <div className='flex items-center justify-between gap-2 w-full'>
                            <p className='text-sm'>Upload Date: {new Date(photo.uploadDate).toLocaleDateString()}</p>
                            <button
                                className='bg-red-400 hover:bg-red-500 text-white px-2 py-1 rounded-lg'
                                onClick={() => {
                                    const currentIndex = photos.findIndex((doc, ind) => ind === index);
                                    setPhotos((prev) => {
                                        const newPhotos = [...prev];
                                        newPhotos.splice(currentIndex, 1);
                                        return newPhotos;
                                    });
                                }}>
                                Remove
                            </button>
                        </div>
                        <div className='flex items-center justify-between gap-10 w-full'>
                            <input
                                className='w-full'
                                type='text'
                                name="url"
                                label="URL"
                                value={photo.url}
                                readOnly
                            />
                        </div>
                        <img
                            className='aspect-square w-52'
                            src={photo.url}
                            alt="uploaded-image" />
                    </div>
                ))}
            </div>
            <div className='flex items-center justify-between gap-2'>
                <UploadButton
                    title="Upload photos"
                    uploadUrls={urls.split('\n')}
                    onUploadUrlChange={onBannerChangeUrl}
                />
                <div className='flex-1 flex items-center gap-4'>
                    <input
                        className='w-full'
                        type='text'
                        name="url"
                        label="URL"
                        placeholder='https://example.com/image.jpg'
                        value={newPhoto.url}
                        onChange={handleInputChange}
                    />
                    <button
                        className='bg-red-400 hover:bg-red-500 text-white px-2 py-2 rounded-lg whitespace-nowrap'
                        onClick={handleAddPhoto} >
                        + Add Photo
                    </button>
                </div>
            </div>
        </Box>
    );
};

PhotosUploader.propTypes = {
    photos: PropTypes.array.isRequired,
    setPhotos: PropTypes.func.isRequired,
};


const GuestPhotosUploader = ({ guestPhotos, setGuestPhotos }) => {
    const [newGuestPhoto, setNewGuestPhoto] = useState({
        bookingId: '',
        guestId: '',
        mimeType: '',
        url: '',
        uploadDate: '',
        createdAt: '',
    });

    const [confirmBooking, setConfirmBooking] = useState(false);


    const [urls, setUrls] = useState('');
    const [banners, setBanners] = useState([]);
    console.log(banners);
    const onBannerChangeUrl = (urls) => {
        setBanners(urls);
        setUrls(urls.join('\n'));

        setGuestPhotos(prev => {
            const newPhotos = urls
                .filter(url => url !== '' && !prev.some(photo => photo.url === url))
                .map(url => ({
                    ...newGuestPhoto,
                    url,
                    uploadDate: new Date(),
                    createdAt: new Date()
                }));

            return [...prev, ...newPhotos];
        });
    };
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setNewGuestPhoto((prev) => ({ ...prev, [name]: value }));
    };

    const handleAddGuestPhoto = () => {
        setGuestPhotos((prev) => [...prev, { ...newGuestPhoto, uploadDate: new Date(), createdAt: new Date() }]);
        setNewGuestPhoto((prev) => ({
            ...prev,
            mimeType: '',
            url: '',
        }));
    };

    return (
        <Box mb={2}>
            <h3 className="text-lg font-semibold">Guest Photos</h3>
            <div className='w-full grid grid-cols-2 gap-4 my-3'>
                {guestPhotos.map((guestPhoto, index) => (
                    <div className='grid grid-cols-1 gap-2 p-2 border rounded-lg' key={guestPhoto.url} >
                        <div className='flex items-center justify-between gap-2 w-full'>
                            <img src={guestPhoto.url} alt="uploaded" className="aspect-square w-52 place-self-center" />
                            <button
                                className='bg-red-400 hover:bg-red-500 text-white px-2 py-1 rounded-lg'
                                onClick={() => {
                                    const currentIndex = guestPhotos.findIndex((doc, ind) => ind === index);
                                    setGuestPhotos((prev) => {
                                        const newPhotos = [...prev];
                                        newPhotos.splice(currentIndex, 1);
                                        return newPhotos;
                                    });
                                }}>
                                Remove
                            </button>
                        </div>
                        <div className='grid grid-cols-2 text-xs'>
                            <p className='whitespace-nowrap'>Booking ID: {guestPhoto.bookingId}</p>
                            <p className='place-self-end'>Upload Date: {new Date(guestPhoto.uploadDate).toLocaleDateString()}</p>
                            <p className='whitespace-nowrap'>Guest ID: {guestPhoto.guestId}</p>
                            <p className='place-self-end'>Created At: {new Date(guestPhoto.createdAt).toLocaleDateString()}</p>
                        </div>
                    </div>
                ))}
            </div>
            <Box mb={2}>
                {!confirmBooking ? <> <TextField
                    name="bookingId"
                    label="Booking ID"
                    value={newGuestPhoto.bookingId}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                />
                    <TextField
                        name="guestId"
                        label="Guest ID"
                        value={newGuestPhoto.guestId}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <button className='bg-red-400 hover:bg-red-500 text-white px-4 py-2 rounded-lg whitespace-nowrap'
                        onClick={() => {
                            setConfirmBooking(true);
                        }}>
                        Confirm BookingID
                    </button>
                </> : <>
                    <TextField
                        name="url"
                        label="URL"
                        value={newGuestPhoto.url}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <div className='flex items-center justify-between gap-2'>
                        <UploadButton
                            title="Upload guest photos"
                            uploadUrls={urls.split('\n')}
                            onUploadUrlChange={onBannerChangeUrl}
                        />
                        <Button
                            className='bg-red-400'
                            onClick={handleAddGuestPhoto} variant="contained" color="primary">
                            + Add Guest Photo
                        </Button>
                    </div>
                </>}
            </Box>
        </Box>
    );
};

GuestPhotosUploader.propTypes = {
    guestPhotos: PropTypes.array.isRequired,
    setGuestPhotos: PropTypes.func.isRequired,
};

export {
    PhotosUploader,
    GuestPhotosUploader
};
