import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import io from "socket.io-client";
import { API_URL } from "src/utils/constants";
import { useLocation } from "react-router-dom";
import { conversation } from "./coPilotData";

export default function LailaRemote() {
    const { pathname } = useLocation();
    const [socketId, code] = pathname.split("/").pop().split(",");
    const [socket, setSocket] = useState(null);
    const currentConversation = conversation[code];
    const [sentMessages, setSentMessages] = useState([]);

    useEffect(() => {
        const initializeSocket = async () => {
            try {
                const ws = await new Promise((resolve, reject) => {
                    const socketInstance = io(API_URL + '/laila');
                    socketInstance.on("connect", () => {
                        resolve(socketInstance);
                    });
                    socketInstance.on("connect_error", (error) => {
                        reject(error);
                    });
                });
                setSocket(ws);
            } catch (error) {
                console.error("Error loading Socket.IO client:", error);
            }
        };
        initializeSocket();
        // Clean up function if needed
        return () => {
            if (socket) {
                socket.disconnect();
            }
        };
    }, []);


    const handleMessageSent = async (message) => {
        try {
            if (socket) {
                await socket.emit("MB:messageToLaila", { message, socketId });
                setSentMessages(prev => [...prev, message])
            }
        } catch (error) {
            console.log(error)
        }
    }
    let lailaMessage = 1
    let travellerMessage = 1

    return (
        <div>
            <Container>
                <h1 className="text-xl font-bold mb-3">CODE : {code}</h1>
                <div className="grid gap-4">
                    {currentConversation?.map((message, index) => {
                        return (
                            <div key={index} className="flex items-start justify-between gap-4 border-b border-gray-200">
                                <div>
                                    <h1><span className="font-bold">Type: </span> {`(${message.type})`}
                                        {message.type.includes('text') && message.from === 'Laila' ? <span className="font-bold ml-2">{`(L${lailaMessage++})`}</span> : message.type.includes('text') && <span className="font-bold ml-2">{`(T${travellerMessage++})`}</span>}
                                    </h1>
                                    <div>
                                        <span className="font-bold">{message.from}: </span>{message.content}</div>
                                </div>
                                {sentMessages.includes(message) ? <h1>Sent</h1> : <button
                                    onClick={async () => await handleMessageSent(message)}
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-center">
                                    <span className="font-bold">Send</span>
                                </button>}
                            </div>
                        );
                    })}
                </div>
            </Container>
        </div>
    );
}

