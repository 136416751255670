/* eslint-disable */
import _ from 'lodash';
import React from 'react';
import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import axios from 'axios';
import CircleIcon from '@mui/icons-material/Circle';
import './../components/gallery/gallery.css';
import CircularProgress from '@mui/material/CircularProgress';
import {
    Button,
    Container,
    Stack,
    TextField,
    Typography,
    Grid,
    Card,
    CardHeader,
    CardContent,
    Box,
    InputLabel,
    Select,
    MenuItem,
    Autocomplete,
    Input,
} from '@mui/material';

// components
import Page from '../components/Page';
import ActivityList from '../components/activityList/activityList';
import CreateGenericActivityParams from '../components/activityList/createGenericActivityParams';

import { Link, useParams } from 'react-router-dom';
import { API_URL } from 'src/utils/constants';
import { ActivityBanners } from 'src/components/banners/activityBanners';
import Proptypes from 'prop-types';
import FlyHTMLeditor from 'src/components/FlyHTMLeditor';

const ACTIVITY_ALLOCATION_TYPE = [
    'fixed',
    'fixed-but-partner-action-required',
    'manual',
    'manual-and-partner-action-required',
]

const ACTIVITY_CONFIRMATION_TYPE = [
    'instant',
    'manual',
]
export default function EditFulfillmentPolicyPage() {
    const [activity, setActivity] = useState([]);
    const [fulfillmentPolicy, setFulfillmentPolicy] = useState({
        confirmationType: activity?.fulfillmentPolicy?.confirmationType || "",
        allocationType: activity?.fulfillmentPolicy?.allocationType || "",
        preferredPartnerId: activity?.fulfillmentPolicy?.preferredPartnerId || "",
    });
    const [partners, setPartners] = useState([]);
    const [partnerNames, setPartnerNames] = useState([]);
    const [isLoading, setLoader] = useState(false);
    const { slug } = useParams();

    useEffect(() => {
        if (activity) {
            setFulfillmentPolicy({
                confirmationType: activity?.fulfillmentPolicy?.confirmationType || "",
                allocationType: activity?.fulfillmentPolicy?.allocationType || "",
                preferredPartnerId: activity?.fulfillmentPolicy?.preferredPartnerId || "",
            });
        }
    }, [activity]);
    const getActivity = async () => {
        try {
            const res = await axios.get(`${API_URL}/activity/mb/${slug}`);
            setActivity(res.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
    }, []);

    const getPartners = async () => {
        try {
            // Replace 'your_api_endpoint' with the actual endpoint URL
            const response = await fetch(`${API_URL}/admin/mb/partners`);
            const json = await response.json();
            const partnerNames = json.map((partner) => partner.title);
            setPartnerNames(partnerNames);
            setPartners(json);
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };

    useEffect(() => {
        getActivity();
        getPartners();

    }, []);

    const saveFinalData = async () => {
        setLoader(true);
        try {
            const body = { ...activity, fulfillmentPolicy }
            const res = await axios
                .put(`${API_URL}/activity/mb`, body)
                .then((response) => {
                    setLoader(false);
                    location.reload();
                });
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <Page title="Edit Fulfillment Policy">
            <Container>
                <Typography variant="h4" sx={{ mb: 2 }}>
                    {activity.title}
                </Typography>
                {/* <h1 className='text-xl font-bold'>{activity.title}</h1> */}
                {/* <Link target='_blank' to={`${API_URL}/activity/${activity.slug}`}>Visit activity</Link> */}
                <div className='grid grid-cols-3 gap-4'>
                    <div className='flex flex-col gap-4'>
                        <label className='font-bold'>Confirmation Type</label>
                        <Autocomplete
                            id="combo-box-demo"
                            options={ACTIVITY_CONFIRMATION_TYPE}
                            sx={{ width: 340 }}
                            name='Confirmation Type'
                            renderInput={(params) => <TextField {...params} label="Confirmation Type" />}
                            value={fulfillmentPolicy.confirmationType}
                            onChange={(_, item) => {
                                setFulfillmentPolicy((prev) => ({ ...prev, confirmationType: item }));
                            }}
                        />
                    </div>
                    <div className='flex flex-col gap-4'>
                        <label className='font-bold'>Allocation Type</label>
                        <Autocomplete
                            id="combo-box-demo"
                            options={ACTIVITY_ALLOCATION_TYPE}
                            sx={{ width: 340 }}
                            name='Allocation Type'
                            renderInput={(params) => <TextField {...params} label="Allocation Type" />}
                            onChange={(_, item) => {
                                setFulfillmentPolicy((prev) => ({ ...prev, allocationType: item }));
                            }}
                            value={fulfillmentPolicy.allocationType}
                        />
                    </div>

                    <div className='flex flex-col gap-4'>
                        <label className='font-bold'>Preferred Partner</label>
                        <Autocomplete
                            id="combo-box-demo"
                            options={partnerNames}
                            sx={{ width: 340 }}
                            name='location'
                            renderInput={(params) => <TextField {...params} label="Preferred Partner" />}
                            value={partners.filter((partner) => partner.id === fulfillmentPolicy.preferredPartnerId)[0]?.title || ""}
                            onChange={(_, item) => {
                                const partnerId = partners.filter((partner) => partner.title === item)[0]?.id;
                                setFulfillmentPolicy((prev) => ({ ...prev, preferredPartnerId: partnerId }));
                            }}
                        />
                    </div>
                </div>

                <h1 className='text-xl font-bold flex items-end justify-start my-4 gap-4 '>Current preferred partner:
                    <p className=''>
                        {partners?.filter((partner) => partner.id === fulfillmentPolicy.preferredPartnerId)[0]?.title || "NA"}
                    </p>
                </h1>
                <div className="finalsave">
                    <Button
                        variant="outlined"
                        className='save'
                        disabled={isLoading}
                        enabled
                        onClick={saveFinalData}
                    >
                        SAVE
                    </Button>
                    {isLoading ? <CircularProgress /> : null}
                </div>
            </Container>
        </Page>
    );
}


const SingleItineraryView = ({ props, destinations, number, onItineraryChange }) => {
    const { thumbnail } = props;
    const [itinerary, setItinerary] = useState({ ...props, location: props.location || '' });
    const [descHtml, setDescHtml] = useState({});

    const handleChange = (event) => {
        const { name, value } = event.target;
        setItinerary((prev) => ({ ...prev, [name]: value }));
    };

    const onEditorStateChange = (editorHTML, attribute) => {
        setDescHtml({
            ...descHtml,
            [attribute]: editorHTML,
        });
    };

    useEffect(() => {
        onItineraryChange(number, { ...itinerary, descHtml: descHtml?.descHtml || props?.descHtml });
    }, [itinerary, descHtml]);


    return (<div className='flex flex-col bg-white rounded-xl p-6 border-2 border-gray-200'>
        <div className='flex items-start gap-10'>
            <div className='flex flex-col gap-4'>
                <label className='font-bold'>Day Label</label>
                <Input onChange={handleChange} name='dayLabel' value={itinerary?.dayLabel} />
            </div>
            <div className='flex flex-col'>
                <label className='font-bold'>Destination</label>
                <Autocomplete
                    id="combo-box-demo"
                    options={destinations}
                    sx={{ width: 340 }}
                    name='location'
                    renderInput={(params) => <TextField {...params} label="Destination" />}
                    value={itinerary?.location}
                    onChange={(_, item) => {
                        setItinerary(prev => ({ ...prev, location: item?.label }));
                    }}
                />
            </div>
            <p className='text-xl font-bold ml-auto'>Itinerary #{number + 1}</p>
        </div>
        <div className='flex flex-col'>
            <label className='font-bold'>Title</label>
            <Input onChange={handleChange} name='title' value={itinerary?.title} />
            <label className='font-bold'>Short Desc</label>
            <Input onChange={handleChange} name='desc' value={itinerary?.desc} />
            <label className='font-bold'>Long Desc {`(HTML)`}</label>
            <FlyHTMLeditor
                initialData={itinerary?.descHtml}
                onEditorStateChange={onEditorStateChange}
                attribute={'descHtml'}
            ></FlyHTMLeditor>
        </div>
        <div className='flex flex-col gap-4'>
            <ActivityBanners handleActivityBannerChange={(banners, sections) => {
                handleChange({ target: { name: 'thumbnail', value: banners[0] || props?.thumbnail } });
            }}
                initialUrls={thumbnail} />
        </div>
    </div>);
};
SingleItineraryView.prototype = {
    dayLabel: Proptypes.object,
    destinations: Proptypes.array,
    number: Proptypes.number,
    onItineraryChange: Proptypes.func,
};
