/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  Autocomplete,
  Card,
  CardContent,
} from '@mui/material';
import { Container, Typography } from '@mui/material';
import Page from '../../components/Page';
import axios from 'axios';
import { API_URL, PASSPRT_PARTNER_ID } from 'src/utils/constants';

// const activityId = 'aiumcyi';

const UpdateMasterInventoryPage = () => {
  const [data, setData] = useState(null);
  const [activities, setActivities] = useState([]);
  const [partners, setPartners] = useState([]);
  const [activityId, setActivityId] = useState(null);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [inventory, setInventory] = useState([]);
  const [selectedPlaceId, setSelectedPlaceId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [updateLiveInventory, setUpdateLiveInventory] = useState(false);
  const [isNewStay, setIsNewStay] = useState(false);
  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/activity/mb?bookingType=passprt-managed&status=live,draft&placeId=${selectedPlaceId}`
        );
        console.log(response.data);
        const fetchedActivities = response.data.map((activity) => ({
          id: activity.id,
          slug: activity.slug,
          title: activity.label,
          label: activity.label,
          priceLabel: activity.priceLabel,
          fulfillmentPolicy: activity?.fulfillmentPolicy,
          preferredPartner: activity?.fulfillmentPolicy?.preferredPartnerId,
        }));
        console.log(fetchedActivities);
        setActivities(fetchedActivities);
      } catch (error) {
        console.error('Error fetching activities:', error);
      }
    };
    if (selectedPlaceId) {
      fetchActivities();
    } else {
      setSelectedPlaceId(null);
      setActivities([]);
    }
  }, [selectedPlaceId]);

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const partners = await axios.get(`${API_URL}/admin/mb/partners`);
        const fetchedPartners = await partners.data.map((partner) => ({
          id: partner.id,
          title: partner.title,
          label: partner.title,
        }));
        setPartners(fetchedPartners);
      } catch (error) {
        console.error('Error fetching activities:', error);
      }
    };

    if (activityId) {
      fetchPartners();
    } else {
      setPartners([]);
    }
  }, [activityId])

  const handleActivityChange = async (_, newValue) => {
    try {
      const id = newValue?.slug.split('-')[0];
      setData(null);
      setActivityId(id);
    } catch (error) {
      console.error('Error fetching activity variants:', error);
    }
  };

  const handlePlaceChange = async (_, newValue) => {
    try {
      const id = newValue?.id;
      setSelectedPlaceId(id);
      setActivities([]);
      setData(null);
      setActivityId(null);
    } catch (error) {
      console.error('Error fetching activity variants:', error);
    }
  };

  const handlePartnerChange = async (_, newValue) => {
    try {
      const id = newValue?.id || null;
      setSelectedPartner(id);
    } catch (error) {
      console.error('Error fetching activity variants:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        setInventory([]);
        setData(null);
        const response = await axios.get(
          `${API_URL}/activity/${activityId}/skeleton?partnerId=${selectedPartner}`
        );
        const fetchedInventory = response.data.variants.map((variant) => ({
          variantId: variant.variantId,
          availability: variant.availability ?? 0,
          pricing: variant.pricing.map((pricing) => ({
            pricingId: pricing._id,
            title: pricing.title,
            type: pricing.type,
            dayPrices: pricing.dayPrices.map((dayPrice) => ({
              days: dayPrice.days,
              price: dayPrice.price,
              extraPrices: dayPrice.extraPrices
                ? dayPrice.extraPrices.map((extraPrice) => ({
                  id: extraPrice.id,
                  price: extraPrice.price,
                  title: extraPrice.title, // Ensure title is included
                }))
                : [],
            })),
          })),
        }))
        setInventory(fetchedInventory);
        setData(response.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
  }, [selectedPartner]);

  const handleInventoryChange = (variantIndex, newValue) => {
    const newInventory = [...inventory];
    newInventory[variantIndex].availability = newValue;
    setInventory(newInventory);
  };

  const handlePriceChange = (
    variantIndex,
    pricingIndex,
    dayIndex,
    newValue
  ) => {
    const newInventory = [...inventory];
    newInventory[variantIndex].pricing[pricingIndex].dayPrices[dayIndex].price =
      newValue;
    setInventory(newInventory);
  };

  const handleExtraPriceChange = (
    variantIndex,
    pricingIndex,
    dayIndex,
    extraPriceIndex,
    newValue
  ) => {
    const newInventory = [...inventory];
    newInventory[variantIndex].pricing[pricingIndex].dayPrices[
      dayIndex
    ].extraPrices[extraPriceIndex].price = newValue;
    setInventory(newInventory);
  };

  const handleSave = async () => {
    const response = await axios.post(`${API_URL}/inventory/partner/master`, {
      activityId,
      partnerId: selectedPartner,
      variants: inventory,
      updateLiveInventory
    }).then(response => {
      window.location.reload();
    });
    setUpdateLiveInventory(false);
    setData(null);
    setActivityId('');
    setSelectedPartner('');
  };

  const handleCopyFromPassprt = async () => {
    const response = await axios.post(`${API_URL}/inventory/partner/copyFromMaster`, {
      activityId,
      partnerId: selectedPartner,
    }).then(response => {
      window.location.reload();
    });
  }
  return (
    <>
      <Page title="Master Inventory">
        <Container>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <div>
                <label>Place</label>
                <Autocomplete
                  id="combo-box-demo"
                  options={[
                    {
                      id: 'p2975b6',
                      title: 'Rishikesh',
                      label: 'Rishikesh',
                    },
                    {
                      id: 'paf3c28',
                      title: 'Ladakh',
                      label: 'Ladakh',
                    },
                  ]}
                  sx={{ width: 340 }}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={handlePlaceChange}
                />
              </div>
              <div>
                <label>Activity Name</label>
                <Autocomplete
                  id="combo-box-demo"
                  options={activities}
                  sx={{ width: 340 }}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={handleActivityChange}
                />
              </div>

              <div>
                <label>Partner Name</label>
                <Autocomplete
                  id="combo-box-demo"
                  options={partners}
                  sx={{ width: 340 }}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={handlePartnerChange}
                />
              </div>
            </div>

            {selectedPartner && activityId && selectedPlaceId &&
              <>
                {selectedPartner !== null && (selectedPartner === PASSPRT_PARTNER_ID ?
                  <h1 className='font-bold mt-4'>B2C Prices</h1> :
                  <h1 className='font-bold mt-4'>B2B Prices</h1>)}
                {!isLoading ? <div>
                  {data &&
                    data.variants.map((variant, variantIndex) => (
                      <div key={variant._id}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            //   margin: '60px 0px',
                            padding: '60px 0px 20px 0px',
                          }}
                        >
                          <h3>{variant.title}</h3>
                          <TextField
                            style={{ padding: '0px 20px' }}
                            size="small"
                            key={variant._id}
                            value={inventory[variantIndex].availability}
                            onChange={(e) =>
                              handleInventoryChange(variantIndex, e.target.value.replace(/\D/g, ''))
                            }
                            type="text"
                          />
                        </div>
                        {variant.pricing.map((price, pricingIndex) => (
                          <>
                            {['Weekday', 'Weekend'].map((dayType, dayIndex) => (
                              <div key={price._id}>
                                <label>{price.title + '   '}</label>
                                <label>{dayType}</label>
                                <div
                                  key={price._id}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: '10px',
                                  }}
                                >
                                  {/* <label>{price.title}</label> */}
                                  <TextField
                                    type="text"
                                    variant="standard"
                                    value={
                                      inventory[variantIndex].pricing[pricingIndex]
                                        .dayPrices[dayIndex].price
                                    }
                                    onChange={(e) =>
                                      handlePriceChange(
                                        variantIndex,
                                        pricingIndex,
                                        dayIndex,
                                        e.target.value.replace(/\D/g, '')
                                      )
                                    }
                                    style={{ flexGrow: 1, marginRight: '10px' }}
                                  />
                                  {data.type === "activity" && price.extraPrices?.length &&
                                    price.extraPrices.map(
                                      (extraPrice, extraPriceIndex) => (
                                        <TextField
                                          key={extraPrice.id}
                                          variant="standard"
                                          type="text"
                                          helperText={extraPrice.title}
                                          onChange={(e) =>
                                            handleExtraPriceChange(
                                              variantIndex,
                                              pricingIndex,
                                              dayIndex,
                                              extraPriceIndex,
                                              e.target.value.replace(/\D/g, '')
                                            )
                                          }
                                          value={
                                            inventory[variantIndex].pricing[
                                              pricingIndex
                                            ].dayPrices[dayIndex].extraPrices[
                                              extraPriceIndex
                                            ].price
                                          }
                                          style={{ flexGrow: 1, marginRight: '10px' }}
                                        />
                                      )
                                    )}

                                  {price.dayPrices[dayIndex].extraPrices?.length &&
                                    price.dayPrices[dayIndex].extraPrices.map(
                                      (extraPrice, extraPriceIndex) => (
                                        <TextField
                                          key={extraPrice.id}
                                          variant="standard"
                                          type="text"
                                          helperText={extraPrice.title}
                                          onChange={(e) =>
                                            handleExtraPriceChange(
                                              variantIndex,
                                              pricingIndex,
                                              dayIndex,
                                              extraPriceIndex,
                                              e.target.value.replace(/\D/g, '')
                                            )
                                          }
                                          value={
                                            inventory[variantIndex].pricing[
                                              pricingIndex
                                            ].dayPrices[dayIndex].extraPrices[
                                              extraPriceIndex
                                            ].price
                                          }
                                          style={{ flexGrow: 1, marginRight: '10px' }}
                                        />
                                      )
                                    )}
                                </div>
                              </div>
                            ))}
                          </>
                        ))}
                      </div>
                    ))}
                  <div className='flex items-center gap-4'>
                    <label className='m-0'>Do you want to update live inventory?</label>
                    <input
                      type="checkbox"
                      checked={updateLiveInventory}
                      onChange={(e) => setUpdateLiveInventory(e.target.checked)}
                    />
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    style={{ marginTop: '20px' }}
                  >
                    Save
                  </Button>
                </div>
                  : <div>
                    {selectedPartner && activityId && selectedPlaceId &&
                      <h1>Loading...</h1>}
                  </div>}
              </>}
            {selectedPartner && PASSPRT_PARTNER_ID !== selectedPartner && <div>
              <h1 className='font-bold mt-4'>Copy inventory from Passprt</h1>
              <Button
                variant="contained"
                className="bg-red-400"
                onClick={handleCopyFromPassprt}
                style={{ marginTop: '20px' }}
              >
                Copy
              </Button>
            </div>}
          </div>
        </Container>
      </Page>
    </>
  );
};

export default UpdateMasterInventoryPage;
