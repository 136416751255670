/* eslint-disable */

import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  Autocomplete,
  Card,
  CardContent,
} from '@mui/material';
import { Container, Typography } from '@mui/material';
import Page from '../../components/Page';
import axios from 'axios';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import PropTypes from 'prop-types';
import { API_URL } from 'src/utils/constants';
import { Margin } from '@mui/icons-material';

export default function CreateBookingPage({ id, booking }) {
  const [bookingData, setBookingData] = useState({
    activityName: '',
    variantName: '',
    startDate: '',
    endDate: '',
    total: 0,
  });
  const [activities, setActivities] = useState([]);
  const [activity, setActivity] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [activityVariants, setActivityVariants] = useState([]);

  useEffect(() => {
    const fetchActivities = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${API_URL}/activity/mb?bookingType=passprt-managed&status=live&placeId=p2975b6`
        );
        const fetchedActivities = response.data.map((activity) => ({
          id: activity.id,
          slug: activity.slug,
          title: activity.title,
          label: activity.label,
          priceLabel: activity.priceLabel,
        }));

        setActivities(fetchedActivities);
      } catch (error) {
        console.error('Error fetching activities:', error);
      }
      setIsLoading(false);
    };

    fetchActivities();
  }, []);

  const handleActivityChange = async (_, newValue) => {
    try {
      const { id: activityID, slug: activitySlug } = newValue;
      const date = bookingData.startDate
      const response = await axios.get(`${API_URL}/activity/${activityID}/variants?date=${date}`);
      setActivityVariants(response.data);
    } catch (error) {
      console.error('Error fetching activity variants:', error);
      setActivity({});
    }
  };

  const handleChange = (e) => {
    setBookingData({ ...bookingData, [e.target.name]: e.target.value });
  };

  //   const handleAddBooking = () => {
  //     setBookingData({ ...bookingData, total: bookingData.total + bookingData.adultPrice });
  //   };

  const handleCreateOrder = async () => {
    // Find the selected activity and variant
    const selectedActivity = activities.find(
      (activity) => activity.title === bookingData.activityName
    );
    const selectedVariant = activityVariants.find(
      (variant) => variant.title === bookingData.variantName
    );

    if (!selectedActivity || !selectedVariant) {
      console.error('Selected activity or variant not found');
      return;
    }

    // Prepare the data to be sent
    const orderData = {
      bookings: [
        {
          activityId: selectedActivity.slug,
          activitySlug: selectedActivity.slug,
          activityTitle: selectedActivity.title,
          thumbnail: selectedActivity.thumbnail || '',
          totalPrice: bookingData.total,
          date: new Date(bookingData.date).toISOString(),
          dateLabel: new Date(bookingData.date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }),

          variants: [
            {
              variantId: selectedVariant.id,
              variantTitle: selectedVariant.title,
              pricing: [
                {
                  quantity: bookingData.total / selectedVariant.price,
                  title: 'Person',
                },
              ],
            },
          ],
          startDate: new Date(bookingData.date).toISOString(),
        },
      ],
      user: {
        phone: bookingData.phone,
        name: bookingData.name,
      },
      paymentMethod: 'razorpay-gateway-and-link',
      amountFinal: bookingData.total, // Assuming no discounts for now
      amountTotal: bookingData.total,
      amountDiscount: 0, // Assuming no discounts for now
      coupon: '', // Assuming no coupon for now
    };

    try {
      // Sending POST request to the server with the orderData
      const response = await axios.post(`${API_URL}/order`, orderData);
      console.log('Order response:', response.data);
      // Handle further logic here, like redirecting to a thank you page or resetting the form
    } catch (error) {
      console.error('Error creating order:', error);
    }
  };

  const Pricing = ({ price, onAdd, onRemove, count }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          my: 2,
        }}
      >
        <Box>
          <Typography variant="subtitle1">Person</Typography>
          <Typography variant="caption" color="text.secondary">
            Above 14 years old
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button size="small" onClick={onRemove} disabled={count <= 1}>
            <RemoveCircleOutlineIcon />
          </Button>
          <Typography sx={{ marginX: 1 }}>{count}</Typography>
          <Button size="small" onClick={onAdd}>
            <AddCircleOutlineIcon />
          </Button>
          <Typography>₹{price}</Typography>
        </Box>
      </Box>
    );
  };

  Pricing.propTypes = {
    price: PropTypes.number.isRequired,
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    count: PropTypes.number.isRequired,
  };

  Pricing.defaultProps = {
    count: 1, // Assuming 1 as default count if not passed
  };

  return (
    <Page title="Create Booking | Travel App">
      <Container>
        <Card>
          <CardContent
            className="cardContent-padding"
            style={{ margin: '20px 0px' }}
          >
            <Typography variant="h5">Booking: {id}</Typography>

            <div className='flex items-center justify-start gap-4 min-w-full'>
              <div className='flex flex-col items-start justify-center'>
                <label>Activity Name</label>
                <Autocomplete
                  id="combo-box-demo"
                  options={activities}
                  sx={{ width: 340 }}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={handleActivityChange}
                />
              </div>
              <div className='flex flex-col items-start justify-center'>
                <label>Start Date</label>
                <TextField
                  id="date"
                  type="date"
                  name="startDate"
                  value={bookingData.startDate}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className='flex flex-col items-start justify-center'>
                <label>End Date</label>
                <TextField
                  id="date"
                  type="date"
                  name="endDate"
                  value={bookingData.endDate}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
            {activityVariants &&
              <FormControl fullWidth>
                <label>Variants</label>
                <Select
                  fullWidth
                  labelId="variant-name-label"
                  name="variantName"
                  value={bookingData.variantName}
                  label="Variant Name"
                  onChange={handleChange}
                  disabled={!activityVariants.length}
                >
                  {activityVariants.map((variant) => (
                    <MenuItem
                      fullWidth
                      key={variant.id} value={variant.title}>
                      {variant.title} - {variant.desc} (₹{variant.price})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            }

            {/* Add Date Selector */}

            <label>Pricing</label>
            {/* <Pricing
              price={bookingData.adultPrice}
              count={bookingData.total / bookingData.adultPrice} // assuming this is the count of adults
              onAdd={() =>
                setBookingData({
                  ...bookingData,
                  total: bookingData.total + bookingData.adultPrice,
                })
              }
              onRemove={() =>
                setBookingData({
                  ...bookingData,
                  total: bookingData.total - bookingData.adultPrice,
                })
              }
            /> */}
            <Typography variant="h6">Total: ₹ {bookingData.total}</Typography>

            <Button
              onClick={handleCreateOrder}
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              Create Order
            </Button>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
