export const conversation = {
  HELLO: [
    {
      from: 'Laila',
      type: 'text',
      content: `Hey traveler, I'm Laila, your personal travel assistant. What exciting adventure are you planning next?`,
      audioFile:
        'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/HELLO/laila/Laila1.mp3',
    },
    {
      from: 'Traveller',
      type: 'text',
      content: `I’m planning a 5-day trip to the mountains with my elderly parents. I’d like a biking adventure too, so I’m thinking of Spiti or Ladakh.`,
      audioFile:
        'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/HELLO/traveller/Traveller1.mp3',
    },
    {
      from: 'Laila',
      type: 'text',
      content:
        'That sounds amazing! For which dates are you planning your trip?',
      audioFile:
        'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/HELLO/laila/Laila2.mp3',
    },
    {
      from: 'Traveller',
      type: 'text',
      content: `Next week, around 15th August.`,
      audioFile:
        'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/HELLO/traveller/Traveller2.mp3',
    },
    {
      from: 'Laila',
      type: 'text',
      content: `I’d recommend Ladakh for a couple of reasons:
\n&nbsp;
\nFirst, the best route to Spiti is via Manali, which is currently experiencing heavy rainfall, as reported by the Times of India [on 12th August](https://timesofindia.indiatimes.com/city/manali/manali-experiences-heavy-rainfall/articleshow/102470408.cms). Last year, this resulted in major landslides.
\n&nbsp;
\nSecond, Ladakh has direct flights from Delhi, which would be much easier on your parents.`,
      audioFile:
        'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/HELLO/laila/Laila3.mp3',
    },

    // Partial bookings
    {
      from: 'Traveller',
      type: 'text',
      content:
        'I’m a bit worried about how my parents will handle the high altitude in Ladakh. I’m thinking of booking just the first 2 days and deciding on the rest once we’re there, depending on how they feel.',
      audioFile:
        'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/HELLO/traveller/Traveller3.mp3',
    },
    {
      from: 'Laila',
      type: 'text',
      content:
        'That sounds like a good plan. I can help you with the bookings for the first 2 days, and you can always reach out to me while you’re in Ladakh for the rest. What would you like to book first?',
      audioFile:
        'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/HELLO/laila/Laila4.mp3',
    },

    // Hotel bookings`
    {
      from: 'Traveller',
      type: 'text',
      content:
        'Please suggest stay options in Leh. My budget is around ₹8000 per night for the three of us. If I get something special, I don’t mind spending 5-6 thousand extra for one of the days.',
      audioFile:
        'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/HELLO/traveller/Traveller4.mp3',
    },
    {
      from: 'Laila',
      type: 'text',
      content: 'Here are the best options I found for you, all within or close to your budget:',
      audioFile:
        'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/HELLO/laila/Laila5.mp3',
    },

    {
      from: 'Laila',
      type: 'carousel',
      content: 'Hotel options',
      items: [
        {
          title: 'Grand Himalaya Exotic Luxury Stay',
          subTitle: 'Best views of the Himalayas',
          url: 'https://www.passprt.co/stays/ajheoi8-grand-himalaya-exotic-luxury-stay',
          priceLabel: 'From ₹7,125',
          labelType: 'instagram-trending',
          mimeType: 'video',
          poster: 'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/assets/images/grand_himalaya_exotic_luxury_stay.png',
          src: 'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/assets/grand-himalaya-exotic-luxury-stay.mp4',
        },
        {
          title: 'The Indus River Camp',
          url: 'https://www.passprt.co/stays/aj8rra2-the-indus-river-camp',
          mimeType: 'image',
          subTitle: '40-acre natural riverside sanctuary',
          priceLabel: 'From ₹11,645',
          src: 'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/assets/images/the_indus_river_camp.jpg',
        },
        {
          title: 'Essence at Ladakh',
          labelType: 'instagram-trending',
          url: 'https://www.passprt.co/stays/afc9afq-essence-at-ladakh',
          subTitle: 'Traditional style luxury cottages',
          priceLabel: 'From ₹10,500',
          mimeType: 'video',
          poster: 'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/assets/images/essence_at_ladakh.png',
          src: 'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/assets/essence-at-ladakh.mp4',
        },
        {
          title: 'Ashoka Ladakh ',
          labelType: 'instagram-trending',
          url: 'https://www.passprt.co/stays/aj8rra2-the-indus-river-camp',
          mimeType: 'image',
          subTitle: 'Ideal spot for family visits',
          priceLabel: 'From ₹8,645',
          src: 'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/assets/images/ashoka_ladakh.jpg',
        },
        {
          title: 'The Highland Ladakh',
          url: 'https://www.passprt.co/stays/afc9afq-essence-at-ladakh',
          subTitle: 'Get the celebrity treatment',
          priceLabel: 'From ₹11,367',
          mimeType: 'image',
          src: 'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/assets/images/the_highland_ladakh.jpg',
        },
        {
          title: 'Caravan Center',
          subTitle: 'A garden retreat',
          priceLabel: 'From ₹9,368',
          mimeType: 'image',
          src: 'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/assets/images/caravan_center.jpg',
        },
        {
          type: 'see-all',
          title: 'See all',
          subTitle: 'Duration: 2 hours',
          priceLabel: 'From ₹1,500 per person',
          mimeType: 'image',
          src: 'https://www.passprt.co/d/Ladakh/experiences',
        },
      ],

      audioFile:
        'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/Blank.mp3',
    },

    {
      from: 'Traveller',
      type: 'text',
      content:
        'Looks good, but are there any stays with telescopes for stargazing in Leh?',
      audioFile:
        'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/HELLO/traveller/Traveller5.mp3',
    },

    {
      from: 'Laila',
      type: 'text',
      content:
        'Yes, here are the stays with telescopes',
      audioFile:
        'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/HELLO/laila/Laila6.mp3',
    },
    {
      from: 'Laila',
      type: 'carousel',
      content: 'Updated Hotel options',
      items: [
        {
          title: 'The Grand Dragon Ladakh',
          labelType: 'instagram-trending',
          url: 'https://www.passprt.co/stays/aj8rra2-the-indus-river-camp',
          mimeType: 'image',
          subTitle: 'Telescope available',
          priceLabel: 'From ₹16,727',
          src: 'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/assets/images/the_grand_dragon_ladakh.png',
        },
        {
          title: 'The Bodhi Tree',
          labelType: 'instagram-trending',
          url: 'https://www.passprt.co/stays/aj8rra2-the-indus-river-camp',
          mimeType: 'image',
          subTitle: 'Telescope available',
          priceLabel: 'From ₹12,125',
          src: 'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/assets/images/the_bodhi_tree.jpg',
        },
        // {
        //   title: 'Grand Himalaya Exotic Luxury Stay',
        //   labelType: 'instagram-trending',
        //   url: 'https://www.passprt.co/stays/ajheoi8-grand-himalaya-exotic-luxury-stay',
        //   subTitle: 'Telescope available',
        //   priceLabel: 'From ₹7,125',
        //   mimeType: 'video',
        //   poster: 'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/assets/images/grand_himalaya_exotic_luxury_stay.png',
        //   src: 'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/assets/grand-himalaya-exotic-luxury-stay.mp4',
        // },
        {
          title: 'Gomang Stays',
          subTitle: 'Telescope available',
          url: 'https://www.passprt.co/stays/aw3gs9v-gomang-hotel',
          priceLabel: 'From ₹14,727',
          mimeType: 'image',
          src: 'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/assets/images/gomang_stays.jpg',
        },
        // {
        //   title: 'Essence at Ladakh',
        //   labelType: 'instagram-trending',
        //   url: 'https://www.passprt.co/stays/afc9afq-essence-at-ladakh',
        //   subTitle: 'Telescope available',
        //   priceLabel: 'From ₹10,500',
        //   mimeType: 'video',
        //   poster: 'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/assets/images/essence_at_ladakh.png',
        //   src: 'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/assets/essence-at-ladakh.mp4',
        // },
        {
          title: 'Caravan Center',
          subTitle: 'Telescope available',
          url: 'https://www.passprt.co/stays/arm628z-caravan-center',
          priceLabel: 'From ₹9,368',
          mimeType: 'image',
          src: 'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/assets/images/caravan_center.jpg',
        },
        // {
        //   title: 'The Indus River Camp',
        //   labelType: 'instagram-trending',
        //   url: 'https://www.passprt.co/stays/aj8rra2-the-indus-river-camp',
        //   mimeType: 'image',
        //   subTitle: 'Telescope available',
        //   priceLabel: 'From ₹11,645',
        //   src: 'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/assets/images/the_indus_river_camp.jpg',
        // },
        {
          type: 'see-all',
          title: 'See all',
          subTitle: 'Duration: 2 hours',
          priceLabel: 'From ₹1,500 per person',
          mimeType: 'image',
          src: 'https://www.passprt.co/d/Ladakh/experiences',
        },
      ],

      audioFile:
        'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/Blank.mp3',
    },

    {
      from: 'Traveller',
      type: 'text',
      content: 'I like Gomang Stays and The Grand Dragon Ladakh. I’m vegan, do either of these provide vegan food?',
      audioFile:
        'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/HELLO/traveller/Traveller6.mp3',
    },

    {
      from: 'Laila',
      type: 'alarm-text',
      content:
        'The Grand Dragon Ladakh has vegan options available on their menu. I’m not entirely sure about Gomang Stays, but I can check with the hotel staff and get back to you. It should only take about 12 minutes.',
      audioFile:
        'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/HELLO/laila/Laila7.mp3',
    },

    // Help with other bookings
    {
      from: 'Laila',
      type: 'text',
      content:
        'In the meantime, would you like me to help with planning local experiences, taxi, and bike rentals?',
      audioFile:
        'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/HELLO/laila/Laila8.mp3',
    },

    // Local experiences
    {
      from: 'Traveller',
      type: 'text',
      content: 'What local experiences do you recommend we try?',
      audioFile:
        'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/HELLO/traveller/Traveller7.mp3',
    },

    {
      from: 'Laila',
      type: 'text',
      content: 'Here are some exciting local experiences',
      audioFile:
        'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/HELLO/laila/Laila9.mp3',
    },

    {
      from: 'Laila',
      type: 'carousel',
      content: 'Local experiences options',
      items: [
        {
          title: `World's Highest Altitude Bungee Jumping`,
          url: 'https://www.passprt.co/activity/arocm3w-65-meter-bungee',
          subTitle: 'Duration: 2 hours',
          priceLabel: '₹4,000 / person',
          mimeType: 'video',
          labelType: 'instagram-trending',
          poster: 'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/assets/images/worlds_highest_altitude_bungee_jumping.png',
          src: 'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/assets/worlds-highest-altitude-bungee-jumping.mp4',
        },
        {
          title: `World's Highest Altitude Rafting`,
          url: 'https://www.passprt.co/activity/a27m1tl-river-rafting-in-leh',
          mimeType: 'image',
          subTitle: 'Duration: 6 hours',
          priceLabel: 'From ₹1,100 / person',
          src: 'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/assets/images/worlds_highest_altitude_rafting.png',
        },
        {
          title: `Thrilling Paragliding`,
          url: 'https://www.passprt.co/activity/atjnojd-paragliding-in-ladakh',
          labelType: 'instagram-trending',
          mimeType: 'video',
          subTitle: 'Duration: 6 hours',
          priceLabel: '₹4,900 / person',
          poster: 'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/assets/images/thrilling_paragliding.png',
          src: 'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/assets/thrilling-paragliding.mp4',
        },
        {
          title: 'Candlelit Dinner',
          url: 'https://www.passprt.co/activity/apvnzrn-candlelit-dinner-at-nubra-valleys-white-sand-dunes',
          subTitle: 'Duration: 4 hours',
          priceLabel: '₹1,500 per person',
          mimeType: 'image',
          src: 'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/assets/images/candle_lit_dinner.png',
        },

        {
          title: 'Ladakhi Live Kitchen',
          url: 'https://www.passprt.co/activity/a74lqo9-ladakhi-live-kitchen-in-hunder',
          subTitle: 'Duration: 2 hours',
          priceLabel: 'From ₹800 per person',
          mimeType: 'image',
          src: 'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/assets/images/ladakhi_live_kitchen.png',
        },

        {
          title: 'Sundowner at Nubra Valley',
          url: 'https://www.passprt.co/activity/a9020b1-sundowner-at-nubra-valley',
          subTitle: 'Duration: 3 hours',
          priceLabel: 'From ₹1,000 per person',
          mimeType: 'image',
          src: 'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/assets/images/sundowner_at_nubra_valley.png',
        },
        {
          title: 'Ladakhi 9 Course Meal',
          url: 'https://www.passprt.co/activity/ah8sqlt-ladakhi-9-course-meal-in-hunder',
          subTitle: 'Duration: 2 hours',
          priceLabel: 'From ₹1,500 per person',
          mimeType: 'image',
          src: 'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/assets/images/ladakhi_9_course_meal.png',
        },
        {
          type: 'see-all',
          title: 'See all',
          subTitle: 'Duration: 2 hours',
          priceLabel: 'From ₹1,500 per person',
          mimeType: 'image',
          src: 'https://www.passprt.co/d/Ladakh/experiences',
        },
      ],

      audioFile:
        'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/Blank.mp3',
    },

    {
      from: 'Traveller',
      type: 'text',
      content: 'Can my parents do rafting?',
      audioFile:
        'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/HELLO/traveller/Traveller8.mp3',
    },

    {
      from: 'Laila',
      type: 'text',
      content: `Rafting in Leh requires good fitness due to the high altitude. Participants should generally be fit enough to comfortably walk 3 kilometers in 30 minutes without feeling overly exhausted. Also, they shouldn't have any prior heart conditions..`,
      audioFile:
        'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/HELLO/laila/Laila10.mp3',
    },

    // taxi bookings
    {
      from: 'Traveller',
      type: 'text',
      content:
        'Got it. I will need a taxi for Day 2. Also, can we include more monasteries on the same day?',
      audioFile:
        'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/HELLO/traveller/Traveller9.mp3',
    },

    {
      from: 'Laila',
      type: 'text',
      content:
        'Absolutely! Along with the Sham Valley tour, you can visit Alchi and Lamayuru Monasteries. Does that sound good?',
      audioFile:
        'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/HELLO/laila/Laila11.mp3',
    },
    {
      from: 'Laila',
      type: 'carousel',
      content: 'Taxi tour options for Day 2',
      items: [
        {
          title: 'Sham Valley Sightseeing Tour',
          mimeType: 'image',
          subTitle: 'Duration: 6 hours',
          priceLabel: 'From ₹3,500',
          src: 'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/assets/images/sham_valley_sightseeing_tour.jpg',
        },
        {
          title: 'Sham Valley Sightseeing Tour with Alchi Monastery',
          subTitle: 'Duration: 8 hours',
          priceLabel: 'From ₹5,000',
          mimeType: 'image',
          src: 'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/assets/images/sham_valley_sightseeing_tour_with_alchi_monasterys.jpg',
        },
        {
          title: 'Sham Valley Sightseeing Tour with Alchi & Lamayuru Monastery',
          subTitle: 'Duration: 10 hours',
          priceLabel: 'From ₹7,000',
          mimeType: 'image',
          src: 'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/assets/images/sham_valley_sightseeing_tour_with_alchi_and_lamayuru_monasterys.jpg',
        },
      ],

      audioFile:
        'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/Blank.mp3',
    },
    {
      from: 'Traveller',
      type: 'text',
      content: 'Can I also visit Hemis Monastery?',
      audioFile:
        'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/HELLO/traveller/Traveller10.mp3',
    },
    {
      from: 'Laila',
      type: 'text',
      content:
        'Hemis Monastery is on the route to Pangong Lake. You can visit it on your way back from Pangong Lake later on. Does that work for you?',
      audioFile:
        'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/HELLO/laila/Laila12.mp3',
    },

    // Bike rental
    {
      from: 'Traveller',
      type: 'text',
      content: `What about the bike? Should I book it just for Day 2 or for the entire trip? I haven't ridden in the mountains before, so I'm a bit unsure.`,
      audioFile:
        'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/HELLO/traveller/Traveller11.mp3',
    },
    {
      from: 'Laila',
      type: 'text',
      content: `The roads from Nubra to Pangong are in rough condition with four water crossings. Since you're with your parents, it might be best to book the bike just for Day 2 and then enjoy the rest of the journey in a taxi with them. Does that sound good?`,
      audioFile:
        'https://passprt-laila-majnu-test.s3.ap-south-1.amazonaws.com/HELLO/laila/Laila13.mp3',
    },
  ],
};
