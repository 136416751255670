import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

import {
  //  useLocation,
  useNavigate
} from 'react-router-dom';
import { API_URL } from 'src/utils/constants';
import axiosMB from 'src/utils/axios/interceptors';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  // const { pathname } = useLocation();
  const navigate = useNavigate();

  const _user = localStorage.getItem("user");
  if (!_user) localStorage.setItem("user", "{}");
  const getUser = () => {
    try { return JSON.parse(_user); }
    catch (error) { return {} }
  }
  const user = getUser()

  // const routeType = pathname.split("/")[1]
  // const userRoutePolicy = {
  //   admin: ['dashboard', 'partner', '404'],
  //   partner: ['partner', '404'],
  // }

  const isUserAllowed = async (user) => {
    const { userType } = user;
    // const isRouteAllowed = userRoutePolicy[userType].includes(routeType);
    const { partnerId, id } = user;
    const verifiedPartner = await authenticateUser({ userType, userId: id, partnerId });
    if (verifiedPartner) {
      if (userType === 'partner') {
        navigate(`/partner/${partnerId}/bookings`)
      } else if (userType === 'admin') {
        setLoading(false);
        return;
      } else {
        navigate("/login");
      }
      setLoading(false);
      return;
    }
    else {
      navigate("/login");
    }
  }

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      isUserAllowed(user)
    } else {
      navigate("/login");
    }

  }, []);


  const authenticateUser = async ({ userType, userId, partnerId }) => {
    try {
      const response = await axiosMB.post(API_URL + "/admin/mb/authenticateUser", { userType, userId, partnerId }, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const success = response.data
      return success;
    } catch (error) {
      console.log(error);
      return false
    }
  }

  if (loading)
    return (<RootStyle>
      <div>Loading...</div>
    </RootStyle>
    );

  return (
    (

      <RootStyle>
        <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
        <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        <MainStyle>
          <Outlet />
        </MainStyle>

      </RootStyle>
    )
  );
}
