// /*eslint-disable no-unused-vars*/
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  IconButton,
  TextField,
  Menu,
  MenuItem,
  FormControlLabel,
  Switch,
  TablePagination,
  Select,
  InputLabel,
  FormControl,
  OutlinedInput,
  Button,
  Checkbox,
  Box,
  Container,

} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Page from 'src/components/Page';
import PageHeading from 'src/components/PageHeading';
import React, { useEffect, useState, useMemo } from 'react';
import { API_URL } from 'src/utils/constants';
import axios from 'axios';
import { Loading } from 'src/components/Loading';
import { AddOrEditCoupon } from './AddOrEditCoupon';
const columns = [
  { id: 'code', label: 'Code', sortable: true, searchable: true },
  { id: 'couponType', label: 'Coupon Type', sortable: true, searchable: true },
  { id: 'status', label: 'Status', sortable: true, searchable: true },
  { id: 'discountType', label: 'Discount Type', sortable: true, searchable: true },
  { id: 'discountValue', label: 'Discount Value', sortable: true, searchable: true },
  { id: 'maxDiscountAmount', label: 'Max Discount Amount', sortable: true, searchable: true },
];

const createData = (id, code, couponType, status, discountType, discountValue, maxDiscountAmount) => {
  return { id, code, couponType, status, discountType, discountValue, maxDiscountAmount };
};


function ListPartnersPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [couponId, setCouponId] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  console.log(couponId)
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${API_URL}/admin/mb/coupons`);
      const data = response.data;
      const formattedData = data.map((row) => createData(
        row.id,
        row.code,
        row.couponType,
        row.status,
        row.discountType,
        row.discountValue,
        row.maxDiscountAmount,
      ));
      setRows(formattedData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Failed to fetch data:', error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
    setCouponId(null);
  };
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('product');
  const [visibleColumnIds, setVisibleColumnIds] = useState(columns.map((col) => col.id));
  const [columnOrder, setColumnOrder] = useState(columns.map((col) => col.id));
  const [searchTerm, setSearchTerm] = useState('');
  const [dense, setDense] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [categoryFilter, setCategoryFilter] = useState('All');
  const [anchorEl, setAnchorEl] = useState(null);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleColumnVisibility = (id) => {
    setVisibleColumnIds((prev) => {
      const newColumnIds = prev.includes(id)
        ? prev.filter((colId) => colId !== id)
        : [...prev, id].sort((a, b) => columns.findIndex(col => col.id === a) - columns.findIndex(col => col.id === b));
      setColumnOrder(newColumnIds);
      return newColumnIds;
    });
  };


  const handleDenseChange = (event) => {
    setDense(event.target.checked);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCategoryChange = (event) => {
    setCategoryFilter(event.target.value);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const filteredRows = useMemo(() => {
    return rows.filter((row) => {
      const matchesSearch = Object.keys(row).some(
        (key) => row[key]?.toString().toLowerCase().includes(searchTerm)
      );
      const matchesCategory = categoryFilter === 'All' || row.status === categoryFilter;
      return matchesSearch && matchesCategory;
    });
  }, [searchTerm, categoryFilter, rows]);

  const sortedRows = useMemo(() => {
    return filteredRows.sort((a, b) => {
      let aValue = a[orderBy];
      let bValue = b[orderBy];

      // Handle different data types for proper sorting
      if (orderBy === 'date') {
        aValue = new Date(aValue);
        bValue = new Date(bValue);
        // } else if (orderBy === 'stock') {
        //     aValue = parseInt(aValue.replace(/\D/g, ''), 10);
        //     bValue = parseInt(bValue.replace(/\D/g, ''), 10);
      } else {
        aValue = aValue?.toString().toLowerCase();
        bValue = bValue?.toString().toLowerCase();
      }

      if (aValue < bValue) return order === 'asc' ? -1 : 1;
      if (aValue > bValue) return order === 'asc' ? 1 : -1;
      return 0;
    });
  }, [filteredRows, orderBy, order, rows]);

  const paginatedRows = useMemo(() => {
    return sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [sortedRows, page, rowsPerPage, filteredRows, orderBy, order, rows]);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <Page title="Coupons List">
      <Container>
        <PageHeading heading="Coupons List" />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 4, gap: 2 }}>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            onChange={handleSearch}
          />

          <FormControl variant="outlined" size="small" sx={{ width: '200px' }}>
            <InputLabel>Status</InputLabel>
            <Select
              value={categoryFilter}
              onChange={handleCategoryChange}
              input={<OutlinedInput label="Category" />}
            >
              <MenuItem value="All">All</MenuItem>
              {Array.from(new Set(rows.map((row) => row.status))).map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControlLabel
            sx={{ m: 0 }}
            control={<Switch checked={dense} onChange={handleDenseChange} />}
            label="Dense padding"
          />
          <IconButton
            aria-label="settings"
            onClick={handleMenuClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {columns.map((column) => (
              <MenuItem key={column.id} onClick={() => handleColumnVisibility(column.id)}>
                <Checkbox
                  checked={visibleColumnIds.includes(column.id)}
                />
                {column.label}
              </MenuItem>
            ))}
          </Menu>
          <Button variant='contained' color="error" onClick={handleClickOpen}>
            Add Coupon
          </Button>
        </Box>

        <TableContainer>
          <Table size={dense ? 'small' : 'medium'}>
            <TableHead>
              <TableRow>
                {columnOrder.map((colId) => {
                  const column = columns.find((col) => col.id === colId);
                  if (!column || !visibleColumnIds.includes(column.id)) return null;
                  return (
                    <TableCell key={column.id}>
                      {column.sortable ? (
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={orderBy === column.id ? order : 'asc'}
                          onClick={(event) => handleRequestSort(event, column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      ) : (
                        column.label
                      )}
                    </TableCell>
                  );
                })}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedRows.map((row, index) => (
                <TableRow key={index} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                  {columnOrder.map((colId) => {
                    const column = columns.find((col) => col.id === colId);
                    if (!column || !visibleColumnIds.includes(column.id)) return null;
                    return (
                      <TableCell key={column.id}>{row[column.id]}</TableCell>
                    );
                  })}
                  <TableCell>
                    <Button
                      onClick={() => {
                        setCouponId(row.id);
                        setOpen(true);
                      }}
                      variant='contained' color="error">
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={sortedRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Container>
      {/* Dialog for Adding Coupon */}
      {open && (
        <AddOrEditCoupon
          isOpen={open}
          couponId={couponId}
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
    </Page>
  );
}

//     </div>
//   );
// }



export default ListPartnersPage;

