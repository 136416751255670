import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo() {
  return (
    <RouterLink to="/">
      <Box className='w-[100px] hover:border-0' component="img" src="/static/logo.png" />
    </RouterLink>
  );
}
