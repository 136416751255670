import { useState } from 'react';
import axios from 'axios';
import './../components/gallery/gallery.css';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, Container, Stack, Typography } from '@mui/material';

import Page from '../components/Page';
import CreateGenericActivityParams from '../components/activityList/createGenericActivityParams';
import { API_URL } from '../utils/constants';
import { ActivityVariant } from '../components/activityList/activityVariant';
import { ActivityBanners } from '../components/banners/activityBanners';

export default function ActivityPage() {
  const [newVariants, setNewVariants] = useState([]);
  const [banners, setBanners] = useState([]);
  const [bannerSections, setBannerSections] = useState({});
  const [activityCommonParams, setActivityCommonParams] = useState({});
  const [isLoading, setLoader] = useState(false);

  const handleActivityBannerChange = (newBanners, bannerSections) => {
    setBanners(newBanners);
    setBannerSections(bannerSections);
  };

  const onVariantChange = (variants) => {
    setNewVariants(variants);
  };

  const openInNewTab = (href) => {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: href,
    }).click();
  };

  const saveFinalData = async () => {
    setLoader(true);
    const body = {
      ...activityCommonParams,
      banners: banners.filter((b) => b != '' && b != ' '),
      bannerSections: bannerSections,
      newVariants: newVariants,
      channel: 'motherboard',
    };
    await axios
      .post(`${API_URL}/activity`, body)
      .then((response) => {
        setLoader(false);
        openInNewTab(`https://www.passprt.co/activity/${response.data.slug}`);
      })
      .catch((error) => console.log(error));
  };

  return (
    <Page title="Add Activity">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Create Activity
        </Typography>
        <CreateGenericActivityParams
          passActivityData={(value) => setActivityCommonParams(value)}
        />
        <ActivityBanners
          handleActivityBannerChange={handleActivityBannerChange}
        />
        <ActivityVariant
          activityType={activityCommonParams.type}
          onVariantChange={onVariantChange}
        />

        <Stack direction="column" sx={{ mt: 5 }}></Stack>

        <div className="finalsave">
          <Button
            className="save"
            disabled={false}
            enabled
            onClick={saveFinalData}
          >
            SAVE
          </Button>
          {isLoading ? <CircularProgress /> : null}
        </div>
      </Container>
    </Page>
  );
}
