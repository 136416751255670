import React, { useEffect, useMemo, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    Modal,
    Box,
} from '@mui/material';
import { API_URL } from 'src/utils/constants';
import PropTypes from 'prop-types';
import { isNull } from 'lodash';
import axios from 'axios';

export const AddOrEditCoupon = (props) => {
    const { isOpen, couponId, onClose } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [couponLoading, setCouponLoading] = useState(false);
    const [validationIds, setValidationIds] = useState([]);
    const [activities, setActivities] = useState([]);
    const [couponData, setCouponData] = useState({
        code: '',
        couponType: '',
        status: '',
        discountType: '',
        minCartAmount: '',
        discountValue: '',
        maxDiscountAmount: '',
        expiry: '',
        maxUsagePerUser: '',
        validationType: '',
    });
    const [findActivities, setFindActivities] = useState('');

    const fetchActivities = async () => {
        try {
            const response = await axios.get(
                `${API_URL}/activity/mb?bookingType=passprt-managed&status=live,draft`
            );
            const fetchedActivities = response.data.map((activity) => ({
                id: activity.id,
                title: activity.label,
                label: activity.label,
            }));
            setActivities(fetchedActivities);
        } catch (error) {
            console.error('Error fetching activities:', error);
        }
    };

    useEffect(() => {
        if (!isNull(couponId)) {
            fetchData();
        }
        fetchActivities();
    }, [couponId]);

    const fetchData = async () => {
        try {
            // Replace 'your_api_endpoint' with the actual endpoint URL
            setCouponLoading(true);
            const response = await fetch(`${API_URL}/admin/mb/coupons/${couponId}`);
            const json = await response.json();
            setCouponData(json);
            setValidationIds(json.validationIds);
            setCouponLoading(false);
        } catch (error) {
            setCouponLoading(false);
            console.error('Failed to fetch data:', error);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setCouponData((prev) => ({ ...prev, [name]: value }));
    };

    const handleUpdate = async () => {
        try {
            setIsLoading(true);
            // Replace 'your_post_api_endpoint' with your actual POST API endpoint
            const response = await fetch(`${API_URL}/admin/mb/coupons/${couponId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...couponData, validationIds }),
            });
            if (response.ok) {
                handleClose(); // Close the dialog on successful POST
                setIsLoading(false);
                location.reload();
                // fetchData(); // Refresh the data on the page
            } else {
                console.error('Failed to submit coupon:', response.statusText);
            }
        } catch (error) {
            console.error('Error submitting coupon:', error);
        }
    };
    const handleCreate = async () => {
        try {
            setIsLoading(true);
            // Replace 'your_post_api_endpoint' with your actual POST API endpoint
            const response = await fetch(`${API_URL}/admin/mb/coupons`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...couponData, validationIds }),
            });
            if (response.ok) {
                handleClose(); // Close the dialog on successful POST
                setIsLoading(false);
                location.reload();
                // fetchData(); // Refresh the data on the page
            } else {
                console.error('Failed to submit coupon:', response.statusText);
            }
        } catch (error) {
            console.error('Error submitting coupon:', error);
        }
    };
    const couponMemo = useMemo(() => {
        const selectedActivities = activities.filter(activity => validationIds.includes(activity.id));
        const unselectedActivities = activities.filter(activity => !validationIds.includes(activity.id) && activity.title.toLowerCase().includes(findActivities.toLowerCase()));

        const renderActivity = (activity) => (
            <MenuItem
                key={activity.id}
                value={activity}
                onClick={() => {
                    if (validationIds.includes(activity.id)) {
                        setValidationIds(prev => prev.filter(item => item !== activity.id));
                    } else {
                        setValidationIds(prev => [...prev, activity.id]);
                    }
                }}
            >
                <Checkbox checked={validationIds.includes(activity.id)} />
                <ListItemText primary={activity.title} />
            </MenuItem>
        );

        return [
            ...selectedActivities.map(renderActivity),
            ...unselectedActivities.map(renderActivity)
        ];
    }, [validationIds, activities, findActivities]);


    // Reset the form
    const handleClose = () => {
        onClose();
        setCouponData({});
        setValidationIds([]);
    };

    return (
        <div>
            {couponLoading ? (
                <Dialog open={isOpen} onClose={handleClose}>
                    <DialogTitle>Loading...</DialogTitle>
                    <DialogContent></DialogContent>
                </Dialog>
            ) : (
                <Modal
                    open={isOpen} onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        className='absolute flex flex-col py-6 p-4 bg-white rounded-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[90vw] h-full max-h-[90vh]
               overflow-scroll
              '
                    >
                        <TextField
                            margin="dense"
                            name="code"
                            placeholder="Code"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={couponData.code}
                            onChange={handleInputChange}
                        />
                        <div className="grid grid-cols-2 gap-x-3">
                            <label className="text-gray-700 font-semibold whitespace-nowrap">
                                Coupon Type
                            </label>
                            <Select
                                margin="dense"
                                name="couponType"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={couponData.couponType}
                                onChange={handleInputChange}
                            >
                                <MenuItem value="activity">Activity</MenuItem>
                                <MenuItem value="user">User</MenuItem>
                            </Select>
                            <label className="text-gray-700 font-semibold whitespace-nowrap">
                                Coupon Status
                            </label>
                            <Select
                                margin="dense"
                                name="status"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={couponData.status}
                                onChange={handleInputChange}
                            >
                                <MenuItem value="active">Active</MenuItem>
                                <MenuItem value="inactive">Inactive</MenuItem>
                            </Select>
                            <label className="text-gray-700 font-semibold whitespace-nowrap">
                                Discount Type
                            </label>
                            <Select
                                margin="dense"
                                name="discountType"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={couponData.discountType}
                                onChange={handleInputChange}
                            >
                                <MenuItem value="fixed">Fixed</MenuItem>
                                <MenuItem value="percentage">Percentage</MenuItem>
                            </Select>
                            <label className="text-gray-700 font-semibold whitespace-nowrap">
                                Discount Value
                            </label>
                            <TextField
                                margin="dense"
                                name="discountValue"
                                placeholder="Discount Value"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={couponData.discountValue}
                                onChange={handleInputChange}
                            />
                            <label className="text-gray-700 font-semibold whitespace-nowrap">
                                Min Cart Amount
                            </label>
                            <TextField
                                margin="dense"
                                name="minCartAmount"
                                placeholder="Min Cart Amount"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={couponData.minCartAmount}
                                onChange={handleInputChange}
                            />

                            <label className="text-gray-700 font-semibold whitespace-nowrap">
                                Max Discount Amount
                            </label>
                            <TextField
                                margin="dense"
                                name="maxDiscountAmount"
                                placeholder="Max Discount Amount"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={couponData.maxDiscountAmount}
                                onChange={handleInputChange}
                            />
                            <label className="text-gray-700 font-semibold whitespace-nowrap">
                                Expiry
                            </label>
                            <input
                                type="date"
                                name="expiry"
                                value={couponData.expiry}
                                onChange={handleInputChange}
                            />
                            {/* <TextField margin="dense" name="expiry" placeholder="Expiry" type="text" fullWidth variant="standard" value={couponData.expiry} onChange={handleInputChange} /> */}

                            <label className="text-gray-700 font-semibold whitespace-nowrap">
                                Max Usage Per User
                            </label>
                            <TextField
                                margin="dense"
                                name="maxUsagePerUser"
                                placeholder="Max Usage Per User"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={couponData.maxUsagePerUser}
                                onChange={handleInputChange}
                            />
                            <label className="text-gray-700 font-semibold whitespace-nowrap">
                                Validation Type
                            </label>
                            <Select
                                margin="dense"
                                name="validationType"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={couponData.validationType}
                                onChange={handleInputChange}
                            >
                                <MenuItem value="blacklist">Blacklist</MenuItem>
                                <MenuItem value="whitelist">Whitelist</MenuItem>
                            </Select>
                            <div className="col-span-2 flex items-end gap-4 mt-4">
                                <label className="text-gray-700 whitespace-nowrap font-bold">
                                    Search activities
                                </label>
                                <TextField
                                    margin="dense"
                                    name="findActivities"
                                    placeholder="Find Activities"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    value={findActivities}
                                    onChange={(e) => setFindActivities(e.target.value)}
                                />
                            </div>
                            <div className="col-span-2 grid grid-cols-2 gap-1">
                                {couponMemo}
                            </div>
                        </div>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button
                                disabled={isLoading}
                                onClick={couponId ? handleUpdate : handleCreate}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </Box>
                </Modal>
            )}
        </div>
    );
};

AddOrEditCoupon.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    couponId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};