/* eslint-disable*/
import { Autocomplete, Box, Container, Modal, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL } from "src/utils/constants";
import PropTypes from 'prop-types';
import { ActivityBanners } from "src/components/banners/activityBanners";

function PlacesListPage() {
    const [places, setPlaces] = useState([]);
    const [addPlaceOpen, setAddPlaceOpen] = useState(false);
    const [selectedPlace, setSelectedPlace] = useState(null);
    useEffect(() => {
        axios.get(`${API_URL}/place/mb?isActive=true`)
            .then((res) => {
                setPlaces(Object.keys(res.data).length > 0 ? res.data : []);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    return (
        <Container>
            <div className="grid grid-cols-1 gap-4">
                <div className="flex items-center justify-between mb-2">
                    <h1 className="font-bold text-xl">Places</h1>
                    <button
                        onClick={() => setAddPlaceOpen(true)}
                        className="bg-[#FF455B] hover:bg-[#da4848] text-white font-extralight p-1.5 px-3 rounded-lg whitespace-nowrap">Add Place</button>
                </div>
                <div className="grid grid-cols-1 gap-4">
                    <div className="grid grid-cols-4 gap-1 font-bold" >
                        <p>Name</p>
                        <h2>Id</h2>
                        <p>type</p>
                        <p className="place-self-center">Action</p>
                    </div>
                    {places?.map((place, index) => (
                        <div
                            className="grid grid-cols-4 gap-1 whitespace-nowrap "
                            key={index}>
                            <p>{place.label}</p>
                            <h2>{place.id}</h2>
                            <p className={`${place.type === 'attraction' ? 'font-bold' : ''}`}>{place.type}</p>
                            <button
                                onClick={() => {
                                    setAddPlaceOpen(true)
                                    setSelectedPlace(place.label)
                                }}
                                className="place-self-center bg-[#FF455B] hover:bg-[#da4848] w-32 text-white font-extralight p-1.5 px-3 rounded-lg whitespace-nowrap"
                            >Edit</button>
                        </div>
                    ))}
                </div>
            </div>
            {addPlaceOpen && (
                <AddOrEditPlace
                    place={selectedPlace}
                    isOpen={addPlaceOpen}
                    onClose={() => {
                        setSelectedPlace(null);
                        setAddPlaceOpen(false);
                    }}
                    places={places}
                />
            )}
        </Container>
    );
}

const booleanSelect = [
    'true',
    'false',
]

const placeType = [
    'attraction',
    'region_level_3',
    'region_level_4',
    'region_level_5',
    'region_level_6',
]

const placeStatus = [
    'soft-deleted',
]
const AddOrEditPlace = ({ place, isOpen, onClose, places }) => {
    const [newPlace, setNewPlace] = useState({
        name: '',
        description: '',
        path: '',
        pathLabel: '',
        thumbnails: [],
        pthumbnails: [],
        type: '',
        // status: null,
        locationCoordinateType: 'Point',
        longitude: '',
        latitude: '',
        isActive: true,
        isLive: true
    });

    const [initialUrls, setInitialUrls] = useState({});
    const [thumbnail, setThumbnail] = useState({});
    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'latitude' || name === 'longitude') {
            const numValue = value.replace(/[^0-9.+-]/g, '');
            setNewPlace((prev) => ({ ...prev, [name]: numValue }));
        } else {
            setNewPlace((prev) => ({ ...prev, [name]: value }));
        }
    };
    useEffect(() => {
        if (place) {
            axios
                .get(`${API_URL}/place/${place}`)
                .then((res) => {
                    const { id, name, thumbnails, pthumbnails, description, location, path, isActive, isLive, type } = res.data;
                    setInitialUrls({ thumbnails: thumbnails.map(item => item.src), pthumbnails: pthumbnails.map(item => item.src) });
                    setNewPlace((prev) => ({
                        ...prev,
                        id,
                        name,
                        description,
                        path,
                        type,
                        pathLabel: places.filter(item => item.path === path)[0]?.label || '',
                        thumbnails,
                        pthumbnails,
                        locationCoordinateType: location?.type || 'Point',
                        longitude: location?.coordinates[0] || '',
                        latitude: location?.coordinates[1] || '',
                        isActive: isActive || false,
                        isLive: isLive || false
                    }));
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, []);


    useEffect(() => {
        setNewPlace((prev) => ({ ...prev, thumbnails: thumbnail.thumbnail, pthumbnails: thumbnail.pthumbnail }));
    }, [thumbnail]);


    const handleUpdate = async () => {
        try {
            const updatePlace = {
                ...newPlace,
                location: {
                    type: newPlace.locationCoordinateType,
                    coordinates: [parseFloat(newPlace.longitude), parseFloat(newPlace.latitude)],
                }
            }
            updatePlace.latitude = undefined
            updatePlace.longitude = undefined
            updatePlace.locationCoordinateType = undefined
            updatePlace.pathLabel = undefined
            const response = await fetch(`${API_URL}/place/${updatePlace.id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...updatePlace }),
            });
            if (response.ok) {
                location.reload();
            } else {
                console.error('Failed to submit place:', response.statusText);
            }
        } catch (error) {
            console.error('Error submitting place:', error);
        }
        return
    };
    const handleCreate = async () => {
        const createPlace = {
            ...newPlace,
            location: {
                type: newPlace.locationCoordinateType,
                coordinates: [parseFloat(newPlace.longitude), parseFloat(newPlace.latitude)],
            }
        }
        createPlace.latitude = undefined
        createPlace.longitude = undefined
        createPlace.locationCoordinateType = undefined
        createPlace.pathLabel = undefined

        try {
            const response = await fetch(`${API_URL}/place`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...createPlace }),
            });
            if (response.ok) {
                location.reload();
            } else {
                console.error('Failed to submit place:', response.statusText);
            }
        } catch (error) {
            console.error('Error submitting place:', error);
        }
    };
    const handleBannerChange = (event) => {
        const { name, value } = event.target;
        setThumbnail((prev) => ({ ...prev, [name]: value }));
    };
    return (
        <Modal open={isOpen} onClose={onClose} aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box
                className='absolute flex flex-col py-6 p-4 bg-white rounded-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-[90vw] h-full max-h-[90vh] overflow-y-scroll'>
                <label className="font-bold">Name</label>
                <input className="min-h-[45px]" type="text" name="name" placeholder="Place name" value={newPlace.name} onChange={handleChange} />
                <label className="font-bold">Description</label>
                <textarea className="min-h-[100px] border-2" type="text" name="description" placeholder="Place description" value={newPlace.description} onChange={handleChange} />
                {/* Path */}
                <label className="font-bold">Path</label>
                <Autocomplete
                    disabled={place ? true : false}
                    id="combo-box-demo"
                    options={places}
                    // sx={{ width: 340 }}
                    renderInput={(params) => <TextField {...params} label="Location Path" />}
                    onChange={(_, newValue) => {
                        if (newValue) {
                            setNewPlace(prev => ({ ...prev, path: newValue.path, pathLabel: newValue.label }));
                        } else {
                            setNewPlace(prev => ({ ...prev, path: '', pathLabel: '' }));
                        }
                    }}
                    value={newPlace.pathLabel}
                />
                {/* Type */}
                <label className="font-bold">Type</label>
                <Autocomplete
                    disabled={place ? true : false}
                    id="combo-box-demo"
                    options={placeType}
                    // sx={{ width: 340 }}
                    renderInput={(params) => <TextField {...params} label="Location Type" />}
                    onChange={(_, newValue) => {
                        if (newValue) {
                            setNewPlace(prev => ({ ...prev, type: newValue }));
                        } else {
                            setNewPlace(prev => ({ ...prev, type: '' }));
                        }
                    }}
                    value={newPlace.type}
                />
                {/* Status */}
                <label className="font-bold">Status</label>
                <Autocomplete
                    disabled={place ? true : false}
                    id="combo-box-demo"
                    options={placeStatus}
                    // sx={{ width: 340 }}
                    renderInput={(params) => <TextField {...params} label="Location Type" />}
                    onChange={(_, newValue) => {
                        if (newValue) {
                            setNewPlace(prev => ({ ...prev, status: newValue }));
                        } else {
                            setNewPlace(prev => ({ ...prev, status: null }));
                        }
                    }}
                    value={newPlace.status}
                />
                <div className="grid grid-cols-2 gap-4">
                    <label className="font-bold">Is Active</label>
                    <label className="font-bold">Is Live</label>
                    <Autocomplete
                        id="combo-box-demo"
                        options={booleanSelect}
                        // sx={{ width: 340 }}
                        renderInput={(params) => <TextField {...params} label="Is Active" />}
                        onChange={(_, newValue) => {
                            if (newValue) {
                                handleChange({ target: { name: 'isActive', value: newValue } });
                            } else {
                                handleChange({ target: { name: 'isActive', value: false } });
                            }
                        }}
                        value={newPlace.isActive}
                    />
                    <Autocomplete
                        id="combo-box-demo"
                        options={booleanSelect}
                        // sx={{ width: 340 }}
                        renderInput={(params) => <TextField {...params} label="Is Live" />}
                        onChange={(_, newValue) => {
                            if (newValue) {
                                handleChange({ target: { name: 'isLive', value: newValue } });
                            } else {
                                handleChange({ target: { name: 'isLive', value: false } });
                            }
                        }}
                        value={newPlace.isLive}
                    />


                </div>
                <div className="grid grid-cols-3 gap-4">
                    <label className="font-bold">Coordinate type</label>
                    <label className="font-bold">latitude</label>
                    <label className="font-bold">longitude</label>
                    <input type="text" name="locationCoordinateType" placeholder="Location Coordinate Type" value={newPlace.locationCoordinateType} disabled={true} />
                    <input type="text" name="latitude" placeholder="Latitude" value={newPlace.latitude} onChange={handleChange} />
                    <input type="text" name="longitude" placeholder="Longitude" value={newPlace.longitude} onChange={handleChange} />
                </div>
                <div className="grid grid-cols-4 gap-4 mt-4">
                    <div className="grid grid-cols-1">
                        <label className=" font-bold -p-10">Thumbnail</label>
                        <ActivityBanners handleActivityBannerChange={(banners) => {
                            handleBannerChange({ target: { name: 'thumbnail', value: banners.map(item => ({ src: item })) } });
                        }}
                            initialUrls={initialUrls?.thumbnails && initialUrls?.thumbnails.length > 0 ? initialUrls?.thumbnails.join('\n') : null}
                        />
                    </div>
                    {/* Thumbnails */}
                    <div className="grid grid-cols-1 justify-items-start">
                        <label className=" font-bold -p-10">Action URL</label>
                        {newPlace.thumbnails?.map((item, index) => (
                            <div className="flex items-center gap-2" key={`${item.src}+${index}`}>
                                <p>{index + 1}</p>
                                <input
                                    value={item.actionUrl}
                                    onChange={(e) => {
                                        const changedBanner = newPlace.thumbnails[index];
                                        changedBanner.actionUrl = e.target.value;
                                        handleChange({ target: { name: 'thumbnails', value: newPlace.thumbnails.map((item, index) => item === index ? changedBanner : item) } });
                                    }}
                                    className="w-full" type="text" /></div>
                        ))}
                    </div>
                    <div className="grid grid-cols-1">
                        <label className=" font-bold -p-10">Phone Thumbnail</label>
                        <ActivityBanners handleActivityBannerChange={(banners) => {
                            handleBannerChange({ target: { name: 'pthumbnail', value: banners.map(item => ({ src: item })) } });
                        }}
                            initialUrls={initialUrls?.pthumbnails && initialUrls?.pthumbnails?.length > 0 ? initialUrls?.pthumbnails.join('\n') : null}
                        />
                    </div>
                    <div className="grid grid-cols-1 justify-items-start">
                        <label className=" font-bold -p-10">PhoneAction URL</label>
                        {newPlace.pthumbnails?.map((item, index) => (
                            <div className="flex items-center gap-2" key={`${item.src}-${index}`}>
                                <p>{index + 1}</p>
                                <input
                                    value={item.actionUrl}
                                    onChange={(e) => {
                                        const changedBanner = newPlace.pthumbnails[index];
                                        changedBanner.actionUrl = e.target.value;
                                        handleChange({ target: { name: 'pthumbnails', value: newPlace.pthumbnails.map((item, index) => item === index ? changedBanner : item) } });
                                    }}
                                    className="w-full" type="text" /></div>
                        ))}
                    </div>
                </div>

                {/* <input type="text" name="path" placeholder="Path" value={newPlace.path} onChange={handleChange} /> */}
                <div className="col-span-2 flex items-end gap-4 mt-4">
                    {place ?
                        (<button
                            onClick={handleUpdate}
                            className="bg-[#FF455B] hover:bg-[#da4848] text-white font-extralight p-1.5 px-3 rounded-lg whitespace-nowrap"
                        >Update</button>) :
                        (<button
                            onClick={handleCreate}
                            className="bg-[#FF455B] hover:bg-[#da4848] text-white font-extralight p-1.5 px-3 rounded-lg whitespace-nowrap"
                        >Create</button>)
                    }
                </div>
            </Box>
        </Modal>
    );
};

AddOrEditPlace.propTypes = {
    place: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    places: PropTypes.array.isRequired,
};

export default PlacesListPage;