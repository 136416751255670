import React, { useState } from 'react';
import {
    Button,
    TextField,
    Box,
} from '@mui/material';
import PropTypes from 'prop-types';
import { UploadLegalDocuments } from 'src/components/buttons/uploadLegalDocuments';

export const LegalDocumentsUploader = ({ path, documents, setDocuments }) => {
    const [newDocument, setNewDocument] = useState({
        title: '',
        documentType: '',
        mimeType: '',
        url: '',
        uploadDate: '',
    });
    const [uploadedFileName, setUploadedFileName] = useState(null);
    const [error, setError] = useState(null);
    const onUploadSuccess = (data) => {
        setNewDocument((prev) => ({
            ...prev,
            url: data.url,
            mimeType: data.fileType
        }));
        setUploadedFileName(data.fileName);
        if (newDocument.title && newDocument.documentType) {
            setError(null);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setNewDocument((prev) => ({ ...prev, [name]: value }));
    };

    const handleAddDocument = () => {
        if (newDocument.title &&
            newDocument.documentType &&
            newDocument.mimeType &&
            newDocument.url
        ) {
            setDocuments((prev) => [...prev, { ...newDocument, uploadDate: new Date() }]);
            setNewDocument({
                title: '',
                documentType: '',
                mimeType: '',
                url: '',
                uploadDate: '',
            });
            setUploadedFileName(null);
        } else {
            if (newDocument.title && newDocument.documentType) {
                setError('Please upload the docuemnt.');
            } else {
                setError('Please fill all the fields');
            }
        }
    };
    if (!path) {
        return <div>
            <h3 className="text-lg font-semibold">Legal Documents</h3>
            <h1 className='text-red-500'>Please provide a path</h1>
        </div>
    }
    return (
        <Box mb={2}>
            <h3 className="text-lg font-semibold">Legal Documents</h3>
            <div className='w-full grid grid-cols-2 gap-4 my-3'>
                {documents?.map((doc, index) => (
                    <div className='grid grid-cols-1 place-items-center gap-2 p-2 border rounded-lg' key={index} >
                        <div className='flex items-center justify-between gap-2 w-full'>
                            <div className='flex flex-col items-start text-sm'>
                                <h1 className='text-lg font-semibold m-0'>{doc.title}</h1>
                                <p>Type: {doc.documentType}</p>
                                <p>{new Date(doc.uploadDate).toLocaleDateString()}</p>
                            </div>
                            <button
                                className='bg-red-400 hover:bg-red-500 text-white px-2 py-1 rounded-lg'
                                onClick={() => {
                                    const currentIndex = documents.findIndex((doc, ind) => ind === index);
                                    setDocuments((prev) => {
                                        const newDocuments = [...prev];
                                        newDocuments.splice(currentIndex, 1);
                                        return newDocuments;
                                    });
                                }}>
                                Remove
                            </button>
                        </div>
                        <img
                            className='aspect-square w-52'
                            src={doc.url}
                            alt="uploaded-image" />
                    </div>
                ))}
            </div>
            <Box mb={2}>
                <TextField
                    name="title"
                    label="Title"
                    value={newDocument.title}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    name="documentType"
                    label="Document Type"
                    value={newDocument.documentType}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                />
                {/* <TextField
                    name="url"
                    label="URL"
                    value={newDocument.url}
                    onChange={handleInputChange}
                    fullWidth
                    disabled
                    margin="normal"
                /> */}
                {newDocument.url && uploadedFileName && (
                    <div>
                        <h1 className='my-2'>Document uploaded successfully - <span className='font-semibold underline'>{uploadedFileName}</span></h1>
                    </div>)}
                {error && (
                    <div>
                        <h1 className='my-2 text-red-400'>{error}</h1>
                    </div>)}
                <div className='flex items-center justify-between gap-2'>
                    <UploadLegalDocuments
                        path={path}
                        title="Upload document"
                        onUploadSuccess={onUploadSuccess}
                    />
                    <Button
                        className='bg-red-400'
                        onClick={handleAddDocument} variant="contained" color="primary">
                        + Add
                    </Button>
                </div>
            </Box>
        </Box>
    );
};

LegalDocumentsUploader.propTypes = {
    path: PropTypes.string.isRequired,
    documents: PropTypes.array.isRequired,
    setDocuments: PropTypes.func.isRequired,
};