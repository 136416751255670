import React from 'react';
import { Card, CardHeader, Box, Typography, Grid, Divider } from '@mui/material';
import { green, red } from '@mui/material/colors';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useEffect, useState } from 'react';
import { API_URL } from 'src/utils/constants';
import axios from 'axios';
import { formatCurrency } from 'src/utils/formatCurrency';

export const getWeeklySalesData = async (weeks) => {
  const weeklySalesData = await axios.get(`${API_URL}/admin/mb/dashboard/weeklyReports?weeks=${weeks || 1}`);
  return weeklySalesData.data;
};


const getArrowIcon = (currentCount, previousCount) => {
  if (currentCount > previousCount) {
    return <ArrowUpwardIcon sx={{ color: green[500] }} />;
  } else if (currentCount < previousCount) {
    return <ArrowDownwardIcon sx={{ color: red[500] }} />;
  } else {
    return null;
  }
};

const LastWeekSalesComparison = () => {
  const [currentWeekSales, setCurrentWeekSales] = useState([]);
  const [previousWeekSales, setPreviousWeekSales] = useState([]);
  useEffect(() => {
    const fetchWeeklySalesData = async () => {
      try {
        const response = await getWeeklySalesData(2);
        if (response.length > 1) {
          setCurrentWeekSales(response[0]);
          setPreviousWeekSales(response[1]);
        } else {
          setCurrentWeekSales(response[0]);
          setPreviousWeekSales([]);
        }
      } catch (error) {
        console.error('Failed to fetch weekly sales data:', error);
      }
    };
    fetchWeeklySalesData();
  }, []);


  return (
    <Card>
      <CardHeader title="Weekly Sales Report" subheader="Comparison of current and previous week" />
      <Box sx={{ p: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} spacing={2}>
            <Typography variant="h6">This Week {`(${currentWeekSales?.startDate} - ${currentWeekSales?.endDate})`}</Typography>
            <Typography className='flex items-center justify-between' variant="body1">
              <span>Total Amount: </span>
              <span>{formatCurrency(currentWeekSales?.totalAmount)} {getArrowIcon(currentWeekSales?.totalAmount, previousWeekSales?.totalAmount || 0)}</span>
            </Typography>
            <Typography className='flex items-center justify-between' variant="body1">
              <span>Total Bookings: </span>
              <span> {currentWeekSales?.totalBookings} {getArrowIcon(currentWeekSales?.totalBookings, previousWeekSales?.totalBookings || 0)} </span>
            </Typography>
          </Grid>
          <Divider className='w-full' orientation="horizontal" />
          <Grid item xs={12} spacing={4}>
            <Typography variant="h6">This Week {`(${previousWeekSales.startDate} - ${previousWeekSales.endDate})`}</Typography>
            <Typography className='flex items-center justify-between' variant="body1">
              <span>Total Amount: </span>
              <span>{formatCurrency(previousWeekSales?.totalAmount)}</span>
            </Typography>
            <Typography className='flex items-center justify-between' variant="body1">
              <span>Total Bookings: </span>
              <span> {previousWeekSales?.totalBookings} </span>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default LastWeekSalesComparison;
