import * as Yup from 'yup';
// import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  TextField,
  Stack,
  // Link,
  // Checkbox,
  // IconButton,
  // InputAdornment,
  // FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { API_URL } from 'src/utils/constants';
// component
// import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    passcode: Yup.string().min(24).max(24).max(24).required('passcode is required'),
  });

  const formik = useFormik({
    initialValues: {
      passcode: '',
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      const success = await loginUser(values.passcode)
      if (success) {
        localStorage.setItem("user", JSON.stringify(success));
        if (success.userType === 'partner') {
          navigate(`/partner/${success.partnerId}/bookings`)
        } else {
          navigate('/dashboard/app', { replace: true });
        }
      }
    }
  });
  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  // console.log(values)

  return (
    <FormikProvider value={formik}>
      <Form className='space-y-4' autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack >
          <TextField
            fullWidth
            autoComplete="passcode"
            type="passcode"
            {...getFieldProps('passcode')}
            error={Boolean(touched.passcode && errors.passcode)}
            helperText={touched.passcode && errors.passcode}
          />
        </Stack>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Sign In
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}

const loginUser = async (passcode) => {
  try {
    const response = await axios.post(API_URL + "/admin/mb/login", { userId: passcode }, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const success = response.data
    return success;
  } catch (error) {
    console.log(error);
    return false
  }
}