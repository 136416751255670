import axios from 'axios';
import { API_URL } from 'src/utils/constants';


const axiosMB = axios.create({
    baseURL: API_URL,
});

axiosMB.interceptors.request.use((config) => {
    const user = localStorage.getItem('user');
    const token = JSON.parse(user)?.token || null
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

axiosMB.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
    }
    return Promise.reject(error);
});

export default axiosMB;