export default function Autocomplete(theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          boxShadow: theme.customShadows.z20,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            top: '-20px',
          },
          '&.MuiFormLabel-filled': {
            top: '-20px',
          },
          '&.MuiInputLabel-shrink': {
            top: '-20px',
          },
          '&.MuiInputLabel-root': {
            top: '-20px',
          },
        },
      },
    },
  };
}

// export default function Autocomplete(theme) {
//   return {
//     MuiAutocomplete: {
//       styleOverrides: {
//         paper: {
//           boxShadow: theme.customShadows.z20
//         },

//         inputRoot: {
//           '& .MuiInputLabel-root': {
//             position: 'absolute',
//             pointerEvents: 'none',
//             left: theme.spacing(2),
//             // top: '-200px',
//             top: 'calc(50% - 20px)'
//           },
//           '&:focus': {
//             outline: 'none',
//             top: '-200px'
//           },
//           '&.Mui-focused': {
//             outline: 'none',
//             top: '-200px'
//           }
//         },
//         option: {
//           '&:hover': {
//             backgroundColor: 'red',
//             color: '#fff'
//           }
//         },
//         // popper: {
//         //   '& .MuiPaper-root': {
//         //     boxShadow: theme.customShadows.dropdown,
//         //     borderRadius: theme.shape.borderRadius,
//         //     position: 'absolute',
//         //     right: 0,
//         //     top: '-200px'
//         //   }
//         // }
//       }
//     }
//   };
// }
