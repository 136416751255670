/* eslint-disable no-unused-vars */
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField,
    FormControlLabel,
    Switch,
    TablePagination,
    Box,
    Container,
    Avatar,
    Button,
} from '@mui/material';
import Page from 'src/components/Page';
import PageHeading from 'src/components/PageHeading';
import React, { useEffect, useState, useMemo } from 'react';
import { API_URL } from 'src/utils/constants';
import axios from 'axios';
import { Loading } from 'src/components/Loading';
import { Link } from 'react-router-dom';

const columns = [
    { id: 'id', label: 'Icon ID', sortable: true },
    { id: 'name', label: 'Name', sortable: true, searchable: true },
    { id: 'icon', label: 'Icon', sortable: false, searchable: false },
];

const createData = (id, name, iconUrl) => {
    return { id, name, iconUrl };
};

function FlyIconsPage() {
    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [visibleColumnIds, setVisibleColumnIds] = useState(columns.map((col) => col.id));
    const [columnOrder, setColumnOrder] = useState(columns.map((col) => col.id));
    const [searchTerm, setSearchTerm] = useState('');
    const [dense, setDense] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${API_URL}/admin/mb/flyIcons`);
            const data = response.data;
            const formattedData = data.map((row) =>
                createData(
                    row.id,
                    row.flyIconId,
                    `https://cdn-passprt.s3.ap-south-1.amazonaws.com/assets/flyIcons/${row.flyIconId}`
                )

            );
            setRows(formattedData);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Failed to fetch data:', error);
        }
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const handleDenseChange = (event) => {
        setDense(event.target.checked);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredRows = useMemo(() => {
        return rows.filter((row) => {
            return Object.keys(row).some(
                (key) => row[key]?.toString().toLowerCase().includes(searchTerm)
            );
        });
    }, [searchTerm, rows]);

    const sortedRows = useMemo(() => {
        return filteredRows.sort((a, b) => {
            let aValue = a[orderBy];
            let bValue = b[orderBy];

            // Handle sorting for different data types
            if (orderBy === 'date') {
                aValue = new Date(aValue);
                bValue = new Date(bValue);
            } else {
                aValue = aValue?.toString().toLowerCase();
                bValue = bValue?.toString().toLowerCase();
            }

            if (aValue < bValue) return order === 'asc' ? -1 : 1;
            if (aValue > bValue) return order === 'asc' ? 1 : -1;
            return 0;
        });
    }, [filteredRows, orderBy, order]);

    const paginatedRows = useMemo(() => {
        return sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }, [sortedRows, page, rowsPerPage]);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Page title="FlyIcons List">
            <Container>
                <div className='flex justify-between'>
                    <PageHeading heading="FlyIcons List" />
                    <Link to='create' >
                        <Button
                            color='primary'
                            variant='outlined'>
                            Add
                        </Button>
                    </Link>

                </div>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 4, gap: 2 }}>
                    <TextField
                        label="Search"
                        variant="outlined"
                        size="small"
                        onChange={handleSearch}
                    />

                    <FormControlLabel
                        sx={{ m: 0 }}
                        control={<Switch checked={dense} onChange={handleDenseChange} />}
                        label="Dense padding"
                    />
                </Box>

                <TableContainer>
                    <Table size={dense ? 'small' : 'medium'}>
                        <TableHead>
                            <TableRow>
                                {columnOrder.map((colId) => {
                                    const column = columns.find((col) => col.id === colId);
                                    if (!column || !visibleColumnIds.includes(column.id)) return null;
                                    return (
                                        <TableCell key={column.id}>
                                            {column.sortable ? (
                                                <TableSortLabel
                                                    active={orderBy === column.id}
                                                    direction={orderBy === column.id ? order : 'asc'}
                                                    onClick={(event) => handleRequestSort(event, column.id)}
                                                >
                                                    {column.label}
                                                </TableSortLabel>
                                            ) : (
                                                column.label
                                            )}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedRows.map((row, index) => {
                                console.log(row)
                                return (
                                    <TableRow key={index} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>
                                            <img className='w-20 aspect-square' alt={row.name} src={row.iconUrl} />
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 50, 100]}
                    component="div"
                    count={sortedRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            </Container>
        </Page>
    );
}

export default FlyIconsPage;
