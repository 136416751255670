/* eslint-disable */
import _ from 'lodash';
import React from 'react';
import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import axios from 'axios';
import CircleIcon from '@mui/icons-material/Circle';
import './../components/gallery/gallery.css';
import CircularProgress from '@mui/material/CircularProgress';
import {
    Button,
    Container,
    Stack,
    TextField,
    Typography,
    Grid,
    Card,
    CardHeader,
    CardContent,
    Box,
    InputLabel,
    Select,
    MenuItem,
    Autocomplete,
    Input,
} from '@mui/material';

// components
import Page from '../components/Page';
import ActivityList from '../components/activityList/activityList';
import CreateGenericActivityParams from '../components/activityList/createGenericActivityParams';

import { useParams } from 'react-router-dom';
import { API_URL } from 'src/utils/constants';
import { ActivityBanners } from 'src/components/banners/activityBanners';
import Proptypes from 'prop-types';
import FlyHTMLeditor from 'src/components/FlyHTMLeditor';

const newEmptyItinerary = { dayLabel: '', thumbnail: '', title: '', desc: '', descHtml: '' };

export default function EditInventoryPage() {
    const [activity, setActivity] = useState([]);
    const [itineraryView, setItineraryView] = useState([]);
    const [newVariants, setNewVariants] = useState([]);
    const [productData, setProductData] = useState([]);

    // const [banners, setBanners] = useState([]);
    // const [bannerSections, setBannerSelection] = React.useState([]);
    const [destinations, setDestinations] = useState([]);


    const [activityCommonParams, setActivityCommonParams] = useState({});

    const [textAreaValue, setTextAreaValue] = useState('');
    const [isLoading, setLoader] = useState(false);
    const { slug } = useParams();
    const getActivity = async () => {
        try {
            const res = await axios.get(`${API_URL}/activity/mb/${slug}`);
            setActivity(res.data);
            setItineraryView(res.data.itineraryView);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (!destinations.length) {
            axios
                .get(`${API_URL}/place/mb?isActive=true`)
                .then((res) => {
                    setDestinations(res.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, []);


    useEffect(() => {
        getActivity();
    }, []);

    const onBannerChanged = (event) => {
        const urls = event.target.value;
        const _banners = urls.split('\n');
        // setBanners(_banners);
    };

    const openInNewTab = (href) => {
        Object.assign(document.createElement('a'), {
            target: '_blank',
            rel: 'noopener noreferrer',
            href: href,
        }).click();
    };

    const onItineraryChange = (index, change) => {
        const changedItinerary = itineraryView.map((item, i) => {
            if (i === index) {
                return change;
            }
            return item;
        });
        setItineraryView(changedItinerary);
    };

    const onSelecteActivityChange = (_, newValue) => {
        const _activity = getActivity(newValue.slug);
    };

    const handleActivityBannerChange = (newBanners, bannerSections) => {
        // setBanners(newBanners);
        // setBannerSelection(bannerSections);
    };

    const saveFinalData = async () => {
        setLoader(true);
        try {
            const body = { ...activity, itineraryView }
            const res = await axios
                .put(`${API_URL}/activity/mb`, body)
                .then((response) => {
                    setLoader(false);
                    location.reload();
                });
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <Page title="Edit Itinerary">
            <Container>
                <Typography variant="h4" sx={{ mb: 2 }}>
                    Edit Itinerary {`(${itineraryView?.length})`}
                </Typography>
                <Button
                    sx={{ mb: 2 }}
                    className="bg-red-400 hover:bg-red-500 text-white px-4 py-2 rounded-lg"
                    onClick={() => {
                        const newItinerary = [...itineraryView, newEmptyItinerary];
                        setItineraryView(newItinerary);
                    }}>Add New Itinerary</Button>
                <div className='flex flex-col gap-4'>
                    {itineraryView?.map((item, index) => {
                        // const { dayLabel, thumbnail, title, desc, descHtml } = item;
                        return (<div key={index}>
                            <SingleItineraryView number={index} props={item} destinations={destinations} onItineraryChange={onItineraryChange} />
                        </div>)
                    })}
                </div>
                <div className="finalsave">
                    <Button
                        className="save"
                        disabled={isLoading}
                        enabled
                        onClick={saveFinalData}
                    >
                        SAVE
                    </Button>
                    {isLoading ? <CircularProgress /> : null}
                </div>
            </Container>
        </Page>
    );
}


const SingleItineraryView = ({ props, destinations, number, onItineraryChange }) => {
    const { thumbnail } = props;
    const [itinerary, setItinerary] = useState({ ...props, location: props.location || '' });
    const [descHtml, setDescHtml] = useState({});

    const handleChange = (event) => {
        const { name, value } = event.target;
        setItinerary((prev) => ({ ...prev, [name]: value }));
    };

    const onEditorStateChange = (editorHTML, attribute) => {
        setDescHtml({
            ...descHtml,
            [attribute]: editorHTML,
        });
    };

    useEffect(() => {
        onItineraryChange(number, { ...itinerary, descHtml: descHtml?.descHtml || props?.descHtml });
    }, [itinerary, descHtml]);


    return (<div className='flex flex-col bg-white rounded-xl p-6 border-2 border-gray-200'>
        <div className='flex items-start gap-10'>
            <div className='flex flex-col gap-4'>
                <label className='font-bold'>Day Label</label>
                <Input onChange={handleChange} name='dayLabel' value={itinerary?.dayLabel} />
            </div>
            <div className='flex flex-col'>
                <label className='font-bold'>Destination</label>
                <Autocomplete
                    id="combo-box-demo"
                    options={destinations}
                    sx={{ width: 340 }}
                    name='location'
                    renderInput={(params) => <TextField {...params} label="Destination" />}
                    value={itinerary?.location}
                    onChange={(_, item) => {
                        setItinerary(prev => ({ ...prev, location: item?.label }));
                    }}
                />
            </div>
            <p className='text-xl font-bold ml-auto'>Itinerary #{number + 1}</p>
        </div>
        <div className='flex flex-col'>
            <label className='font-bold'>Title</label>
            <Input onChange={handleChange} name='title' value={itinerary?.title} />
            <label className='font-bold'>Short Desc</label>
            <Input onChange={handleChange} name='desc' value={itinerary?.desc} />
            <label className='font-bold'>Long Desc {`(HTML)`}</label>
            <FlyHTMLeditor
                initialData={itinerary?.descHtml}
                onEditorStateChange={onEditorStateChange}
                attribute={'descHtml'}
            ></FlyHTMLeditor>
        </div>
        <div className='flex flex-col gap-4'>
            <ActivityBanners handleActivityBannerChange={(banners, sections) => {
                handleChange({ target: { name: 'thumbnail', value: banners[0] || props?.thumbnail } });
            }}
                initialUrls={thumbnail} />
        </div>
    </div>);
};
SingleItineraryView.prototype = {
    dayLabel: Proptypes.object,
    destinations: Proptypes.array,
    number: Proptypes.number,
    onItineraryChange: Proptypes.func,
};
