import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import io from "socket.io-client";
import { API_URL } from "src/utils/constants";


export default function LailaClients() {
    const [socket, setSocket] = useState(null);
    const [allConnections, setAllConnections] = useState([]);


    useEffect(() => {
        const initializeSocket = async () => {
            try {
                const ws = await new Promise((resolve, reject) => {
                    const socketInstance = io(API_URL + '/laila');
                    socketInstance.on("connect", () => {
                        resolve(socketInstance);
                    });
                    socketInstance.on("connect_error", (error) => {
                        reject(error);
                    });
                });
                setSocket(ws);
            } catch (error) {
                console.error("Error loading Socket.IO client:", error);
            }
        };
        initializeSocket();
        // Clean up function if needed
        return () => {
            if (socket) {
                socket.disconnect();
            }
        };
    }, []);

    useEffect(() => {
        if (socket) {
            socket.emit("MB:showAll", (data) => {
                console.log(data);
            });
            socket.on("MB:allConnection", (data) => {
                setAllConnections(data);
            });
        }
    }, [socket]);

    return (
        <div>
            <Container>
                <h1 className="text-xl font-bold mb-3">Co-pilot Clients</h1>
                <div className="grid grid-cols-1 gap-4">
                    {Object.keys(allConnections)?.map((connection, index) => {
                        return (
                            <div key={index} className="grid grid-cols-5 gap-4 border-b border-gray-200 ">
                                <span>{index + 1}</span>
                                <h1 className="col-span-2">Socket ID : {connection}</h1>
                                <h2>CODE : {allConnections[connection]}</h2>
                                <Link
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-center"
                                    rel="noreferrer"
                                    target="_blank"
                                    to={`/dashboard/laila-remote/${connection},${allConnections[connection]}`}>Connect</Link>
                            </div>);
                    })}
                </div>
            </Container>
        </div>
    );
}

