import React, {
    useEffect,
    useState
} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Modal,
    Box,
    Autocomplete,
} from '@mui/material';

import { API_URL } from 'src/utils/constants';
import PropTypes from 'prop-types';
import axios from 'axios';
import { fetchPartnersExtract } from 'src/utils/partners/fetchPartnersExtract';
import moment from 'moment';
import { GuestPhotosUploader, PhotosUploader } from './PhotoUploaders';
import { LegalDocumentsUploader } from 'src/components/LegalDocumentUploader';

export const AddOrEditAsset = (props) => {
    const { isOpen, asset, onClose } = props;
    const assetId = asset?.id;
    const [isLoading, setIsLoading] = useState(false);
    // Asset
    const [assetLoading, setAssetLoading] = useState(false);
    const [assetData, setAssetData] = useState({
        partnerId: '',
        sharingPartners: [],
        name: '',
        model: '',
        brand: '',
        manufactureYear: null,
        purchaseDate: null,
    });
    // Partner
    const [partners, setPartners] = useState([]);
    const [legalDocuments, setLegalDocuments] = useState([]);
    const [photos, setPhotos] = useState([]);
    const [guestPhotos, setGuestPhotos] = useState([]);
    const fetchAsset = async (assetId) => {
        try {
            setAssetLoading(true);
            const response = await axios.get(`${API_URL}/admin/mb/assets/${assetId}`);
            const assetData = response.data
            setAssetData({
                sharingPartners: assetData?.sharingPartners || [],
                partnerId: assetData.partnerId || '',
                name: assetData?.name || '',
                model: assetData?.model || '',
                brand: assetData?.brand || '',
                manufactureYear: assetData?.manufactureYear || null,
                purchaseDate: assetData?.purchaseDate ? moment(assetData?.purchaseDate).format('YYYY-MM-DD') : null,
            });
            setLegalDocuments(assetData?.legalDocuments || []);
            setPhotos(assetData?.photos || []);
            setGuestPhotos(assetData?.guestPhotos || []);
            setAssetLoading(false);
        } catch (error) {
            console.log(error.response.data);
            setAssetLoading(false);
            console.error('Failed to fetch assets:', error);
        }
    };

    // Fetch partners
    useEffect(() => {
        fetchPartnersExtract(() => { }).then((partners) => {
            setAssetLoading(true);
            const autoCompleteOptions = partners.map((partner) => ({
                label: partner.title,
                id: partner.id,
            }));
            setPartners(autoCompleteOptions);
            setAssetLoading(false);
        });
    }, []);

    // Fetch asset
    useEffect(() => {
        if (assetId) {
            fetchAsset(assetId);
        }
    }, [assetId]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'name') {
            setAssetData((prev) => ({ ...prev, [name]: value.replace(/\s/g, '').toUpperCase() }));
        } else {
            if (name === 'manufactureYear') {
                setAssetData((prev) => ({ ...prev, [name]: value ? value.replace(/\D/g, '') : null }));
            } else if (name === 'purchaseDate') {
                setAssetData((prev) => ({ ...prev, [name]: value ? moment(value).format('YYYY-MM-DD') : null }));
            } else {
                setAssetData((prev) => ({ ...prev, [name]: value }));
            }
        }
    };

    const handleUpdate = async () => {
        try {
            setIsLoading(true);
            const data = assetData;
            if (assetData.purchaseDate === null) {
                delete data.purchaseDate;
            }
            if (assetData.manufactureYear === null) {
                delete data.manufactureYear;
            }
            const response = await axios.patch(`${API_URL}/admin/mb/assets/${assetId}`,
                {
                    ...data,
                    legalDocuments,
                    photos,
                    guestPhotos
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
            if (response.status == 200) {
                handleClose(); // Close the dialog on successful POST
                setIsLoading(false);
                location.reload();
            } else {
                console.error('Failed to submit Asset:', response.statusText);
            }
        } catch (error) {
            console.error('Error submitting Asset:', error);
        }
    };

    const handleCreate = async () => {
        try {
            setIsLoading(true);
            if (assetData.partnerId === null || assetData.partnerId === '' || assetData.name === null || assetData.name === '') {
                setIsLoading(false);
                return;
            }
            const data = assetData;
            if (assetData.purchaseDate === null) {
                delete data.purchaseDate;
            }
            if (assetData.manufactureYear === null) {
                delete data.manufactureYear;
            }
            const response = await fetch(`${API_URL}/admin/mb/assets`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...data,
                    legalDocuments,
                    photos,
                    guestPhotos
                }),
            });
            if (response.ok) {
                handleClose(); // Close the dialog on successful POST
                setIsLoading(false);
                location.reload();
            } else {
                console.error('Failed to submit Asset:', response.statusText);
            }
        } catch (error) {
            console.error('Error submitting Asset:', error);
        }
    };

    // Reset the form
    const handleClose = () => {
        onClose();
        setAssetData({});
    };

    return (
        <div>
            {assetLoading ? (
                <Dialog open={isOpen} onClose={handleClose}>
                    <DialogTitle>Loading...</DialogTitle>
                    <DialogContent></DialogContent>
                </Dialog>
            ) : (
                <Modal
                    open={isOpen} onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        className='absolute flex flex-col py-6 p-8 bg-white rounded-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[800px] h-full max-h-[90vh] overflow-y-scroll
            '
                    >
                        {/* AutoComplete here */}
                        <div className="grid grid-cols-2 gap-x-3 place-items-start">

                            <label className="text-gray-700 font-semibold whitespace-nowrap">
                                Partner
                            </label>
                            <Autocomplete
                                id="combo-box-demo"
                                options={partners}
                                sx={{ width: 340 }}
                                renderInput={(params) => <TextField {...params} label="Partner" />}
                                onChange={(_, newValue) => {
                                    handleInputChange({
                                        target: {
                                            name: 'partnerId',
                                            value: newValue?.id || null,
                                        },
                                    });
                                    setAssetData((prev) => {
                                        const newSharingPartners = new Set(prev.sharingPartners);
                                        if (newValue?.id) {
                                            newSharingPartners.add(newValue.id);
                                        }
                                        return { ...prev, sharingPartners: Array.from(newSharingPartners) };
                                    });
                                }}
                                value={partners.find(partner => partner.id === assetData.partnerId)}
                            />
                            <label className="text-gray-700 font-semibold whitespace-nowrap">
                                Name
                            </label>
                            <TextField
                                margin="dense"
                                name="name"
                                placeholder="Asset name"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={assetData.name}
                                onChange={handleInputChange}
                            />
                            <label className="text-gray-700 font-semibold whitespace-nowrap">
                                Brand
                            </label>
                            <TextField
                                margin="dense"
                                name="brand"
                                placeholder="Asset Brand"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={assetData.brand}
                                onChange={handleInputChange}
                            />
                            <label className="text-gray-700 font-semibold whitespace-nowrap">
                                Model
                            </label>
                            <TextField
                                margin="dense"
                                name="model"
                                placeholder="Asset Model"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={assetData.model}
                                onChange={handleInputChange}
                            />
                            <label className="text-gray-700 font-semibold whitespace-nowrap">
                                Manufacture year
                            </label>
                            <TextField
                                margin="dense"
                                name="manufactureYear"
                                placeholder="Asset Manufacture Year"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={assetData.manufactureYear}
                                onChange={handleInputChange}
                            />
                            <label className="text-gray-700 font-semibold whitespace-nowrap">
                                Purchase date
                            </label>
                            <input
                                className='w-full'
                                name="purchaseDate"
                                placeholder="Asset Purchase Date"
                                value={assetData.purchaseDate}
                                onChange={handleInputChange}
                                type="date"
                            />
                            <label className="text-gray-700 font-semibold whitespace-nowrap">
                                Add Sharing Partner
                            </label>
                            <Autocomplete
                                id="combo-box-demo"
                                options={partners}
                                sx={{ width: 340 }}
                                renderInput={(params) => <TextField {...params} label="Partner" />}
                                onChange={(_, newValue) => {
                                    setAssetData((prev) => {
                                        const newSharingPartners = new Set(prev.sharingPartners);
                                        if (newValue?.id) {
                                            newSharingPartners.add(newValue.id);
                                        }
                                        return { ...prev, sharingPartners: Array.from(newSharingPartners) };
                                    });
                                }}
                            />
                        </div>
                        <div className="col-span-2 grid grid-cols-2 gap-1 py-4">
                            <span className='bg-gray-400 w-full h-px my-2 col-span-full'></span>
                            <h1 className='col-span-full text-lg font-semibold'>Sharing Partners</h1>
                            {assetData.sharingPartners?.sort((partnerId) => partnerId === assetData.partnerId ? -1 : 1).map((partnerId) => {
                                return (
                                    <div key={partnerId} className='flex items-center justify-between gap-2 col-span-full my-2 border-b'>
                                        <p>{partners.find(partner => partner.id === partnerId)?.label}</p>
                                        {partnerId !== assetData.partnerId &&
                                            <button
                                                className='bg-red-400 hover:bg-red-500 text-white px-2 py-1 rounded-lg'
                                                onClick={() => {
                                                    setAssetData((prev) => {
                                                        const newSharingPartners = prev.sharingPartners.filter(id => id !== partnerId);
                                                        return { ...prev, sharingPartners: newSharingPartners };
                                                    });
                                                }}>Remove</button>}
                                    </div>
                                )
                            })}
                        </div>
                        <div className="col-span-2 grid grid-cols-1 gap-1 py-4">
                            <span className='bg-gray-400 w-full h-px my-2'></span>
                            <LegalDocumentsUploader
                                path='partners/assets/'
                                documents={legalDocuments} setDocuments={setLegalDocuments} />
                            <span className='bg-gray-400 w-full h-px my-2'></span>
                            <PhotosUploader photos={photos} setPhotos={setPhotos} />
                            <span className='bg-gray-400 w-full h-px my-2'></span>
                            <GuestPhotosUploader guestPhotos={guestPhotos} setGuestPhotos={setGuestPhotos} />
                        </div>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button
                                disabled={isLoading}
                                onClick={assetId ? handleUpdate : handleCreate}
                            >
                                {assetId ? "Save" : "Create"}
                            </Button>
                        </DialogActions>
                    </Box>
                </Modal>
            )}
        </div>
    );
};

AddOrEditAsset.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    asset: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};
