import React, { useEffect, useState } from 'react';
import {
    Container,
    TextField,
    Button,
    Box,
    Typography,
    Snackbar,
    Alert,
    Autocomplete,
    Avatar
} from '@mui/material';
import axios from 'axios';
import { API_URL } from 'src/utils/constants';
import PropTypes from 'prop-types';
// Dummy ENUM options for Autocomplete fields
const mainTypeOptions = ['Stay', 'Activity',];
const subTypeOptions = ['Amenities', 'option2'];
const subType2Options = ['option1', 'option2', 'option3'];

const AddFlyIcon = () => {
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [iconName, setIconName] = useState('');
    const [iconMainType, setIconMainType] = useState('');
    const [iconSubType, setIconSubType] = useState('');
    const [iconSubType2, setIconSubType2] = useState('');
    const [iconId, setIcondId] = useState('')
    const [imageUploaded, setImageUploaded] = useState(false)
    useEffect(() => {
        let id = ''
        id += iconMainType ? `${iconMainType}-` : ''
        id += iconSubType ? `${iconSubType}--` : ''
        id += iconSubType2 ? `${iconSubType2}---` : ''
        id += iconName
        setIcondId(id)

    }, [iconMainType, iconSubType, iconSubType2, iconName])
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        if (!imageUploaded || imageUploaded === null) {
            setErrorMessage("Please upload icon")
            setLoading(false)
            return
        }

        try {
            const response = await axios.post(`${API_URL}/admin/mb/flyIcons`, {
                id: iconId,
            });
            console.log(response);
            setSuccessMessage('Icon added successfully!');
            setIconName('');
            setIconMainType('');
            setIconSubType('');
            setIconSubType2('');
            location.reload()
        } catch (error) {
            setErrorMessage('Failed to add icon. Please try again.');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleImageUpload = (data) => {
        setImageUploaded(data)
        if (data !== null)
            setSuccessMessage("File uploaded successfully.")
    }


    return (
        <Container>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Add FlyIcon
                </Typography>

                <div className='flex gap-2'>
                    <Autocomplete
                        disabled={imageUploaded || imageUploaded !== null}
                        id="main-type-select"
                        options={mainTypeOptions.map(item => item.toLocaleLowerCase())}
                        sx={{ width: 340, margin: '16px 0' }}
                        renderInput={(params) => <TextField {...params} label="Main Type" />}
                        onChange={(_, newValue) => setIconMainType(newValue)}
                        value={iconMainType}
                    />

                    <Autocomplete
                        disabled={imageUploaded || imageUploaded !== null}
                        id="sub-type-select"
                        options={subTypeOptions.map(item => item.toLocaleLowerCase())}
                        sx={{ width: 340, margin: '16px 0' }}
                        renderInput={(params) => <TextField {...params} label="Sub Type" />}
                        onChange={(_, newValue) => setIconSubType(newValue)}
                        value={iconSubType}
                    />

                    <Autocomplete
                        disabled={imageUploaded || imageUploaded !== null}
                        id="sub-type2-select"
                        options={subType2Options.map(item => item.toLocaleLowerCase())}
                        sx={{ width: 340, margin: '16px 0' }}
                        renderInput={(params) => <TextField {...params} label="Sub Type 2" />}
                        onChange={(_, newValue) => setIconSubType2(newValue)}
                        value={iconSubType2}
                    />
                    <TextField
                        disabled={imageUploaded || imageUploaded !== null}
                        fullWidth
                        margin="normal"
                        label="Icon Name"
                        name="iconName"
                        value={iconName}
                        onChange={(e) => setIconName(e.target.value.replace(/\s+/g, '-'))}
                        required
                    />
                </div>
                <SingleImageUploader disabled={iconName.length <= 0} path='assets/flyIcons' fileName={iconId} onUploadSuccess={handleImageUpload} />

                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={loading}
                    sx={{ mt: 2 }}
                >
                    {loading ? 'Submitting...' : 'Add Icon'}
                </Button>
            </Box>

            {/* Success Snackbar */}
            {successMessage && (
                <Snackbar open autoHideDuration={6000} onClose={() => setSuccessMessage('')}>
                    <Alert onClose={() => setSuccessMessage('')} severity="success">
                        {successMessage}
                    </Alert>
                </Snackbar>
            )}

            {/* Error Snackbar */}
            {errorMessage && (
                <Snackbar open autoHideDuration={6000} onClose={() => setErrorMessage('')}>
                    <Alert onClose={() => setErrorMessage('')} severity="error">
                        {errorMessage}
                    </Alert>
                </Snackbar>
            )}
        </Container>
    );
};



const SingleImageUploader = ({ path, fileName, onUploadSuccess, disabled }) => {
    const [uploadedDocument, setUploadedDocument] = useState({});
    const [previewUrl, setPreviewUrl] = useState(''); // for displaying image preview

    const handleImageUpload = async (e, path) => {
        console.log(fileName)
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            if (!file) {
                onUploadSuccess(null);
                return
            }

            // const fileExtenstion = file.name.split('.').pop()
            const filePath = path?.endsWith('/') ? path : path + '/'
            const body = {
                file: {
                    // fileName: `${filePath}${fileName}.${fileExtenstion}`,
                    fileName: `${filePath}${fileName}`,
                    fileType: file.type,
                },
                type: 'aws',
                bucket: 'cdn-passprt'
            };

            axios
                .post(`${API_URL}/util/signed-url`, body)
                .then((response) => {
                    const url = response.data?.data?.url;
                    console.log(url)
                    console.log(response.data)
                    if (url?.length > 0) {
                        setUploadedDocument({
                            url,
                            file: file,
                            fileName: file.name,
                            fileType: file.type,
                        });
                        setPreviewUrl(URL.createObjectURL(file)); // Set image preview URL
                    } else {
                        setUploadedDocument({
                            url: null,
                            file: null,
                        });
                    }
                })
                .catch((error) => console.log(error));
        } else {
            onUploadSuccess(null);

        }
    };

    useEffect(() => {
        if (uploadedDocument?.url && uploadedDocument?.file) {
            const body = new FormData();
            const api = uploadedDocument.url;
            const file = uploadedDocument.file;
            body.append(uploadedDocument.fileName, file);
            axios
                .put(api, file, {
                    headers: {
                        'Content-Type': file.type,
                    },
                })
                .then((res) => {
                    if (res.status === 200) {
                        const s3Url = res.request.responseURL.split('?Content-Type=')[0];
                        onUploadSuccess({
                            url: s3Url,
                            fileType: file.type,
                            fileName: file.name,
                        });
                    }
                })
                .catch((error) => console.log('Upload: ', error));

            setUploadedDocument({});
        } else {
            onUploadSuccess(null)
        }
    }, [uploadedDocument]);

    return (
        <Box>
            {/* Image Preview */}
            {previewUrl && (
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Avatar
                        alt="Uploaded Image"
                        src={previewUrl}
                        sx={{ width: 150, height: 150 }}
                        variant="rounded"
                    />
                </Box>
            )}

            {/* Upload Input */}
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <input
                    disabled={disabled}
                    accept="image/*"
                    multiple={false}
                    type="file"
                    id="image-upload"
                    style={{ display: 'none' }} // Hide the default input
                    onChange={(e) => handleImageUpload(e, path)}
                />
                <label htmlFor="image-upload">
                    <Button
                        disabled={disabled}
                        variant="contained"
                        color="primary"
                        component="span"
                    >
                        Upload Image
                    </Button>
                </label>
            </Box>

            {/* Instructions or Filename */}
            {uploadedDocument.fileName && (
                <Box sx={{ mt: 2, textAlign: 'center' }}>
                    <Typography variant="body2" color="textSecondary">
                        {uploadedDocument.fileName}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

SingleImageUploader.propTypes = {
    path: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    fileName: PropTypes.string.isRequired,
    onUploadSuccess: PropTypes.func.isRequired,
};

export default AddFlyIcon;
