import axios from "axios";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { API_URL } from "src/utils/constants";
import { formatCurrency } from "src/utils/formatCurrency";
// import StayPricingSelector from "./StayPricingSelector";
// import ActivityPricingSelector from "./ActivityPricingSelector";
// import RangeDateSelector from "./RangeDateSelector";
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { useParams } from 'react-router-dom';
import StayPricingSelector from "src/components/Activities/StayPricingSelector";
import ActivityPricingSelector from "src/components/Activities/ActivityPricingSelector";
import RangeDateSelector from "src/components/Activities/RangeDateSelector";



export default function EditBookingVariants() {
    const { bookingId } = useParams();
    const [booking, setBooking] = useState({})
    const [activity, setActivity] = useState({});
    const [loading, setLoading] = useState(true);
    const [selectedVariant, setSelectedVariant] = useState({})
    const [selectedPricing, setSelectedPricing] = useState({})
    const [isPricingLoading, setIsPricingLoading] = useState(true);
    const [inventory, setInventory] = useState([]);
    // const [nights, setNights] = useState(0);
    const [finalUpdates, setFinalUpdates] = useState({})


    useEffect(() => {
        const fetchBooking = async () => {
            try {
                // Replace 'your_api_endpoint' with the actual API endpoint.
                const response = await fetch(
                    `${API_URL}/admin/mb/bookings/${bookingId}`
                );
                const data = await response.json();
                setBooking(data);
                getActivity(data.activititySlug);
            } catch (error) {
                console.error('Failed to fetch bookings:', error);
            }
        };


        fetchBooking();
    }, []);

    useEffect(() => {
        if (Object.keys(selectedVariant).length > 0 && Object.keys(selectedPricing).length > 0) {
            const updates = {
                variantId: selectedVariant.id,
                variantTitle: selectedVariant.title,
                pricing: [selectedPricing.pricing[0]],
            }
            setFinalUpdates(updates)
        }
    }, [selectedVariant, selectedPricing])
    console.log(finalUpdates)
    // console.log(nights)
    const getActivity = async (slug) => {
        try {
            setLoading(true);
            const response = await axios.get(`${API_URL}/activity/${slug}`);
            setActivity(response.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Failed to fetch bookings:', error);
        }
    }

    const { availableDates, variants, type: activityType, } = activity

    const handleDateRangeChange = async (date) => {
        setIsPricingLoading(true);
        const { startDate, endDate } = date;
        const _inventoryData = await axios.get(
            `${API_URL}/activity/${activity?.id}/inventory?startDate=${startDate}&endDate=${endDate}`
        );
        const _inventory = [];
        for (const _inv of _inventoryData.data) {
            const _vPricings = variants.find((v) => v.id == _inv.variantId)?.pricing;
            if (!_vPricings) return;
            _inventory.push({
                ..._inv,
                pricing: _inv.pricing.map((p) => {
                    const _vP = _vPricings.find((v) => v.id == p.pricingId);
                    return { ..._vP, ...p };
                }),
            });
        }
        // const ondDay = 1000 * 60 * 60 * 24;

        // setNights((new Date(endDate) - new Date(startDate)) / ondDay);
        setIsPricingLoading(false);
        setInventory(_inventory);
    };



    if (loading) return <h1>Loading...</h1>
    const {
        activityTitle,
        orderId,
        guest,
        date,
        id,
        // activititySlug,
        // partner,
        // isPartnerAllocated,
        // isPartnerAssignedTo,
        // partnerAssignedTo,
        // amountFinal,
        // amountDiscount,
        // amountAdvance,
        // amountTotal,
        // status,
        // internalStatus,
        // amountPartnerSettlementRecommended,
        // amountPartnerSettlementActual,
        // amountPartnerSettlementLeft,
        // amountPartnerAdvancePaid,
        // amountPartnerToBeCollected,
        // amountPartnerCollected,
        // // startDate,
        // endDate,
        // startDateLabel,
        // endDateLabel,
        // notesInternal,
        // notesPartner,
        // notesGuest,
        // updateHistory,
        // bookingsSummary,
        // leadDate,
        // assignedAssets,
        // partnerMargin
    } = booking;
    return (
        <div className="bg-white p-6 rounded-lg">

            <div className='flex flex-col justify-start gap-8'>
                <h1 className="text-xl font-semibold">Change activity variant</h1>
                <div className='flex justify-between items-center rounded-lg shadow-md p-4'>
                    <div className='flex items-center justify-start gap-8 w-full'>
                        <LabelWithContent label={"Type"} content={activityType} />
                        <LabelWithContent label={"Title"} content={activityTitle} />
                        <LabelWithContent label={"Order"} content={`#${orderId}`} />
                        <LabelWithContent label={"Booking"} content={`#${id}`} />
                        <LabelWithContent label={"Guest"} content={guest?.name} />
                        <LabelWithContent label={"Date"} content={date} />
                        <div className='ml-auto flex items-center gap-8'>
                            <div className='flex items-center gap-4'>
                                <LabelWithContent label={"Phone"} content={guest?.phone} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-6 ">
                {
                    activityType === 'stay' ?
                        <Section title="Select Date" className="max-w-fit">
                            <RangeDateSelector availableDates={availableDates} onSelect={handleDateRangeChange} />
                        </Section> :
                        <SingleDateSelector availableDates={availableDates} onActivityDateSelected={handleDateRangeChange} />
                }

                <Section title="Select Variant" >
                    <div className="flex flex-col gap-2">
                        {variants.map(variant => {
                            const { id } = variant
                            return (
                                <button
                                    key={id}
                                    onClick={() => { setSelectedVariant(variant) }}
                                    className={`flex flex-col gap-2 border rounded-lg p-2 px-3 min-w-fit
                                    ${id === selectedVariant?.id ? 'bg-gray-200 border border-red-400' : ''}`}>
                                    <ActivityVariantCard
                                        variant={variant}
                                        price={inventory.find((i) => i.variantId == id)?.variantPrice}
                                        priceLoading={isPricingLoading} />
                                </button>
                            )
                        })}
                    </div>
                </Section>
                {!isPricingLoading && Object.keys(selectedVariant).length > 0 && inventory.length > 0 && (
                    <Section title="Select Pricing" >
                        <PricingSelector
                            onPriceChange={(pricingData) => { setSelectedPricing(pricingData) }}
                            activityType={activityType}
                            variantTitle={selectedVariant?.title}
                            inventory={inventory.find((v) => v.variantId == selectedVariant?.id)} />
                    </Section>
                )}

            </div>
        </div>
    );
}


const PricingSelector = ({ activityType, variantTitle, inventory, onPriceChange }) => {
    const [selectedPricing, setSelectedPricing] = useState({
        pricing: [],
        totalPrice: 0,
    });
    const [newPricing, setNewPricing] = useState(inventory?.pricing)
    const [newAvailability, setNewAvailability] = useState(inventory?.availability)

    useEffect(() => {
        if (inventory?.availability && inventory?.pricing) {
            setNewAvailability(inventory?.availability)
            setNewPricing(inventory?.pricing)
        }
    }, [inventory])

    useEffect(() => {
        onPriceChange(selectedPricing);
    }, [selectedPricing])
    return (
        <>
            {activityType === 'stay' ? (
                <div className="px-4 space-y-2">
                    <p className="ml-2">{variantTitle}</p>
                    <StayPricingSelector
                        pricing={newPricing}
                        setSelectedPricing={setSelectedPricing}
                        maxRoomsCount={newAvailability}
                    />
                </div>
            ) : (
                <div className="px-4 space-y-2">
                    <p className="ml-2">{variantTitle}</p>
                    <ActivityPricingSelector
                        pricing={newPricing}
                        setSelectedPricing={setSelectedPricing}
                    />
                </div>
            )}
        </>)

}

PricingSelector.propTypes = {
    activityType: PropTypes.string.isRequired,
    variantTitle: PropTypes.string.isRequired,
    inventory: PropTypes.object.isRequired,
    onPriceChange: PropTypes.func.isRequired
}

const ActivityVariantCard = ({ variant, price, priceLoading }) => {
    const { title, desc, addonPricing } = variant
    return (
        <div className="flex flex-col items-start gap-1">
            <h1 className="font-semibold">{title}</h1>
            <p>{desc}</p>
            {priceLoading ? <p>...</p> : <p className="font-semibold">{formatCurrency(price)}</p>}
            <p>{addonPricing ?? addonPricing}</p>
        </div>
    )
}

ActivityVariantCard.propTypes = {
    variant: PropTypes.object.isRequired,
    price: PropTypes.string.isRequired,
    priceLoading: PropTypes.bool.isRequired,
};

const SingleDateSelector = ({ availableDates, onActivityDateSelected }) => {
    const [selectedDate, setSelectedDate] = useState(availableDates[0].date)
    useEffect(() => {
        if (availableDates && availableDates.length > 0) {
            const date = availableDates[0].date
            onActivityDateSelected({ startDate: date, endDate: date })
        }
    }, [availableDates])
    return (
        <Section title="Select Date" noBorder>
            <div className="flex gap-2 overflow-x-scroll">
                {availableDates.map(item => {
                    const { date, dateLabel, subTitle, dayLabel } = item
                    return (
                        <button
                            key={date}
                            onClick={() => {
                                setSelectedDate(date)
                                onActivityDateSelected({ startDate: date, endDate: date });
                            }}
                            className={`border rounded-lg p-4 min-w-24 aspect-square
                        ${date === selectedDate ? 'bg-gray-200 border border-red-400' : ''}`}>
                            <p>{dayLabel}</p>
                            <p>{dateLabel}</p>
                            <p>{subTitle}</p>
                        </button>
                    )
                })}
            </div>
        </Section>
    )

}

SingleDateSelector.propTypes = {
    availableDates: PropTypes.array.isRequired,
    onActivityDateSelected: PropTypes.func.isRequired,
};



const Section = ({ children, title, subtitle, noBorder }) => (
    <div className={`py-4 ${noBorder ? '' : 'border-t border-[rgb(221_221_221)]'} w-full`}>
        <h1 className={`text-[22px] ${subtitle ? 'mb-2' : 'mb-8'} font-semibold`}>
            {title}
        </h1>
        {subtitle && <h2 className="mb-8 leading-[20px]">{subtitle}</h2>}
        {children}
    </div>
);

Section.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    noBorder: PropTypes.bool,
    children: PropTypes.node,
};


function LabelWithContent({ label, content, editable, onClick, className }) {
    return (
        <div className='flex flex-col '>
            <h6 className='text-gray-400 text-xs'>{label}</h6>
            <div className={`flex items-center justify-start ${editable ? 'gap-1' : 'gap-0'}`}>
                <h2 className={className}>{content}</h2>
                {editable && <CreateOutlinedIcon className='cursor-pointer' onClick={onClick} fontSize='35px' />}
            </div>

        </div>
    );
}

LabelWithContent.propTypes = {
    label: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    editable: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string,
}
