/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Paper, TextField, Typography, Box, Button } from '@mui/material';
import axios from 'axios';
import { API_URL } from 'src/utils/constants';

const activityId = 'aiumcyi';

const VariantAvailability = ({ data = [], setData }) => {
  const handlePriceChange = (variantIndex, dateIndex, priceIndex, newValue) => {
    const newData = [...data];
    newData[variantIndex].inventory[dateIndex].pricing[priceIndex].price =
      newValue;
    setData(newData);
  };

  const handleInventoryChange = (variantIndex, dateIndex, newValue) => {
    const newData = [...data];
    newData[variantIndex].inventory[dateIndex].availability = newValue;
    setData(newData);
  };
  useEffect(() => {
    document.title = "Inventory";
  }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h6" gutterBottom>
        Variant Availability
      </Typography>
      <Box display="flex">
        <Box display="flex" flexDirection="column" m={2}>
          {data.length && (
            <>
              <Typography>Date</Typography>
              {data[0].inventory.map((avail, dateIndex) => (
                <Box
                  key={dateIndex}
                  p={1}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  style={{ height: '140px' }}
                  justifyContent="center"
                >
                  {(() => {
                    const date = new Date(avail.date);
                    // Format the date as needed, for example: "Mon, Jun 23"
                    const options = {
                      weekday: 'short',
                      month: 'short',
                      day: 'numeric',
                    };
                    const formattedDate = date.toLocaleDateString(
                      'en-US',
                      options
                    );

                    return <Typography>{formattedDate}</Typography>;
                  })()}
                </Box>
              ))}
            </>
          )}
        </Box>
        {data.map((variant, variantIndex) => (
          <Box
            key={variant.variantId}
            display="flex"
            flexDirection="column"
            m={2}
          >
            <Typography variant="subtitle1">{variant.variantTitle}</Typography>
            {variant.inventory.map((avail, dateIndex) => (
              <>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  style={{ height: '140px' }} // Adjust this height as needed to maintain layout
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    // justifyContent="space-between" // This ensures that the first element is at the top, the last at the bottom, and any in-between are evenly spaced.
                    height="100%" // Adjust this based on your UI needs
                  >
                    <TextField
                      style={{ paddingBottom: '20px' }}
                      size="small"
                      key={dateIndex}
                      value={avail.availability ?? 0}
                      onChange={(e) =>
                        handleInventoryChange(
                          variantIndex,
                          dateIndex,
                          e.target.value.replace(/\D/g, '')
                        )
                      }
                      type="text"
                    />
                    {avail.pricing.map((price, priceIndex) => (
                      <TextField
                        size="small"
                        variant="standard"
                        key={priceIndex}
                        value={price.price}
                        onChange={(e) =>
                          handlePriceChange(
                            variantIndex,
                            dateIndex,
                            priceIndex,
                            e.target.value.replace(/\D/g, '')
                          )
                        }
                        type="text"
                      />
                    ))}
                  </Box>
                </Box>
              </>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const UpdateInventoryPage = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/inventory/mb/activity/${activityId}`
        );
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
  }, []);

  const handleSave = async () => {
    const response = await axios.post(
      `${API_URL}/inventory/mb/activity/a100001`,
      data
    );
  };

  return (
    <>
      {' '}
      <VariantAvailability data={data} setData={setData} />
      <Button
        onClick={handleSave}
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
      >
        Save
      </Button>
    </>
  );
};

export default UpdateInventoryPage;
