import { statusColors } from 'src/utils/statuses/colors';

export const partnerLedgerLabels = {
    "cancelled-refund-pending": "Cancelled",
    "confirmed": "Pending",
    "completed": "Pending",
    "partner-settlement-acknowledgement-pending": "Pending",
    "partner-settlement-pending": "Pending",

    "completed-settlement-done": "Settlement Done",
    "cancelled-refund-processed": "Cancelled",
    "cancelled-refund-adjusted": "Cancelled",
    "cancelled-no-refund": "Cancelled",
    "cancelled-partial-refund": "Cancelled",

    "processing": "Pending",
    "confirmation-pending": "Pending",
    "confirmed-and-partner-action-pending": "Pending",
};

export const partnerLedgerStatusColors = {
    // Green
    "completed-settlement-done": statusColors.done,
    // Orange
    "confirmed": statusColors.processing,
    "processing": statusColors.processing,
    "completed": statusColors.processing,
    "confirmation-pending": statusColors.processing,
    "confirmed-and-partner-action-pending": statusColors.processing,
    "partner-settlement-acknowledgement-pending": statusColors.processing,
    "partner-settlement-pending": statusColors.processing,

    // Red
    "cancelled-refund-pending": statusColors.action,
    "cancelled-refund-processed": statusColors.action,
    "cancelled-refund-adjusted": statusColors.action,
    "cancelled-no-refund": statusColors.action,
    "cancelled-partial-refund": statusColors.action,
};